<template>
  <div class="group-imput">
    <div class="group-imput-item" data-test-select-group>
      <ItemSelector
        v-if="listGroup.length"
        :input-config="{
          label: $t('LateralMenu.PeopleMenu.groupCombo'),
          showAvatar: true,
        }"
        :menu-config="{
          align: 'right',
          attach: false,
          showTabs: false,
        }"
        :menu-options="listGroup"
        :fill-width="true"
        :multiple="true"
        :show-elements="true"
        :local-search="true"
        @update:item="setValueGroups($event)"
      ></ItemSelector>
    </div>

    <div class="group-imput-item" data-test-select-roles>
      <ItemSelector
        :input-config="{
          label: $t('LateralMenu.PeopleMenu.rolesCombo'),
          showAvatar: false,
        }"
        :menu-config="{
          align: 'right',
          attach: false,
          showTabs: false,
        }"
        :menu-options="listRoles"
        :fill-width="true"
        :multiple="true"
        :show-elements="true"
        @update:item="setValueRoles($event)"
        @focus:input="getOptionsRoles()"
        @search:item="getOptionsRoles($event)"
      ></ItemSelector>
    </div>

    <div class="group-imput-item" data-test-select-group-permissions>
      <ItemSelector
        :input-config="{
          label: $t('LateralMenu.PeopleMenu.groupPermissionsCombo'),
          showAvatar: false,
        }"
        :menu-config="{
          align: 'right',
          attach: false,
          showTabs: false,
        }"
        :menu-options="listGroupPermissions"
        :fill-width="true"
        :multiple="true"
        :show-elements="true"
        @update:item="setValueGroupsPermissions($event)"
        @focus:input="getOptionsGroupPermissions()"
        @search:item="getOptionsGroupPermissions($event)"
      ></ItemSelector>
    </div>

    <div class="group-imput-item" data-test-select-status>
      <ItemSelector
        :input-config="{
          label: $t('LateralMenu.PeopleMenu.statusCombo'),
          showAvatar: false,
        }"
        :menu-config="{
          align: 'right',
          attach: false,
          showTabs: false,
        }"
        :menu-options="listStatus"
        :fill-width="true"
        :multiple="true"
        :show-elements="true"
        @update:item="setValueStatus($event)"
        @focus:input="getOptionsStatus()"
      ></ItemSelector>
    </div>
  </div>
</template>

<script>
import { getPeopleRoles, advancedSearch } from '@/services/people/index.js'
import { getGroupsTree } from '@/services/groups/index.js'
import { getPermissionGroups } from '@/services/permission-groups/index.js'
import { getPeopleAttributes } from '@/services/people-attributes/index.js'
import { mapActions, mapGetters } from 'vuex'
import { alertErrorBus } from '@/helpers/alerts'

export default {
  name: 'MenuPeoplePage',

  data() {
    return {
      alert: {
        model: false,
        statusAlert: '',
        messageAlert: '',
      },
      listGroup: [],
      listRoles: [],
      listGroupPermissions: [],
      listPeopleAttributes: [],
      listStatus: [],
      form: {
        selectlistGroup: [],
        selectListRoles: [],
        selectListGroupPermissions: [],
        selectListPeopleAttributes: [],
        selectListStatus: [],
      },
      params: {
        name: null,
        offset: 0,
        limit: 20,
      },
    }
  },
  watch: {
    getReload() {
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
    getfilterPeopleParamsByName(value) {
      this.params.name = value
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
    getfilterPeopleParamsByLimit(value) {
      this.params.limit = value
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
    getfilterPeopleParamsByOffset(value) {
      this.params.offset = value
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
  },
  computed: {
    ...mapGetters([
      'getReload',
      'getfilterPeopleParamsByName',
      'getfilterPeopleParamsByLimit',
      'getfilterPeopleParamsByOffset',
    ]),
  },
  methods: {
    ...mapActions(['setFilterPeople']),
    fetchGroups() {
      getGroupsTree().then(({ data }) => {
        this.listGroup = [
          {
            value: 'groups',
            label: this.$t('LateralMenu.PeopleMenu.groupCombo'),
            type: 'treeview',
            items: data.filter(e => e.id),
          },
        ]
      })
    },
    async getOptionsRoles(event) {
      const label = event == '' ? null : event
      await getPeopleRoles({ limit: 500, offset: 0, label }).then(res => {
        for (let i = 0; i < this.listRoles.length; i++) {
          this.listRoles.pop()
        }

        const list = res.data.map(e => {
          return {
            ...e,
            id: e.id,
            label: e.label,
          }
        })

        this.listRoles.push({
          value: 'roles',
          label: this.$t('LateralMenu.PeopleMenu.rolesCombo'),
          type: 'listview',
          items: list,
        })
      })
    },
    async getOptionsGroupPermissions(event) {
      const label = event == '' ? null : event
      await getPermissionGroups({ limit: 99, offset: 0, label: label }).then(
        res => {
          for (let i = 0; i < this.listGroupPermissions.length; i++) {
            this.listGroupPermissions.pop()
          }

          const list = res.data.map(e => {
            return {
              ...e,
              id: e.id,
              label: e.label,
            }
          })

          this.listGroupPermissions.push({
            value: 'permissions',
            label: this.$t('LateralMenu.PeopleMenu.groupPermissionsCombo'),
            type: 'listview',
            items: list,
          })
        }
      )
    },
    async getOptionsPeopleAttributes(search = null) {
      await getPeopleAttributes({ limit: 99, offset: 0, label: search }).then(
        res => {
          for (let i = 0; i < this.listPeopleAttributes.length; i++) {
            this.listPeopleAttributes.pop()
          }

          const list = res.data.map(e => {
            return {
              ...e,
              id: e.id,
              label: e.label,
              multi: e.multi,
              private: e.private,
              readonly: e.readonly,
              type: e.type,
              options: e.options,
            }
          })

          this.listPeopleAttributes.push({
            value: 'attributes',
            label: this.$t('LateralMenu.PeopleMenu.attributesCombo'),
            type: 'listview',
            items: list,
          })
        }
      )
    },
    getOptionsStatus() {
      for (let i = 0; i < this.listStatus.length; i++) {
        this.listStatus.pop()
      }
      const list = [
        { id: 1, label: this.$t('PeoplePage.confirmedLabel') },
        { id: 2, label: this.$t('PeoplePage.guestLabel') },
        { id: 3, label: this.$t('PeoplePage.inactiveLabel') },
      ]

      this.listStatus.push({
        value: 'status',
        label: this.$t('LateralMenu.PeopleMenu.statusCombo'),
        type: 'listview',
        items: list,
      })
    },
    setValueGroups(value) {
      this.form.selectlistGroup = value
      this.lateralMenuSearch()
    },
    setValueGroupsPermissions(value) {
      this.form.selectListGroupPermissions = value
      this.lateralMenuSearch()
    },
    setValuePeopleAttributes(value) {
      this.form.selectListPeopleAttributes = value
      this.lateralMenuSearch()
    },
    setValueRoles(value) {
      this.form.selectListRoles = value
      this.lateralMenuSearch()
    },
    setValueStatus(value) {
      for (let i = 0; i < 3; i++) {
        this.form.selectListStatus.pop()
      }

      for (let i = 0; i < value.length; i++) {
        if (value[i].label === this.$t('PeoplePage.confirmedLabel')) {
          this.form.selectListStatus.push('confirmed')
        } else if (value[i].label === this.$t('PeoplePage.guestLabel')) {
          this.form.selectListStatus.push('invited')
        } else if (value[i].label === this.$t('PeoplePage.inactiveLabel')) {
          this.form.selectListStatus.push('inactive')
        }
      }

      this.lateralMenuSearch()
    },
    async lateralMenuSearch() {
      const statusList = []
      for (let i = 0; i < this.form.selectListStatus.length; i++) {
        statusList.push(this.form.selectListStatus[i])
      }

      const groupList = []
      for (let i = 0; i < this.form.selectlistGroup.length; i++) {
        const { id } = this.form.selectlistGroup[i]
        id && groupList.push(id)
      }

      const rolesList = []
      for (let i = 0; i < this.form.selectListRoles.length; i++) {
        rolesList.push(this.form.selectListRoles[i].id)
      }

      const permissionsGroupList = []
      for (let i = 0; i < this.form.selectListGroupPermissions.length; i++) {
        permissionsGroupList.push(this.form.selectListGroupPermissions[i].id)
      }

      const attributesList = []
      for (let i = 0; i < this.form.selectListPeopleAttributes.length; i++) {
        attributesList.push(this.form.selectListPeopleAttributes[i].id)
      }

      const data = {
        sortBy: 'name',
        sortOrder: 'asc',
        limit: this.params.limit,
        offset: this.params.offset,
        name: this.params.name,
        statuses: statusList,
        groupIDs: groupList,
        roleIDs: rolesList,
        permissionGroupIDs: permissionsGroupList,
        attributes: null,
        showInactive: true,
      }
      try {
        const { data: res, headers } = await advancedSearch(data)
        this.setFilterPeople({
          items: res,
          payload: {
            sortBy: 'name',
            sortOrder: 'asc',
            name: this.params.name,
            statuses: statusList,
            groupIDs: groupList,
            roleIDs: rolesList,
            permissionGroupIDs: permissionsGroupList,
            showInactive: true,
          },
          countItems: parseInt(headers['x-count']) || 0,
          isDirtySearch: [
            this.params.name,
            statusList,
            groupList,
            rolesList,
            permissionsGroupList,
          ].some(el => !!el?.length),
        })
      } catch (error) {
        alertErrorBus(this.$t('LateralMenu.PeopleMenu.errorSearch'), error)
      }
    },
    cleanFilterPeople() {
      this.listGroup = []
      this.listRoles = []
      this.listGroupPermissions = []
      this.listPeopleAttributes = []
      this.listStatus = []
      this.form = {
        selectlistGroup: [],
        selectListRoles: [],
        selectListGroupPermissions: [],
        selectListPeopleAttributes: [],
        selectListStatus: [],
      }
      this.lateralMenuSearch()
    },
  },
  beforeMount() {
    this.fetchGroups()
  },
  mounted() {
    this.lateralMenuSearch()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
