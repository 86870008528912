import { render, staticRenderFns } from "./TemplatesMenu.vue?vue&type=template&id=8993b32a&scoped=true&"
import script from "./TemplatesMenu.vue?vue&type=script&lang=js&"
export * from "./TemplatesMenu.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=8993b32a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8993b32a",
  null
  
)

export default component.exports