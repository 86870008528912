<template>
  <Modal
    data-test-confirm-move-modal
    ref="modal"
    :title="$t('confirmMoveMetadataGroup.title')"
    :confirmButton="$t('confirmMoveMetadataGroup.submit')"
    :hasButtons="true"
    width="540px"
    @cancel="canceled"
    @close="canceled"
    @confirmed="confirmed"
  >
    <template v-slot:no-tab>
      <div class="modal-content">
        <i18n path="confirmMoveMetadataGroup.content.text" tag="p">
          <template #metadata>
            <strong v-text="metadata.label" />
          </template>
          <template #section>
            <strong v-text="metadata.group.label" />
          </template>
        </i18n>

        <i18n
          path="confirmMoveMetadataGroup.remind.text"
          tag="p"
          class="remind"
        >
          <template #strong>
            <strong v-text="$t('confirmMoveMetadataGroup.remind.strong')" />
          </template>
        </i18n>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ConfirmMoveModal',
  data() {
    return {
      metadata: {
        label: '',
        group: {
          label: '',
        },
      },
    }
  },

  methods: {
    handleOpenDialog(payload) {
      this.resetModal(payload)

      if (this.$refs.modal) {
        this.$refs.modal.handleDialog(true)
      }
    },

    resetModal(
      payload = {
        label: '',
        group: {
          label: '',
        },
      }
    ) {
      this.metadata = payload
    },

    canceled() {
      this.resetModal()
    },

    confirmed(resetDialogCallback) {
      this.$emit('confirmed', this.metadata)
      resetDialogCallback()

      this.resetModal()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
