var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"default-field-form",attrs:{"data-test-default-field-form":""}},[_c('AlertBar',{ref:"AlertBar"}),_c('div',[_c('v-row',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-label input",attrs:{"type":"button"}}):_c('CustomInput',{ref:"label",staticClass:"input",attrs:{"currentValue":_vm.defaultField.name.label,"mandatory":true,"viewMode":_vm.editMode,"readonly":_vm.editMode,"label":this.$t('viewDefaultFields.details.fieldName')}})],1),_c('v-row',{staticClass:"input select-type"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-label",attrs:{"type":"button"}}):_c('ItemSelector',{attrs:{"inputConfig":{
            label: this.$t('viewDefaultFields.details.type'),
            showAvatar: false,
            showIcon: true,
            closeOnSelect: true,
            subtleMode: _vm.editMode,
          },"menuConfig":{
            attach: true,
            showTabs: false,
          },"menuOptions":_vm._TypeOptions,"currentValue":{
            data: this.currentType,
            origin: 'type',
          },"readonly":_vm.editMode,"persistent":false,"watchCurrent":true,"fillWidth":true,"multiple":false,"showElements":true,"local-search":true}})],1)],1),_c('v-divider'),_c('div',{attrs:{"id":"view-modal-default-fields"}},[_c('v-row',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-toggle input-bottom",attrs:{"type":"button"}}):_c('div',{staticClass:"input-bottom"},[_vm._v(" "+_vm._s(_vm.$t('viewDefaultFields.details.requiredField'))+" "),_c('v-switch',{staticClass:"switch-toggle",attrs:{"data-test-required-field":"","inset":"","disabled":!_vm._canEditListDefaultFields},on:{"click":_vm.handleToggle},model:{value:(_vm.requiredField),callback:function ($$v) {_vm.requiredField=$$v},expression:"requiredField"}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }