<template>
  <div class="cut" data-test-html-message>
    <span
      data-test-html-message-content
      dark
      v-html="data.content"
      class="content"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
