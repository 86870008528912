var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"view-modal-custom-fields","data-test-view-modal-custom-fields":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"item-selector"},[_c('ItemSelector',{attrs:{"inputConfig":{
            label: _vm.$t('viewCustomFields.details.activeCycles'),
            showAvatar: true,
            subtleMode: true,
          },"menuConfig":{
            attach: true,
            showTabs: false,
          },"menuOptions":_vm._CycleOptions,"currentValue":{
            data: this.form.cycles || [],
            origin: 'cycles',
          },"readonly":_vm.readonly,"fillWidth":true,"multiple":true,"showElements":true,"local-search":true}})],1)]),_c('v-col',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('viewCustomFields.details.requiredField'))+" "),_c('v-switch',{staticClass:"switch-toggle",attrs:{"inset":"","disabled":_vm.readonly},model:{value:(_vm.form.required),callback:function ($$v) {_vm.$set(_vm.form, "required", $$v)},expression:"form.required"}})],1)])],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('viewCustomFields.details.private'))+" "),_c('v-switch',{staticClass:"switch-toggle",attrs:{"inset":"","disabled":_vm.readonly},model:{value:(_vm.form.private),callback:function ($$v) {_vm.$set(_vm.form, "private", $$v)},expression:"form.private"}})],1)]),_c('v-row',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('viewCustomFields.details.hierarchy'))+" "),_c('v-switch',{staticClass:"switch-toggle",attrs:{"inset":"","disabled":_vm.readonly},model:{value:(_vm.showItemSelectorHierarchy),callback:function ($$v) {_vm.showItemSelectorHierarchy=$$v},expression:"showItemSelectorHierarchy"}})],1),(_vm.showItemSelectorHierarchy)?_c('div',{staticClass:"item-selector"},[_c('itemSelector',{ref:"entityType",attrs:{"data-test-selector-entity-type":"","inputConfig":{
              label: _vm.$t('viewCustomFields.details.hierarchy'),
              showAvatar: false,
              subtleMode: true,
            },"menuConfig":{
              attach: false,
              showTabs: false,
            },"menuOptions":_vm._listEntityType,"currentValue":{
              data: this.form.hierarchy || [],
              origin: 'hierarchy',
            },"readonly":_vm.readonly,"fillWidth":true,"multiple":true,"showElements":true,"local-search":true}})],1):_vm._e()])],1),_c('v-col',[_c('v-row',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('viewCustomFields.details.readonly'))+" "),_c('v-switch',{staticClass:"switch-toggle",attrs:{"inset":"","disabled":_vm.readonly},model:{value:(_vm.form.readonly),callback:function ($$v) {_vm.$set(_vm.form, "readonly", $$v)},expression:"form.readonly"}})],1)]),_c('v-row',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('viewCustomFields.details.autoAddNewCycles'))+" "),_c('v-switch',{staticClass:"switch-toggle",attrs:{"inset":"","disabled":_vm.readonly},model:{value:(_vm.form.autoAddNewCycles),callback:function ($$v) {_vm.$set(_vm.form, "autoAddNewCycles", $$v)},expression:"form.autoAddNewCycles"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }