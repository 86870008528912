import api from '@/services/Http'
import { extractSignal } from '@/helpers/api'

export async function updatePermissions(permissions) {
  return (await api()).put(`/management/permissions`, permissions)
}

export async function getPermissions(data) {
  const signal = extractSignal(data)

  return (await api()).get(`/management/permissions/${data.type}`, {
    params: data.params,
    ...signal,
  })
}
