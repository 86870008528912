<template>
  <div data-test-entity-item id="entityitem" class="d-flex">
    <v-list-item
      v-ripple="false"
      class="px-0"
      dense
      :disabled="!clickable"
      @click="submit()"
    >
      <v-list-item-avatar class="ma-0">
        <AvatarComponent
          :id="id"
          :src="data.image"
          :text="data.title"
          :size="'22'"
        />
      </v-list-item-avatar>

      <v-list-item-content class="py-0">
        <v-list-item-title data-test-data-table-entity-title class="item-title">
          {{ data.title }}
        </v-list-item-title>

        <v-list-item-subtitle v-if="!!data.subtitle" class="item-subtitle">
          <v-tooltip bottom z-index="1000">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ data.subtitle }}</span>
            </template>
            <slot name="tooltip"></slot>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },

    clickable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      this.$emit('submit', this.id)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
