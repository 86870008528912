<template>
  <v-hover v-slot="{ hover }">
    <div class="tree-node" :class="borderColor" data-test-treenode-card>
      <div class="tree-node-body">
        <TextIcon
          class="text-icon"
          :label="node.name"
          :image="node.image"
          :overflow="30"
          :onClick="() => handleAction('edit')"
        />
        <TextStatus class="text-status" :active="!node.inactive" />

        <MoreOptions
          v-show="hover"
          class="dots"
          :options="actions"
          @handleAction="handleAction"
        />
      </div>

      <div class="tree-node-body-mt">
        <ChipGroup
          class="text-icon"
          :data="countPeople"
          data-test-people-chip
        />
        <ChipGroup :data="countSubGroups" data-test-group-chip />
      </div>

      <div class="tree-node-body-mt" data-test-updated-node>
        <div class="last-change">
          <p>{{ $t('TreeNode.updatedAt') }}</p>
          <p>{{ updatedAt }}</p>
        </div>
      </div>

      <span
        v-if="canCreateGroup"
        v-show="hover"
        class="add-new-node"
        data-test-add-new-node
        @click="handleAction('add')"
      >
        + {{ $t('GroupsPage.treeNode.addSubGroup') }}
      </span>

      <v-btn
        v-if="hasChildren"
        class="open-node-position"
        ref="collapse"
        data-test-toggle-children
        @click="toggleChildren(node, collapsed)"
      >
        <i
          class="fi-br-arrow-small-down"
          :class="{ rotate180: !collapsed }"
        ></i>
      </v-btn>
      <span
        v-if="hasChildren"
        class="children-count open-node-position"
        data-test-children-count
      >
        {{ childrenLength }}
      </span>

      <v-tooltip left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="expand-parent"
            v-if="hasHiddenParents"
            v-bind="attrs"
            v-on="on"
            @click="handleAction('expand-parent')"
          >
            <i class="fi-rr-apps-sort"></i>
          </v-btn>
        </template>
        <div>{{ $t('TreeNode.expandHierarchy') }}</div>
      </v-tooltip>
    </div>
  </v-hover>
</template>
<script>
import moment from 'moment'
import TextIcon from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextIcon/TextIcon.vue'
import TextStatus from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextStatus/TextStatus.vue'

export default {
  name: 'TreeNode',
  components: {
    TextIcon,
    TextStatus,
  },
  inject: ['toggleChildren'],
  props: {
    node: {
      type: Object,
    },
    collapsed: {
      type: Boolean,
    },
    config: {
      type: Object,
    },
    actions: {
      type: Array,
    },
    canCreateGroup: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleAction(act) {
      this.$emit('handleAction', act, this.node)
    },
  },
  computed: {
    hasHiddenParents() {
      return this.node?.hasHiddenParents
    },
    updatedAt() {
      return moment(this.node.updatedAt).format('DD/MM/YYYY')
    },
    countPeople() {
      return [this.node.peopleCount + ' Pessoas']
    },
    countSubGroups() {
      return [this.node.childrenCount + ' Sub-grupos']
    },
    name() {
      return { label: this.node.name, image: this.node.image }
    },
    childrenLength() {
      return this.node?.childrenCount
    },
    hasChildren() {
      return this.node?.childrenCount
    },
    borderColor() {
      if (!this.node.inactive) {
        return 'green--border'
      }
      return 'neutral6--border'
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
