<template>
  <div id="list-custom-fields" v-if="_canAccessMetadataList">
    <DeleteCustomFieldsModal
      @CustomFieldsDeleted="handleMetadata(data)"
      @display-alert="$emit('display-alert', $event)"
      :CustomFields="customFieldsDelete"
      ref="modal"
    ></DeleteCustomFieldsModal>

    <DetailSectionAccordion
      type="people"
      :title="this.$t('PerformanceTab.ListCustomFields.dataTableTitle')"
      :opened="true"
    >
      <DataTable
        id="paper-table-body"
        :title="$t('PerformanceTab.ListCustomFields.dataTableTitle')"
        class="data-table-margin-resized"
        :headers="_headers"
        :loading="loading"
        :skeleton-lines="data.limit"
        :rolesReadonly="false"
        :hideHeader="true"
        :hideOption="true"
        :attach="true"
        :itemsPerPage="data.limit"
        :limitPagination="limitPagination"
        :items="items"
        :downLeftButton="_addCustomFieldButton"
        @search="searchCustomFields($event)"
        @get-data="searchCustomFields($event)"
        @remove="deleteCustomFields($event)"
        @edit="editCustomFields($event)"
      >
        <template v-slot:hover-modal-content="{ item }">
          <ViewModalCustomFields :item="item" />
        </template>
      </DataTable>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import { getMetadata } from '@/services/metadata/index.js'
import DataTable from '@/components/DataTable/DataTable.vue'
import DeleteCustomFieldsModal from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListCustomFields/DeleteCustomFieldsModal/DeleteCustomFieldsModal.vue'
import ViewModalCustomFields from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListCustomFields/ViewModalCustomFields/ViewModalCustomFields.vue'

import {
  management_performance_metadata,
  management_performance_metadata_delete,
  management_performance_metadata_create,
} from '@/helpers/ability'

export default {
  name: 'ListCustomFields',
  components: {
    DataTable,
    DeleteCustomFieldsModal,
    ViewModalCustomFields,
  },
  data() {
    return {
      data: {
        limit: 5,
        offset: 0,
        label: null,
        matchHierarchy: false,
        entityType: null,
        entityClass: null,
      },
      items: [],
      loading: true,
      limitPagination: 0,
      searchText: '',
      customFieldsDelete: null,
    }
  },
  computed: {
    _canAccessMetadataList() {
      return this.$can('access', management_performance_metadata)
    },
    _canAccessCreateMetadata() {
      return this.$can('access', management_performance_metadata_create)
    },
    _canAccessDeleteMetadata() {
      return this.$can('access', management_performance_metadata_delete)
    },
    _headers() {
      return [
        {
          text: this.$t('PerformanceTab.ListCustomFields.header.name'),
          value: 'name',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.ListCustomFields.header.section'),
          value: 'section',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.ListCustomFields.header.type'),
          value: 'type',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.ListCustomFields.header.detail'),
          value: 'detail',
          type: 'hover-modal',
        },
        {
          text: this.$t('PerformanceTab.ListCustomFields.header.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
    _addCustomFieldButton() {
      return this._canAccessCreateMetadata
        ? {
            click: this.addCustomFields,
            text: this.$t('PerformanceTab.ListCustomFields.addCustomFields'),
            icon: 'mdi-plus',
          }
        : null
    },
  },
  methods: {
    searchCustomFields(event) {
      this.items = []
      this.loadingTable = true
      this.data.label = event.name
      if (this.data.label == '') {
        this.data.label = null
      }
      this.data.offset = event.offset
      this.data.limit = event.limit

      this.handleMetadata(this.data)
    },
    addCustomFields() {
      this.$emit('addCustomFields')
    },
    editCustomFields($event) {
      this.$emit('editCustomFields', $event)
    },

    deleteCustomFields(customFieldsSelected) {
      this.customFieldsDelete = customFieldsSelected
      this.$refs.modal.handleDialog()
    },
    getTraductionKeyValueType(type) {
      return this.$t(`PerformanceTab.ListCustomFields.type.${type}`)
    },
    handleHierarchy(hierarchy) {
      if (!hierarchy) return

      const hierarchyMap = hierarchy.map(item => {
        const title = this.getTitle(item.entityType, item.entityClass)
        const id = title.replace(/\s/g, '')
        return {
          id: id,
          label: title,
          entityType: item.entityType,
          entityClass: item.entityClass,
        }
      })

      return hierarchyMap
    },
    handleCycles(cycles) {
      if (!cycles) return

      const cyclesMap = cycles?.map(e => {
        return {
          id: e.id,
          label: e.title,
        }
      })

      return cyclesMap
    },
    getTitle(entityType, entityClass) {
      const etype = entityType ? entityType : 'emptyType'
      const eclass = entityClass ? entityClass : 'emptyClass'
      const translateKey = `${etype}-${eclass}`
      return this.words(translateKey, 'EntityTypes')
    },
    words(complement, customDefaultKey = null) {
      const defaultKey = customDefaultKey ?? `EntityTypes`
      return this.$t(`${defaultKey}.${complement}`)
    },
    async handleMetadata(params) {
      try {
        this.items = []
        const { data: res, headers } = await getMetadata(params)
        this.loading = res.length > 0 ? true : false
        for (const element of res) {
          this.menuTable = ['edit']

          if (this._canAccessDeleteMetadata) {
            this.menuTable.push('remove')
          }

          this.items.push({
            actions: this.menuTable,
            id: element.id,
            name: {
              label: element.label,
              status: '',
            },
            section: {
              label: element.group ? element.group.label : '--',
              status: '',
            },
            type: {
              label: this.getTraductionKeyValueType(element.type),
              status: '',
            },
            detail: {
              label: this.$t(`PerformanceTab.ListCustomFields.header.detail`),
              icon: 'fi-rr-interrogation',
              title: this.$t(
                `PerformanceTab.ListCustomFields.titleDetailHover`
              ),
            },
            private: element.private,
            readonly: element.readonly,
            multi: element.multi,
            hierarchy: this.handleHierarchy(element.hierarchy),
            cycles: this.handleCycles(element.cycles),
            required: element.required,
            autoAddNewCycles: element.autoAddNewCycles,
          })
        }
        this.limitPagination = parseInt(headers['x-count']) || 0
      } catch (error) {
        this.$emit('display-alert', {
          statusAlert: 'error',
          messageAlert: error.message,
        })
      }
    },
  },
  beforeMount() {
    this.data.entityType = this.$route.params.entityType
    this.data.entityClass = this.$route.params.entityClass
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
