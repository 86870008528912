<template>
  <div id="floating-alert" data-test-id="floating-alert" v-if="visible">
    <div
      class="floating-alert d-flex align-center justify-space-between"
      :class="`floating-alert-${status}`"
    >
      <span class="floating-alert-text">{{ message }}</span>
      <i
        @click="hide"
        class="mr-2 floating-alert-close fi fi-rr-cross-circle"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatingAlert',
  props: {
    status: {
      type: String,
      default: 'success',
    },
    message: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    hide() {
      this.visible = false
    },
    hideAfterDelay() {
      setTimeout(() => {
        this.hide()
      }, this.duration)
    },
    initializeComponent() {
      this.visible = this.show
      if (this.visible) {
        this.hideAfterDelay()
      }
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue
      if (this.visible) {
        this.hideAfterDelay()
      }
    },
  },
  mounted() {
    this.initializeComponent()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
