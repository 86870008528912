<template>
  <div data-test-attribute-input class="attribute-input-item">
    <v-img
      height="1rem"
      width="1rem"
      max-width="1rem"
      class="attribute-input-item-flag"
      :src="`${CDN_BASE_URL}/images/Demographic/${item.flag}.svg`"
      :alt="item.flag"
    ></v-img>

    <v-skeleton-loader
      v-if="loading"
      type="button"
      class="loading-custom-input"
    ></v-skeleton-loader>
    <CustomInput
      v-else
      ref="input"
      :current-value="item.value"
      v-bind="_customInputProps"
      v-on="$listeners"
    ></CustomInput>
  </div>
</template>

<script>
export default {
  name: 'AttributeInput',
  props: {
    item: {
      type: Object,
      required: true,
    },

    customInputProps: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _customInputProps() {
      return {
        type: 'text',
        ...this.customInputProps,
      }
    },
  },

  methods: {
    validate() {
      return this.$refs.input.validate()
    },
    resetValidate() {
      this.$refs.input.resetValidate()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
