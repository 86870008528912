<template>
  <v-dialog v-if="target" v-model="show" class="show">
    <v-card>
      <v-card-title class="dialog-header">
        <span class="ml-5">{{ $t('TreeView.deleteModal.title') }}</span>
        <i class="fi-rr-cross-small" @click="toggleModal(false)"></i>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="p-3 mt-2">
        <p class="mb-0">
          {{ $t('TreeView.deleteModal.confirmAsk') }}
        </p>
        <p class="mt-0">
          {{ target.id }} <span class="font-bold">{{ target.name }}</span>
        </p>
        <p>
          <span class="text-warning"
            >{{ $t('TreeView.deleteModal.remember') }}:</span
          >
          {{ $t('TreeView.deleteModal.warning-2') }}
          {{ $t('TreeView.deleteModal.warning-1') }}
          {{ $t('TreeView.deleteModal.warning-3') }}
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <button @click="toggleModal(false)" class="btn ml-auto">
          {{ $t('GroupsPage.treeModal.cancel') }}
        </button>
        <button
          @click="submit"
          data-test-delete-confirm-bttn
          class="btn btn-primary"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="white"
            size="22"
            width="3"
          />
          <span v-else>
            {{ $t('TreeView.deleteModal.submit') }}
          </span>
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { removeGroup } from '@/services/groups'
export default {
  name: 'TreeModal',
  props: {
    callback: {
      type: Function,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
    }
  },
  methods: {
    toggleModal(state) {
      this.show = state
    },
    async submit() {
      if (this.loading) return false
      this.loading = true

      const removed = await removeGroup(this.target)

      this.loading = false
      this.show = false
      if (this.callback) this.callback(removed)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
