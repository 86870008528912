<template>
  <div id="view-modal-custom-fields" data-test-view-modal-custom-fields>
    <v-row>
      <v-col>
        <div class="item-selector">
          <ItemSelector
            :inputConfig="{
              label: $t('viewCustomFields.details.activeCycles'),
              showAvatar: true,
              subtleMode: true,
            }"
            :menuConfig="{
              attach: true,
              showTabs: false,
            }"
            :menuOptions="_CycleOptions"
            :currentValue="{
              data: this.form.cycles || [],
              origin: 'cycles',
            }"
            :readonly="readonly"
            :fillWidth="true"
            :multiple="true"
            :showElements="true"
            :local-search="true"
          />
        </div>
      </v-col>

      <v-col>
        <div>
          {{ $t('viewCustomFields.details.requiredField') }}
          <v-switch
            v-model="form.required"
            class="switch-toggle"
            inset
            :disabled="readonly"
          ></v-switch>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <div>
            {{ $t('viewCustomFields.details.private') }}
            <v-switch
              v-model="form.private"
              class="switch-toggle"
              inset
              :disabled="readonly"
            ></v-switch>
          </div>
        </v-row>

        <v-row>
          <div>
            {{ $t('viewCustomFields.details.hierarchy') }}
            <v-switch
              v-model="showItemSelectorHierarchy"
              class="switch-toggle"
              inset
              :disabled="readonly"
            ></v-switch>
          </div>

          <div v-if="showItemSelectorHierarchy" class="item-selector">
            <itemSelector
              data-test-selector-entity-type
              ref="entityType"
              :inputConfig="{
                label: $t('viewCustomFields.details.hierarchy'),
                showAvatar: false,
                subtleMode: true,
              }"
              :menuConfig="{
                attach: false,
                showTabs: false,
              }"
              :menuOptions="_listEntityType"
              :currentValue="{
                data: this.form.hierarchy || [],
                origin: 'hierarchy',
              }"
              :readonly="readonly"
              :fillWidth="true"
              :multiple="true"
              :showElements="true"
              :local-search="true"
            />
          </div>
        </v-row>
      </v-col>

      <v-col>
        <v-row>
          <div>
            {{ $t('viewCustomFields.details.readonly') }}
            <v-switch
              v-model="form.readonly"
              class="switch-toggle"
              inset
              :disabled="readonly"
            ></v-switch>
          </div>
        </v-row>

        <v-row>
          <div>
            {{ $t('viewCustomFields.details.autoAddNewCycles') }}
            <v-switch
              v-model="form.autoAddNewCycles"
              class="switch-toggle"
              inset
              :disabled="readonly"
            ></v-switch>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ViewModalCustomFields',
  data() {
    return {
      readonly: true,
      form: {},
    }
  },
  computed: {
    showItemSelectorHierarchy() {
      if (this.form.hierarchy?.length > 0) {
        return true
      }
      return false
    },
    _listEntityType() {
      return [
        {
          value: 'hierarchy',
          type: 'listview',
          label: this.$t('viewCustomFields.details.hierarchy'),
          items: this.form.hierarchy || [],
        },
      ]
    },
    _CycleOptions() {
      return [
        {
          value: 'cycles',
          label: this.$t('viewCustomFields.details.activeCycles'),
          type: 'listview',
          items: this.form.cycles,
        },
      ]
    },
  },
  props: {
    item: {
      type: Object,
    },
  },
  watch: {
    item: {
      handler() {
        this.form = Object.assign({}, this.item)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
