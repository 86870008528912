<template>
  <div data-test-permissions-page-apply-permissions>
    <Modal
      data-test-modal-permissions-page-apply-permissions
      ref="modal"
      :title="$t('PermissionsPage.applyPermissions.title')"
      :confirmButton="$t('PermissionsPage.applyPermissions.submit')"
      :hasButtons="true"
      width="634px"
      @confirmed="confirmed"
      @cancel="$emit('cancel')"
    >
      <template v-slot:no-tab>
        <div class="container-content">
          <span class="sure-text">{{
            $t('PermissionsPage.applyPermissions.sureText')
          }}</span>
          <span class="text-remind-you"
            ><span class="c-pink">{{
              $t('PermissionsPage.applyPermissions.remembering')
            }}</span>
            {{ $t('PermissionsPage.applyPermissions.textRemindYou') }}
          </span>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'ApllyPermissions',

  methods: {
    confirmed() {
      this.$emit('saveChanges')
      this.handleOpenDialog()
    },

    handleOpenDialog() {
      this.$refs.modal.handleDialog()
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
