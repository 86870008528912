<template>
  <v-btn
    :data-test-action-button="action.action"
    class="btn"
    depressed
    small
    icon
    v-on="$listeners"
  >
    <i :class="action.icon" class="icon" aria-hidden="true" />
  </v-btn>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" scoped lang="scss"></style>
