<template>
  <div id="upload-img">
    <FloatingAlert
      v-if="MessageAlert"
      class="alert"
      status="error"
      :message="MessageAlert"
    ></FloatingAlert>

    <div class="container-add">
      <v-avatar
        class="image-profile-add image-upload mr-4"
        :style="getCursoPointer()"
        @click="selectIMG()"
      >
        <img v-if="image !== null && image !== ''" :src="image" z-index="99" />
        <div :class="getClassIMG()">
          <label
            :style="getCursoPointer()"
            class="control-label label-bordered"
            >{{ $t('PeoplePage.imageUploadLabel') }}</label
          >
          <input
            ref="inputIMG"
            type="file"
            class="input-img"
            accept=".jpg, .jpeg, .png, .svg"
            @input="handleInput"
          />
          <i class="fi fi-rr-upload"></i>
        </div>
      </v-avatar>
    </div>
  </div>
</template>

<script>
import FloatingAlert from '@/components/FloatingAlert/FloatingAlert.vue'
import { uploadIMG } from '@/services/resources'
export default {
  name: 'UploadIMGPage',
  components: {
    FloatingAlert,
  },
  props: {
    linkImage: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: this.linkImage,
      ImgURL: '',
      Alert: false,
      MessageAlert: '',
    }
  },
  watch: {
    linkImage: function (val) {
      if (val === '') {
        this.image = val
        this.ImgURL = ''
      }
    },
  },
  created() {
    this.image = this.linkImage
  },
  methods: {
    getCursoPointer() {
      if (!this.readonly) {
        return 'cursor: pointer'
      }

      return
    },
    getClassIMG() {
      if (this.image !== null && this.image !== '') {
        return 'overlay image-profile-add image-upload'
      } else {
        return 'image-profile-add image-upload'
      }
    },
    handleInput(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])

      reader.onload = () => this.upImageProfile(reader.result)
      reader.onerror = error => (this.MessageAlert = error)
    },
    selectIMG() {
      if (!this.readonly) {
        this.$refs.inputIMG.click()
      }
    },
    async upImageProfile(image) {
      const res = await uploadIMG({ image: image })

      if (res.status == 200 || res.status == 201) {
        this.image = res.data.image
        this.$emit('handleInput', this.image)
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
