var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LateralMenu',{scopedSlots:_vm._u([{key:"default",fn:function({ isMini }){return [(
        [
          'GroupPermissionsPage',
          'PeoplePage',
          'PerformancePage',
          'AuditLogsPage',
        ].includes(_vm.currentPage)
      )?_c('LateralMenuItem',{staticClass:"lateral-menu-item",attrs:{"title":_vm.$t('LateralMenu.filterTitle'),"is-mini":isMini},scopedSlots:_vm._u([{key:"title-icon",fn:function({ size }){return [_c('v-icon',{attrs:{"size":size}},[_vm._v("fi-sr-filter")])]}},{key:"header-action",fn:function(){return [_c('v-btn',{staticClass:"pa-1",attrs:{"plain":"","text":"","small":"","data-test-clean-entity-filter":""},on:{"click":function($event){return _vm.cleanFilter()}}},[_vm._v(_vm._s(_vm.$t('LateralMenu.actions.clean')))])]},proxy:true},{key:"default",fn:function(){return [_c('div',[(_vm.currentPage === 'GroupPermissionsPage')?_c('MenuPermissionsPage',{ref:"MenuPermissionsPage"}):_vm._e(),(_vm.currentPage === 'PeoplePage')?_c('MenuPeoplePage',{ref:"MenuPeoplePage"}):_vm._e(),(_vm.currentPage === 'PerformancePage')?_c('MenuPerformancePage',{ref:"MenuPerformancePage"}):_vm._e(),(_vm.currentPage === 'AuditLogsPage')?_c('MenuAuditLogPage',{ref:"MenuAuditLogPage"}):_vm._e()],1)]},proxy:true}],null,true)}):_vm._e(),_c('LateralMenuItem',{attrs:{"title":_vm.$t('LateralMenu.quickMenuTitle'),"is-mini":isMini},scopedSlots:_vm._u([{key:"title-icon",fn:function({ size }){return [_c('v-icon',{attrs:{"size":size}},[_vm._v("fi-rr-settings-sliders")])]}},{key:"default",fn:function(){return _vm._l((_vm._items),function(item,index){return _c('div',{key:index},[(_vm.$can('access', item.permissionKey))?_c('div',{staticClass:"btn-qck-menu",on:{"click":function($event){return item.action()}}},[_c('span',{domProps:{"textContent":_vm._s(item.value)}})]):_vm._e()])})},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }