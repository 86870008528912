<template>
  <Modal
    ref="confirmDefaultDialog"
    :title="$t('PerformanceTab.cycleForm.defaultDialog.title')"
    width="33.75rem"
    @cancel="$emit('submit', { action: 'cancel' })"
  >
    <template v-slot:no-tab>
      <div data-test-confirm-default-dialog>
        <div class="dialog-content">
          <div class="text-message">
            <div>
              {{ $t('PerformanceTab.cycleForm.defaultDialog.text1') }}
            </div>
            <br />
            <div>
              {{
                $t('PerformanceTab.cycleForm.defaultDialog.text2', {
                  cycle: cycleName,
                })
              }}
            </div>
          </div>
        </div>

        <div class="dialog-footer">
          <v-spacer />

          <v-btn
            class="text-none text-btn"
            data-test-cancel-default
            text
            @click="submit('cancel')"
          >
            {{ $t('PerformanceTab.cycleForm.defaultDialog.cancel') }}
          </v-btn>

          <v-btn
            class="text-none primary-btn"
            color="primary"
            data-test-confirm-default
            depressed
            @click="submit('change')"
          >
            {{ $t('PerformanceTab.cycleForm.defaultDialog.confirm') }}
          </v-btn>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  props: {
    cycleName: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleDialog() {
      this.$refs.confirmDefaultDialog.handleDialog()
    },

    submit(action) {
      this.$emit('submit', { action: action })
      this.handleDialog()
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
