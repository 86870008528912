export function formatToInsert(node) {
  const body = {
    name: node.name,
    image: node.image,
    costCenter: node.costCenter,
    inactive: node.inactive,
    parentGroupID: node.parentGroupID || null,
    subgroupIDs: node.subgroupIDs || [],
    people: node.people || [],
  }

  return body
}

export function formatToEdit(node) {
  const body = {
    name: node.name,
    image: node.image,
    costCenter: node.costCenter,
    inactive: node.inactive,
    parentGroupID: node.parentGroupID || null,
  }

  return { body, groupID: node.id }
}
