<template>
  <div id="change-order-cycle-modal">
    <Modal
      ref="changeOrderCycleModal"
      :confirm-button="$t('changeOrderCycleModal.confirmButton')"
      :has-buttons="true"
      :title="$t('changeOrderCycleModal.title')"
      width="33.75rem"
      :style="{ 'z-index': 1000 }"
      @confirmed="handleConfirm"
      @close="handleClose"
    >
      <template #no-tab>
        <div class="modal-content">
          <DataTable
            data-test-cycles-group-form-order
            :title="$t('changeOrderCycleModal.title')"
            :fixed-header="true"
            :hide-table-header="true"
            :hide-header="true"
            :hide-option="true"
            :hide-search="true"
            :roles-readonly="true"
            :enable-draggable="true"
            :can-drag="true"
            :items="_cyclesItems"
            :loading="loading"
            :skeleton-lines="3"
            :headers="_tableHeaders"
            :items-per-page="-1"
            :enable-pagination="false"
            @update:table-order="handleOrderCycles($event)"
          >
            <template #leftButton="{ position }">
              <v-tooltip bottom open-on-hover>
                <template #activator="{ on, attrs }">
                  <v-icon
                    data-test-definition-icon-tooltip
                    v-bind="attrs"
                    size="0.75rem"
                    class="tooltip-icon"
                    v-on="on"
                  >
                    fi-rr-info
                  </v-icon>
                </template>
                <div data-test-definition-tooltip>
                  {{ handleTextTooltip(position) }}
                </div>
              </v-tooltip>

              <div class="input-bottom">
                <span class="input-label">{{
                  $t('changeOrderCycleModal.expand')
                }}</span>
                <CustomSwitch
                  :value="_cyclesItems[position].expanded"
                  :data-test-btn-switch="_cyclesItems[position].period"
                  @input="handleToggle($event, position)"
                ></CustomSwitch>
              </div>
            </template>
          </DataTable>
        </div>
      </template>

      <template #footer-left>
        <v-btn
          data-test-modal-restore-btn
          class="button-cancel"
          text
          tabindex="1"
          @click="handleRestoreDefault"
        >
          <span v-text="$t('changeOrderCycleModal.restore')"></span>
        </v-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'ChangeOrderCycleModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    currentOrderCycles: {
      type: Array,
      required: true,
    },

    defaultOrderCycles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newOrderCycles: [],
      loading: false,
    }
  },
  computed: {
    _tableHeaders() {
      return [
        {
          text: '',
          value: 'name',
          type: 'text-status',
          naturalizeIndex: true,
        },
        {
          text: '',
          value: 'actions',
          type: 'action-buttons',
        },
      ]
    },
    _cyclesItems() {
      let items = this.newOrderCycles.length
        ? this.newOrderCycles
        : this.defaultOrderCycles

      const arrayCycles = items.map((item, index) => {
        return Object.assign({}, item, {
          position: index,
          name: {
            label: this.$t(`changeOrderCycleModal.label-${item.period}`),
          },
          actions: [''],
        })
      })

      return arrayCycles
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$refs.changeOrderCycleModal.handleDialog()
        this.newOrderCycles = structuredClone(this.currentOrderCycles)
      }
    },
    currentOrderCycles(newValue) {
      this.newOrderCycles = newValue
    },
  },
  mounted() {
    if (this.show) {
      this.$refs.changeOrderCycleModal.handleDialog()
    }
  },
  methods: {
    handleTextTooltip(position) {
      const cycleSelected = this._cyclesItems[position]
      if (cycleSelected.period === 'current') {
        return this.$t('changeOrderCycleModal.tooltipCurrent')
      }

      if (cycleSelected.period === 'future') {
        return this.$t('changeOrderCycleModal.tooltipFuture')
      }

      return this.$t('changeOrderCycleModal.tooltipPast')
    },
    handleToggle(event, position) {
      this.newOrderCycles[position].expanded = event
      const cycleSelected = this._cyclesItems[position]
      this.newOrderCycles[position].expanded = cycleSelected.expanded
    },
    handleOrderCycles(orderCycles) {
      const calcOrder = cycleGroups => {
        return cycleGroups
          .map((cycle, index) => `${index}_${cycle.position}`)
          .join(',')
      }

      const payloadPositions = calcOrder(orderCycles)
      const cycleGroupsPositions = calcOrder(this.newOrderCycles)

      if (payloadPositions === cycleGroupsPositions) {
        return
      }

      this.newOrderCycles = structuredClone(orderCycles)
    },
    handleRestoreDefault() {
      this.newOrderCycles = structuredClone(this.defaultOrderCycles)
    },
    handleConfirm() {
      this.$emit(
        'handleConfirm',
        this.$refs.changeOrderCycleModal,
        this.newOrderCycles
      )
    },
    handleClose() {
      this.$emit('handleClose', this.$refs.changeOrderCycleModal)
    },
  },
}
</script>

<style src="./style.scss" scoped lang="scss"></style>
