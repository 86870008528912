import i18n from '@/plugins/i18n'
import { formatAsString } from '@/helpers/strings/date'

import TextStatus from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextStatus/TextStatus.vue'
import TextIcon from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextIcon/TextIcon.vue'
import MoreActions from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/Actions/Actions.vue'

export const searchOptions = {
  searchable: true,
  path: ['name'],
  placeholder: '',
}

export const columns = (actions, handleAction) => [
  {
    key: 'name',
    label: i18n.t('GroupsPage.tableTree.columns.name'),
    component: 'TextIcon',
    schemas: [
      {
        name: 'label',
        mutation: row => row.name,
      },
      {
        name: 'image',
        mutation: row => row.image,
      },
      {
        name: 'overflow',
        mutation: () => 44,
      },
      {
        name: 'onClick',
        mutation: (row, i) =>
          i !== 0 ? () => handleAction('edit', row, i) : null,
      },
    ],
  },
  {
    key: 'childrenCount',
    label: i18n.t('GroupsPage.tableTree.columns.childrenCount'),
    component: 'ChipGroup',
    schemas: [
      {
        name: 'data',
        mutation: row => {
          const value = row?.childrenCount
          if (!value) return ['--']
          return [
            i18n.tc('GroupsPage.tableTree.columns.childrenCount-data', value),
          ]
        },
      },
    ],
  },
  {
    key: 'peopleCount',
    label: i18n.t('GroupsPage.tableTree.columns.peopleCount'),
    component: 'ChipGroup',
    schemas: [
      {
        name: 'data',
        mutation: row => {
          const value = row?.peopleCount
          if (!value) return ['--']

          return [
            i18n.tc('GroupsPage.tableTree.columns.peopleCount-data', value),
          ]
        },
      },
    ],
  },
  {
    key: 'status',
    label: i18n.t('GroupsPage.tableTree.columns.status'),
    component: 'TextStatus',
    schemas: [
      {
        name: 'active',
        mutation: row => !row.inactive,
      },
      {
        name: 'outlined',
        mutation: () => true,
      },
    ],
  },
  {
    key: 'updatedAt',
    label: i18n.t('GroupsPage.tableTree.columns.updatedAt'),
    component: 'date',
    transform: row => {
      const value = row?.updatedAt
      if (!value) return '--'
      return formatAsString(
        i18n.t('GroupsPage.tableTree.columns.updatedAt-format'),
        value,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        i18n.t('locale')
      )
    },
  },
  {
    key: 'actions',
    label: i18n.t('GroupsPage.tableTree.columns.actions'),
    component: 'MoreActions',
    schemas: [
      {
        name: 'options',
        mutation: row => (row.id ? actions(row) : []),
      },
    ],
    width: '50px',
  },
]

export const components = [
  {
    component: TextStatus,
    name: 'TextStatus',
  },
  {
    component: MoreActions,
    name: 'MoreActions',
  },
  {
    component: TextIcon,
    name: 'TextIcon',
  },
]

export const props = {
  searchOptions: searchOptions,
  components: components,
  columns: columns,
  hideNodesAfter: 3,
  tree: [],
}
