<template>
  <div class="metadata-group-form" data-test-metadata-group>
    <div
      v-if="loading"
      class="metadata-group-form-content loading"
      data-test-metadata-group-form="loading"
    >
      <v-skeleton-loader type="button"></v-skeleton-loader>

      <v-skeleton-loader type="image" class="table"></v-skeleton-loader>
    </div>

    <v-form
      v-else
      v-show="!newMetadataSide.show"
      data-test-metadata-group-form
      ref="form"
      class="metadata-group-form-content"
      v-model="formIsValid"
      lazy-validation
      v-on:submit.prevent
    >
      <CustomInput
        ref="label"
        data-test-metadata-group-form-label
        class="label-input"
        :currentValue="form.label"
        :mandatory="true"
        :showTextPresented="false"
        :viewMode="_EditMode"
        :readonly="_EditMode && !_canEditMetadata"
        :label="$t('addMetadataGroup.form.label')"
        @handleInput="handleInputLabel($event)"
      ></CustomInput>

      <DetailSectionAccordion
        type="people"
        :title="$t('addMetadataGroup.form.table.title')"
        :opened="true"
      >
        <DataTable
          data-test-metadata-group-form-order
          :title="$t('addMetadataGroup.form.table.placeholder')"
          :attach="false"
          :hideHeader="true"
          :hideOption="true"
          :rolesReadonly="_EditMode && !_canEditMetadata"
          :enableDraggable="true"
          :canDrag="!filter.label && _canAction"
          :hideAddElements="_SelectorHideAddElements"
          :loadingAddOptions="MetadatasApiController.loading"
          :addOptions="_SelectorMetadataOptions"
          :emptyPlaceholder="
            filter.label
              ? $t('addMetadataGroup.form.table.empty-filter')
              : $t('addMetadataGroup.form.table.empty')
          "
          :items="_FormMetadatasFiltered"
          :headers="tableHeaders"
          :itemsPerPage="-1"
          :enablePagination="false"
          :localAddSearch="true"
          :ItemSelectorProps="{
            inputConfig: {
              showAvatar: false,
              showIcon: true,
            },
            menuConfig: {
              showTabs: false,
              showFooter: true,
            },
            menuProps: {
              'offset-overflow': true,
            },
          }"
          @focus:input="handleSearch($event)"
          @search:item="handleSearch($event)"
          @add-data="handleAddMetadata($event)"
          @delete="handleDeleteMetadata($event)"
          @search="handleFilterMetadatas($event)"
          @update:table-order="handleOrderMetadata($event)"
        >
          <template v-slot:hover-modal-content="{ item }">
            <ViewModalCustomFields :item="item" />
          </template>

          <template v-slot:item-selector-footer="{ callbackCloseMenu }">
            <v-btn
              v-if="_canCreateMetadata"
              x-small
              text
              data-test-metadata-group-form-add-metadata
              class="add-metadata-action"
              @click="handleCreateMetadata(callbackCloseMenu)"
            >
              <v-icon size="1rem">fi-rr-plus-small</v-icon>
              <span
                class="label-add-metadata"
                v-text="'Adicionar novo campo personalizado'"
              />
            </v-btn>
          </template>
        </DataTable>
      </DetailSectionAccordion>
    </v-form>

    <template v-if="newMetadataSide.show">
      <SideQuickRegister
        :title="newMetadataSide.actionSelected.title"
        :footerButtons="newMetadataSide.footerButtons"
        :show="newMetadataSide.show"
        @handleAction="handleNewMetadataSideActions"
      >
        <CustomFieldForm
          ref="CustomFieldForm"
          :showAlert="showAlert"
          :enforcedCreationMode="true"
          @success="handleNewMetadataSideSuccess"
          @close="handleNewMetadataSideActions('close')"
        ></CustomFieldForm>
      </SideQuickRegister>
    </template>

    <ConfirmMoveModal
      ref="ConfirmMoveModal"
      @confirmed="handleAddMetadataConfirm"
    ></ConfirmMoveModal>
  </div>
</template>

<script>
import _ from 'lodash'
import DataTable from '@/components/DataTable/DataTable.vue'
import ViewModalCustomFields from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListCustomFields/ViewModalCustomFields/ViewModalCustomFields.vue'

import CustomFieldForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/CustomFieldForm/CustomFieldForm.vue'

import ConfirmMoveModal from './Partials/ConfirmMoveModal/ConfirmMoveModal.vue'

import {
  getMetadata,
  postMetadataGroups,
  getMetadataGroupsByID,
  putMetadataGroups,
} from '@/services/metadata'
import {
  handleAxiosSignalAbortController,
  handleApiRequestWithController,
} from '@/helpers/api'

import {
  ScrollIntoViewErroClass,
  ValidateCustomInputReturn,
} from '@/helpers/validations'

import {
  management_performance_metadata_create,
  management_performance_metadata_groups_edit,
} from '@/helpers/ability'

export default {
  name: 'MetadataGroupForm',
  components: {
    DataTable,
    ViewModalCustomFields,
    CustomFieldForm,
    ConfirmMoveModal,
  },
  props: {
    showAlert: {
      type: Function,
    },
  },
  data() {
    return {
      entityType: null,
      entityClass: null,

      loading: false,

      formIsValid: true,
      form: {
        label: '',
        metadatas: [],
      },

      filter: {
        label: '',
      },

      tableHeaders: [
        {
          text: this.$t('addMetadataGroup.form.table.header.name'),
          value: 'name',
          type: 'text-status',
          naturalizeIndex: true,
        },
        {
          text: this.$t('addMetadataGroup.form.table.header.type'),
          value: 'type-name',
          type: 'text-status',
        },
        {
          text: this.$t('addMetadataGroup.form.table.header.detail'),
          value: 'detail',
          type: 'hover-modal',
        },
        {
          text: this.$t('addMetadataGroup.form.table.header.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '1.75rem',
        },
      ],

      AxiosController: {
        SignalHistory: [],
      },

      MetadatasApiController: {
        label: null,
        limit: 500,
        offset: 0,
        loading: false,
        itens: [],
      },

      MetadataType: [
        {
          type: 'text',
          multi: false,
          typeIcon: 'fi-rr-text',
        },
        {
          type: 'date',
          multi: false,
          typeIcon: 'fi-rr-calendar',
        },
        {
          type: 'people',
          multi: false,
          typeIcon: 'mdi-account-group-outline',
        },
        {
          type: 'people',
          multi: true,
          typeIcon: 'mdi-account-group-outline',
        },
        {
          type: 'select',
          multi: false,
          typeIcon: 'fi-rr-list',
        },
        {
          type: 'select',
          multi: true,
          typeIcon: 'fi-rr-list',
        },
      ],

      newMetadataSide: {
        show: false,
        actionSelected: {
          confirmButton: this.$t('newCustomFields.confirmButton'),
          title: this.$t('newCustomFields.title'),
          action: 'addCustomFields',
        },
        footerButtons: [
          {
            name: this.$t('newCustomFields.cancelButton'),
            action: 'close',
            type: 'text',
          },
          {
            name: this.$t('newCustomFields.confirmButton'),
            action: 'submit',
            type: 'default',
          },
        ],
      },
    }
  },

  computed: {
    _canCreateMetadata() {
      return this.$can('access', management_performance_metadata_create)
    },

    _EditMode() {
      return this.isEditMode()
    },

    _canAction() {
      return !this._EditMode || (this._EditMode && this._canEditMetadata)
    },

    _canEditMetadata() {
      if (!this._EditMode) {
        return false
      }

      return this.$can('access', management_performance_metadata_groups_edit)
    },

    _canEditMode() {
      if (!this._EditMode) {
        return true
      }

      return !this.form.default
    },

    _FormMetadatasFiltered() {
      const filterTerm = this.filter.label?.toLowerCase()
      if (!filterTerm) {
        return this._FormMetadatasItems
      }

      return this._FormMetadatasItems.filter(item =>
        item.label?.toLowerCase().includes(filterTerm)
      )
    },
    _FormMetadatasItems() {
      const items = this.form.metadatas
      return this.calcMetadataGroupsItensToTable(items)
    },
    _SelectorMetadataItems() {
      const items = this.MetadatasApiController.itens
      return this.calcMetadataItensToOptions(items)
    },
    _SelectorMetadataOptions() {
      return [
        {
          value: 'metadatas',
          label: this.$t('addMetadataGroup.form.table.title'),
          type: 'listview',
          items: this._SelectorMetadataItems,
        },
      ]
    },

    _SelectorHideAddElements() {
      return this.form.metadatas.map(metadata => metadata.id)
    },
  },

  methods: {
    validate() {
      let valid = ValidateCustomInputReturn(this.$refs['label'].validate())

      if (!valid) {
        ScrollIntoViewErroClass(this.$el)
      }

      return valid
    },

    async submit() {
      if (!this.validate()) {
        return
      }

      if (this.isEditMode()) {
        await this.handleUpdateMetadataGroup()
        return
      }

      await this.handleNewMetadataGroup()
    },

    async handleUpdateMetadataGroup() {
      const payload = {
        label: this.form.label,
        metadatas: this.form.metadatas.map((metadata, index) => ({
          id: metadata.id,
          position: index,
        })),
      }

      await putMetadataGroups(this.form.id, payload)
        .then(() => {
          this.showAlert({
            statusAlert: 'success',
            title: this.$t('addMetadataGroup.alert.put.success'),
          })
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            title: this.$t('addMetadataGroup.alert.put.error'),
            messageAlert: error?.response?.data?.message,
          })
        })
    },

    async handleNewMetadataGroup() {
      const payload = {
        label: this.form.label,
        entityType: this.entityType,
        entityClass: this.entityClass,
        metadatas: this.form.metadatas.map((metadata, index) => ({
          id: metadata.id,
          position: index,
        })),
      }

      await postMetadataGroups(payload)
        .then(() => {
          this.showAlert({
            statusAlert: 'success',
            title: this.$t('addMetadataGroup.alert.post.success'),
          })
          this.$emit('close')
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            title: this.$t('addMetadataGroup.alert.post.error'),
            messageAlert: error?.response?.data?.message,
          })
        })
    },

    isEditMode() {
      return this.$route.params.id !== 'new'
    },

    setEntity(
      entityType = this.$route.params.entityType,
      entityClass = this.$route.params.entityClass
    ) {
      this.entityType = entityType
      this.entityClass = entityClass
    },

    getTraductionKeyValueType(type) {
      return this.$t(`PerformanceTab.ListCustomFields.type.${type}`)
    },
    getTraductionKeyValueTitle(entityType, entityClass) {
      let label = []

      if (entityType) {
        label.push(this.$t(`entityType.${entityType}`))
      }

      if (entityClass) {
        label.push(this.$t(`entityClass.${entityClass}`))
      }

      return label.join(' ')
    },

    getCyclesItems(cycles) {
      if (!cycles || !Array.isArray(cycles)) {
        return []
      }

      const cyclesMap = cycles?.map(cycle => {
        return {
          id: cycle.id,
          label: cycle.title,
        }
      })

      return cyclesMap || []
    },
    getHierarchyItems(hierarchy) {
      if (!hierarchy || !Array.isArray(hierarchy)) {
        return []
      }

      const hierarchyMap = hierarchy?.map(item => {
        const title = this.getTraductionKeyValueTitle(
          item.entityType,
          item.entityClass
        )

        const id = title.replace(/\s/g, '')

        return {
          id: id,
          label: title,
          entityType: item.entityType,
          entityClass: item.entityClass,
        }
      })

      return hierarchyMap || []
    },

    calcMetadataGroupsItensToTable(itens) {
      if (!Array.isArray(itens)) {
        return []
      }

      const canDelete = this._canAction

      const actions = []
      if (canDelete && !this.form.default) {
        actions.push('delete')
      }

      return itens?.map(item => {
        return {
          ...item,
          id: item.id,
          position: item?.position || 0,
          private: item.private,
          readonly: item.readonly,
          multi: item.multi,
          autoAddNewCycles: item.autoAddNewCycles,
          cycles: this.getCyclesItems(item?.cycles),
          hierarchy: this.getHierarchyItems(item?.hierarchy),

          name: {
            label: item.label,
            status: '',
          },
          'type-name': {
            label: this.getTraductionKeyValueType(item.type),
            status: '',
          },
          detail: {
            label: this.$t(`PerformanceTab.ListCustomFields.header.detail`),
            icon: 'fi-rr-interrogation',
            title: this.$t(`PerformanceTab.ListCustomFields.titleDetailHover`),
          },
          actions: actions,
        }
      })
    },

    getIconByTypeMulti(metadata) {
      const typeWithIcon = this.MetadataType.find(
        type => type.type === metadata.type && type.multi === !!metadata?.multi
      )

      return typeWithIcon?.typeIcon
    },

    calcMetadataItensToOptions(itens) {
      if (!Array.isArray(itens)) {
        return []
      }

      return itens.map(metadata => {
        const iconByTypeMulti = this.getIconByTypeMulti(metadata)

        return {
          ...metadata,
          typeIcon: iconByTypeMulti,
        }
      })
    },

    async getOptionsSearch(search = null) {
      const id = 'Metadatas'

      const { signal } = handleAxiosSignalAbortController(
        this.AxiosController.SignalHistory,
        id
      )

      const payload = {
        limit: this.MetadatasApiController.limit,
        offset: 0,
        entityType: this.entityType,
        entityClass: this.entityClass,
        label: search,
        matchHierarchy: false,
      }

      this.MetadatasApiController = Object.assign(
        {},
        this.MetadatasApiController,
        {
          loading: true,
          itens: [],
          ...payload,
        }
      )

      await handleApiRequestWithController(
        getMetadata,
        payload,
        this.MetadatasApiController,
        'id',
        signal
      )
    },

    async handleSearch(searchTerm = '') {
      if (
        searchTerm === this.MetadatasApiController.label &&
        this.MetadatasApiController.itens.length
      ) {
        return
      }

      await this.getOptionsSearch(searchTerm)
    },

    handleNewMetadataSideSuccess() {
      this.MetadatasApiController.label = null
    },

    handleCreateMetadata(callbackCloseMenu = () => {}) {
      this.newMetadataSide.show = true

      callbackCloseMenu()
    },

    handleNewMetadataSideClose() {
      this.newMetadataSide.show = false
    },

    handleNewMetadataSideSubmit() {
      this.$refs.CustomFieldForm.submit()
    },

    handleNewMetadataSideActions(act) {
      const actionCallback = {
        close: this.handleNewMetadataSideClose,
        submit: this.handleNewMetadataSideSubmit,
      }[act]

      if (actionCallback) {
        actionCallback()
      }
    },

    handleAddMetadata(payload) {
      if (!payload?.group?.id) {
        this.handleAddMetadataConfirm(payload)

        return
      }

      if (this.$refs.ConfirmMoveModal) {
        this.$refs.ConfirmMoveModal.handleOpenDialog(payload)
      }
    },
    handleAddMetadataConfirm(payload) {
      const metadata = structuredClone(payload)
      metadata.position = this.form.metadatas.length
      this.form.metadatas.push(metadata)

      this.watchUpdateMetadataGroup()
      this.handleMetadataItensUpdate(payload)
    },
    handleMetadataItensUpdate(payload) {
      if (!this.isEditMode()) {
        return
      }

      const itemIndex = this.MetadatasApiController.itens.findIndex(
        metadata => metadata.id === payload.id
      )

      if (itemIndex === -1) {
        return
      }

      const updateGroup = {
        id: this.$route.params.id,
        label: this.form.label,
      }
      payload.group = updateGroup

      this.$set(this.MetadatasApiController.itens, itemIndex, payload)
    },

    async handleDeleteMetadata(payload) {
      const metadataIndex = this.form.metadatas.findIndex(
        metadata => metadata.id === payload.id
      )

      if (metadataIndex === -1) {
        return
      }

      this.form.metadatas.splice(metadataIndex, 1)

      const metadatas = this.handleUpdateOrders(
        this.form.metadatas,
        metadataIndex
      )
      this.$set(this.form, 'metadatas', metadatas)

      this.watchUpdateMetadataGroup()
    },

    handleUpdateOrders(metadatas, fromIndex = 0) {
      if (!Array.isArray(metadatas)) {
        return []
      }

      const totalItens = metadatas.length
      if (fromIndex > totalItens) {
        return metadatas
      }

      const metadatasFromIndex = metadatas
        .slice(fromIndex)
        .map((metadata, index) => ({
          ...metadata,
          position: fromIndex + index,
        }))

      return metadatas.toSpliced(fromIndex, totalItens, ...metadatasFromIndex)
    },

    handleOrderMetadata(payload = []) {
      if (this.filter.label) {
        return
      }

      function calcOrder(metadatas) {
        return metadatas
          .map((metadata, index) => `${index}_${metadata.id}`)
          .join(',')
      }

      const payloadPositions = calcOrder(payload)
      const metadatasPositions = calcOrder(this.form.metadatas)

      if (payloadPositions === metadatasPositions) {
        return
      }

      this.form.metadatas = payload.map((metadata, index) => {
        return {
          ...metadata,
          position: index,
        }
      })

      this.watchUpdateMetadataGroup()
    },

    handleInputLabel(label) {
      this.form.label = label

      this.watchUpdateMetadataGroup()
    },

    handleFilterMetadatas(payload) {
      const searchTerm = payload.name
      this.filter.label = searchTerm
    },

    watchUpdateMetadataGroup: _.debounce(function () {
      if (!this._canEditMetadata) {
        return
      }

      this.submit()
    }, 500),

    calcMetadataWithApiItens(metadatas) {
      if (!Array.isArray(metadatas)) {
        return []
      }

      return metadatas.map(metadata => {
        const metadataItem = this.MetadatasApiController.itens.find(
          item => item.id === metadata.id
        )

        if (!metadataItem) {
          return metadata
        }

        return Object.assign({}, metadata, metadataItem)
      })
    },

    async handleEditableMetadata() {
      if (!this.isEditMode()) {
        this.loading = false
        return
      }

      this.loading = true

      await this.fetchMetadataGroup(this.$route.params.id)
      await this.handleSearch()

      const metadatas = this.calcMetadataWithApiItens(this.form.metadatas)
      this.$set(this.form, 'metadatas', metadatas)

      this.loading = false
    },

    async fetchMetadataGroup(id) {
      return await getMetadataGroupsByID(id)
        .then(({ data }) => {
          this.form = Object.assign({}, this.form, data)
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            title: this.$t('addMetadataGroup.alert.get.error'),
            messageAlert: error?.response?.data?.message,
          })

          this.$emit('close')
        })
    },
  },

  beforeMount() {
    this.setEntity()
    this.handleEditableMetadata()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
