<template>
  <div class="entity-config-list" data-test-entity-okr-list>
    <SearchBar @input="handleInput" @clear="handleClear" v-if="search" />

    <div class="entity-box-group">
      <template v-if="loading">
        <v-skeleton-loader
          v-for="i in 4"
          :key="i"
          type="button"
          class="entity-skeleton"
        />
      </template>

      <div
        v-else
        v-for="entity in _entityList"
        :class="colClass"
        :key="entity.id"
      >
        <EntityBox :entity="entity" />
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrList/Parts/SearchBar/SearchBar.vue'
import EntityBox from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrList/Parts/EntityBox/EntityBox.vue'

export default {
  name: 'EntityConfigList',
  components: {
    SearchBar,
    EntityBox,
  },
  data() {
    return {
      text: '',
      loading: false,
    }
  },
  props: {
    entityList: {
      type: Array,
      default: () => [],
    },
    search: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    colClass() {
      return 'two-columns'
    },
    _entityList() {
      return this.text
        ? this.entityList.filter(entity => entity.label.includes(this.text))
        : this.entityList
    },
  },
  methods: {
    handleInput(text) {
      this.text = text
    },
    handleClear() {
      this.text = ''
    },
    handleLoading(force = null) {
      this.loading = force ? force : !this.loading
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
