<template>
  <div data-test-item-view-history-item class="history-item" :class="item.type">
    <div class="author">
      <AvatarComponent
        :id="item.author.id"
        :src="item.author.photo"
        :text="item.author.name"
        size="20px"
      ></AvatarComponent>
      <span class="author-name">{{ item.author.name }}:</span>
      <v-tooltip
        v-if="
          $vuetify.breakpoint.width <= 374 ||
          $vuetify.breakpoint.width / item.author.name.length < 20
        "
        bottom
        :nudge-top="3"
        :nudge-left="-16"
        allow-overflow
        open-on-click
      >
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" class="name-extended" v-on="on">
            <v-icon class="name-icon">mdi-dots-horizontal</v-icon>
          </div>
        </template>

        <div class="name-extended-tooltip">
          {{ item.author.name }}
        </div>
      </v-tooltip>
    </div>

    <div class="content">
      <i18n v-bind="templateString">
        <template #entityName>
          <span>{{ item.entity.code }} - {{ item.entity.title }}</span>
        </template>
        <template #entityType>
          <span>{{ formatEntityType }}</span>
        </template>
        <template #permission>
          <strong>{{ getKey(item.permission) }}</strong>
        </template>
        <template #type>
          <strong>{{ item.permissionGroup.label }}</strong>
        </template>

        <template #text>
          <span>{{ item?.text }}</span>
        </template>
        <template #tag>
          <span>{{ item.tag }}</span>
        </template>
        <template #comment>
          <span v-html="item.text"></span>
        </template>

        <template #person>
          <strong class="extra">{{ item.person.name }}</strong>
        </template>
        <template #access>
          <strong>({{ item.access }})</strong>
        </template>

        <template #linebreak>
          <br v-if="formatAttribute.hideFromValue" />
          <br v-if="formatAttribute.hideFromValue" />
          <br v-if="isLineBreack" />
          <br v-if="isLineBreack" />
        </template>

        <template #fromValue>
          <span
            v-if="formatAttribute.hideFromValue && !displayFromValue"
            class="hidden"
            @click="showFromValue()"
          >
            “{{ $t('management-people-history.showMore') }}”
          </span>
          <strong v-else>
            “<span class="no-margins" v-html="handleURI(item.fromValue)"></span
            >”
          </strong>
        </template>
        <template #toValue>
          <strong>
            “<span class="no-margins" v-html="handleURI(item.toValue)"></span>”
          </strong>
        </template>
        <template #value>
          <strong>{{ item?.value }}</strong>
        </template>
        <template #visibility>
          <strong>{{ formatVisibility.title }}</strong>
        </template>
        <template #progress>
          <strong class="extra">{{ item.value }}%</strong>
        </template>
        <template #attribute>
          <strong class="extra">{{ formatAttribute?.title }}</strong>
        </template>
        <template #name>
          <strong class="extra">{{ formatName?.title }}</strong>
        </template>

        <template #status>
          <strong
            class="status"
            :style="{ color: formatStatus.color }"
            v-text="`( ${formatStatus.title} )`"
          ></strong>
        </template>
        <template #date>
          <strong>{{ formatDate }}</strong>
        </template>

        <template #priorization>
          <span>
            {{ translateEntityTypeFrom(item.entity.entityType) }}:
            <strong> {{ item.entity.code }} - {{ item.entity.title }}</strong>
          </span>
        </template>
        <template #placement>
          <span v-if="!item.fromValue">
            {{ $t('management-people-history.to') }}
            <strong>{{ handleURI(item.toValue) }}°</strong>
          </span>
          <span v-else>
            {{ $t('management-people-history.from') }}
            <strong>{{ handleURI(item.fromValue) }}°</strong>
            {{ $t('management-people-history.to') }}
            <strong>{{ handleURI(item.toValue) }}°</strong>
          </span>
        </template>
        <template #moveThisEntity>
          <span>{{ translateEntityTypeFrom(item.entityFrom.entityType) }}</span>
          <strong>
            {{ item.entityFrom.code }} - {{ item.entityFrom.title }}
          </strong>
        </template>
        <template #toEntity>
          <span v-if="item.entityTo">
            {{ translateEntityTypeTo(item.entityTo.entityType) }}
            <strong>
              {{ item.entityTo.code }} - {{ item.entityTo.title }}
            </strong>
          </span>

          <span v-else-if="item.entityFrom">
            {{ translateEntityTypeFrom(item.entityFrom.entityType) }}
            <strong>
              {{ item.entityFrom.code }} - {{ item.entityFrom.title }}
            </strong>
          </span>
        </template>
        <template #childMoved>
          <span>{{ translateEntityTypeTo(item.entity.entityType) }}:</span>
          <strong> {{ item.entity.code }} - {{ item.entity.title }}</strong>
        </template>
        <template #newParent>
          <span>{{
            $t(`management-people-history.entityType.${entityType}`)
          }}</span>
        </template>
      </i18n>

      <div v-if="!dateOutside" class="data">
        {{ timePassed }}
      </div>
    </div>

    <div v-if="dateOutside" class="data">
      {{ timePassed }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getTranslate } from '@/providers/permission-groups'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    colorPallete: {
      type: Object,
    },
    translatePallete: {
      type: Object,
    },
    entityType: {
      type: String,
    },
  },
  data() {
    return {
      displayFromValue: false,
    }
  },
  computed: {
    timePassed() {
      const locale = this.$t('management-people-history.moment.locale')
      moment.locale(locale)

      const pastLabel = this.$t('management-people-history.moment.past')
      const futureLabel = this.$t('management-people-history.moment.future')
      const secondLabel = this.$t('management-people-history.moment.second')
      const minuteLabel = this.$t('management-people-history.moment.minute')
      const hourLabel = this.$t('management-people-history.moment.hour')
      const dayLabel = this.$t('management-people-history.moment.day')
      const weekLabel = this.$t('management-people-history.moment.week')
      const monthLabel = this.$t('management-people-history.moment.month')
      const yearLabel = this.$t('management-people-history.moment.year')

      moment.updateLocale(locale, {
        relativeTime: {
          future: `${futureLabel} %s`,
          past: `${pastLabel} %s`,
          s: `0${secondLabel}`,
          ss: `%d${secondLabel}`,
          m: `1${minuteLabel}`,
          mm: `%d${minuteLabel}`,
          h: `1${hourLabel}`,
          hh: `%d${hourLabel}`,
          d: `1${dayLabel}`,
          dd: `%d${dayLabel}`,
          w: `1${weekLabel}`,
          ww: `%d${weekLabel}`,
          M: `1${monthLabel}`,
          MM: `%d${monthLabel}`,
          y: `1${yearLabel}`,
          yy: `%d${yearLabel}`,
        },
      })

      return moment(this.item.createdAt).fromNow(true)
    },
    formatDate() {
      const locale = this.$t('management-people-history.moment.locale')
      const dateFormat = this.$t('management-people-history.moment.dateFormat')
      moment.locale(locale)

      moment.defaultFormat = 'YYYY-MM-DD'
      return moment(this.item.date, moment.defaultFormat).format(dateFormat)
    },
    formatStatus() {
      const status = this.item.status
      const pallete = this.colorPallete

      let title = ''
      let color
      if (pallete && pallete[status]) {
        if (pallete[status].title) title = pallete[status].title
        if (pallete[status].color) color = pallete[status].color
      }

      if (title === '') title = status
      return { title, color }
    },
    formatAttribute() {
      const attribute = this.item.attribute
      const { title } = this.formatTranslate(attribute)

      const fromValue = this.item.fromValue
      let hideFromValue = false
      if (fromValue) if (fromValue.length > 140) hideFromValue = true

      return { title, hideFromValue }
    },
    isLineBreack() {
      return this.item.type === 'series' ? true : false
    },
    formatName() {
      const type = this.item.type
      const translated = this.formatTranslate(type)

      return translated
    },
    formatVisibility() {
      const type = this.item.type
      const value = '' + this.item.value
      const translated = this.formatTranslate(type, value)

      return translated
    },
    formatEntityType() {
      let type = this.entityType

      if (
        'entity' in this.item &&
        'entityType' in this.item.entity &&
        typeof this.item.entity.entityType === 'string'
      ) {
        type = this.item.entity.entityType
      }

      return this.$t(`management-people-history.entityType.${type}`)
    },
    templateString() {
      const { type, action } = this.item
      const mountedString = `management-people-history.actions.${type}.${action}`

      return {
        path: mountedString,
      }
    },
    dateOutside() {
      return this.item.type !== 'comment'
    },
  },
  methods: {
    getKey(permission) {
      return getTranslate(permission)
    },
    showFromValue() {
      this.displayFromValue = true
    },
    formatTranslate(key, subKey = null) {
      const pallete = this.translatePallete
      let title = subKey || key

      const keyResult = this.insideKey(pallete, key)
      if (typeof keyResult === 'object') {
        if ('title' in keyResult && !subKey) title = keyResult.title
        else {
          const subKeyResult = this.insideKey(keyResult, subKey)
          title = subKeyResult
        }
      } else if (typeof keyResult === 'string') {
        title = keyResult
      }

      return { title }
    },
    insideKey(pallete, Key) {
      if (pallete && pallete[`${Key}`]) {
        return pallete[`${Key}`]
      }
    },
    translateEntityTypeFrom(entityType) {
      return this.$t(`management-people-history.fromEntityType.${entityType}`)
    },
    translateEntityTypeTo(entityType) {
      return this.$t(`management-people-history.theEntityType.${entityType}`)
    },
    handleURI(value) {
      if ([null, undefined].includes(value)) return ''
      if (typeof value === 'string') return value

      if (typeof value === 'object' && Object.keys(value).length === 0)
        return ''
      if (value?.name) return value.name
      if (value?.label) return value.label

      return value
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
