<template>
  <v-form ref="form" v-model="valid">
    <div data-test-custom-field-form class="custom-field-form">
      <AlertBar ref="AlertBar"></AlertBar>

      <div>
        <v-row>
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="skeleton-label input"
          ></v-skeleton-loader>

          <CustomInput
            v-else
            ref="label"
            class="input"
            :current-value="form.label"
            :mandatory="true"
            :view-mode="false"
            :readonly="editMode && !_canEditMetadata"
            :label="$t('newCustomFields.details.title')"
            @handleInput="setValueTitle($event)"
          ></CustomInput>
        </v-row>

        <v-skeleton-loader
          v-if="loading"
          type="button"
          class="skeleton-label input"
        ></v-skeleton-loader>

        <div v-else class="input select-type">
          <RichTextEditor
            ref="richTextEditor"
            data-test-details-section-rich-text-editor
            :value="form.description"
            :editable="editMode ? _canEditMetadata : true"
            @blur="handleChangeDescription($event)"
          ></RichTextEditor>
        </div>

        <v-row>
          <div class="input select-type">
            <v-skeleton-loader
              v-if="loading"
              type="button"
              class="skeleton-label"
            ></v-skeleton-loader>

            <ItemSelector
              v-else
              :input-config="{
                label: $t('newCustomFields.details.type'),
                showAvatar: false,
                showIcon: true,
                rules: rules,
                closeOnSelect: true,
                subtleMode: editMode,
              }"
              :menu-config="{
                attach: true,
                showTabs: false,
              }"
              :menu-options="_TypeOptions"
              :current-value="{
                data: typeSelected,
                origin: 'type',
              }"
              :readonly="editMode"
              :persistent="false"
              :watch-current="true"
              :fill-width="true"
              :multiple="false"
              :show-elements="true"
              :local-search="true"
              @update:item="setValueType($event)"
            ></ItemSelector>
          </div>
        </v-row>

        <v-row class="input-bottom">
          <div class="table-list-options">
            <span
              v-if="showLabelOptions"
              class="down-left-button"
              data-test-show-more-label
              @click="showTable(true)"
            >
              {{ $t('viewCustomFields.labelShowMore') }}
            </span>

            <DataTable
              v-if="showTableOptions"
              :title="$t('viewCustomFields.tableOptions.title')"
              :enable-draggable="true"
              :can-drag="_canAction"
              :headers="_headers"
              :loading="loadingTable"
              :roles-readonly="_canAction"
              :fixed-header="true"
              :hide-search="true"
              :hide-header="true"
              :hide-option="true"
              :attach="true"
              :enable-pagination="false"
              :items="_itens"
              :down-left-button="_addOptions"
              @update-inputEdit="updateInputEdit($event)"
              @update:table-order="handleOrderOptionsList($event)"
              @remove="handleDeleteOptionsList($event)"
            ></DataTable>
          </div>
        </v-row>

        <v-divider></v-divider>
      </div>
      <div id="view-modal-custom-fields">
        <v-row>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              type="button"
              class="skeleton-toggle"
            ></v-skeleton-loader>
            <div v-else class="input-bottom itemSelector">
              <ItemSelector
                :input-config="{
                  label: $t('newCustomFields.details.activeCycles'),
                  showAvatar: false,
                  subtleMode: editMode && !_canEditMetadata,
                }"
                :menu-config="{
                  attach: true,
                  showTabs: false,
                }"
                :current-value="{
                  data: cyclesSelected || [],
                  origin: 'cycles',
                }"
                :readonly="editMode && !_canEditMetadata"
                :watch-current="true"
                :menu-options="_CycleOptions"
                :fill-width="true"
                :multiple="true"
                :show-elements="true"
                :local-search="true"
                @update:item="setValueCycle($event)"
                @focus:input="getCycles()"
              ></ItemSelector>
            </div>
          </v-col>

          <v-col>
            <v-skeleton-loader
              v-if="loading"
              type="button"
              class="skeleton-toggle input-bottom"
            ></v-skeleton-loader>

            <div v-else class="input-bottom">
              {{ $t('newCustomFields.details.requiredField') }}
              <v-switch
                v-model="form.required"
                class="switch-toggle"
                inset
                :disabled="editMode && !_canEditMetadata"
                @click="handleToggle"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                type="button"
                class="skeleton-toggle input-bottom"
              ></v-skeleton-loader>

              <div v-else class="input-bottom">
                {{ $t('newCustomFields.details.private') }}
                <v-switch
                  v-model="form.private"
                  class="switch-toggle"
                  inset
                  :disabled="editMode && !_canEditMetadata"
                  @click="handleToggle"
                ></v-switch>
              </div>
            </v-col>
          </v-col>

          <v-col>
            <v-skeleton-loader
              v-if="loading"
              type="button"
              class="skeleton-toggle input-bottom"
            ></v-skeleton-loader>

            <div v-else class="input-bottom">
              {{ $t('newCustomFields.details.readonly') }}
              <v-switch
                v-model="form.readonly"
                class="switch-toggle"
                inset
                :disabled="editMode && !_canEditMetadata"
                @click="handleToggle"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="_haveHierarchy">
            <v-skeleton-loader
              v-if="loading"
              type="button"
              class="skeleton-toggle input-bottom"
            ></v-skeleton-loader>

            <div v-else class="input-bottom">
              {{ $t('newCustomFields.details.hierarchy') }}
              <v-switch
                v-model="showItemSelectorHierarchy"
                class="switch-toggle"
                inset
                :disabled="editMode && !_canEditMetadata"
              ></v-switch>
            </div>

            <div
              v-if="showItemSelectorHierarchy"
              class="input-bottom itemSelector"
            >
              <itemSelector
                ref="entityType"
                data-test-selector-entity-type
                :input-config="{
                  label: $t('newCustomFields.details.hierarchy'),
                  showAvatar: false,
                  rules: rules,
                }"
                :menu-config="{
                  attach: false,
                  showTabs: false,
                }"
                :menu-options="_listEntityType"
                :current-value="{
                  data: hierarchySelected || [],
                  origin: 'hierarchy',
                }"
                :watch-current="true"
                :fill-width="true"
                :multiple="true"
                :show-elements="true"
                :local-search="true"
                @update:item="handleEntityType"
              ></itemSelector>
            </div>
          </v-col>
          <v-col>
            <v-skeleton-loader
              v-if="loading"
              type="button"
              class="skeleton-toggle"
            ></v-skeleton-loader>

            <div v-else>
              {{ $t('newCustomFields.details.autoAddNewCycles') }}
              <v-switch
                v-model="form.autoAddNewCycles"
                class="switch-toggle"
                inset
                :disabled="editMode && !_canEditMetadata"
                @click="handleToggle"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-form>
</template>
<script>
import _ from 'lodash'
import { getCycles } from '@/services/cycles/index.js'
import { getHierarchySettingsWithChildren } from '@/services/entities'
import { postMetadata, getMetadataByID, putMetadata } from '@/services/metadata'
import DataTable from '@/components/DataTable/DataTable.vue'
import { v4 as uuidv4 } from 'uuid'
import { management_performance_metadata_edit } from '@/helpers/ability'

import { getMetadataGroups } from '@/services/metadata'

export default {
  name: 'CustomFieldForm',
  components: {
    DataTable,
  },
  props: {
    enforcedCreationMode: {
      type: Boolean,
      default: false,
    },
    showAlert: {
      type: Function,
    },
  },
  data() {
    return {
      optionsList: [],
      items: [],
      loadingTable: false,
      showLabelOptions: false,
      showTableOptions: false,
      interval: null,
      loading: false,
      editMode: false,
      showItemSelectorHierarchy: false,
      valid: false,
      rules: [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
      ],
      readonly: false,
      cyclesSelected: [],
      typeSelected: [],
      cycles: [],
      listEntityType: [],
      hierarchySelected: [],
      value: false,
      form: {
        label: '',
        description: '',
        private: false,
        readonly: false,
        entityType: null,
        entityClass: null,
        type: null,
        status: 'active',
        hierarchy: [],
        group: null,
        multi: false,
        cycles: null,
        autoAddNewCycles: false,
        required: false,
      },
      type: [
        {
          id: 'text',
          label: this.$t('newCustomFields.typeList.text'),
          typeIcon: 'fi-rr-text',
        },
        {
          id: 'date',
          label: this.$t('newCustomFields.typeList.date'),
          typeIcon: 'fi-rr-calendar',
        },
        {
          id: 'people',
          label: this.$t('newCustomFields.typeList.simplePeople'),
          typeIcon: 'mdi-account-group-outline',
        },
        {
          id: 'multiPeople',
          label: this.$t('newCustomFields.typeList.multiPeople'),
          typeIcon: 'mdi-account-group-outline',
        },
        {
          id: 'select',
          label: this.$t('newCustomFields.typeList.simpleList'),
          typeIcon: 'fi-rr-list',
        },
        {
          id: 'multiList',
          label: this.$t('newCustomFields.typeList.multiList'),
          typeIcon: 'fi-rr-list',
        },
      ],
    }
  },
  computed: {
    _haveHierarchy() {
      return this.listEntityType.length
    },
    _canEditMetadata() {
      return this.$can('access', management_performance_metadata_edit)
    },
    _canAction() {
      return !this.editMode || (this.editMode && this._canEditMetadata)
    },
    _itens() {
      if (!this.optionsList) {
        return []
      }

      return structuredClone(this.optionsList)
    },
    _addOptions() {
      return this._canEditMetadata
        ? {
            click: this.addOptions,
            text: this.$t('viewCustomFields.tableOptions.addNewOption'),
            icon: 'mdi-plus',
          }
        : null
    },
    _headers() {
      return [
        {
          text: this.$t('viewCustomFields.headerTable.name'),
          value: 'label',
          type: 'input-edit',
        },
        {
          text: this.$t('viewCustomFields.headerTable.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
    _CycleOptions() {
      return [
        {
          value: 'cycles',
          label: this.$t('newCustomFields.details.activeCycles'),
          type: 'listview',
          items: this.cycles,
        },
      ]
    },
    _TypeOptions() {
      return [
        {
          value: 'type',
          label: this.$t('newCustomFields.details.type'),
          type: 'listview',
          items: this.type,
        },
      ]
    },
    _listEntityType() {
      return [
        {
          value: 'hierarchy',
          type: 'listview',
          label: this.$t('newCustomFields.details.hierarchy'),
          items: this.listEntityType,
        },
      ]
    },
  },
  async beforeMount() {
    this.handleGetEntityTypes()
    this.startValues()
    await this.isEditMode()
  },
  async mounted() {
    await this.getCycles()
    if (!this.editMode) {
      this.cyclesSelected = this.cycles
    }
  },
  methods: {
    showTable(value) {
      this.showTableOptions = value
      this.showLabelOptions = false
    },
    handleDeleteOptionsList(optionDelete) {
      if (this.optionsList.length <= 1) return

      const OptionsIndex = this.optionsList.findIndex(
        option => option._id === optionDelete._id
      )

      if (OptionsIndex === -1) {
        return
      }

      this.optionsList.splice(OptionsIndex, 1)

      const options = this.handleUpdateOrders(this.optionsList, OptionsIndex)

      this.optionsList = options

      if (this.editMode) {
        this.submit()
      }
    },
    handleUpdateOrders(options, fromIndex = 0) {
      if (!Array.isArray(options)) {
        return []
      }

      const totalItens = options.length
      if (fromIndex > totalItens) {
        return options
      }

      const optionsFromIndex = options
        .slice(fromIndex)
        .map((metadata, index) => ({
          ...metadata,
          position: fromIndex + index,
        }))

      return options.toSpliced(fromIndex, totalItens, ...optionsFromIndex)
    },
    handleOrderOptionsList(optionList) {
      if (!optionList) return
      let emptyObject = false

      optionList.map(el => {
        if (el.label.value === null) {
          emptyObject = true
        }
      })

      if (emptyObject) return

      function calcOrder(options) {
        return options
          .map(option => `${option.position}_${option._id}`)
          .join(',')
      }

      const payloadPositions = calcOrder(optionList)
      const optionsPositions = calcOrder(this.optionsList)

      if (payloadPositions === optionsPositions) {
        return
      }

      this.optionsList = optionList.map((metadata, index) => {
        return {
          ...metadata,
          position: index,
        }
      })

      if (this.editMode) {
        this.submit()
      }
    },
    updateInputEdit(optionUpdate) {
      const OptionsIndex = this.optionsList.findIndex(
        option => option.position === optionUpdate.index
      )

      if (OptionsIndex === -1) {
        return
      }

      this.optionsList[OptionsIndex].label.value = optionUpdate.value

      if (this.editMode) {
        this.submit()
      }
    },
    checkOptionsList() {
      return this.optionsList.some(option => option.label.value === null)
        ? true
        : false
    },
    addOptions() {
      if (this.checkOptionsList()) return
      this.optionsList.push({
        _id: uuidv4(),
        position: this.optionsList.length,
        label: {
          value: null,
        },
        actions: ['remove'],
      })
    },
    handleShowTableOptins(value) {
      if (this.showTableOptions) return
      this.showLabelOptions = value
    },
    handleToggle() {
      this.updateMetadata()
    },
    ValidateCustomInputReturn(result) {
      const isInvalid = result instanceof Node
      if (isInvalid) {
        return false
      }

      return result === undefined
    },
    async handleDefaultGroup() {
      let defaultGroup = null

      let ListdefaultGroup = await getMetadataGroups({
        entityType: this.form.entityType,
        entityClass: this.form.entityClass,
      }).catch(error => {
        this.showAlert({
          statusAlert: 'error',
          title: error?.response?.data?.message,
          messageAlert: error?.message,
        })
      })

      ListdefaultGroup.data.map(e => {
        if (e.default === true) {
          defaultGroup = {
            id: e.id,
            label: e.label,
          }
        }
      })

      return defaultGroup
    },
    validate() {
      const forms = ['form']
      let valid = true

      forms.forEach(e => {
        const value = this.$refs[e].validate()
        if (!value && value != undefined) valid = false
      })

      let CustomInputReturn = this.ValidateCustomInputReturn(
        this.$refs['label'].validate()
      )

      if (valid && !CustomInputReturn) valid = false

      if (!valid && !this.enforcedCreationMode) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }
      return valid
    },
    _submit: _.debounce(function () {
      this.submit()
    }, 1000),
    async submit() {
      if (!this.validate()) return

      if (!this.editMode) {
        this.form.group = await this.handleDefaultGroup()
        this.form.multi = false
      }

      this.form.type = this.typeSelected[0].id

      if (this.typeSelected[0].id === 'multiPeople') {
        this.form.type = 'people'
        this.form.multi = true
      }

      if (this.typeSelected[0].id === 'multiList') {
        this.form.type = 'select'
        this.form.multi = true
      }

      this.form.cycles = this.cyclesSelected.map(obj => {
        return {
          id: obj.id,
          title: obj.label,
        }
      })

      if (!this.showItemSelectorHierarchy) {
        this.form.hierarchy = []
      }

      if (this.showItemSelectorHierarchy) {
        this.form.hierarchy = this.hierarchySelected.map(obj => ({
          entityType: obj.entityType,
          entityClass: obj.entityClass,
        }))
      }

      if (this.form.type === 'select') {
        this.form.options = this.optionsList.map((obj, index) => {
          return {
            id: obj.id,
            position: index,
            value: obj.label.value,
          }
        })
      }

      if (!this.editMode) {
        await postMetadata(this.form)
          .then(() => {
            this.showAlert({
              statusAlert: 'success',
              messageAlert: this.$t(
                'newCustomFields.alert.messageSuccessCreate'
              ),
            })
            this.$emit('close')
            this.$emit('success')
          })
          .catch(error => {
            this.showAlert({
              statusAlert: 'error',
              title: error.response.data.message,
              messageAlert: error?.message,
            })
          })
      } else {
        await putMetadata(this.$route.params.id, this.form)
          .then(() => {
            this.showAlert({
              statusAlert: 'success',
              messageAlert: this.$t(
                'viewCustomFields.alert.messageSuccessCreate'
              ),
            })
            this.$emit('success')
          })
          .catch(error => {
            this.showAlert({
              statusAlert: 'error',
              title: error.response.data.message,
              messageAlert: error?.message,
            })
          })
      }
    },
    async handleGetEntityTypes() {
      this.loading = true
      this.listEntityType = []
      await this.fetchHierarchySettings(this.$route.params.entityID)
      const settings = JSON.parse(JSON.stringify(this.listEntityType))

      this.listEntityType = settings
      this.loading = false
    },
    async fetchHierarchySettings(payload = {}) {
      return await getHierarchySettingsWithChildren(payload)
        .then(res => {
          this.listEntityType = this.handleEntityTypeDataToItens(res.data)
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            messageAlert: error?.message,
          })
        })
    },
    handleEntityTypeDataToItens(data = []) {
      if (!Array.isArray(data)) {
        return []
      }

      const listEntitiesWithoutCurrent = data.filter(
        item =>
          !(
            item.entityClass === this.form.entityClass &&
            item.entityType === this.form.entityType
          )
      )

      return listEntitiesWithoutCurrent.map(item => {
        const title = this.getTitle(item.entityType, item.entityClass)
        const id = title.replace(/\s/g, '')
        return {
          id: id,
          label: title,
          entityType: item.entityType,
          entityClass: item.entityClass,
        }
      })
    },
    getTitle(entityType, entityClass) {
      const etype = entityType ? entityType : 'emptyType'
      const eclass = entityClass ? entityClass : 'emptyClass'
      const translateKey = `${etype}-${eclass}`
      return this.words(translateKey, 'EntityTypes')
    },
    words(complement, customDefaultKey = null) {
      const defaultKey = customDefaultKey ?? `EntityTypes`
      return this.$t(`${defaultKey}.${complement}`)
    },
    handleEntityType(value) {
      const hierarchy = value.map(obj => ({
        id: obj.id,
        label: obj.label,
        entityType: obj.entityType,
        entityClass: obj.entityClass,
      }))

      this.hierarchySelected = hierarchy

      this.updateMetadata()
    },
    updateMetadata() {
      if (this.editMode) {
        if (this.interval) clearInterval(this.interval)
        this.interval = setTimeout(() => {
          this.submit()
        }, 800)
      }
    },
    setValueTitle(value) {
      this.form.label = value
      this.updateMetadata()
    },
    setValueType(value) {
      if (!value) return
      this.typeSelected = [value]

      value.id === 'multiList' || value.id === 'select'
        ? this.handleShowTableOptins(true)
        : this.showTable(false)
    },
    setValueCycle(value) {
      this.cyclesSelected = value
      this.updateMetadata()
    },
    async isEditMode() {
      if (this.enforcedCreationMode) {
        return
      }

      if (this.$route.params.id != 'new') {
        this.editMode = true
      }

      if (this.editMode) {
        await this.getMetadata(this.$route.params.id)

        if (this.hierarchySelected?.length > 0) {
          this.showItemSelectorHierarchy = true
          return
        }
        this.showItemSelectorHierarchy = false
      }
    },
    startValues() {
      this.form.entityClass = this.$route.params.entityClass
        ? this.$route.params.entityClass
        : null

      this.form.entityType = this.$route.params.entityType

      this.typeSelected = this.handleType('text')
    },
    handleHierarchy(hierarchy) {
      if (!hierarchy) return

      const hierarchyMap = hierarchy.map(item => {
        const title = this.getTitle(item.entityType, item.entityClass)
        const id = title.replace(/\s/g, '')
        return {
          id: id,
          label: title,
          entityType: item.entityType,
          entityClass: item.entityClass,
        }
      })

      return hierarchyMap
    },
    handleType(type, isMultiple = false) {
      let metadataType = type
      if (type === 'select' && isMultiple) {
        metadataType = 'multiList'
      }

      if (type === 'people' && isMultiple) {
        metadataType = 'multiPeople'
      }

      let showTableOptions = false
      if (metadataType === 'multiList' || metadataType === 'select') {
        showTableOptions = true
      }

      this.handleShowTableOptins(showTableOptions)

      const foundObject = this.type.find(obj => obj.id === metadataType)
      return [foundObject]
    },
    async getCycles() {
      await getCycles().then(res => {
        this.cycles = res.data.map(e => {
          return {
            id: e.id,
            label: e.title,
          }
        })
      })
    },
    handleCycle(cycle) {
      if (!cycle) return []

      const cycleFormated = cycle.map(e => {
        return {
          id: e.id,
          label: e.title,
        }
      })

      return cycleFormated
    },
    handleOptions(options) {
      if (!options) return []

      const ListOptions = options.map((el, index) => ({
        _id: el.id,
        id: el.id,
        position: el.position ? el.position : index || 0,
        label: {
          value: el.value,
        },
        actions: this._canEditMetadata ? ['remove'] : [],
      }))

      return ListOptions
    },
    async getMetadata(id) {
      this.loading = true

      await getMetadataByID(id)
        .then(res => {
          this.form.label = res.data.label
          this.form.description = res.data.description
          this.form.private = res.data.private ? res.data.private : false
          this.form.readonly = res.data.readonly ? res.data.readonly : false
          this.form.status = res.data.status ? res.data.status : 'active'
          this.form.group = res.data.group ? res.data.group : null
          this.form.multi = res.data.multi ? res.data.multi : false
          this.form.autoAddNewCycles = res.data.autoAddNewCycles
            ? res.data.autoAddNewCycles
            : false
          this.form.required = res.data.required ? res.data.required : false
          this.optionsList = this.handleOptions(res.data.options)

          this.hierarchySelected = this.handleHierarchy(res.data.hierarchy)
          this.typeSelected = this.handleType(res.data.type, res.data.multi)
          this.cyclesSelected = this.handleCycle(
            res.data.cycles ? res.data.cycles : null
          )
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            messageAlert: error?.message,
          })
        })

      this.loading = false
    },

    handleChangeDescription(event) {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.form.description = event

        if (this.editMode) this.submit()
      }, 500)
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
