<template>
  <Modal
    ref="confirmDefaultDialog"
    :title="_modalTitle"
    class="modal"
    width="43.75rem"
  >
    <template v-slot:no-tab>
      <SearchBar
        ref="search"
        class="ml-5 mb-2 mr-3 mt-2"
        :cycleType="cycleType"
        @search="_search"
      />

      <SearchResults
        :text="text"
        :nodes="_nodes || []"
        :cycleType="cycleType"
        :selected="selected"
        :component="component"
        :loading="filtering"
        @change="changeSelection"
        @scroll="handleScroll"
      />

      <v-divider></v-divider>

      <div class="dialog-footer">
        <button @click="toggleModal(false)" class="btn ml-auto">
          {{ $t('GroupsPage.treeModal.cancel') }}
        </button>

        <button
          data-test-submit-insert-modal
          @click="submit"
          class="btn btn-primary"
        >
          <span>
            {{ $t('GroupsPage.treeModal.submit') }}
          </span>
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
import SearchBar from '../SearchBar/SearchBar.vue'
import SearchResults from '../SearchResults/SearchResults.vue'
import ItemOfList from '@/components/ItemOfList/ItemOfList.vue'

import { getHierarchyTree } from '@/services/groups'
import { getCycles } from '@/services/cycles'

export default {
  name: 'InsertModal',
  components: {
    SearchBar,
    SearchResults,
  },
  props: {
    cycleType: {
      type: Boolean,
      default: false,
    },
    filterOnlyRootCycles: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
    },
    component: {
      type: Object,
      default: () => ItemOfList,
    },
    node: {
      type: Object,
      default: () => {},
    },
    ignoredNodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      text: '',
      nodes: [],
      filteredNodes: [],
      selected: null,
      loading: {
        scroll: false,
      },
      filtering: false,
      filterKeys: ['id', 'name', 'costCenter'],
      filterInterval: null,
    }
  },
  watch: {
    async visible() {
      this.selected = null
      this.text = ''
      this.$refs.search && (this.$refs.search.text = '')

      if (this.visible && !this.cycleType) {
        await this.fetchAllNodes()
      }
      if (this.visible && this.cycleType) {
        await this.fetchCycles()
      }
    },
  },
  computed: {
    _modalTitle() {
      if (this.cycleType) {
        return this.$t('PerformanceTab.listCycleSettings.modalHierachy.title')
      }
      return this.$t('TreeView.insertModal.title')
    },
    _nodes: function () {
      return this.text && this.text.length ? this.filteredNodes : this.nodes
    },
  },
  methods: {
    changeSelection(node) {
      if (this.selected) this.selected._selected = false
      this.selected = node
      this.selected._selected = true
    },
    async handleScroll() {},
    async submit() {
      if (this.callback) this.callback(this.selected)
      else this.$emit('linkSelected', this.selected)

      this.$refs.confirmDefaultDialog.handleDialog()
    },
    _search(text) {
      this.filtering = true
      const keys = this.filterKeys
      clearInterval(this.filterInterval)
      this.filterInterval = setTimeout(() => {
        this.filteredNodes = this.nodes.filter(e => {
          for (const key of keys) {
            if (e[key] && e[key].toLowerCase().includes(text.toLowerCase()))
              return true
          }
        })

        this.selected = null
        this.text = text
        this.filtering = false

        document
          .getElementById('dialog-body')
          .scrollTo({ top: 0, behavior: 'smooth' })
      }, 250)
    },
    toggleModal(state) {
      this.visible = state
      this.$refs.confirmDefaultDialog.handleDialog()
    },
    async fetchCycles() {
      const payload = {}
      if (this.cycleType) {
        payload.limit = 500
      }
      await getCycles(payload).then(({ data }) => {
        let cycles = data.map(cycle => {
          return {
            ...cycle,
            name: cycle.title,
            inactive: this.cycleStatusVerify(cycle.status),
          }
        })

        if (this.filterOnlyRootCycles) {
          cycles = cycles.filter(cycle => cycle.parentCycleID === null)
        }

        this.nodes = cycles
      })
    },
    cycleStatusVerify(status) {
      if (status === 'active') {
        return false
      }
      return true
    },
    async fetchAllNodes() {
      await getHierarchyTree().then(({ data }) => {
        const nodeList = []
        const convertTreeToList = node => {
          if (this.ignoredNodes.some(e => e.id === node.id)) return

          nodeList.push({
            id: node.id,
            name: node.name,
            image: node.image,
            costCenter: node.costCenter,
            peopleCount: node.peopleCount,
            inactive: node.inactive,
          })

          if (node.children && node.children.length)
            for (const child of node.children) convertTreeToList(child)
        }

        data.forEach(e => convertTreeToList(e))

        this.nodes = nodeList
      })
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
