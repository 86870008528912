var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-2",on:{"mouseleave":function($event){_vm.hide = true}}},[_c('SearchBar',{ref:"searchTree",on:{"search":_vm._search,"input":_vm._input,"clear":_vm.clear,"click":_vm.handleClick}}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hide),expression:"!hide"}],staticClass:"results",class:{ 'hide-results': !_vm.resultsVisible }},[_c('SearchResults',{attrs:{"component":_vm.component,"nodes":_vm.nodes,"text":_vm.text,"_style":{
        height: '244px',
        width: '330px',
        maxHeight: '244px',
        overflow: 'auto',
        background: '#fff',
      }},on:{"change":_vm._submit,"scroll":_vm.handleScroll}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }