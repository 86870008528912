<template>
  <div class="tree-node">
    <div class="tree-root-div">
      <TextIcon class="text-icon" :label="node.name" :image="node.image" />
      <i
        :class="`fi fi-rr-caret-${editor ? 'down' : 'up'}`"
        @click="editor = !editor"
      ></i>
    </div>
    <div class="tree-root--content-body" v-show="editor">
      <RichTextEditor
        class="text-editor"
        :value="node.description"
        @change="_save"
        @blur="_save"
      />
    </div>

    <span
      v-if="canCreateGroup"
      class="add-new-node"
      @click="handleAction('add')"
    >
      + Adicionar grupo
    </span>
  </div>
</template>
<script>
import TextIcon from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextIcon/TextIcon.vue'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.vue'
import _ from 'lodash'

import { putDescription } from '@/services/account'

export default {
  name: 'TreeRoot',
  components: {
    TextIcon,
    RichTextEditor,
  },
  data() {
    return {
      editor: false,
    }
  },
  inject: ['toggleChildren', 'insertCopy'],
  props: {
    node: {
      type: Object,
    },
    collapsed: {
      type: Boolean,
    },
    config: {
      type: Object,
    },
    actions: {
      type: Array,
    },
    canCreateGroup: {
      type: Boolean,
    },
  },
  methods: {
    handleAction(act) {
      this.$emit('handleAction', act, this.node)
    },
    save(text) {
      putDescription(text)
      this.$store.dispatch('currentAccount/changeDescription', text)
    },
    _save: _.debounce(function (text) {
      this.save(text)
    }, 1000),
  },
  mounted() {
    this.toggleChildren(this.node)
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
