<template>
  <div class="ml-2" @mouseleave="hide = true">
    <SearchBar
      ref="searchTree"
      @search="_search"
      @input="_input"
      @clear="clear"
      @click="handleClick"
    />
    <v-card
      class="results"
      :class="{ 'hide-results': !resultsVisible }"
      v-show="!hide"
    >
      <SearchResults
        :component="component"
        :nodes="nodes"
        :text="text"
        @change="_submit"
        @scroll="handleScroll"
        :_style="{
          height: '244px',
          width: '330px',
          maxHeight: '244px',
          overflow: 'auto',
          background: '#fff',
        }"
      />
    </v-card>
  </div>
</template>
<script>
import _ from 'lodash'
import SearchBar from '../../parts/SearchBar/SearchBar.vue'
import SearchResults from '../../parts/SearchResults/SearchResults.vue'

export default {
  components: {
    SearchBar,
    SearchResults,
  },
  props: {
    component: {
      type: Object,
    },
    search: {
      type: Function,
    },
    submit: {
      type: Function,
    },
    callback: {
      type: Function,
    },
    act: {
      type: String,
    },
  },
  data() {
    return {
      hide: false,
      text: '',
      nodes: [],
      resultsVisible: false,
      loading: {
        scroll: false,
        search: false,
      },
      selected: null,
    }
  },
  methods: {
    handleClick() {
      this.hide = false
    },
    insertText(text, searched) {
      this.$refs?.searchTree?.insertText(text, searched)
    },
    hideResults() {
      setTimeout(() => {
        this.resultsVisible = false
      }, 150)
      setTimeout(() => {
        this.hide = true
      }, 500)
    },
    async handleScroll(e) {
      const scrollY = Math.floor(e.currentTarget.scrollTop)

      const listItemSize = 78
      const onScreen = 3
      const limit = 20
      const page = Math.ceil(this.nodes.length / limit)
      const overScroll = listItemSize * (page * limit) - listItemSize * onScreen

      if (scrollY > overScroll && !this.loading.scroll) {
        this.loading.scroll = true

        const offset = this.nodes.length
        const nodes = await this.search(this.text, limit, offset)
        this.nodes = this.nodes.concat(nodes)

        this.loading.scroll = false
      }
    },
    clear() {
      if (this.selected) this.$emit('reset')
      this.hideResults()
      this.hide = true
      this.text = ''
      this.selected = null
    },
    _submit(node) {
      this.selected = node
      this.insertText(node.name, true)
      this.hideResults()
      this.submit(node)
    },
    _input(text) {
      this.text = text
      this.loading.search = true
      if (this.selected && !this.text) {
        this.$emit('reset')
        this.hideResults()
      }
    },
    _search: _.debounce(async function (text) {
      if (this.selected && !text) return this.$emit('reset')
      const nodes = await this.search(text)
      this.selected = null
      this.nodes = nodes
      this.resultsVisible = true
      this.hide = false
      this.loading.search = false

      document
        .getElementById('dialog-body')
        .scrollTo({ top: 0, behavior: 'smooth' })
    }, 750),
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
