<template>
  <div class="planned-list" data-test-planned-list>
    <DetailSectionAccordion
      type="people"
      :title="$t('plannedList.title')"
      :show-tooltip="true"
      :opened="true"
    >
      <DataTable
        data-test-planned-list-table
        :title="$t('plannedList.tableSearch')"
        :attach="true"
        :loading="loading"
        :hide-header="true"
        :hide-option="true"
        :items="filteredItems"
        :headers="_tableHeaders"
        :enable-pagination="true"
        :skeleton-lines="tableConfig.limit"
        :items-per-page="tableConfig.limit"
        :down-left-button="_downLeftButton"
        @search="handleSearch($event)"
        @remove="handleDelete($event)"
      ></DataTable>

      <template #tooltip-description>
        <div class="custom-tooltip-message">
          {{
            $t('plannedList.tooltipMessage', {
              entityType: _entityTypeLabel,
              entityClass: _entityClassLabel,
            })
          }}
        </div>
      </template>
    </DetailSectionAccordion>

    <DeleteNewPlannedModal
      ref="deleteNewPlannedModal"
      :entity-type-label="_entityTypeLabel"
      :entity-class-label="_entityClassLabel"
      @handle-delete-planned="handleDeletePlanned"
    ></DeleteNewPlannedModal>
  </div>
</template>
<script>
import {
  management_performance_series_planned_create,
  management_performance_series_planned_delete,
} from '@/helpers/ability'

import { alertErrorMessage } from '@/helpers/alerts'

import DataTable from '@/components/DataTable/DataTable.vue'
import DeleteNewPlannedModal from './Partials/DeleteNewPlannedModal/DeleteNewPlannedModal.vue'

export default {
  name: 'PlannedList',
  components: {
    DataTable,
    DeleteNewPlannedModal,
  },
  props: {
    settingsId: {
      type: String,
      required: true,
    },
    settingsByEntity: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      tableConfig: {
        limit: 4,
      },
      filteredItems: [],
      items: [],
      hierarchySettings: null,
    }
  },
  computed: {
    _entityTypeLabel() {
      return this.$t(
        `plannedList.entityType-name.${this.$route.params.entityType}`
      )
    },
    _entityClassLabel() {
      return this.hierarchySettings?.entityClass
        ? this.$t(
            `plannedList.entityClass-name.${this.$route.params.entityClass}`
          )
        : ''
    },
    _canAccessSeriesPlannedCreate() {
      return this.$can('access', management_performance_series_planned_create)
    },
    _canAccessSeriesPlannedDelete() {
      return this.$can('access', management_performance_series_planned_delete)
    },
    _downLeftButton() {
      if (this._canAccessSeriesPlannedCreate) {
        return {
          click: this.handleNewPlannedForm,
          text: this.$t('plannedList.btnNewPlanned'),
          icon: 'mdi-plus',
        }
      }

      return null
    },
    _tableHeaders() {
      return [
        {
          text: this.$t('plannedList.header.name'),
          value: 'cycle',
          type: 'text-status',
        },
        {
          text: this.$t('plannedList.header.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
  },
  watch: {
    async settingsByEntity() {
      if (this.settingsByEntity) {
        this.hierarchySettings = structuredClone(this.settingsByEntity)
        this.fetchPlannedList()
      }
    },
  },
  mounted() {
    this.fetchPlannedList()
  },
  methods: {
    handleNewPlannedForm() {
      this.$emit('handle-new-planned-form')
    },
    handleDeletePlanned(event) {
      if (this.hierarchySettings) {
        delete this.hierarchySettings.id
      }

      const deleteIndex = this.hierarchySettings.newPlannedSeries.findIndex(
        el => el.cycle.id === event.id
      )

      if (deleteIndex === -1) return

      this.hierarchySettings.newPlannedSeries.splice(deleteIndex, 1)

      this.update()
    },
    async update() {
      this.$emit('submit', this.hierarchySettings)
      this.$emit('handle-hierarchy-settings', this.hierarchySettings)
    },
    async handlePayload() {
      return this.hierarchySettings
    },
    handleDelete(event) {
      this.$refs.deleteNewPlannedModal.handleOpen(event)
    },
    handleSearch(event) {
      const array = []
      if (event.name != '') {
        this.items.map(el => {
          if (el?.cycle?.label.toLowerCase().includes(event.name.toLowerCase()))
            array.push(el)
        })

        this.filteredItems = array
        return
      }

      if (event.name === '') this.fetchPlannedList()
    },
    async fetchPlannedList() {
      try {
        this.loading = true
        this.items = []

        if (
          this.hierarchySettings?.newPlannedSeries &&
          this.hierarchySettings?.newPlannedSeries.length > 0
        ) {
          this.hierarchySettings.newPlannedSeries.map(e => {
            this.items.push({
              actions: this._canAccessSeriesPlannedDelete ? ['remove'] : [],
              id: e.cycle.id,
              cycle: {
                label: e.cycle.title,
                status: '',
              },
              copyPreviousPlannedValues: e.copyPreviousPlannedValues,
            })
          })
        }

        this.filteredItems = structuredClone(this.items)

        this.loading = false
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PerformanceTab.EntitiesConfigurations.alert.get.error'),
          error
        )
        this.displayAlert(alertPayload)
      }
    },
    displayAlert(alertPayload) {
      this.$emit('display-alert', alertPayload)
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
