import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function fetchModulesPermissions(module = null) {
  const params = await constructParams(['module'], {
    module,
  })

  return (await api()).get('/management/permission-groups/available', {
    params,
  })
}

export async function fetchGroupPermissionByID(groupID) {
  return (await api()).get(`/management/permission-groups/${groupID}`)
}

export async function createPermissionGroup(form) {
  return (await api()).post('/management/permission-groups', form)
}

export async function updatePermissionGroup(form, groupID) {
  return (await api()).put(`/management/permission-groups/${groupID}`, form)
}

export async function fetchGroupPermissionHistory(
  groupID,
  limit = 20,
  offset = 0
) {
  return (await api()).get(`/management/permission-groups/${groupID}/history`, {
    params: {
      limit,
      offset,
    },
  })
}

export async function getPermissionGroups(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'label'], data)

  return (await api()).get('/management/permission-groups', {
    params: params,
    ...signal,
  })
}

export const deletePermissionGroups = async data =>
  (await api()).delete(`/management/permission-groups/${data}`)

export async function advancedSearch(data) {
  return (await api()).post('/management/permission-groups/search', data)
}

export async function fecthPermissions() {
  return (await api()).get('/management/permission-groups/permissions')
}

export async function addMassPermissionGroup(permissionGroupID, params) {
  return (await api()).put(
    `/management/people/batch/permission-groups/${permissionGroupID}`,
    {
      ...params,
    }
  )
}
export async function deleteMassPermissionGroup(permissionGroupID, data) {
  return (await api()).delete(
    `/management/people/batch/permission-groups/${permissionGroupID}`,
    {
      data: data,
    }
  )
}
