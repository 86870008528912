var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal",attrs:{"data-test-modal-permissions-page-edit-entity-role":"","title":_vm.$t('PermissionsPage.EditEntityRole.title'),"confirmButton":_vm.$t('PermissionsPage.EditEntityRole.submit'),"hasButtons":true,"width":"39.625rem"},on:{"confirmed":_vm.submit,"cancel":_vm.canceled},scopedSlots:_vm._u([{key:"no-tab",fn:function({ dialogState }){return [(dialogState)?_c('v-form',{ref:"form",staticClass:"dropdown-content",attrs:{"data-test-form-edit-entity-role":""},on:{"reset":_vm.resetForm},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('CustomInput',{ref:"roleName",staticClass:"name-input",attrs:{"data-test-name-input":"","label":_vm.$t('PermissionsPage.AddNewEntityRole.namePaper.title'),"currentValue":_vm.form.roleName,"mandatory":true,"type":"text"},on:{"handleInput":_vm.handleName}}),_c('itemSelector',{ref:"entityType",attrs:{"data-test-selector-entity-type":"","currentValue":{
          data: _vm.selectedEntityTypeRole,
          origin: 'entityTypes',
        },"watchCurrent":true,"inputConfig":{
          label: _vm.$t('PermissionsPage.EditEntityRole.typeEntity.title'),
          showAvatar: false,
          subtleMode: true,
        },"menuConfig":{
          attach: false,
          showTabs: false,
        },"menuOptions":_vm._listEntityType,"fillWidth":true,"multiple":false,"showElements":true,"readonly":true,"local-search":true}}),_c('itemSelector',{ref:"activeCycles",attrs:{"data-test-selector-active-cycles":"","currentValue":{
          data: _vm.selectedActiveCycles,
          origin: 'cycles',
        },"watchCurrent":true,"inputConfig":{
          label: _vm.$t('PermissionsPage.EditEntityRole.activeCycles.title'),
          showAvatar: false,
        },"menuConfig":{
          attach: false,
          showTabs: false,
        },"menuOptions":_vm._listActiveCycles,"fillWidth":true,"multiple":true,"showElements":true,"local-search":true},on:{"update:item":_vm.handleActiveCycles}})],1):_vm._e()]}},(_vm.deletable && _vm._deleteEntityRolePermission)?{key:"footer-left",fn:function(){return [_c('v-btn',{staticClass:"footer-left",attrs:{"data-test-modal-btn-delete":"","elevation":"0"},on:{"click":_vm.deleteEntity}},[_c('v-icon',{staticClass:"fi-rr-trash"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('Modal.buttonExcluir')))])],1)]},proxy:true}:null,{key:"footer-right",fn:function({ cancelEvent, confirmedEvent }){return [_c('v-btn',{staticClass:"button-cancel",attrs:{"data-test-modal-cancel":"","text":"","tabindex":"1"},on:{"click":cancelEvent}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Modal.buttonCancel'))}})]),_c('v-btn',{staticClass:"button-confirm",attrs:{"data-test-edit-entity-role":"","text":"","loading":_vm.loading,"tabindex":"2"},on:{"click":confirmedEvent}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('PermissionsPage.EditEntityRole.submit'))}})])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }