<template>
  <Modal
    ref="modal"
    :title="$t('PermissionsPage.EditRoles.group-roles.title')"
    :has-buttons="true"
    width="634px"
    @confirmed="confirmed"
    @cancel="canceled"
    @close="canceled"
  >
    <template #no-tab="{ dialogState }">
      <template v-if="!Object.keys(form).length">
        <div
          data-test-edit-group-role-form="loading"
          class="modal-content loading"
          :class="{ [moduleSeleted]: true }"
        >
          <v-skeleton-loader type="button" class="col"></v-skeleton-loader>
          <v-skeleton-loader
            v-if="['performance'].includes(moduleSeleted)"
            type="button"
            class="col"
          ></v-skeleton-loader>
        </div>
      </template>

      <template v-else>
        <v-form
          v-if="dialogState"
          ref="form"
          v-model="formIsValid"
          data-test-edit-group-role-form
          class="modal-content"
          :class="{ [moduleSeleted]: true }"
          lazy-validation
          @submit.prevent
        >
          <ComposeForm
            ref="ComposeForm"
            :form-name="'groups-roles'"
            :selectors="_filterSelectors"
            :lazy-submit="false"
            :storage-preferences="false"
            @compose:values="edgeValues = $event"
            @hot:loading="handleFormReady()"
            @mounted="handleDataLabel"
            @api:itens="handleDataCycles"
          ></ComposeForm>
        </v-form>
      </template>
    </template>

    <template v-if="deletable && _deleteGroupRolePermission" #footer-left>
      <v-btn
        data-test-modal-delete
        class="button-cancel button-delete"
        text
        tabindex="1"
        @click="deleteEvent()"
      >
        <v-icon size="0.875rem" class="fi-rr-trash"></v-icon>
        <span
          v-text="$t('PermissionsPage.EditRoles.group-roles.delete')"
        ></span>
      </v-btn>
    </template>

    <template #footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        tabindex="1"
        @click="cancelEvent"
      >
        <span v-text="$t('Modal.buttonCancel')"></span>
      </v-btn>

      <v-btn
        data-test-configure-groups-roles-permissions-confirm
        class="button-confirm"
        text
        :loading="formIsAnimating"
        tabindex="2"
        @click="confirmedEvent"
      >
        <span
          v-text="$t('PermissionsPage.EditRoles.group-roles.submit')"
        ></span>
      </v-btn>
    </template>
  </Modal>
</template>

<script>
import { management_performance_group_roles_delete } from '@/helpers/ability'

import {
  fetchPerformanceEntityList,
  fetchGroupPaper,
  updateGroupPaper,
} from '@/services/papers'

import { getCycles } from '@/services/cycles'

import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'

import { debounce } from 'lodash'

export default {
  name: 'EditGroupRole',

  props: {
    tabSeleted: {
      type: String,
      default: 'group-roles',
    },
    moduleSeleted: {
      type: String,
      default: 'performance',
    },
    cycleSeleted: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      deletable: false,
      handleConfirmed: debounce(async function (resetDialogCallback) {
        await this.editGroupsRolesPermissions().then(status => {
          this.formIsAnimating = false

          if (status === 'success') {
            resetDialogCallback()
          }
        })
      }, 500),

      filters: {
        default: [
          {
            id: 'label',
            label: this.$t('PermissionsPage.AddNewRoles.fields.label'),
            type: 'CustomInput',
            CustomInput: {
              mandatory: true,
              type: 'text',
            },
          },
        ],
        performance: [
          {
            id: 'activeCycleIDs',
            _label: this.$t('PermissionsPage.filter.fields.activeCycles'),
            type: 'ItemSelector',
            submit: {
              type: 'arrayOf',
              identifier: 'id',
            },
            ItemSelector: {
              localSearch: false,
              multiple: true,
            },
            eagerApi: true,
            api: {
              endpoint: getCycles,
              searchKey: 'title',
              infinity: true,
              identifier: 'id',
              hidrateFn: item => {
                return {
                  ...item,
                  label: item.title,
                }
              },
            },
          },
        ],
        'access-profiles': [
          {
            id: 'Type_Class',
            _label: this.$t('PermissionsPage.filter.fields.category'),
            type: 'ItemSelector',
            submit: {
              type: 'original',
            },
            ItemSelector: {
              localSearch: true,
              multiple: false,
              readonly: true,
              inputConfig: {
                subtleMode: true,
              },
            },
            api: {
              endpoint: fetchPerformanceEntityList,
              searchKey: null,
              infinity: false,
              identifier: 'id',
              hidrateFn: item => {
                const calcLabel = item => {
                  let label = []

                  if (item.entityType) {
                    label.push(this.$t(`entityType.${item.entityType}`))
                  }

                  if (item.entityClass) {
                    label.push(this.$t(`entityClass.${item.entityClass}`))
                  }

                  return label.join(' ')
                }

                return {
                  ...item,
                  label: calcLabel(item),
                }
              },
            },
          },
        ],
      },

      edgeValues: {},
      form: {},
      formIsAnimating: false,
      formIsValid: true,
    }
  },
  computed: {
    _deleteGroupRolePermission() {
      return this.$can('access', management_performance_group_roles_delete)
    },
    _filterSelectors() {
      return this.getFilterSelectors(this.moduleSeleted, this.tabSeleted)
    },
  },
  methods: {
    getFilterSelectors(moduleSeleted, tabSeleted) {
      const defaultFilterSelectors = this.filters.default
      const currentModuleFilterSelectors = this.filters[moduleSeleted] || []
      const currentTabFilterSelectors = this.filters[tabSeleted] || []

      const FilterSelectors = {
        fields: [
          ...defaultFilterSelectors,
          ...currentTabFilterSelectors,
          ...currentModuleFilterSelectors,
        ],
      }

      return FilterSelectors
    },

    manualValidate() {
      if (this.$refs.ComposeForm) {
        const { formValid } = this.$refs.ComposeForm.validate()

        if (formValid === false) {
          return false
        }
      }

      if (this.$refs.form) {
        const formValid = this.$refs.form.validate()

        if (formValid === false) {
          return false
        }
      }

      return true
    },

    displayAlert(alertPayload) {
      this.$emit('alert', alertPayload)
    },

    resetForm() {
      this.form = {}
      this.edgeValues = {}
      this.formIsAnimating = false
      this.formIsValid = true
    },

    deleteEvent() {
      if (!this.form?.id) {
        return
      }

      this.$emit('delete', this.form.id)
    },

    canceled() {
      this.formIsAnimating = false
      this.handleConfirmed.cancel()

      this.resetForm()
      this.$emit('cancel')
    },

    async confirmed(resetDialogCallback) {
      if (!this.manualValidate()) {
        return
      }

      this.formIsAnimating = true
      this.handleConfirmed(resetDialogCallback)
    },

    handleFormReady() {
      this.handleConfirmed.flush()
    },

    handleSuccess(payload, data) {
      const moduleDefaultCycle = data.modules.find(
        item =>
          item.module === this.moduleSeleted && typeof item?.cycle !== 'object'
      )

      let isActive = !!moduleDefaultCycle?.active

      if (this.moduleSeleted === 'performance' && this.cycleSeleted) {
        isActive = payload.activeCycleIDs.includes(this.cycleSeleted)
      }

      this.$emit('success', {
        ...data,
        active: isActive,
      })
    },

    async editGroupsRolesPermissions() {
      const payload = structuredClone(this.edgeValues)
      return await updateGroupPaper(this.form.id, {
        ...payload,
        module: this.moduleSeleted,
      })
        .then(data => {
          this.handleSuccess(payload, data)

          const alertPayload = alertSuccessMessage(
            this.$t('PermissionsPage.EditRoles.group-roles.alert.success')
          )

          this.displayAlert(alertPayload)
          return 'success'
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.EditRoles.group-roles.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
          return 'error'
        })
    },

    handleDataLabel() {
      this.$nextTick(() => {
        if (!this.$refs.ComposeForm) {
          return
        }

        const payload = {
          label: this.form.label,
        }

        this.$refs.ComposeForm.defineFieldsAsValue(payload, false)
      })
    },

    handleDataCycles({ fieldID, hidratedItens, currentValue, isDirt }) {
      if (!this.$refs.ComposeForm) {
        return
      }

      const HidrateOnFirstLoad = !Array.isArray(currentValue) && !isDirt
      const HasItensToHidrate =
        Array.isArray(hidratedItens) && hidratedItens.length

      if (
        fieldID === 'activeCycleIDs' &&
        HidrateOnFirstLoad &&
        HasItensToHidrate
      ) {
        const hidrateActiveCycles = this.filters.performance[0].api.hidrateFn
        this.$refs.ComposeForm.defineFieldsAsValue(
          {
            [fieldID]: this.form.activeCycles.map(cycle =>
              hidrateActiveCycles(cycle)
            ),
          },
          false
        )
      }
    },

    async getGroupRoleByID(groupRoleID) {
      return await fetchGroupPaper(groupRoleID)
    },

    async handleOpenDialog(GroupRolePayload) {
      this.deletable = !!GroupRolePayload.deletable
      if (!this.$refs.modal) {
        return
      }

      if (!GroupRolePayload?.id) {
        return
      }

      this.$refs.modal.handleDialog(true)

      await this.getGroupRoleByID(GroupRolePayload.id)

        .then(data => {
          this.form = {
            ...data,
            activeCycles: data?.activeCycles?.map(cycle => cycle.cycle) || [],
          }
        })
        .catch(error => {
          this.$refs.modal.handleDialog(false)

          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.EditRoles.group-roles.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
