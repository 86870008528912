<template>
  <div data-test-export-results class="page-selects-export">
    <v-menu
      content-class="elevation-neutral border-neutral"
      offset-y
      bottom
      attach
      transition="slide-y-transition"
      z-index="100"
      min-width="10.5rem"
      nudge-bottom="5"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="export-btn border-neutral"
          large
          text
          :disabled="isDownloading"
          v-on="on"
        >
          <v-icon v-if="!isDownloading" small>fi-rr-download</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            size="22"
            width="2"
            color="primary"
          ></v-progress-circular>
        </v-btn>
      </template>

      <div class="export-menu">
        <template v-for="exportable in exportablesOptions">
          <v-btn
            :key="exportable.id"
            :data-test-export-results="exportable.id"
            text
            tile
            class="export-item"
            @click="handleExportable(exportable)"
          >
            <span v-text="exportable.label"></span>
          </v-btn>
        </template>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { projectAuth } from '@/firebase/config'
import { alertErrorBus } from '@/helpers/alerts'

export default {
  name: 'ExportResults',
  props: {
    exportablesOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
    }
  },

  methods: {
    genDownloadPromise(ObjectURL, downloadLINK) {
      return new Promise((resolve, reject) => {
        const downloadCompleted = () => {
          resolve()

          setTimeout(() => {
            URL.revokeObjectURL(ObjectURL)
          }, 0)
        }

        const downloadCanceled = () => {
          reject(new Error(this.$t('exportable.errors.description')))
          URL.revokeObjectURL(ObjectURL)
        }

        downloadLINK.addEventListener('click', downloadCompleted)
        downloadLINK.addEventListener('abort', downloadCanceled)
        downloadLINK.addEventListener('error', downloadCanceled)

        downloadLINK.click()
      })
    },

    calcExportFile(
      response,
      blobProperties = {
        encoding: 'UTF-8',
      }
    ) {
      const filename = response.headers['content-disposition']
        .match(/filename=(?<filename>[^,;]+);?/)[1]
        .trim()

      const filetype = response.headers['content-type']

      blobProperties = {
        type: filetype,
        ...blobProperties,
      }
      const blob =
        response.data instanceof Blob
          ? response.data
          : new Blob([response.data], blobProperties)

      const url = window.URL.createObjectURL(blob, blobProperties)

      const link = document.createElement('a')
      link.href = url
      link.download = filename

      return { url, link }
    },

    async handleExportable(exportable) {
      this.isDownloading = true

      try {
        const response = await exportable.endpoint({ ...exportable.payload })

        if (response?.data?.link) {
          const token = await projectAuth.currentUser.getIdToken()
          const linkWithToken = new URL(response.data.link)
          linkWithToken.searchParams.set('t', token)

          window.open(linkWithToken.toString(), '_blank')
          return
        }

        if (response?.data?.code) {
          const type = {
            GroupsPage: 'groups',
            PeoplePage: 'people',
          }[exportable.name]

          window.open(
            this.$router.resolve({
              name: 'ExporterPage',
              params: {
                type,
                code: response.data.code,
              },
            }).href,
            '_blank'
          )

          return
        }

        const { url, link } = this.calcExportFile(response)

        await this.genDownloadPromise(url, link)
      } catch (error) {
        alertErrorBus(
          this.$t('exportable.errors.title', {
            format: exportable.id,
          }),
          error
        )
      } finally {
        this.isDownloading = false
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
