export function ScrollIntoViewErroClass($el) {
  const errors = $el.getElementsByClassName('error--text')

  setTimeout(() => {
    const el = errors[0]
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, 0)
}

export const ValidateCustomInputReturn = result => {
  const isInvalid = result instanceof Node
  if (isInvalid) {
    return false
  }

  return result === undefined
}
