var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"planned-form",attrs:{"data-test-planned-form":""}},[_c('div',{staticClass:"planned-form-content",attrs:{"data-test-planned-form":""}},[_c('v-card',{staticClass:"message-card",attrs:{"flat":""}},[_c('div',{staticClass:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('plannedForm.axelTip', {
              entityClass: _vm.$t(
                `plannedList.entityClass-name.${_vm.hierarchySetting?.entityClass}`
              ),
            })
          )}})])]),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ItemSelector',{ref:"cycleInput",attrs:{"inputConfig":{
          label: _vm.$t('plannedForm.cycleField'),
          showAvatar: false,
          rules: _vm.rules,
        },"menuConfig":{
          align: 'right',
          attach: false,
          showTabs: false,
        },"menuOptions":_vm._listCycles,"fillWidth":true,"multiple":false,"showElements":true,"local-search":true},on:{"update:item":function($event){return _vm.setValueCycles($event)}}}),_c('div',{staticClass:"toggle"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('plannedForm.copyPreviousPlannedValuesField')))]),_c('v-menu',{attrs:{"content-class":"custom-tooltip","max-width":"18.75rem","open-on-hover":"","open-on-click":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('i',_vm._g(_vm._b({staticClass:"fi-rr-interrogation",attrs:{"data-test-tooltip-icon":""}},'i',attrs,false),on))]}}])},[_c('v-card',{staticClass:"custom-tooltip-message",attrs:{"width":"18.75rem","data-test-tooltip-message":"","flat":""}},[_c('div',{staticClass:"custom-tooltip-message-description"},[_vm._v(" "+_vm._s(_vm.$t('plannedForm.tooltipDescription'))+" ")])])],1),_c('CustomSwitch',{on:{"input":_vm.handleToggle}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }