<template>
  <div
    data-test-item-of-list
    class="row"
    :class="{ 'bg-primary': selected }"
    v-if="node"
    @click="handleClick"
  >
    <div v-if="!cycleType" class="col-2">
      <div class="item-of-list-flex-row">
        <AvatarComponent
          :id="node.id"
          :src="node.image"
          :text="node.name"
          size="44"
        />
      </div>
    </div>
    <div :class="_getClass">
      <div class="row">
        <div class="col-12 item-list-node-name" data-test-node-name>
          <span class="cycle-type-code" v-if="cycleType">{{
            node.code + ' - '
          }}</span>
          <span :class="{ 'cycle-type-name': cycleType }">{{ node.name }}</span>
        </div>
        <div class="col-12 item-of-list-flex-row" data-test-list-row>
          {{ node.id }}
          <img
            v-if="selected"
            class="check-mark-icon"
            :src="require('@/assets/icons/checkmark.svg')"
            :alt="require('@/assets/icons/checkmark.svg')"
          />
        </div>
        <div class="col-12">
          <TextStatus
            class="text-status"
            :active="!node.inactive"
            :outlined="true"
            :cycleType="cycleType"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextStatus from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextStatus/TextStatus.vue'

export default {
  name: 'ItemOfList',
  components: {
    TextStatus,
  },
  props: {
    cycleType: {
      type: Boolean,
      default: false,
    },
    node: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
  },
  methods: {
    handleClick() {
      this.$emit('selected', this.node)
    },
  },
  computed: {
    _getClass() {
      return this.cycleType ? 'col-12 cycle-type' : 'col-10'
    },
    _status() {
      if (!this.node.inactive)
        return { label: this.$t('TreeNode.status.active'), status: 'success' }

      return { label: this.$t('TreeNode.status.inactive'), status: 'inactive' }
    },
    countPeople() {
      return [`${this.node.peopleCount} Pessoas`]
    },
    countSubGroups() {
      return [`${this.node.childrenCount} Sub grupos`]
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
