<template>
  <div class="hierarchy-content" data-test-hierarchy-cycle>
    <div
      class="hierarchy-item"
      v-for="(item, i) in items"
      :key="i"
      @click.stop.prevent="openCycle(item)"
    >
      <div class="title">
        {{ item.title }}
      </div>

      <div class="subtitle">
        {{ item.code }}
      </div>

      <div class="row">
        <div v-if="root" class="subtitle">
          <i class="fi-rr-calendar" />

          {{ formatDate(item.startDate) }} - {{ formatDate(item.endDate) }}
        </div>

        <i v-else-if="i < items.length - 1" class="fi-rr-arrow-small-down" />

        <div class="label">
          <span :class="item.status">
            {{
              $t(
                `PerformanceTab.listCycleSettings.statusLabel.${
                  item.status === 'active' ? 'active' : 'filed'
                }`
              )
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/strings/date/index'

export default {
  name: 'CycleHierarchy',

  props: {
    items: {
      type: Array,
      required: true,
    },

    root: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    openCycle(data) {
      this.$router.push({
        name: 'EditCycle',
        params: { id: data.id },
      })
    },

    formatDate,
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
