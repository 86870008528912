var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metadata-group-form",attrs:{"data-test-metadata-group":""}},[(_vm.loading)?_c('div',{staticClass:"metadata-group-form-content loading",attrs:{"data-test-metadata-group-form":"loading"}},[_c('v-skeleton-loader',{attrs:{"type":"button"}}),_c('v-skeleton-loader',{staticClass:"table",attrs:{"type":"image"}})],1):_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.newMetadataSide.show),expression:"!newMetadataSide.show"}],ref:"form",staticClass:"metadata-group-form-content",attrs:{"data-test-metadata-group-form":"","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('CustomInput',{ref:"label",staticClass:"label-input",attrs:{"data-test-metadata-group-form-label":"","currentValue":_vm.form.label,"mandatory":true,"showTextPresented":false,"viewMode":_vm._EditMode,"readonly":_vm._EditMode && !_vm._canEditMetadata,"label":_vm.$t('addMetadataGroup.form.label')},on:{"handleInput":function($event){return _vm.handleInputLabel($event)}}}),_c('DetailSectionAccordion',{attrs:{"type":"people","title":_vm.$t('addMetadataGroup.form.table.title'),"opened":true}},[_c('DataTable',{attrs:{"data-test-metadata-group-form-order":"","title":_vm.$t('addMetadataGroup.form.table.placeholder'),"attach":false,"hideHeader":true,"hideOption":true,"rolesReadonly":_vm._EditMode && !_vm._canEditMetadata,"enableDraggable":true,"canDrag":!_vm.filter.label && _vm._canAction,"hideAddElements":_vm._SelectorHideAddElements,"loadingAddOptions":_vm.MetadatasApiController.loading,"addOptions":_vm._SelectorMetadataOptions,"emptyPlaceholder":_vm.filter.label
            ? _vm.$t('addMetadataGroup.form.table.empty-filter')
            : _vm.$t('addMetadataGroup.form.table.empty'),"items":_vm._FormMetadatasFiltered,"headers":_vm.tableHeaders,"itemsPerPage":-1,"enablePagination":false,"localAddSearch":true,"ItemSelectorProps":{
          inputConfig: {
            showAvatar: false,
            showIcon: true,
          },
          menuConfig: {
            showTabs: false,
            showFooter: true,
          },
          menuProps: {
            'offset-overflow': true,
          },
        }},on:{"focus:input":function($event){return _vm.handleSearch($event)},"search:item":function($event){return _vm.handleSearch($event)},"add-data":function($event){return _vm.handleAddMetadata($event)},"delete":function($event){return _vm.handleDeleteMetadata($event)},"search":function($event){return _vm.handleFilterMetadatas($event)},"update:table-order":function($event){return _vm.handleOrderMetadata($event)}},scopedSlots:_vm._u([{key:"hover-modal-content",fn:function({ item }){return [_c('ViewModalCustomFields',{attrs:{"item":item}})]}},{key:"item-selector-footer",fn:function({ callbackCloseMenu }){return [(_vm._canCreateMetadata)?_c('v-btn',{staticClass:"add-metadata-action",attrs:{"x-small":"","text":"","data-test-metadata-group-form-add-metadata":""},on:{"click":function($event){return _vm.handleCreateMetadata(callbackCloseMenu)}}},[_c('v-icon',{attrs:{"size":"1rem"}},[_vm._v("fi-rr-plus-small")]),_c('span',{staticClass:"label-add-metadata",domProps:{"textContent":_vm._s('Adicionar novo campo personalizado')}})],1):_vm._e()]}}])})],1)],1),(_vm.newMetadataSide.show)?[_c('SideQuickRegister',{attrs:{"title":_vm.newMetadataSide.actionSelected.title,"footerButtons":_vm.newMetadataSide.footerButtons,"show":_vm.newMetadataSide.show},on:{"handleAction":_vm.handleNewMetadataSideActions}},[_c('CustomFieldForm',{ref:"CustomFieldForm",attrs:{"showAlert":_vm.showAlert,"enforcedCreationMode":true},on:{"success":_vm.handleNewMetadataSideSuccess,"close":function($event){return _vm.handleNewMetadataSideActions('close')}}})],1)]:_vm._e(),_c('ConfirmMoveModal',{ref:"ConfirmMoveModal",on:{"confirmed":_vm.handleAddMetadataConfirm}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }