<template>
  <div :data-test-permissions-page-delete-paper="type">
    <Modal
      :data-test-modal-permissions-page-delete-paper="type"
      ref="modal"
      :title="$t(`PermissionsPage.deleteRole.${type}.title`)"
      :confirmButton="$t('PermissionsPage.deleteRole.submit')"
      :hasButtons="true"
      width="634px"
      @confirmed="confirmed"
      @cancel="$emit('cancel')"
    >
      <template v-slot:no-tab>
        <div class="container-content">
          <span class="sure-text">{{
            $t(`PermissionsPage.deleteRole.${type}.sureText`)
          }}</span>
          <span class="text-remind-you"
            ><span class="c-pink">{{
              $t(`PermissionsPage.deleteRole.${type}.remembering`)
            }}</span>
            {{ $t(`PermissionsPage.deleteRole.${type}.textRemindYou`) }}
          </span>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'deleteRole',
  data() {
    return {
      type: null,
      id: null,
    }
  },
  methods: {
    handleOpenDialog(type, id) {
      this.type = type
      this.id = id
      this.$refs.modal.handleDialog()
    },
    confirmed() {
      this.$emit('deleteRoleSubmit', this.type, this.id)
      this.handleOpenDialog()
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
