<template>
  <Modal
    data-test-confirm-delete
    ref="confirmDeleteTag"
    :confirmButton="$t('PerformancePage.TagManagement.deleteDialog.confirm')"
    :hasButtons="true"
    :title="$t('PerformancePage.TagManagement.deleteDialog.title')"
    width="540px"
    @confirmed="handleConfirm"
    :style="{ 'z-index': 1000 }"
  >
    <template v-slot:no-tab>
      <div class="message strong">
        {{ $t('PerformancePage.TagManagement.deleteDialog.text1') }}
        {{ selected ? selected.label.value : '' }}

        <br />
        <br />

        <span class="highlight">{{
          $t('PerformancePage.TagManagement.deleteDialog.text2')
        }}</span
        >: {{ $t('PerformancePage.TagManagement.deleteDialog.text3') }}
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ConfirmDeleteTasg',

  data() {
    return {
      selected: null,
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('handleConfirm', this.selected)
      this.handleModal()
    },

    handleOpen(data) {
      this.selected = data
      this.handleModal()
    },

    handleModal() {
      this.$refs.confirmDeleteTag.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
