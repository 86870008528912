<template>
  <div id="comboselection" data-test-combo-selection class="d-flex">
    <v-tooltip v-if="readonly" bottom>
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="combo-input-readonly"
          data-test-activator-selection
          v-on="on"
        >
          <span
            class="mr-1 label"
            data-test-combo-label
            v-text="handleItem"
          ></span>
        </div>
      </template>

      <div v-if="actives.length > 1" class="px-2 d-flex">
        <span>{{ _actives.toString().replace(/,/g, ', ') }}</span>
      </div>
    </v-tooltip>

    <v-menu
      v-else
      v-model="menu"
      offset-y
      :disabled="options?.length < 1"
      :close-on-content-click="false"
      z-index="9999"
    >
      <template #activator="{ on: menu, attrs }">
        <v-tooltip bottom z-index="9999">
          <template #activator="{ on: tooltip }">
            <div
              class="clickable text-content"
              v-bind="attrs"
              v-on="{ ...menu, ...tooltip }"
            >
              <span class="mr-1 label">
                {{ handleItem }}
              </span>

              <v-icon v-if="options.length > 0" color="neutral1" x-small>
                fi-br-caret-down
              </v-icon>
            </div>
          </template>

          <div v-if="actives.length > 1" class="px-2 d-flex">
            <span>{{ _actives.toString().replace(/,/g, ', ') }}</span>
          </div>
        </v-tooltip>
      </template>

      <v-list v-if="options.length > 0" class="combobox-scroll" dense>
        <v-list-item-group v-model="selected" multiple>
          <template v-for="(item, i) in options">
            <v-list-item
              :key="i"
              class="px-2"
              data-test-table-combo-item
              active-class="primary-text"
              :value="item.id"
              dense
              :disabled="readonly"
            >
              <template #default="{ active }">
                <v-list-item-action class="mr-2">
                  <v-checkbox
                    :on-icon="'fi fi-sr-checkbox'"
                    :off-icon="'fi fi-rr-square'"
                    :input-value="active"
                  >
                  </v-checkbox>
                </v-list-item-action>

                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      actives: [],
      selected: [],
    }
  },

  computed: {
    handleItem() {
      if (this.options.length === 0) {
        if (this._actives.length < 1) return this.$t('dataTable.none')

        if (this._actives.length === 1) {
          const element = this.data.find(
            e => String(e.id) === String(this.actives[0])
          )

          return element ? element.label : this.$t('dataTable.none')
        }
      }

      if (this.actives.length < 1) return this.$t('dataTable.none')

      if (this.actives.length === 1) {
        const element = this.options.find(
          e => String(e.id) === String(this.actives[0])
        )

        return element ? element.label : this.$t('dataTable.none')
      }
      return `${this.actives.length} ${this.label}s`
    },

    _actives() {
      const array = []

      if (this.options.length === 0) {
        this.data.map(e => {
          array.push(e.label)
        })
        return array
      }

      this.options.map(e => {
        if (this.actives.includes(e.id)) array.push(e.label)
      })
      return array
    },
  },

  watch: {
    data() {
      this.handleSelected()
    },

    menu(v) {
      if (!v) {
        this.updateSelected()
      }
    },
  },

  beforeMount() {
    this.handleSelected()
  },

  methods: {
    handleSelected() {
      this.selected = this.data.map(e => e.id)
      this.actives = this.selected
    },

    updateSelected() {
      if (this.options.length > 0)
        this.$emit('update', {
          values: this.selected.map(e => {
            return {
              id: e,
            }
          }),
        })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
