<template>
  <div class="group-imput" data-test-lateral-menu-people>
    <div class="group-imput-item" data-test-select-people>
      <ItemSelector
        :inputConfig="{
          label: $t('LateralMenu.PermissionsMenu.peopleCombo'),
          showAvatar: true,
        }"
        :menuConfig="{
          attach: true,
        }"
        :menuOptions="listPeople"
        :fillWidth="true"
        :multiple="true"
        :showElements="true"
        @update:item="setValuePeople($event)"
        @focus:input="getOptionsPeople()"
        @search:item="getOptionsPeople($event)"
      />
    </div>

    <div class="group-imput-item" data-test-select-permission>
      <ItemSelector
        :inputConfig="{
          label: $t('LateralMenu.PermissionsMenu.functionalitiesCombo'),
          showAvatar: false,
        }"
        :menuConfig="{
          attach: true,
        }"
        :menuOptions="listFunctionalities"
        :fillWidth="true"
        :multiple="true"
        :showElements="true"
        @update:item="setValueFunctionalities($event)"
        @focus:input="getOptionsFunctionalities()"
        @search:item="getOptionsFunctionalities($event)"
      />
    </div>
  </div>
</template>

<script>
import { getPeople } from '@/services/people/index.js'
import {
  fecthPermissions,
  advancedSearch,
} from '@/services/permission-groups/index.js'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MenuPermissionsPage',
  data() {
    return {
      listPeople: [],
      listFunctionalities: [],
      form: {
        selectListPeople: [],
        selectListFunctionalities: [],
      },
      params: {
        name: null,
        offset: 0,
        limit: 20,
      },
    }
  },
  watch: {
    getReload() {
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
    getfilterPermissionParamsByName(value) {
      this.params.name = value
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
    getfilterPermissionParamsByLimit(value) {
      this.params.limit = value
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
    getfilterPermissionParamsByOffset(value) {
      this.params.offset = value
      this.lateralMenuSearch(
        this.params.name,
        this.params.offset,
        this.params.limit
      )
    },
  },
  computed: {
    ...mapGetters([
      'getReload',
      'getfilterPermissionParamsByName',
      'getfilterPermissionParamsByLimit',
      'getfilterPermissionParamsByOffset',
    ]),
  },
  methods: {
    ...mapActions(['setFilterPermission']),
    async getOptionsPeople(event) {
      const label = event == '' ? null : event
      await getPeople({ limit: 30, offset: 0, name: label }).then(res => {
        this.listPeople = []

        const options = []
        const itens = res.data.map(e => {
          return {
            ...e,
            id: e.id,
            label: e.name,
            email: e.email,
            image: e.photo,
          }
        })
        for (let i = 0; i < itens.length; i++) {
          options.push({
            id: itens[i].id,
            label: itens[i].name,
            image: itens[i].photo,
          })
        }
        this.listPeople.push({
          value: 'people',
          label: this.$t('LateralMenu.PermissionsMenu.peopleCombo'),
          type: 'listview',
          items: options,
        })
      })
    },
    async getOptionsFunctionalities(event) {
      await fecthPermissions().then(res => {
        this.listFunctionalities = []

        let list = res.data.map(e => ({ ...e, id: e.permission }))

        if (event && event.length) {
          list = list.filter(e =>
            e.label.toLowerCase().includes(event.toLowerCase())
          )
        }

        this.listFunctionalities.push({
          value: 'funcionalities',
          label: this.$t('LateralMenu.PermissionsMenu.functionalitiesCombo'),
          type: 'listview',
          items: list,
        })
      })
    },
    setValuePeople(value) {
      this.form.selectListPeople = value
      this.lateralMenuSearch()
    },
    setValueFunctionalities(value) {
      this.form.selectListFunctionalities = value
      this.lateralMenuSearch()
    },
    async lateralMenuSearch() {
      const peopleList = []
      for (let i = 0; i < this.form.selectListPeople.length; i++) {
        peopleList.push(this.form.selectListPeople[i].id)
      }

      const functionalitiesList = []
      for (let i = 0; i < this.form.selectListFunctionalities.length; i++) {
        functionalitiesList.push(this.form.selectListFunctionalities[i].id)
      }

      const data = {
        limit: this.params.limit,
        offset: this.params.offset,
        label: this.params.name,
        permissions: functionalitiesList,
        personIDs: peopleList,
      }

      try {
        const { data: res, headers } = await advancedSearch(data)
        this.setFilterPermission({
          items: res,
          countItems: parseInt(headers['x-count']) || 0,
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message)
      }
    },
    cleanFilterPermissions() {
      this.listPeople = []
      this.listFunctionalities = []
      this.form = {
        selectListPeople: [],
        selectListFunctionalities: [],
      }
      this.lateralMenuSearch()
    },
  },
  mounted() {
    this.lateralMenuSearch()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
