<template>
  <section class="table-tree" data-test-table-tree-component>
    <slot name="search"></slot>

    <div
      class="table-tree-grid-container"
      :class="{ 'container-table': configListCycle }"
    >
      <table class="table-tree-grid" data-test-table-tree>
        <TableColumns
          :columns="columns"
          :config-list-cycle="configListCycle"
        ></TableColumns>

        <template v-if="loading">
          <GhostRow
            v-for="i in 3"
            :key="i + 'ghost'"
            :count="_columns.length"
          ></GhostRow>
        </template>

        <tbody v-if="!loading && rows.length > 0" data-test-table-tree-body>
          <Row
            v-for="(row, i) in _rows"
            :key="row.id + i"
            ref="rows"
            data-test-table-tree-row
            :components="components"
            :controls="controls"
            :stories="stories"
            :row-index="i"
            :row="row"
            :show-row-button-only-root="showRowButtonOnlyRoot"
            @down-side-row-button="handleClick"
            @handleAction="handleAction"
          ></Row>
        </tbody>

        <tbody
          v-if="configListCycle === true && !loading && !rows.length"
          class="empty"
          data-test-table-tree-body-empty
        >
          <tr>
            <th colspan="5">
              <span class="empty-message">
                {{ $t('dataTable.noResults') }}
              </span>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import Row from './parts/Row.vue'
import GhostRow from './parts/GhostRow.vue'
import TableColumns from './parts/Columns.vue'

import ColumnInterface from './interfaces/ColumnInterface'

export default {
  name: 'TableTree',
  components: {
    TableColumns,
    GhostRow,
    Row,
  },
  provide() {
    return {
      hooks: this.hooks,
      stories: this.stories,
      columns: this._columns,
      controls: this.controls,
      components: this.components,
      putHistory: this.putHistory,
      hideNodesAfter: this.hideNodesAfter,
      findParentIndex: this.findParentIndex,
      downSideRowButton: this.downSideRowButton,
    }
  },
  props: {
    showRowButtonOnlyRoot: {
      type: Boolean,
      default: false,
    },
    configListCycle: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    tree: {
      type: Array,
      default: () => [],
    },
    components: {
      type: Array,
      default: () => [],
    },
    hideNodesAfter: {
      type: Number,
      default: 3,
    },
    downSideRowButton: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hooks: {
      type: Object,
      default: () => {
        return {
          beforeCloseNode: null,
          afterCloseNode: null,
          beforeNodeOpen: null,
          afterNodeOpen: null,
        }
      },
    },
  },
  data() {
    return {
      key: 0,
      rows: [],
      stories: [],
      controls: [],
      searchText: '',
      lazyIndex: 20,
    }
  },
  computed: {
    _columns() {
      return this.columns?.map(column => new ColumnInterface(column))
    },
    _rows() {
      return this.rows?.slice(0, this.lazyIndex)
    },
  },
  created() {
    window.addEventListener('scroll', this.scroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },
  methods: {
    scroll() {
      const el = this.$refs?.rows?.[this.lazyIndex - 1]?.$el
      if (el) {
        const rect = el.getBoundingClientRect()
        if (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          this.lazyIndex += 20
        }
      }
    },
    nodeToRow(node, nivel, parent = null, rows, controls) {
      if (!node.key) node.key = this.nextKey(this.key)

      const control = {
        key: node.key,
        open: false,
        nivel,
        parent,
        visible: !nivel,
        hideButton: false,
      }

      rows.push(node)
      controls.push(control)

      if (node?.children?.length)
        return this.nodesToRows(node.children, nivel + 1, node, rows, controls)
    },
    async nodesToRows(childs, nivel, parent, rows, controls) {
      return childs.forEach(node =>
        this.nodeToRow(node, nivel, parent, rows, controls)
      )
    },
    putHistory(nodeIndex, parent, control, row) {
      const parentIndex = this.findParentIndex(parent)
      const storyIndex = this.stories.findIndex(
        story => story.key === control.key
      )

      if (storyIndex !== -1) {
        this.stories.splice(storyIndex)
      } else {
        this.stories.push({
          row,
          nodeIndex,
          parentIndex,
          nivel: control.nivel,
          key: control.key,
        })
      }
      return parentIndex
    },
    findParentIndex(parent) {
      return parent ? this.rows.findIndex(row => row.key === parent.key) : null
    },
    findRowIndexBy(key, val) {
      return this.rows.findIndex(row => row[key] === val)
    },
    findRowBy(key, val) {
      return this.rows.find(row => row[key] === val)
    },
    handleClick(button, row, rowIndex) {
      this.$emit(button, button, row, rowIndex)
    },
    handleAction(act, row, rowIndex, control) {
      this.$emit('handleAction', act, row, rowIndex, control)
    },
    insertChild(rowIndex, child, reset = true, open = true) {
      const row = this.rows[rowIndex]
      const rowControl = this.controls[rowIndex]
      const hasChildren = row?.children?.length

      if (row?.children === undefined) row.children = []
      if (reset) this.removeChilds(rowIndex)

      child.key = this.nextKey(this.key)

      const nextRow = rowIndex + 1
      const nivel = this.controls[rowIndex].nivel + 1
      const control = {
        key: child.key,
        open: false,
        nivel: nivel,
        parent: row,
        visible: open,
        hideButton: false,
      }

      if (!hasChildren && open) this.controls[rowIndex].open = true

      const hidingNodesAfter =
        control.nivel > this.hideNodesAfter && !hasChildren
      if (hidingNodesAfter) {
        this.controls[rowIndex].open = false
        control.visible = false
      }

      row.children.unshift(child)
      this.controls.splice(nextRow, 0, control)
      this.rows.splice(nextRow, 0, child)

      return { row, child, hidingNodesAfter, rowControl }
    },
    insertChildren(rowIndex, childs) {
      const row = this.rows[rowIndex]

      if (row.children === undefined) row.children = []
      if (row.children.length) this.removeChilds(rowIndex)

      childs.forEach(child => this.insertChild(rowIndex, child, false, false))
    },
    insertCopy(rowIndex, child) {
      const row = this.rows[rowIndex]
      const hasChildren = row?.children?.length

      child = this.realDeepCopy(child)

      const nextRow = rowIndex + 1
      const nivel = this.controls[rowIndex].nivel + 1
      const control = {
        key: child.key,
        open: false,
        nivel: nivel,
        parent: row,
        visible: this.controls[rowIndex].open,
        hideButton: false,
      }

      if (control.nivel > this.hideNodesAfter && !hasChildren) {
        this.controls[rowIndex].open = false
        control.visible = false
      }

      row.children.unshift(child)
      this.controls.splice(nextRow, 0, control)
      this.rows.splice(nextRow, 0, child)

      return { target: row, child }
    },
    realDeepCopy(node) {
      let obj = { key: this.nextKey(this.key) }
      for (let key in node) {
        if (node[key] === null) {
          obj[key] = null
        } else if (key === 'children' || key === '_children') {
          obj[key] = []
        } else if (Array.isArray(node[key])) {
          obj[key] = node[key]
        } else if (typeof node[key] === 'object') {
          obj[key] = this.realDeepCopy(node[key])
        } else {
          obj[key] = node[key]
        }
      }
      return obj
    },
    async toggleRow(rowIndex) {
      this.$refs.rows[rowIndex].toggleNodeChildren(this.controls, rowIndex)
    },
    async handleToggleRow(row, control, isOpened, rowIndex) {
      this.$refs.rows[rowIndex].handleToggleChildren(
        row,
        control,
        isOpened,
        this.controls,
        rowIndex
      )
    },
    editRow(rowIndex, editedNode) {
      const blocked = ['children', '_children', 'key']
      const row = this.rows[rowIndex]

      for (let key in editedNode) {
        if (!blocked.includes(key)) row[key] = editedNode[key]
      }
    },
    async moveRow(rowIndex, targetIndex, keepOpen = false) {
      const target = this.rows[targetIndex]
      const visible = this.controls[targetIndex]?.open
      const oldParent = this.controls[rowIndex]?.parent

      const { childs, parents } = this.getRowWithChilds(rowIndex)

      await this.removeRow(rowIndex)

      parents[0] = target
      childs.forEach((node, i) => {
        const opening = keepOpen || (visible && !i)
        const parentIndex = this.findParentIndex(parents[i])
        this.insertChild(parentIndex, childs[i], false, opening)
      })

      return { target, oldParent }
    },
    async removeRow(rowIndex) {
      const row = this.rows[rowIndex]
      const parent = this.controls[rowIndex]?.parent

      if (!parent) return

      const childIndex = parent.children.findIndex(child => {
        child.key === row.key
      })

      this.removeChilds(rowIndex)
      this.rows.splice(rowIndex, 1)
      this.controls.splice(rowIndex, 1)
      if (childIndex !== -1) parent.children.splice(childIndex, 1)

      return parent
    },
    removeChilds(rowIndex) {
      const nivel = this.controls[rowIndex].nivel

      this.rows[rowIndex].children = []

      rowIndex++
      while (this.controls[rowIndex]?.nivel > nivel) {
        this.rows.splice(rowIndex, 1)
        this.controls.splice(rowIndex, 1)

        if (!this.controls[rowIndex]) break
      }
    },
    async overrideChildren(hierarchy, targetIndex = 0) {
      let row = this.rows[targetIndex]
      row.children = [hierarchy]

      await this.resetTable()
      this.toggleRow(0)

      do {
        if (row.children === undefined) {
          row.children = []
          return row
        }

        if (!this.controls[targetIndex].open) this.toggleRow(targetIndex)

        row = row.children[0]

        targetIndex++
      } while (row?.children?.length)

      return row
    },
    getRowWithChilds(rowIndex) {
      const nivel = this.controls[rowIndex].nivel
      const childs = [this.rows[rowIndex]]
      const parents = [this.controls[rowIndex].parent]

      rowIndex++
      while (this.controls[rowIndex].nivel > nivel) {
        childs.push(this.rows[rowIndex])
        parents.push(this.controls[rowIndex].parent)
        rowIndex++

        if (!this.controls[rowIndex]) break
      }

      return { childs, parents }
    },
    async resetTable() {
      this.rows = []
      this.stories = []
      this.controls = []

      const rows = []
      const controls = []

      this.nodesToRows(this.tree, 0, null, rows, controls)

      this.rows = rows
      this.controls = controls
    },
    nextKey(key) {
      this.key = key + 1
      return key
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
