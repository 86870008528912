<template>
  <Modal
    ref="modal"
    :title="$t('peopleList.advanced')"
    width="80%"
    :has-tab="false"
  >
    <template v-slot:[`no-tab`]>
      <DataTable
        class="px-2"
        :title="$t('peopleList.title')"
        :headers="headers"
        :items="people.filteredItems"
        :hideHeader="true"
        :hideOption="true"
        :contentStyle="false"
        :selectionOptions="people.selectionOptions"
        :addOptions="people.options"
        :itemsPerPage="7"
        :rolesReadonly="false"
        :uniqueSelectionOptions="true"
        @search="searchPerson"
        @add-data="addPerson"
        @update-data="optionsSelected"
        @delete="deletePerson"
      >
        <template v-slot:filter>
          <FilterSelection
            :show="rolesFilterOpened"
            :options="people.selectionOptions"
            @handleVision="handleRolesFilterVision"
            @handleOptionChange="handleRoleOptionChange"
          >
            <div class="filter-roles" @click="handleRolesFilterMenu">
              <i class="fi fi-rr-filter mr-1 filter-roles-icon-mt"></i>
              <span> {{ filterTitle }}</span>
              <i class="ml-1 filter-roles-icon-mt" :class="rolesFilterIcon"></i>
            </div>
          </FilterSelection>
        </template>
      </DataTable>
    </template>
  </Modal>
</template>

<script>
import FilterSelection from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Partials/FilterSelection/FilterSelection.vue'
import DataTable from '@/components/DataTable/DataTable.vue'

export default {
  name: 'PeopleViewModal',
  components: {
    FilterSelection,
    DataTable,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasPermission: {
      type: Boolean,
      default: true,
    },
    rolesItens: {
      type: Array,
      default: () => [],
    },
    peopleItens: {
      type: Array,
      default: () => [],
    },
    people: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      peopleArray: [],
      countRolesAdded: 0,
      rolesFilterOpened: false,
      rolesSelected: [[], [], []],
    }
  },
  watch: {
    rolesFilterOpened(newValue) {
      if (!newValue) {
        this.$emit('filterPeople', this.rolesSelected, this.countRolesAdded)
      }
    },
  },
  computed: {
    filterTitle() {
      if (this.countRolesAdded === 1) {
        return this.$t('GroupsPage.accordion.people.filterRole', {
          num: this.countRolesAdded,
        })
      }
      if (this.countRolesAdded > 1) {
        return this.$t('GroupsPage.accordion.people.filterNRoles', {
          num: this.countRolesAdded,
        })
      }
      return this.$t('GroupsPage.accordion.people.filterAllRoles')
    },
    rolesFilterIcon() {
      if (this.rolesFilterOpened) {
        return 'fi-br-caret-up'
      }
      return 'fi-br-caret-down'
    },
    headers() {
      const header = [
        {
          text: this.$t('GroupsPage.accordion.people.headers.name'),
          value: 'name',
          type: 'entity-item',
        },
        {
          text: this.$t('GroupsPage.accordion.people.headers.engagement'),
          value: 'engagement',
          type: 'combo-selection',
        },
        {
          text: this.$t('GroupsPage.accordion.people.headers.growth'),
          value: 'growth',
          type: 'combo-selection',
        },
        {
          text: this.$t('GroupsPage.accordion.people.headers.performance'),
          value: 'performance',
          type: 'combo-selection',
        },
        {
          text: this.$t('GroupsPage.accordion.people.headers.status'),
          value: 'access',
          type: 'text-status',
        },
        {
          text: this.$t('GroupsPage.accordion.people.headers.actions'),
          value: 'actions',
          type: 'action-buttons',
        },
      ]
      if (this.hasPermission) {
        return header
      }
      return header.splice(0, 3)
    },
    selectorResponsableOptions() {
      if (this.hasPermission) {
        return [
          {
            value: 'responsable',
            label: 'Responsável',
            type: 'listview',
            items: this.peopleItens,
          },
        ]
      }
      return []
    },
  },

  methods: {
    deletePerson(event) {
      this.$emit('deletePerson', event)
    },
    searchPerson(event) {
      this.$emit('searchPerson', event)
    },
    optionsSelected(event) {
      this.$emit('optionsSelected', event)
    },
    addPerson(event) {
      this.$emit('addPerson', event)
    },
    handleRolesFilterMenu() {
      this.rolesFilterOpened = !this.rolesFilterOpened
    },
    handleRoleOptionChange(role, id) {
      if (this.rolesSelected[id].includes(role)) {
        this.countRolesAdded--
        this.rolesSelected[id] = this.rolesSelected[id].filter(
          item => item.id !== role.id
        )
      } else {
        this.countRolesAdded++
        this.rolesSelected[id].push(role)
      }
    },
    handleRolesFilterVision(value) {
      this.rolesFilterOpened = value
    },
    handleOpenDialog() {
      this.$refs.modal.handleDialog()
    },
  },
  mounted() {},
}
</script>

<style src="./style.scss" lang="scss" scoped />
