<template>
  <div
    data-test-entity-box
    class="entity-box"
    :data-test-entity-type="entity.entityType"
    :data-test-entity-class="entity.entityClass"
  >
    <section class="entity-header">
      <img :src="_icon" />
      <span>{{ entity.label }}</span>
      <div data-test-entity-edit @click="handleClick">
        Alterar<v-icon x-small>fi-rr-pencil</v-icon>
      </div>
    </section>

    <section class="entity-body">
      <div v-for="(key, value) in entity.options" :key="value">
        {{ value }}
        <v-icon x-small :class="color(key)">
          mdi mdi-checkbox-blank-circle</v-icon
        >
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'entityBox',
  props: {
    entity: Object,
  },
  inject: ['openEntityOkrForm'],
  computed: {
    _icon() {
      return this.entity.icon
        ? this.entity.icon
        : require('@/assets/icons/empty.png')
    },
  },
  methods: {
    color(value) {
      if (value === null) return 'gray-color'
      return value ? 'green-color' : 'red-color'
    },
    handleClick() {
      const entityType = this.entity.entityType
      const entityClass = this.entity.entityClass
      const entityId = this.entity.id

      this.openEntityOkrForm('editEntityOkr', {
        entityType,
        entityClass,
        entityId,
      })
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
