var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MenuForm',{ref:"MenuForm",attrs:{"kebabNameMenu":"permission-filter","selectors":_vm._filterSelectors,"menu":{
    title: _vm.$t('PermissionsPage.filter.title'),
    footer: {
      cancel: _vm.$t('PermissionsPage.filter.cancel'),
      apply: _vm.$t('PermissionsPage.filter.apply'),
    },
  },"menuProps":{
    minWidth: '18rem',
    maxWidth: '18rem',
    nudgeBottom: '3px',
  },"restorePreviousState":true},on:{"form:values":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ formHasValue, cleanValueCallback }){return [_c('v-btn',{staticClass:"permission-filter",class:{
        active: formHasValue,
      },attrs:{"elevation":"0","text":""}},[_c('v-icon',{staticClass:"fi-rr-filter",attrs:{"size":"0.75rem"}}),_c('div',[_c('span',{domProps:{"textContent":_vm._s('Filtros')}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(formHasValue),expression:"formHasValue"}],attrs:{"data-test-menu-filters-clear":"permission","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return cleanValueCallback.apply(null, arguments)}}},[_c('v-icon',{staticClass:"clearable fi-rr-cross-small",attrs:{"size":"0.875rem"}})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }