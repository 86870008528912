<template>
  <div data-test-cycle-item id="cycle-item">
    <div data-test-cycle-item-semester class="cycle-semester">
      {{ data.semester }}
    </div>
    <div data-test-cycle-item-period class="cycle-period">
      {{ data.period }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CycleItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" scoped lang="scss"></style>
