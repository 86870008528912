var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cycle-selection","data-test-cycle-selection":""}},[_c('ItemSelector',{ref:"cycleInput",attrs:{"inputConfig":{
      label: _vm.$t('PermissionsPage.cycle.title'),
      showAvatar: false,
      closeOnSelect: true,
    },"menuConfig":{
      attach: true,
      showTabs: false,
    },"currentValue":_vm.currentCycle,"menuOptions":_vm._cycleOptions,"infinityScroll":true,"persistent":true,"watchCurrent":true},on:{"update:item":function($event){return _vm.setCycle($event)},"infinity:scroll":function($event){return _vm.handleCycleScroll($event)},"search:item":function($event){return _vm.searchCycle($event)},"focus:input":function($event){_vm.searchCycles = []}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }