<template>
  <ItemSelector
    data-test-selector-active-cycles
    ref="activeCycles"
    :currentValue="{
      data: selectedActiveCycles,
      origin: 'cycles',
    }"
    :watchCurrent="true"
    :inputConfig="{
      label: $t('PermissionsPage.AddNewEntityRole.activeCycles.title'),
      showAvatar: false,
    }"
    :menuConfig="{
      attach: false,
      showTabs: false,
    }"
    :menuOptions="_listActiveCycles"
    :fillWidth="true"
    :multiple="true"
    :showElements="true"
    :local-search="true"
    @update:item="handleActiveCycle"
  />
</template>

<script>
import { getCycles } from '@/services/cycles'
export default {
  name: 'ActiveCycles',
  data() {
    return {
      selectedActiveCycles: null,
      listActiveCycles: [],
    }
  },
  beforeMount() {
    this.fetchGetCycles()
  },
  computed: {
    _listActiveCycles() {
      return [
        {
          value: 'cycles',
          type: 'listview',
          label: this.$t(
            'PermissionsPage.AddNewEntityRole.cyclesActives.title'
          ),
          items: this.listActiveCycles,
        },
      ]
    },
  },

  methods: {
    handleActiveCycle(event) {
      this.$emit('setActiveCycle', event)
    },
    async fetchGetCycles(
      payload = {
        limit: 500,
      }
    ) {
      return await getCycles(payload)
        .then(res => {
          this.listActiveCycles = this.formatCyclesActives(res.data)
          this.selectedActiveCycles = JSON.parse(
            JSON.stringify(this.listActiveCycles)
          )
          this.$emit('setActiveCycle', this.selectedActiveCycles)
        })
        .catch(error => {
          const message = {
            title: this.$t('AddNewEntityRole.activeCycles.alerts.error'),
            description: error?.response?.data?.message,
            type: 'error',
            hasLeftBorder: true,
          }
          this.$refs.AlertBar.displayAlert(message)
        })
    },
    formatCyclesActives(data) {
      return data.map(cycle => ({ ...cycle, label: cycle.title }))
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
