<template>
  <div v-if="loading.content" class="sidebar">
    <v-skeleton-loader
      class="mb-6"
      type="image"
      height="96"
    ></v-skeleton-loader>

    <v-skeleton-loader class="mb-6" type="image"></v-skeleton-loader>

    <v-row class="mb-6">
      <v-col v-for="n in 6" :key="n" cols="6">
        <v-skeleton-loader
          type="list-item-two-line"
          max-height="54px"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-skeleton-loader type="table-tbody" class="mb-6"></v-skeleton-loader>

    <v-skeleton-loader
      v-for="n in 6"
      :key="n"
      type="list-item-two-line"
      max-height="54px"
    ></v-skeleton-loader>
  </div>

  <v-form v-else ref="form" v-model="valid" class="sidebar">
    <div data-test-form class="person-form">
      <div class="header-data">
        <div class="horizontal-inputs-add mt-4 mb-8">
          <div class="container-add">
            <UploadIMG
              :link-image="form.photo"
              :readonly="!(_canEditUser || _canEditDetail)"
              @handleInput="handleInput($event, 'photo')"
            ></UploadIMG>
          </div>

          <div class="grid-input-size">
            <CustomInput
              data-test-name-input
              class="name-input"
              :current-value="person.name"
              :mandatory="true"
              :view-mode="true"
              :readonly="!(_canEditUser || _canEditDetail)"
              @handleInput="handleInput($event, 'name')"
            ></CustomInput>
          </div>

          <div class="chip-content">
            <div class="label-status">Status</div>

            <v-chip
              :class="`custom-chip custom-chip-${person.status || 'default'}`"
            >
              {{
                person.status
                  ? $t(`PeoplePage.viewPerson.about.status.${person.status}`)
                  : '--'
              }}
            </v-chip>
          </div>
        </div>

        <div class="horizontal-inputs-add">
          <v-row>
            <v-col cols="12" md="6" class="pr-md-2 mb-4">
              <CustomInput
                data-test-email-input
                :label="$t('PeoplePage.viewPerson.about.email')"
                :current-value="person.email"
                :mandatory="true"
                :view-mode="true"
                :readonly="!(_canEditUser || _canEditDetail)"
                @handleInput="handleInput($event, 'email')"
              ></CustomInput>
            </v-col>

            <v-col cols="12" md="6" class="pl-md-2 mb-4">
              <CustomInput
                ref="code"
                data-test-code-input
                :label="$t('PeoplePage.viewPerson.about.code')"
                :current-value="person.code"
                :mandatory="false"
                :view-mode="true"
                :readonly="!(_canEditUser || _canEditDetail)"
                @handleInput="handleInput($event.trim(), 'code')"
              ></CustomInput>
            </v-col>

            <v-col cols="12" md="6" class="pr-md-2 mb-4 mb-md-0">
              <CustomInput
                data-test-phone-input
                mask="+## (##) #####-####"
                :label="$t('PeoplePage.viewPerson.about.phoneNumber')"
                :current-value="person.phoneNumber"
                :view-mode="true"
                :readonly="!(_canEditUser || _canEditDetail)"
                @handleInput="
                  handleInput($event.replaceAll(/[^0-9]/gim, ''), 'phoneNumber')
                "
              ></CustomInput>
            </v-col>
          </v-row>
        </div>
      </div>

      <DetailSectionAccordion
        id="accordion-Add-Information"
        data-test-details
        type="details"
        :title="$t('PeoplePage.viewPerson.details.title')"
        :opened="true"
      >
        <div id="accordion-Add-Information-body">
          <v-row>
            <v-col cols="12" md="6" class="pr-md-2 mb-4">
              <ItemSelector
                :input-config="{
                  label: $t('PeoplePage.viewPerson.details.permissionGroups'),
                  showAvatar: false,
                  subtleMode: true,
                  rules: rules,
                }"
                :menu-config="{
                  attach: true,
                }"
                :menu-options="_permissionGroupsOptions"
                :current-value="
                  person.permissionGroups
                    ? {
                        data: person.permissionGroups,
                        origin: 'groups',
                      }
                    : null
                "
                :fill-width="true"
                :multiple="true"
                :show-avatar="false"
                :show-elements="true"
                :persistent="true"
                :loading="loading.permissionGroups"
                :readonly="!(_canEditUser || _canEditDetail)"
                @update:item="handleInput($event, 'permissionGroups')"
                @search:item="getPermissionGroups($event)"
                @focus:input="getPermissionGroups()"
              ></ItemSelector>
            </v-col>

            <v-col cols="12" md="6" class="pl-md-2 mb-4">
              <ItemSelector
                :input-config="{
                  label: $t('PeoplePage.viewPerson.details.directManager'),
                  subtleMode: true,
                }"
                :menu-config="{
                  attach: true,
                }"
                :menu-options="_managersOptions"
                :current-value="
                  person.directManager
                    ? {
                        data: [
                          {
                            id: person.directManager.id,
                            label: person.directManager.name,
                            inactive:
                              person.directManager?.status === 'inactive',
                          },
                        ],
                        origin: 'managers',
                      }
                    : null
                "
                :fill-width="true"
                :multiple="false"
                :show-avatar="true"
                :show-elements="true"
                :persistent="true"
                :loading="loading.directManagers"
                :readonly="!(_canEditUser || _canEditDetail)"
                @update:item="handleInput($event, 'directManager')"
                @search:item="getDirectManagers($event)"
                @focus:input="getDirectManagers()"
              ></ItemSelector>
            </v-col>

            <v-col cols="12" md="6" class="pr-md-2 mb-4">
              <ItemSelector
                data-test-occupation-input
                :input-config="{
                  label: $t('PeoplePage.viewPerson.details.role'),
                  subtleMode: true,
                }"
                :menu-config="{
                  attach: true,
                }"
                :menu-options="_occupationRolesOptions"
                :current-value="
                  person.occupation
                    ? {
                        data: [
                          {
                            id: person.occupation.id,
                            label: person.occupation.title,
                          },
                        ],
                        origin: 'occupationRoles',
                      }
                    : null
                "
                :fill-width="true"
                :multiple="false"
                :local-search="true"
                :show-elements="true"
                :loading="loading.occupationRoles"
                :readonly="!(_canEditUser || _canEditDetail)"
                @update:item="handleInput($event, 'occupation')"
                @focus:input="getPeopleRoles()"
              ></ItemSelector>
            </v-col>

            <v-col cols="12" md="6" class="pl-md-2 mb-4">
              <DatePicker
                :input-config="{
                  label: $t('PeoplePage.viewPerson.details.birthDate'),
                  subtleMode: true,
                }"
                :menu-config="{
                  align: 'left',
                  attach: true,
                }"
                :current-value="person.birthDate?.split('T')?.[0] || ''"
                :readonly="!(_canEditUser || _canEditDetail)"
                @update:item="handleInput($event, 'birthDate')"
              ></DatePicker>
            </v-col>
          </v-row>
        </div>
      </DetailSectionAccordion>

      <DetailSectionAccordion
        id="accordion-Add-Information"
        data-test-groups
        type="people"
        :title="$t('PeoplePage.viewPerson.groups.title')"
        :opened="true"
      >
        <DataTable
          ref="dataTable"
          class="data-table-margin-resized"
          :title="$t('PeoplePage.viewPerson.groups.title')"
          :items="groupsSearch.length > 0 ? groupsSearch : form.groups"
          :headers="_headers"
          :add-options="_selectorGroupOptions"
          :empty-placeholder="
            $t('PeoplePage.viewPerson.groups.dataTable.empty')
          "
          :unique-selection-options="true"
          :hide-header="true"
          :hide-option="true"
          :attach="true"
          :content-style="false"
          :hide-add-elements="form.groups.map(el => el.id)"
          :items-per-page="7"
          :roles-readonly="!(_canEditUser || _canEditTableGroups)"
          @add-data="handleAddGroup($event)"
          @update-data="updateGroup($event)"
          @delete="removeGroup($event)"
          @search:item="getGroups($event)"
          @search="searchGroup($event)"
          @focus:input="getGroups()"
        >
        </DataTable>
      </DetailSectionAccordion>

      <div v-if="attributes.length">
        <section v-for="(item, i) in attributes" :key="i">
          <DetailSectionAccordion
            v-if="showSection(item) && item.items.length > 0"
            id="accordion-Demographic-Attributes"
            data-test-attributes
            :type="'details'"
            :title="`${$t('PeoplePage.viewPerson.attributes.title')}${
              item.id ? `: ${item.label}` : ''
            }`"
            :opened="true"
          >
            <div id="accordion-Add-Information-body">
              <v-row no-gutters>
                <v-col
                  v-for="(subitem, n) in item.items"
                  :key="n"
                  cols="12"
                  md="6"
                  class="px-1 mb-4"
                >
                  <ItemSelector
                    :multiple="true"
                    :fill-width="true"
                    :local-search="true"
                    :readonly="
                      subitem.readonly || !(_canEditUser || _canEditAttributes)
                    "
                    :input-config="{
                      label: subitem.label,
                      subtleMode: true,
                      showAvatar: false,
                      clearable: true,
                    }"
                    :menu-config="{
                      attach: true,
                      showTabs: false,
                    }"
                    :current-value="{
                      data: getAttributesValues(subitem),
                      origin: 'meta',
                    }"
                    :menu-options="[
                      {
                        value: 'meta',
                        type: 'listview',
                        items: subitem.values,
                      },
                    ]"
                    @update:item="handleChangeAttribute(subitem, $event)"
                  ></ItemSelector>
                </v-col>
              </v-row>
            </div>
          </DetailSectionAccordion>
        </section>
      </div>

      <div>
        <section id="entity-history" class="form-content">
          <DetailHistoryAlteration
            ref="historySection"
            :loading="loading.history"
            :history="history.data"
            @infinity:entity-history="getHistory()"
          ></DetailHistoryAlteration>
        </section>
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import {
  updatePerson,
  changePersonStatus,
  resendPersonInvite,
  getPerson,
  getPeople,
  getPeopleRoles,
  deletePerson,
  getHistory,
} from '@/services/people'

import {
  management_users_edit,
  management_users_edit_detail,
  management_users_edit_groups,
  management_users_edit_attributes,
} from '@/helpers/ability'

import { getPeopleAttributes } from '@/services/attributes'
import {
  getGroups,
  updatePersonGroup,
  removePersonGroup,
} from '@/services/groups'
import { getPermissionGroups } from '@/services/permission-groups'
import { getGroupsRoles } from '@/services/papers'
import UploadIMG from '../Partials/UploadIMG/UploadIMG'
import DataTable from '@/components/DataTable/DataTable.vue'
import DetailHistoryAlteration from '@/components/DetailHistoryAlteration/DetailHistoryAlteration.vue'

export default {
  name: 'PersonForm',

  components: {
    UploadIMG,
    DataTable,
    DetailHistoryAlteration,
  },

  inject: ['configSideQuickRegister'],

  data() {
    return {
      ready: [],
      intersectOn: {
        'entity-history': false,
      },
      interval: null,
      valid: false,
      loading: {
        content: true,
        permissionGroups: false,
        directManagers: false,
        occupationRoles: false,
        groups: false,
        history: false,
      },
      person: null,
      occupationRoles: [],
      directManagers: [],
      permissionGroups: [],
      groups: [],
      groupsSearch: '',
      groupRoles: {},
      attributes: [],
      history: {
        data: [],
        infinite: true,
        limit: 20,
        offset: 0,
      },
      form: {
        photo: '',
        name: '',
        email: '',
        phoneNumber: '',
        permissionGroups: [],
        directManager: {},
        occupation: {},
        groups: [],
        attributes: [],
        language: 'pt-BR', // mock
      },
      oldPhoneNumber: null,
      rules: [
        v =>
          (!!v && v.length > 0) ||
          this.$t('PeoplePage.viewPerson.requiredField'),
      ],
    }
  },

  computed: {
    statusPallete() {
      return {
        'at-risk': {
          title: this.$t('ViewObjective.chart.at-risk'),
          color: '#E71616',
        },
        'on-time': {
          title: this.$t('ViewObjective.chart.on-time'),
          color: '#0AB057',
        },
        delayed: {
          title: this.$t('ViewObjective.chart.delayed'),
          color: '#FBAD17',
        },
      }
    },
    attributePallete() {
      return {
        code: {
          title: this.$t('ViewObjective.historyAlteration.attributes.code'),
          inputType: 'textarea',
        },
      }
    },
    _canEditUser() {
      return this.$can('access', management_users_edit)
    },
    _canEditDetail() {
      return this.$can('access', management_users_edit_detail)
    },
    _canEditTableGroups() {
      return this.$can('access', management_users_edit_groups)
    },
    _canEditAttributes() {
      return this.$can('access', management_users_edit_attributes)
    },
    _headers() {
      return [
        {
          text: this.$t('PeoplePage.viewPerson.groups.dataTable.headers.group'),
          value: 'group',
          type: 'entity-item',
          maxWidth: '180px',
        },
        {
          text: this.$t(
            'PeoplePage.viewPerson.groups.dataTable.headers.engagement'
          ),
          value: 'engagement',
          type: 'combo-selection',
          options: this.groupRoles?.engagement || [],
        },
        {
          text: this.$t(
            'PeoplePage.viewPerson.groups.dataTable.headers.growth'
          ),
          value: 'growth',
          type: 'combo-selection',
          options: this.groupRoles?.growth || [],
        },
        {
          text: this.$t(
            'PeoplePage.viewPerson.groups.dataTable.headers.performance'
          ),
          value: 'performance',
          type: 'combo-selection',
          options: this.groupRoles?.performance || [],
        },
        {
          text: this.$t(
            'PeoplePage.viewPerson.groups.dataTable.headers.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
        },
      ]
    },

    _permissionGroupsOptions() {
      return [
        {
          value: 'groups',
          label: 'Grupos de permissões',
          type: 'listview',
          items: this.permissionGroups.map(el => {
            return {
              id: el.id,
              image: el.image,
              label: el.label,
            }
          }),
        },
      ]
    },

    _managersOptions() {
      return [
        {
          value: 'managers',
          label: 'Pessoas',
          type: 'listview',
          items: this.directManagers.map(el => {
            return {
              id: el.id,
              image: el.photo,
              label: el.name,
              inactive: el.status === 'inactive',
            }
          }),
        },
      ]
    },

    _occupationRolesOptions() {
      return [
        {
          value: 'occupationRoles',
          label: this.$t('PeoplePage.viewPerson.details.role'),
          type: 'listview',
          items: this.occupationRoles,
        },
      ]
    },

    _selectorGroupOptions() {
      return [
        {
          value: 'groups',
          label: 'Grupos',
          type: 'listview',
          items: this.groups.map(el => {
            return {
              id: el.id,
              image: el.image,
              label: el.name,
            }
          }),
        },
      ]
    },
  },

  watch: {
    attributes() {
      this.form.attributes = []
    },
  },

  beforeMount() {
    this.getPeopleAttributes()
    this.getGroupRoles()
    this.getPeopleRoles()
    this.getPerson()
    this.getHistory()
  },

  methods: {
    ...mapActions(['setCurrentPage']),

    async getHistory() {
      if (!this.history.infinite) return

      const { limit, offset } = this.history
      const { data, headers } = await getHistory(
        this.$route.params.personId,
        limit,
        offset
      )

      if (data.message || data.length === 0) {
        this.history.infinite = false
      } else {
        this.history.data = this.history.data.concat(data)
        this.history.offset = this.history.data.length
      }

      const xCount = parseInt(headers['x-count']) || 0
      if (xCount <= this.history.offset && xCount > 0)
        this.history.infinite = false
    },

    getColumnActionGroups() {
      let action = []
      if (this._canEditTableGroups) {
        action = ['delete']
      }
      return action
    },

    calcPayload() {
      const nullOrValue = value => {
        if ([null, undefined].includes(value)) return null
        if (Array.isArray(value) && value.length === 0) return null
        if (typeof value === 'string' && value.trim().length === 0) return null
        if (typeof value === 'object' && Object.keys(value).length === 0)
          return null
        if (Object.keys(value).includes('id')) {
          return !value?.id ? null : { id: value.id }
        }

        return value
      }

      const getPhoneNumber = value => {
        value = nullOrValue(value)
        if (!value) return null

        return '+' + value.replaceAll(/[^0-9]/gim, '')
      }

      return {
        name: this.form.name,
        email: this.form.email,
        language: this.form.language,

        photo: nullOrValue(this.form.photo),
        code: nullOrValue(this.form.code),
        phoneNumber: getPhoneNumber(this.form.phoneNumber),

        permissionGroups: this.form.permissionGroups
          .map(el => nullOrValue(el))
          .filter(Boolean),
        directManager: nullOrValue(this.form?.directManager),
        occupation: nullOrValue(this.form?.occupation),
        birthDate: nullOrValue(this.form.birthDate),

        attributes: this.form.attributes
          .map(el => ({
            id: el.id,
            values: el.values?.map(el => nullOrValue(el)).filter(Boolean) || [],
          }))
          .filter(el => el.values.length),
      }
    },
    async submit() {
      const payload = {
        personID: this.person.id,
        body: {
          ...this.calcPayload(),
        },
      }

      await updatePerson(payload)
        .then(res => {
          this.oldPhoneNumber = res.data.phoneNumber
          this.$emit('success-update', res.data)
          this.displayAlert(
            'success',
            this.$t('PeoplePage.viewPerson.successEdit')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('PeoplePage.viewPerson.errorUpdate')
          )
        })
    },

    async statusSubmit(status) {
      const payload = {
        personID: this.person.id,
        status: status,
      }

      await changePersonStatus(payload)
        .then(() => {
          const message =
            status === 'active'
              ? this.$t('PeopleTab.AlertSuccessActivateStatus')
              : this.$t('PeopleTab.AlertSuccessInativeStatus')
          this.displayAlert('success', message)

          this.getPerson().then(() => {
            this.configSideQuickRegister('viewPerson', null, this.person.status)
          })
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('PeoplePage.viewPerson.errorUpdate')
          )
        })
    },

    async deletePerson() {
      await deletePerson(this.$route.params.personId)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('PeoplePage.viewPerson.successDelete')
          )

          this.$emit('close')
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('PeoplePage.viewPerson.errorDelete')
          )
        })
    },

    async resendInviteSubmit() {
      const payload = {
        personID: this.person.id,
      }

      await resendPersonInvite(payload)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('PeopleTab.AlertSuccessResendInvite')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('PeoplePage.viewPerson.errorResend')
          )
        })
    },

    async groupSubmit(groupID) {
      const group = this.form.groups.find(el => el.id === groupID)

      if (!group) return

      const payload = {
        groupID: groupID,
        personID: this.person.id,
        body: {
          engagement: group.engagement || [],
          growth: group.growth || [],
          performance: group.performance || [],
          management: group.management || [],
        },
      }

      await updatePersonGroup(payload)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('PeoplePage.viewPerson.successGroup')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('PeoplePage.viewPerson.errorUpdate')
          )
        })
    },

    async removePersonGroup(groupID) {
      const payload = {
        groupID: groupID,
        personID: this.person.id,
      }

      await removePersonGroup(payload)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('PeoplePage.viewPerson.successGroupDelete')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('PeoplePage.viewPerson.errorGroupDelete')
          )
        })
    },

    async getPerson() {
      await getPerson(this.$route.params.personId)
        .then(res => {
          this.form.photo = res.data.photo
          this.form.name = res.data.name
          this.form.email = res.data.email

          this.form.code = res.data.code
          this.form.birthDate = res.data.birthDate?.split('T')?.[0] || ''

          this.oldPhoneNumber = res.data.phoneNumber?.replace('+', '') || ''
          this.form.phoneNumber = res.data.phoneNumber?.replace('+', '') || ''

          this.form.permissionGroups = res.data.permissionGroups
            ? [...res.data.permissionGroups]
            : []
          this.form.directManager = res.data.directManager
            ? {
                id: res.data.directManager.id,
                label: res.data.directManager.name,
              }
            : {}
          this.form.occupation = res.data.occupation
            ? {
                id: res.data.occupation.id,
                label: res.data.occupation.title,
              }
            : {}

          this.form.groups = res.data.groups
            ? res.data.groups.map(el => {
                return {
                  id: el.id,
                  group: {
                    image: el.image,
                    title: el.name,
                  },
                  engagement:
                    el.roles?.engagement?.map(role => ({ id: role.id })) || [],
                  growth:
                    el.roles?.growth?.map(role => ({ id: role.id })) || [],
                  performance:
                    el.roles?.performance?.map(role => ({ id: role.id })) || [],
                  management:
                    el.roles?.management?.map(role => ({ id: role.id })) || [],
                  actions: this.getColumnActionGroups(),
                }
              })
            : []

          this.form.attributes = res.data.attributes

          this.person = res.data
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )

          this.$emit('close')
        })
        .finally(() => {
          this.loading.content = false
        })
    },

    async getPermissionGroups(search = '') {
      this.loading.permissionGroups = true

      const payload = {
        limit: 30,
        offset: 0,
        ...(search && { label: search }),
      }

      await getPermissionGroups(payload)
        .then(res => {
          this.permissionGroups = res.data
        })
        .finally(() => {
          this.loading.permissionGroups = false
        })
    },

    async getDirectManagers(search = '') {
      this.loading.directManagers = true

      const payload = {
        limit: 30,
        offset: 0,
        showInactive: true,
        ...(search && { name: search }),
      }

      await getPeople(payload)
        .then(res => {
          this.directManagers = res.data.filter(el => el.id !== this.person.id)
        })
        .finally(() => {
          this.loading.directManagers = false
        })
    },

    async getPeopleRoles() {
      this.loading.occupationRoles = true

      await getPeopleRoles()
        .then(res => {
          this.occupationRoles = res.data
        })
        .finally(() => {
          this.loading.occupationRoles = false
        })
    },
    handleInput(value, inputName) {
      if (this.interval) clearInterval(this.interval)

      if (inputName === 'phoneNumber') {
        if (this.oldPhoneNumber == value) {
          return
        }
      }

      this.form[inputName] = value

      this.interval = setTimeout(() => {
        this.submit()
      }, 500)
    },

    handleAddGroup(event) {
      const group = {
        id: event.id,
        group: {
          image: event.image,
          title: event.label,
        },
        engagement: [],
        growth: [],
        performance: [],
        management: [],
        actions: ['delete'],
      }

      this.form.groups.unshift(group)
    },

    updateGroup(event) {
      const index = this.form.groups.findIndex(el => el.id === event.id)
      this.form.groups[index][event.key] = event.values

      if (this.groupsSearch.length > 0) {
        const index = this.groupsSearch.findIndex(el => el.id === event.id)
        this.groupsSearch[index][event.key] = event.values
      }

      this.groupSubmit(event.id)
    },

    removeGroup(event) {
      const index = this.form.groups.findIndex(el => el.id === event.id)
      this.form.groups.splice(index, 1)

      if (this.groupsSearch.length > 0) {
        const index = this.groupsSearch.findIndex(el => el.id === event.id)
        this.groupsSearch.splice(index, 1)

        if (this.groupsSearch.length === 0) this.$refs.dataTable.clearSearch()
      }

      if (index >= 0) this.removePersonGroup(event.id)
    },

    searchGroup(event) {
      const array = []

      if (event) {
        this.form.groups.map(el => {
          if (el.group.title.toLowerCase().includes(event.name.toLowerCase()))
            array.push(el)
        })
      }

      this.groupsSearch = array
    },

    async getGroups(search = '') {
      this.loading.groups = true

      const payload = {
        limit: 30,
        offset: 0,
        name: search,
      }

      await getGroups(payload)
        .then(res => {
          this.groups = res.data
        })
        .finally(() => {
          this.loading.groups = false
        })
    },

    async getGroupRoles() {
      const payload = {
        limit: 500,
        offset: 0,
      }

      await getGroupsRoles(payload).then(res => {
        this.groupRoles = res.data
      })
    },

    async getPeopleAttributes() {
      const payload = {
        limit: 99,
        offset: 0,
        translated: true,
      }

      await getPeopleAttributes(payload).then(res => {
        const attributes = [
          {
            items: [],
          },
        ]

        res.data.forEach(el => {
          if (!el.group) attributes[0].items.push(el)
          else {
            const index = attributes.findIndex(
              attribute => attribute.id === el.group.id
            )

            if (index >= 0) {
              attributes[index].items.push(el)
            } else {
              attributes.push({
                ...el.group,
                items: [el],
              })
            }
          }
        })

        this.attributes = attributes
      })
    },

    handleStatusColor() {
      switch (this.person.status) {
        case 'active':
          return ''
        case 'invited':
          return '#FDCC4A'
        default:
          return '#858C94'
      }
    },

    handleAttributeValue(meta) {
      const value = this.person.attributes
        ? this.person.attributes.find(e => e.id === meta.id)
        : null

      if (!value) {
        if (meta.type === 'select') return meta.multi ? [] : ''
        else return ''
      }

      if (meta.type === 'select')
        return (() => {
          const options = meta.options.map(e => {
            return {
              id: e.id,
              label: e.value,
            }
          })

          const items = value.values.map(e => {
            return options.find(i => i.id === e.id)
          })

          if (meta.multi) return items
          else return items.length > 0 ? items[0].id : ''
        })()
      else return value[meta.type]
    },

    handleChangeAttribute(attribute, event) {
      const data = {
        id: attribute.id,
        label: attribute.label,
        values: event,
      }

      const index = this.form.attributes.findIndex(
        groupAttributes => groupAttributes.id === attribute.id
      )

      if (index !== -1) {
        this.form.attributes[index] = {
          ...data,
        }
      } else {
        this.form.attributes.push(data)
      }

      this.submit()
    },

    getAttributesValues(attribute) {
      const values = this.form.attributes.find(
        groupAttributes => groupAttributes.id === attribute.id
      )

      if (values) {
        return values.values
      }

      return []
    },

    showSection(data) {
      for (let i = 0; i < data.items.length; i++) {
        if (!data.items[i].readonly) return true
      }

      return false
    },

    displayAlert(type, description) {
      this.$emit('display-alert', { type, description })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
