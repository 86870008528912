<template>
  <CustomInput
    ref="roleName"
    data-test-name-input
    :label="$t('PermissionsPage.AddNewEntityRole.namePaper.title')"
    class="name-input"
    :currentValue="roleName"
    :mandatory="true"
    type="text"
    @handleInput="handleRoleName"
  />
</template>

<script>
export default {
  name: 'RoleName',
  data() {
    return {
      roleName: '',
    }
  },

  methods: {
    handleRoleName(value) {
      this.$emit('setRoleName', value)
    },
    reset() {
      this.roleName = ''
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
