<template>
  <div data-test-positional-item class="positional-item">
    <span v-text="data.position + 'º'" class="position" />
    <span v-text="data.title" class="item-title" />
  </div>
</template>

<script>
export default {
  name: 'PositionalItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
