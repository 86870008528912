<template>
  <SideQuickRegister
    ref="SideQuickRegister"
    data-test-attribute-view
    :show="SideQuickRegister.show"
    v-bind="SideQuickRegister"
    @handleAction="handleViewAction"
  >
    <template #default="{ loading, alert }">
      <AttributeFormEdit
        v-if="SideQuickRegister.action === ACTIONS_VIEW"
        ref="form"
        :demographic-attribute="DemographicAttribute"
        @alert="alert"
      ></AttributeFormEdit>

      <AttributeFormNew
        v-else-if="SideQuickRegister.action === ACTIONS_NEW"
        ref="form"
        @loading="loading"
        @alert="alert"
      ></AttributeFormNew>
    </template>
  </SideQuickRegister>
</template>

<script>
import AttributeFormEdit from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/Partials/AttributeFormEdit/AttributeFormEdit.vue'
import AttributeFormNew from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/Partials/AttributeFormNew/AttributeFormNew.vue'

export const ACTIONS_NEW = 'new'
export const ACTIONS_VIEW = 'view'
export const ACTIONS_CLOSE = 'close'
export const ACTIONS_SUBMIT = 'submit'

export default {
  name: 'AttributeView',
  components: {
    AttributeFormEdit,
    AttributeFormNew,
  },

  inject: ['hiddenOverflow'],

  data() {
    return {
      ACTIONS_VIEW,
      ACTIONS_NEW,

      SideQuickRegister: {
        action: null,
        show: false,
      },
      DemographicAttribute: null,
    }
  },

  watch: {
    'SideQuickRegister.show'(value) {
      this.hiddenOverflow(value)
    },
  },

  created() {
    this.$root.$on('demographic-attribute:view', this.handleViewAction)
  },

  beforeDestroy() {
    this.$root.$off('demographic-attribute:view', this.handleViewAction)
  },

  methods: {
    handleSideQuickState(state) {
      this.$set(this.SideQuickRegister, 'show', state)
      this.$emit('action:close', state)
    },

    handleLoading(state) {
      this.handleSideQuickRef('handleLoading', state)
    },

    handleFormAction(action) {
      const form = this.$refs.form
      if (!form) return

      if ([ACTIONS_SUBMIT].includes(action)) {
        form.submit()
      }
    },

    async handleViewAction(action, payload = null) {
      if (![ACTIONS_CLOSE, ACTIONS_NEW, ACTIONS_VIEW].includes(action)) {
        return this.handleFormAction(action, payload)
      }

      this.$set(this.SideQuickRegister, 'action', action)
      if ([ACTIONS_CLOSE].includes(action)) {
        this.handleSideQuickState(false)
      }

      this.$set(this, 'DemographicAttribute', payload)

      const actionFn = {
        [ACTIONS_NEW]: this.calcNew,
        [ACTIONS_VIEW]: this.calcEdit,
      }?.[action]
      if (typeof actionFn !== 'function') return

      const SideQuickRegister = await actionFn()
      this.SideQuickRegister = Object.assign(
        {},
        this.SideQuickRegister,
        SideQuickRegister
      )

      this.handleSideQuickState(true)
    },

    calcNew() {
      return {
        title: this.$t('DemographicAttributesPage.form.create.title'),
        footerButtons: [
          {
            label: this.$t(
              'DemographicAttributesPage.form.create.buttons.cancel'
            ),
            name: 'cancel',
            action: ACTIONS_CLOSE,
            type: 'text',
          },
          {
            label: this.$t(
              'DemographicAttributesPage.form.create.buttons.submit'
            ),
            name: 'submit',
            action: ACTIONS_SUBMIT,
            type: 'default',
          },
        ],
        headerButtons: [],
        popoverOptions: [],
      }
    },
    calcEdit() {
      return {
        title: this.$t('DemographicAttributesPage.form.edit.title'),
        headerButtons: [],
        footerButtons: [],
        popoverOptions: [],
      }
    },

    handleSideQuickRef(methodName, ...payload) {
      this.$nextTick(() => {
        const method = this.$refs.SideQuickRegister?.[methodName]
        if (typeof method !== 'function') {
          return
        }

        method(...payload)
      })
    },
  },
}
</script>
