<template>
  <div
    data-test-bordered-item
    id="bordered-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      class="d-flex align-center bordered-item"
      :class="{ 'cursor-pointer': data.canClick }"
      @click="itemClicked"
      :style="_color"
    >
      <v-img
        data-test-bordered-item-image
        v-if="data.imageLeft"
        height="11"
        width="11"
        :src="require(`@/assets/options/${data.imageLeft}.png`)"
      />
      <div
        ref="text"
        class="truncated-text"
        :class="{ 'text-on-hover': tooltipOnHover }"
      >
        {{ data.text }}
      </div>
      <v-tooltip
        v-if="truncated"
        top
        allowOverflow
        open-on-hover
        :z-index="tooltipAttrs['z-index'] ? tooltipAttrs['z-index'] : 99"
        max-width="20%"
        v-bind="tooltipAttrs"
        :value="tooltipOnHover ? hover : undefined"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="tooltipOnHover ? {} : on"
            class="item-label"
            :class="{ 'item-on-hover': tooltipOnHover }"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </div>
        </template>
        <div class="item-label-tooltip">
          <slot name="innerTooltip">
            {{ data.text }}
          </slot>
        </div>
      </v-tooltip>
      <v-icon v-if="data.iconRight" data-test-bordered-item-icon>{{
        data.iconRight
      }}</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BorderedItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({}),
    },
    tooltipOnHover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      truncated: false,
      hover: false,
    }
  },
  computed: {
    _color() {
      if (this.data.bgColor) {
        return `background-color: ${this.data.bgColor} !important`
      }
      return ''
    },
    width() {
      return this.$vuetify.breakpoint.width
    },
  },
  methods: {
    itemClicked() {
      if (this.data.canClick) {
        this.$emit('itemClicked', this.data)
      }
    },
    isTruncatingText() {
      const el = this.$refs.text
      if (el) {
        this.truncated = el.scrollWidth > el.offsetWidth
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isTruncatingText()
    })
  },
  updated() {
    this.$nextTick(() => {
      this.isTruncatingText()
    })
  },
  watch: {
    width() {
      this.isTruncatingText()
    },
  },
}
</script>

<style src="./style.scss" scoped lang="scss"></style>
