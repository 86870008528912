import ColumnSchemaInterface from './ColumnSchemaInterface'

class ColumnInterface {
  constructor(props) {
    const { key, label, component, schemas, transform } = props

    this.key = key || ''
    this.label = label || ''
    this.component = component || {}
    this.schemas =
      schemas?.map(schema => new ColumnSchemaInterface(schema)) || []
    this.transform = transform || null
  }

  getComponentProps(row, i) {
    if (!this.schemas.length && row[this.key]) return { data: row[this.key] }

    const props = { data: {} }
    this.schemas.forEach(
      schema => (props[schema.name] = schema.getValue(row, i))
    )
    return props
  }
}

export default ColumnInterface
