<template>
  <div v-if="$route.name === 'EntityOkrForm'">
    <div class="entity-okr-form">
      <EntitiesConfigurations
        v-if="settingsID"
        :settings-by-entity="settingsByEntity"
        @display-alert="handleAlert"
        @submit="updateEntityOkrSettings"
      ></EntitiesConfigurations>

      <PorposeConfig
        v-if="_canShowPorposeConfigAndMeasurementModelConfig"
        :readonly="!_canAccessPerformanceMeasurementModelEdit"
        :settings-by-entity="settingsByEntity"
        :entity-type="entityType"
        @display-alert="handleAlert"
        @submit="updateEntityOkrSettings"
      ></PorposeConfig>

      <MeasurementModelConfig
        v-if="_canShowPorposeConfigAndMeasurementModelConfig"
        :readonly="!_canAccessPerformanceMeasurementModelEdit"
        :settings-by-entity="settingsByEntity"
        @display-alert="handleAlert"
        @submit="updateEntityOkrSettings"
      ></MeasurementModelConfig>

      <ListDefaultFields
        v-if="settingsID"
        :settings-id="settingsID"
        :settings-by-entity="settingsByEntity"
        @display-alert="handleAlert"
        @editDefaultField="editDefaultField"
      ></ListDefaultFields>

      <ListCustomFields
        v-if="entityType !== 'context'"
        ref="ListCustomFields"
        @addCustomFields="addCustomFields"
        @editCustomFields="editCustomFields"
        @display-alert="handleAlert"
      ></ListCustomFields>

      <ListMetadataGroups
        v-if="_canShowMetadataGroups && entityType !== 'context'"
        @display-alert="handleAlert"
        @delete-success="handleMetadataGroupsDeleteSuccess"
      ></ListMetadataGroups>

      <PlannedList
        v-if="
          entityType === 'key-result' && settingsID && _canAccessSeriesPlanned
        "
        :settings-id="settingsID"
        :settings-by-entity="settingsByEntity"
        @handleNewPlannedForm="handleNewPlannedForm"
        @display-alert="handleAlert"
        @handleHierarchySettings="updateNewPlannedSeries"
      ></PlannedList>

      <ListCategories
        v-if="_canShowMetadataGroups && entityType === 'context'"
        @display-alert="handleAlert"
        @delete-success="handleMetadataGroupsDeleteSuccess"
      >
      </ListCategories>
    </div>
  </div>
  <div v-else>
    <router-view
      ref="paperEntityForm"
      :show-alert="showAlert"
      @close="close"
    ></router-view>
  </div>
</template>
<script>
import EntitiesConfigurations from './Parts/EntitiesConfigurations/EntitiesConfigurations.vue'
import ListCustomFields from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListCustomFields/ListCustomFields.vue'
import ListMetadataGroups from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListMetadataGroups/ListMetadataGroups.vue'
import ListDefaultFields from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListDefaultFields/ListDefaultFields.vue'
import MeasurementModelConfig from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/MeasurementModelConfig/MeasurementModelConfig.vue'
import PorposeConfig from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/PurposeConfig/PurposeConfig.vue'
import PlannedList from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/PlannedList/PlannedList.vue'
import ListCategories from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/Parts/ListCategories/ListCategories.vue'

import {
  management_performance_metadata_groups,
  management_performance_entities_access_profiles_create,
  management_performance_entities_access_profiles_delete,
  management_performance_entities_access_profiles_edit,
  management_performance_series_planned,
  management_performance_measurementModel,
  management_performance_measurementModel_edit,
} from '@/helpers/ability'

import { getHierarchySettings } from '@/services/entities'
import {
  fetchEntityOkrSettings,
  updateEntityOkrSettings,
} from '@/services/papers'

export default {
  name: 'EntityOkrForm',
  components: {
    ListCustomFields,
    ListMetadataGroups,
    ListDefaultFields,
    EntitiesConfigurations,
    PlannedList,
    ListCategories,
    MeasurementModelConfig,
    PorposeConfig,
  },
  props: {
    showAlert: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      settingsByEntity: {},
      entityType: null,
      entityClass: null,
      newPlannedSeries: [],
      settingsID: null,
      hierarchySetting: null,
    }
  },
  computed: {
    _entityIsKRorKPIorSubindicator() {
      return (
        this.entityType === 'key-result' ||
        this.entityType === 'kpi' ||
        this.entityType === 'sub-indicator'
      )
    },
    _canShowPorposeConfigAndMeasurementModelConfig() {
      return (
        this.settingsID &&
        this._canAccessPerformanceMeasurementModel &&
        this._entityIsKRorKPIorSubindicator
      )
    },
    _canAccessPerformanceMeasurementModelEdit() {
      return this.$can('access', management_performance_measurementModel_edit)
    },
    _canAccessPerformanceMeasurementModel() {
      return this.$can('access', management_performance_measurementModel)
    },
    _canAccessSeriesPlanned() {
      return this.$can('access', management_performance_series_planned)
    },
    _canShowMetadataGroups() {
      return this.$can('access', management_performance_metadata_groups)
    },
    _canCreatePaper() {
      return this.$can(
        'access',
        management_performance_entities_access_profiles_create
      )
    },
    _canEditPaper() {
      return this.$can(
        'access',
        management_performance_entities_access_profiles_edit
      )
    },
    _canDeletePaper() {
      return this.$can(
        'access',
        management_performance_entities_access_profiles_delete
      )
    },
  },
  beforeMount() {
    this.entityType = this.$route.params.entityType
    this.entityClass = this.$route.params.entityClass
    this.settingsID = this.$route.params.entityId

    this.getSettingsID()
    this.handleEntityOkrSettings()
  },

  methods: {
    async handleEntityOkrSettings() {
      try {
        const { data } = await fetchEntityOkrSettings(this.settingsID)
        this.settingsByEntity = data
      } catch (error) {
        this.handleAlert({
          statusAlert: 'error',
          title: this.$t(
            'PerformanceTab.EntitiesConfigurations.alert.get.error'
          ),
          messageAlert: error?.response?.data?.message,
        })
      }
    },
    async updateEntityOkrSettings(payload) {
      await updateEntityOkrSettings(this.settingsByEntity.id, payload)
        .then(() => {
          this.handleAlert({
            statusAlert: 'success',
            title: this.$t(
              'PerformanceTab.EntitiesConfigurations.alert.put.success'
            ),
          })

          this.handleEntityOkrSettings()
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t(
              'PerformanceTab.EntitiesConfigurations.alert.put.error'
            ),
            messageAlert: error?.response?.data?.message,
          })
        })
    },
    updateNewPlannedSeries(hierarchySetting) {
      this.hierarchySetting = hierarchySetting
      this.newPlannedSeries = hierarchySetting.newPlannedSeries
    },
    handleNewPlannedForm() {
      this.$emit('handleNewPlannedForm', {
        newPlannedSeries: this.newPlannedSeries,
        entityID: this.settingsID,
        hierarchySetting: this.hierarchySetting,
      })
    },
    close() {
      this.$emit('close')
    },
    addCustomFields() {
      this.$emit('addCustomFields', {
        entityID: this.settingsID,
      })
    },
    editDefaultField(defaultField) {
      this.$emit('editDefaultField', defaultField)
    },

    editCustomFields(customField) {
      this.$emit('editCustomFields', {
        ...customField,
        entityID: this.settingsID,
      })
    },
    handleAlert(event) {
      this.$emit('display-alert', event)
    },
    submit() {
      return this.$refs.paperEntityForm.submit()
    },

    handleMetadataGroupsDeleteSuccess() {
      if (this.$refs.ListCustomFields) {
        const eventPayload = {
          name: '',
          offset: 0,
          limit: 5,
        }

        this.$refs.ListCustomFields.searchCustomFields(eventPayload)
      }
    },

    async getSettingsID() {
      await getHierarchySettings({
        entityType: this.entityType,
        entityClass: this.entityClass,
      })
        .then(({ data }) => {
          this.newPlannedSeries = data[0].newPlannedSeries
            ? data[0].newPlannedSeries
            : []

          this.hierarchySetting = data[0] ? data[0] : null

          if (this.settingsID) {
            return
          }

          const possibleMatch = data.find(item => !!item.id)
          if (!possibleMatch) {
            return
          }

          this.settingsID = possibleMatch.id
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t(
              'PerformanceTab.EntitiesConfigurations.alert.get.error'
            ),
            messageAlert: error?.response?.data?.message,
          })
        })
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
