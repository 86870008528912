import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getPeopleAttributes(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'label'], data)

  return (await api()).get('/management/people/attributes', {
    params: params,
    ...signal,
  })
}

export async function getPeopleAttributesDetail(data) {
  const signal = extractSignal(data)

  return (await api()).get('/management/people/attributes/:attributeID', {
    _params: {
      attributeID: data.attributeID,
    },
    ...signal,
  })
}

export async function postPeopleAttributes(data) {
  return (await api()).post('/management/people/attributes', data)
}

export async function putPeopleAttributes(attributeID, data, payload) {
  const signal = extractSignal(payload)

  return (await api()).put('/management/people/attributes/:attributeID', data, {
    _params: {
      attributeID: attributeID,
    },
    ...signal,
  })
}

export async function deletePeopleAttributes(attributeID) {
  return (await api()).delete('/management/people/attributes/:attributeID', {
    _params: {
      attributeID: attributeID,
    },
  })
}
