<template>
  <div data-test-configure-metadata-groups>
    <DeleteModal
      ref="DeleteModal"
      @display-alert="handleAlert($event)"
      @success="handleChangeSuccess('delete')"
    ></DeleteModal>

    <DetailSectionAccordion
      type="people"
      :title="$t('ListCategories.title')"
      :opened="true"
    >
      <DataTable
        data-test-section-metadata-table-body
        :title="$t('ListCategories.table.title')"
        :headers="_headers"
        :fixed-header="true"
        :hide-header="true"
        :hide-option="true"
        :attach="true"
        :loading="categoriesApiController.loading"
        :roles-readonly="true"
        :items="_items"
        :items-per-page="-1"
        :enable-pagination="false"
        :skeleton-lines="tableConfig.limit"
        :down-left-button="_addOptions"
        @update-inputEdit="updateInputEdit($event)"
        @search="handleSearch($event)"
        @get-data="handleSearch($event)"
        @remove="handleDelete($event)"
      ></DataTable>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import { getCategories, postCategories, putCategory } from '@/services/category'
import DataTable from '@/components/DataTable/DataTable.vue'
import DeleteModal from './Partials/DeleteModal/DeleteModal.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ListCategories',
  components: {
    DataTable,
    DeleteModal,
  },
  inject: ['openMetadataGroupForm'],

  data() {
    return {
      searchTerm: '',
      itemsFiltered: [],
      itemsOriginal: [],
      editMode: false,
      currentItemId: null,
      tableConfig: {
        limit: 4,
      },

      categoriesApiController: {
        label: '',
        limit: 500,
        offset: 0,
        loading: false,
        itens: [],
      },

      entityType: null,
      entityClass: null,
    }
  },

  computed: {
    _headers() {
      return [
        {
          text: this.$t('ListCategories.table.header.name'),
          value: 'label',
          type: 'input-edit',
        },
        {
          text: this.$t('ListCategories.table.header.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
    _addOptions() {
      return {
        click: this.addOptions,
        text: this.$t('ListCategories.table.addNew'),
        icon: 'mdi-plus',
      }
    },
    _items() {
      if (this.searchTerm) {
        return this.itemsFiltered
      }

      return this.itemsOriginal
    },
  },

  beforeMount() {
    this.setEntity()
    this.getOptionsSearch()
  },

  methods: {
    handleAlert(payload) {
      this.$emit('display-alert', payload)
    },

    async isEditMode() {
      const itemId = this.$route.params.id
      if (itemId && itemId !== 'new') {
        this.editMode = true
        this.currentItemId = itemId
        await this.fetchItem(itemId)
      } else {
        this.editMode = false
        this.currentItemId = null
      }
    },

    async updateInputEdit(optionUpdate) {
      const itemIndex = this.itemsOriginal.findIndex(
        item => item.id === optionUpdate.id
      )

      if (itemIndex === -1) {
        return
      }

      this.itemsOriginal[itemIndex].label.value = optionUpdate.value
      const item = this.itemsOriginal[itemIndex]
      const payload = { title: optionUpdate.value }

      try {
        if (item.isNew) {
          const response = await postCategories(payload)

          this.itemsOriginal[itemIndex] = {
            ...item,
            id: response.data.id,
            isNew: false,
          }

          this.getOptionsSearch()

          this.handleAlert({
            statusAlert: 'success',
            title: this.$t('createCategory.alert.create.success'),
            messageAlert: this.$t('createCategory.alert.create.successMessage'),
          })
        } else {
          await putCategory(item.id, payload)

          this.getOptionsSearch()

          this.handleAlert({
            statusAlert: 'success',
            title: this.$t('editCategory.alert.edit.success'),
            messageAlert: this.$t('editCategory.alert.edit.successMessage'),
          })
        }
      } catch (error) {
        this.handleAlert({
          statusAlert: 'error',
          title: this.$t('createCategory.alert.create.error'),
          messageAlert: error.response?.data?.message,
        })
      }
    },

    addOptions() {
      const checkOptionsList = this.itemsOriginal.some(
        option => !option.label.value
      )
      if (checkOptionsList) return

      this.itemsOriginal.push({
        id: uuidv4(),
        position: this.itemsOriginal.length,
        label: { value: null },
        actions: ['remove'],
        isNew: true,
      })
    },
    setEntity(
      entityType = this.$route.params.entityType,
      entityClass = this.$route.params.entityClass
    ) {
      this.entityType = entityType
      this.entityClass = entityClass
    },

    handleChangeSuccess(type) {
      this.getOptionsSearch()

      if (type === 'delete') {
        this.$emit('delete-success')
      }
    },
    handleDelete({ id = null }) {
      if (this.$refs.DeleteModal && id) {
        this.$refs.DeleteModal.handleOpenDialog(id)
      }
    },

    async getOptionsSearch(search = '') {
      const payload = {
        limit: this.categoriesApiController.limit || 20,
        offset: this.categoriesApiController.offset || 0,
        ...(search && { title: search }),
      }

      this.categoriesApiController.loading = true

      try {
        const response = await getCategories(payload)
        const apiData = response.data

        this.itemsOriginal = apiData.map(item => ({
          id: item.id,
          label: { value: item.title },
          actions: ['remove'],
        }))
      } catch (error) {
        this.handleAlert({
          statusAlert: 'error',
          title: this.$t('ListCategories.alert.get.error'),
          messageAlert: error.response?.data?.message || 'Erro desconhecido',
        })
      } finally {
        this.categoriesApiController.loading = false
      }
    },

    async handleSearch(event) {
      this.searchTerm = event?.name || ''

      if (!this.searchTerm) {
        await this.getOptionsSearch()
      } else {
        this.itemsFiltered = this.itemsOriginal.filter(item =>
          item.label.value.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      }
    },
  },
}
</script>
