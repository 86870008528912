<template>
  <div data-test-menu-audit-log-page>
    <ComposeForm
      ref="ComposeForm"
      class="form"
      :form-name="'audit-logs-filters'"
      :selectors="{
        fields: _fields,
      }"
      :lazy-submit="false"
      :lazy-mount="false"
      :storage-preferences="false"
      @compose:values="setFilters"
    ></ComposeForm>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'MenuAuditLogPage',

  computed: {
    _fields() {
      return [
        {
          id: 'entity',
          _label: this.$t('AuditLogsPage.table.headers.text-entity'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
            mode: 'objectId',
          },

          ItemSelector: {
            persistent: false,
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },

          api: {
            endpoint: this.getAuditLogsEntities,
            searchKey: null,
            infinity: false,
          },
        },
        {
          id: 'action',
          _label: this.$t('AuditLogsPage.table.headers.text-action'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
            mode: 'objectId',
          },

          ItemSelector: {
            persistent: false,
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },

          api: {
            endpoint: this.getAuditLogsActions,
            searchKey: null,
            infinity: false,
          },
        },
      ]
    },
  },

  beforeMount() {
    this.setFilters()
  },

  methods: {
    ...mapActions({
      getAuditLogsEntities: 'AuditLogsPage/getAuditLogsEntities',
      getAuditLogsActions: 'AuditLogsPage/getAuditLogsActions',
    }),
    ...mapMutations({
      setAuditLogsPageFilters: 'AuditLogsPage/SET_FILTERS',
    }),

    cleanFilter() {
      if (!this.$refs.ComposeForm) return
      this.$refs.ComposeForm.resetComposeForm()
    },

    setFilters(payload = {}) {
      this.setAuditLogsPageFilters({
        ...payload,
        entity: payload.entity?.[0]?.id || null,
        action: payload.action?.[0]?.id || null,
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
