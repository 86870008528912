<template>
  <AttributeForm
    ref="form"
    data-test-attribute-form-new
    action="new"
    @submit="handleCreate"
  ></AttributeForm>
</template>

<script>
import AttributeForm from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/Partials/AttributeForm/AttributeForm.vue'

import { postPeopleAttributes } from '@/services/people-attributes'
import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'

export default {
  name: 'AttributeFormNew',
  components: {
    AttributeForm,
  },

  methods: {
    submit() {
      const form = this.$refs.form
      if (!form) return

      form.submit()
    },

    calcFormPayload(payload) {
      const clearEmptyObjectKeys = obj => {
        Object.keys(obj).forEach(key => {
          if (obj[key] === '') delete obj[key]
        })

        return obj
      }

      return {
        label: clearEmptyObjectKeys(payload.label),
        values: payload.values.map(value => ({
          label: clearEmptyObjectKeys(value.label),
        })),
      }
    },

    async handleCreate(payload) {
      this.$emit('loading', true, 'submit')

      await postPeopleAttributes(this.calcFormPayload(payload))
        .then(({ data }) => {
          this.$emit(
            'alert',
            alertSuccessMessage(
              this.$t('DemographicAttributesPage.form.create.alerts.success')
            )
          )

          this.$root.$emit('demographic-attribute:table')
          this.$router
            .push({
              name: 'ActionDemographicAttributesPage',
              params: {
                action: 'view',
                id: data.id,
              },
            })
            .catch(() => {})
        })
        .catch(error => {
          this.$emit(
            'alert',
            alertErrorMessage(
              this.$t('DemographicAttributesPage.form.create.alerts.error'),
              error
            )
          )
        })
        .finally(() => {
          this.$emit('loading', false, 'submit')
        })
    },
  },
}
</script>
