<template>
  <div class="audit-logs-page" data-test-audit-logs-page>
    <AuditLogsTable @view="handleAuditLogView"></AuditLogsTable>
    <AuditLogsViewer></AuditLogsViewer>
  </div>
</template>

<script>
import AuditLogsTable from '@/views/ManagementPage/Tabs/DataPage/Tabs/AuditLogsPage/Partials/AuditLogsTable/AuditLogsTable.vue'
import AuditLogsViewer from '@/views/ManagementPage/Tabs/DataPage/Tabs/AuditLogsPage/Partials/AuditLogsViewer/AuditLogsViewer.vue'

import { mapActions } from 'vuex'

export default {
  name: 'AuditLogsPage',
  components: {
    AuditLogsTable,
    AuditLogsViewer,
  },

  mounted() {
    this.setCurrentPage('AuditLogsPage')
  },

  methods: {
    ...mapActions(['setCurrentPage']),
    handleAuditLogView(AuditLog) {
      if (!AuditLog?._AuditLog) return

      const title = `${AuditLog['text-entity']} | ${AuditLog['text-date']}`
      this.$root.$emit('audit-log:view', 'view', {
        title,
        AuditLog: AuditLog._AuditLog,
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
