import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

import { UserModule } from './modules/user'
import { AuditLogsPage } from './modules/AuditLogsPage'
import currentAccount from './modules/currentAccount'

/**
 * @type {import('vuex').Store}
 */
export default new Vuex.Store({
  modules: {
    currentUser: UserModule,
    currentAccount,

    AuditLogsPage,
  },

  state: {
    Page: {
      isSignedIn: false,
    },

    workflowColors: {},
    workflowActions: [],
    currentPage: '',

    filterPeople: {
      items: [],
      countItems: '',
      payload: {},
      isDirtySearch: false,
    },
    filterPeopleParams: {
      name: null,
      offset: 0,
      limit: 20,
    },

    filterPermission: {
      items: [],
      countItems: '',
    },
    filterPermissionParams: {
      name: null,
      offset: 0,
      limit: 20,
    },

    reload: null,

    visibleLateralMenu: true,
    visibleFilterLateral: true,
  },
  getters: {
    isSignedIn: ({ Page }) => Page.isSignedIn,

    currentPage: state => state.currentPage,

    workflowColors: state => state.workflowColors,
    workflowActions: state => state.workflowActions,

    getfilterPeopleEmpty: state =>
      state.filterPeople.countItems === 0 && !state.filterPeople.isDirtySearch,
    getfilterPeople: state => state.filterPeople.items,
    getfilterPeopleCount: state => state.filterPeople.countItems,
    getfilterPeopleParams: state => state.filterPeople.payload,
    getfilterPeopleParamsByName: state => state.filterPeopleParams.name,
    getfilterPeopleParamsByOffset: state => state.filterPeopleParams.offset,
    getfilterPeopleParamsByLimit: state => state.filterPeopleParams.limit,

    getfilterPermission: state => state.filterPermission.items,
    getfilterPermissionCount: state => state.filterPermission.countItems,
    getfilterPermissionParamsByName: state => state.filterPermissionParams.name,
    getfilterPermissionParamsByOffset: state =>
      state.filterPermissionParams.offset,
    getfilterPermissionParamsByLimit: state =>
      state.filterPermissionParams.limit,

    getReload: state => state.reload,

    getVisibleLateralMenu: state => state.visibleLateralMenu,
    getVisibleFilterLateral: state => state.visibleFilterLateral,
  },
  mutations: {
    SET_page_isSignedIn(state, payload) {
      state.Page.isSignedIn = payload
    },

    setCurrentPage(state, page) {
      state.currentPage = page
    },

    setWorkflowColors(state, value) {
      state.workflowColors = value
    },
    setWorkflowActions(state, value) {
      state.workflowActions = value
    },

    setFilterPeople(state, params) {
      state.filterPeople.items = params.items
      state.filterPeople.countItems = params.countItems
      state.filterPeople.payload = params.payload
      state.filterPeople.isDirtySearch = params.isDirtySearch
    },
    setFilterPeopleParams(state, params) {
      state.filterPeopleParams.name = params.name
      state.filterPeopleParams.offset = params.offset
      state.filterPeopleParams.limit = params.limit
    },

    setFilterPermission(state, params) {
      state.filterPermission.items = params.items
      state.filterPermission.countItems = params.countItems
    },
    setFilterPermissionParams(state, params) {
      state.filterPermissionParams.name = params.name
      state.filterPermissionParams.offset = params.offset
      state.filterPermissionParams.limit = params.limit
    },

    setReload(state, params) {
      state.reload = params
    },

    setVisibleLateralMenu(state, status) {
      state.visibleLateralMenu = status
    },
    setVisibleFilterLateral(state, status) {
      state.visibleFilterLateral = status
    },
  },
  actions: {
    signedInLogin({ commit }) {
      commit('SET_page_isSignedIn', true)
    },
    signedInLogout({ commit }) {
      commit('SET_page_isSignedIn', false)
    },

    setCurrentPage({ commit }, page) {
      commit('setCurrentPage', page)
    },

    setWorkflowColors({ commit }, value) {
      commit('setWorkflowColors', value)
    },
    setWorkflowActions({ commit }, value) {
      commit('setWorkflowActions', value)
    },

    setFilterPeople({ commit }, params) {
      commit('setFilterPeople', params)
    },
    setFilterPeopleParams({ commit }, params) {
      commit('setFilterPeopleParams', params)
    },

    setFilterPermission({ commit }, params) {
      commit('setFilterPermission', params)
    },
    setFilterPermissionParams({ commit }, params) {
      commit('setFilterPermissionParams', params)
    },

    setReload({ commit }, params) {
      commit('setReload', params)
    },

    setVisibleLateralMenu({ commit }, status) {
      commit('setVisibleLateralMenu', status)
    },
    setVisibleFilterLateral({ commit }, status) {
      commit('setVisibleFilterLateral', status)
    },
  },
})
