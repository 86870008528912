import api from '@/services/Http'
import { constructParams } from '@/helpers/api'

export const createGroupPaper = async payload => {
  const { data: newGroup } = await (
    await api()
  ).post(`/management/groups/roles`, payload)
  return newGroup
}

export const createEntityPaper = async payload => {
  const { data: entityPaper } = await (
    await api()
  ).post('/performance/okrs/entities/access-profiles', payload)

  return entityPaper
}

export const fetchEntityPaper = async paperID => {
  const { data: entityPaper } = await (
    await api()
  ).get(`/performance/okrs/entities/access-profiles/${paperID}`)

  return entityPaper
}

export const updateEntityPaper = async (paperID, payload) => {
  const { data: entityPaper } = await (
    await api()
  ).put(`/performance/okrs/entities/access-profiles/${paperID}`, payload)

  return entityPaper
}

export const fetchGroupPaper = async paperID => {
  const { data: groupPapers } = await (
    await api()
  ).get(`/management/groups/roles/${paperID}`)

  return groupPapers
}

export const updateGroupPaper = async (paperID, payload) => {
  const { data: editedGroup } = await (
    await api()
  ).put(`/management/groups/roles/${paperID}`, payload)

  return editedGroup
}

export const getGroupsRoles = async data => {
  const params = await constructParams(['limit', 'offset', 'label', 'module'], {
    ...data,
  })

  return (await api()).get('/management/groups/roles', { params })
}

export const deleteGroupsRoles = async groupsRolesID =>
  (await api()).delete(`/management/groups/roles/${groupsRolesID}`)

export const fetchOkrGroupPapers = async (
  entityType = null,
  entityClass = null,
  options = {}
) => {
  const { limit = 500, offset = null, label = null } = options

  return (await api()).get('/performance/okrs/entities/access-profiles', {
    params: {
      entityType,
      entityClass,
      withSystemicTypes: true,
      limit,
      offset,
      label,
    },
  })
}
// Papel de Grupos

// Papel de Entidade
export const deleteEntityRole = async paperID => {
  const { data: entityPaper } = await (
    await api()
  ).delete(`/performance/okrs/entities/access-profiles/${paperID}`)

  return entityPaper
}

export async function fetchPerformanceEntityList(data) {
  const params = constructParams(
    [
      'entityType',
      'entityClass',
      'entityTypeAllowedChildren',
      'entityClassAllowedChildren',
      'entityTypeExclusiveParam',
      'showMetadataRequiredFieldsForCycle',
      'root',
    ],
    data
  )

  return (await api()).get(`/performance/okrs/entities/hierarchy/settings`, {
    params,
  })
}

export const fetchEntityOkrSettings = async entityID => {
  return (await api()).get(
    `/performance/okrs/entities/hierarchy/settings/${entityID}`
  )
}

export async function updateEntityOkrSettings(entityID, data) {
  return (await api()).put(
    `/performance/okrs/entities/hierarchy/settings/${entityID}`,
    data
  )
}

export async function getAccessProfiles(payload) {
  return (await api()).get(`/performance/okrs/entities/access-profiles`, {
    params: payload,
  })
}

// Papel de Entidade
