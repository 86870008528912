<template>
  <Modal
    data-test-confirm-delete
    ref="confirmDeleteTag"
    :confirmButton="$t('deleteNewPlannedModal.confirmButton')"
    :hasButtons="true"
    :title="$t('deleteNewPlannedModal.title')"
    width="33.75rem"
    @confirmed="handleConfirm"
    :style="{ 'z-index': 1000 }"
  >
    <template v-slot:no-tab>
      <div class="message content">
        <span>
          {{ $t('deleteNewPlannedModal.description') }}
          <span class="message strong">
            {{ $t('deleteNewPlannedModal.descriptionStrong') }}
            {{ selectedCycle ? selectedCycle.cycle.label : '' }}
            {{ $t('deleteNewPlannedModal.questionMark') }}
          </span>
        </span>

        <br />
        <br />

        <span class="highlight">{{
          $t('deleteNewPlannedModal.rememberLabel')
        }}</span>
        {{ $t('deleteNewPlannedModal.rememberDescription1') }}
        {{ selectedCycle ? selectedCycle.cycle.label : '' }}
        {{ $t('deleteNewPlannedModal.rememberDescription2') }}
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'deleteNewPlannedModal',

  data() {
    return {
      selectedCycle: null,
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('handleDeletePlanned', this.selectedCycle)
      this.handleModal()
    },

    handleOpen(data) {
      this.selectedCycle = data
      this.handleModal()
    },

    handleModal() {
      this.$refs.confirmDeleteTag.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
