import { render, staticRenderFns } from "./MetadataGroupForm.vue?vue&type=template&id=35b8643e&scoped=true&"
import script from "./MetadataGroupForm.vue?vue&type=script&lang=js&"
export * from "./MetadataGroupForm.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=35b8643e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b8643e",
  null
  
)

export default component.exports