import api from '@/services/Http'

export async function getAccount() {
  return (await api()).get('/management/account')
}

export async function getAccountMe() {
  return (await api()).get('/management/account/me')
}

export async function putAccountMe(payload) {
  return (await api()).put('/management/account/me', payload)
}

export async function putDescription(payload) {
  return (await api()).put('/management/account/description', payload)
}

export async function getManagementAccountSettings() {
  return (await api()).get('/management/account/settings')
}

export async function putManagementAccountSettings(payload) {
  return (await api()).put('/management/account/settings', payload)
}

export async function getManagementAccountPerformanceSettings() {
  return (await api()).get('/management/account/settings/performance')
}

export async function putManagementAccountPerformanceSettings(payload) {
  return (await api()).put('/management/account/settings/performance', payload)
}
