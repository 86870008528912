<template>
  <LateralMenu>
    <template #default="{ isMini }">
      <LateralMenuItem
        v-if="
          [
            'GroupPermissionsPage',
            'PeoplePage',
            'PerformancePage',
            'AuditLogsPage',
          ].includes(currentPage)
        "
        :title="$t('LateralMenu.filterTitle')"
        :is-mini="isMini"
        class="lateral-menu-item"
      >
        <template #title-icon="{ size }">
          <v-icon :size="size">fi-sr-filter</v-icon>
        </template>
        <template #header-action>
          <v-btn
            class="pa-1"
            plain
            text
            small
            data-test-clean-entity-filter
            @click="cleanFilter()"
            >{{ $t('LateralMenu.actions.clean') }}</v-btn
          >
        </template>
        <template #default>
          <div>
            <MenuPermissionsPage
              v-if="currentPage === 'GroupPermissionsPage'"
              ref="MenuPermissionsPage"
            ></MenuPermissionsPage>

            <MenuPeoplePage
              v-if="currentPage === 'PeoplePage'"
              ref="MenuPeoplePage"
            ></MenuPeoplePage>

            <MenuPerformancePage
              v-if="currentPage === 'PerformancePage'"
              ref="MenuPerformancePage"
            ></MenuPerformancePage>

            <MenuAuditLogPage
              v-if="currentPage === 'AuditLogsPage'"
              ref="MenuAuditLogPage"
            ></MenuAuditLogPage>
          </div>
        </template>
      </LateralMenuItem>

      <LateralMenuItem
        :title="$t('LateralMenu.quickMenuTitle')"
        :is-mini="isMini"
      >
        <template #title-icon="{ size }">
          <v-icon :size="size">fi-rr-settings-sliders</v-icon>
        </template>
        <template #default>
          <div v-for="(item, index) of _items" :key="index">
            <div
              v-if="$can('access', item.permissionKey)"
              class="btn-qck-menu"
              @click="item.action()"
            >
              <span v-text="item.value"></span>
            </div>
          </div>
        </template>
      </LateralMenuItem>
    </template>
  </LateralMenu>
</template>
<script>
import MenuPermissionsPage from '@/views/LateralMenu/Partials/MenuPermissionsPage/MenuPermissionsPage.vue'
import MenuPeoplePage from '@/views/LateralMenu/Partials/MenuPeoplePage/MenuPeoplePage.vue'
import MenuPerformancePage from '@/views/LateralMenu/Partials/MenuPerformancePage/MenuPerformancePage.vue'
import MenuAuditLogPage from '@/views/LateralMenu/Partials/MenuAuditLogPage/MenuAuditLogPage.vue'

import {
  management_users,
  management_groups,
  management_permissions_permission_groups,
  management_performance,
} from '@/helpers/ability/permissions'
import { mapGetters } from 'vuex'

export default {
  name: 'LateralMenuView',
  components: {
    MenuPermissionsPage,
    MenuPeoplePage,
    MenuPerformancePage,
    MenuAuditLogPage,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currentPage', 'getVisibleFilterLateral']),
    _items: function () {
      return [
        {
          value: this.$t('LateralMenu.quickMenuItens.account'),
          permissionKey: management_performance,
          action: () => this.toRoute({ name: 'PerformancePage' }),
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.engagement'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.growth'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.integration'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.payment'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.people'),
          permissionKey: management_users,
          action: () => this.toRoute({ name: 'PeoplePage' }),
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.groups'),
          permissionKey: management_groups,
          action: () => this.toRoute({ name: 'GroupsPage' }),
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.permissions'),
          permissionKey: management_permissions_permission_groups,
          action: () => this.toRoute({ name: 'PermissionsPage' }),
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.demographic'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.auditory'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.shared'),
          permissionKey: '',
          action: () => {},
        },
        {
          value: this.$t('LateralMenu.quickMenuItens.export'),
          permissionKey: '',
          action: () => {},
        },
      ]
    },
  },
  methods: {
    toRoute({ name, params, query }) {
      if (!name) return
      if (this.$route.name !== name) this.$router.push({ name, params, query })
    },
    cleanFilter() {
      const menuPeople = this.$refs.MenuPeoplePage
      if (menuPeople) {
        return menuPeople.cleanFilterPeople()
      }

      const menuPermissions = this.$refs.MenuPermissionsPage
      if (menuPermissions) {
        return menuPermissions.cleanFilterPermissions()
      }

      const menuAuditLog = this.$refs.MenuAuditLogPage
      if (menuAuditLog) {
        return menuAuditLog.cleanFilter()
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
