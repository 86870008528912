<template>
  <header data-test-module-section class="form-section">
    <div class="form-section__title">
      <v-icon v-if="upgrade" class="crown" size="0.875rem">fi-sr-crown</v-icon>
      <h2 v-text="title"></h2>
    </div>

    <div class="form-section__content">
      <slot></slot>

      <div class="form-section__description">
        <strong v-text="_state"></strong>
        <span v-text="_help"></span>
      </div>
    </div>

    <v-divider class="neutral-divider"></v-divider>
  </header>
</template>

<script>
export default {
  name: 'ModuleSection',
  props: {
    title: {
      type: String,
      default: '',
    },
    module: {
      type: String,
    },
    upgrade: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _state() {
      return {
        true: this.$t('EngagementPage.module.state.true'),
        false: this.$t('EngagementPage.module.state.false'),
      }[this.value]
    },

    _help() {
      return {
        culture: {
          true: this.$t('EngagementPage.module.culture.description.true'),
          false: this.$t('EngagementPage.module.culture.description.false'),
        },
        climate: {
          true: this.$t('EngagementPage.module.climate.description.true'),
          false: this.$t('EngagementPage.module.climate.description.false'),
        },
      }[this.module][this.value]
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
