<template>
  <Modal
    ref="modalDeleteCycle"
    :title="$t('PerformanceTab.cycleForm.deleteCycle.title')"
    width="33.75rem"
  >
    <template v-slot:no-tab>
      <div data-test-delete-cycle-dialog>
        <div class="dialog-content">
          <div class="text-message">
            <div>
              {{ $t('PerformanceTab.cycleForm.deleteCycle.text1') }}
            </div>
            <br />
            <div>
              <span class="highlight">
                {{ $t('PerformanceTab.cycleForm.deleteCycle.text2') }}
              </span>

              {{
                $t(
                  `PerformanceTab.cycleForm.deleteCycle.${
                    !!isChild ? 'textChild' : 'textRoot'
                  }`
                )
              }}
            </div>
          </div>
        </div>

        <div class="dialog-footer">
          <v-spacer />

          <v-btn
            class="text-none text-btn"
            data-test-delete-cancel
            text
            @click="handleDialog()"
          >
            {{ $t('PerformanceTab.cycleForm.deleteCycle.cancel') }}
          </v-btn>

          <v-btn
            class="text-none primary-btn"
            color="primary"
            data-test-delete-confirm
            :disabled="!_canCycleDelete"
            depressed
            @click="submit()"
          >
            {{ $t('PerformanceTab.cycleForm.deleteCycle.confirm') }}
          </v-btn>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { deleteCycle } from '@/services/cycles/index.js'
import { management_performance_cycle_delete } from '@/helpers/ability'

export default {
  name: 'DeleteCycle',

  data() {
    return {
      cycleID: '',
      isChild: '',
    }
  },

  computed: {
    _canCycleDelete() {
      return this.$can('access', management_performance_cycle_delete)
    },
  },

  methods: {
    async submit() {
      await deleteCycle(this.cycleID)
        .then(() => {
          this.$root.$emit('handle-cycles')

          this.$emit('display-alert', {
            statusAlert: 'success',
            messageAlert: this.$t(
              'PerformanceTab.cycleForm.deleteCycle.success'
            ),
          })

          this.$emit('success')
          this.handleDialog()
        })
        .catch(err => {
          this.$emit('display-alert', {
            statusAlert: 'error',
            messageAlert: err?.response?.data?.message,
          })
        })
    },

    handleDialog(event) {
      this.cycleID = event ? event.id : ''
      this.isChild = event ? event.parentCycleID : ''

      this.$refs.modalDeleteCycle.handleDialog()
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
