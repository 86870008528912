import api from '@/services/Http'

export async function getCategories(params = {}) {
  return (await api()).get('/performance/okrs/entities/categories', { params })
}

export async function postCategories(payload) {
  return (await api()).post('/performance/okrs/entities/categories', payload)
}

export async function putCategory(id, payload) {
  return (await api()).put(
    `/performance/okrs/entities/categories/${id}`,
    payload
  )
}

export async function deleteCategory(id) {
  return (await api()).delete(`/performance/okrs/entities/categories/${id}`)
}
