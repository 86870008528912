<template>
  <div class="input-search" @click="handleClick">
    <v-text-field
      ref="search"
      v-model="text"
      hide-details="auto"
      :placeholder="_modalTitle"
      prepend-inner-icon="mdi-magnify"
      @input="handleInput"
    >
    </v-text-field>
    <i v-show="clear" @click="handleClear" class="fi-rr-cross-small"></i>
  </div>
</template>
<script>
export default {
  name: 'SearchBar',
  data() {
    return {
      text: '',
      searched: false,
    }
  },
  props: {
    cycleType: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _modalTitle() {
      if (this.cycleType) {
        return this.$t(
          'PerformanceTab.listCycleSettings.modalHierachy.placeholder'
        )
      }
      return this.$t('GroupsPage.treeSearch.placeholder')
    },
    clear() {
      return !!this.text
    },
  },
  watch: {
    text() {
      if (this.text && !this.searched) this.$emit('search', this.text)
    },
  },
  methods: {
    insertText(text, searched = false) {
      this.searched = searched
      this.text = text
    },
    handleClick() {
      this.$emit('click', this.text)
    },
    handleInput(text) {
      this.searched = false
      if (!text) this.$emit('clear')
      this.$emit('input', text)
    },
    handleClear() {
      this.$emit('clear')
      this.searched = false
      this.text = ''
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
