<template>
  <Modal
    data-test-modal-permissions-page-edit-entity-role
    ref="modal"
    :title="$t('PermissionsPage.EditEntityRole.title')"
    :confirmButton="$t('PermissionsPage.EditEntityRole.submit')"
    :hasButtons="true"
    width="39.625rem"
    @confirmed="submit"
    @cancel="canceled"
  >
    <template v-slot:no-tab="{ dialogState }">
      <v-form
        data-test-form-edit-entity-role
        v-if="dialogState"
        ref="form"
        v-model="valid"
        class="dropdown-content"
        @reset="resetForm"
      >
        <CustomInput
          ref="roleName"
          data-test-name-input
          :label="$t('PermissionsPage.AddNewEntityRole.namePaper.title')"
          class="name-input"
          :currentValue="form.roleName"
          :mandatory="true"
          type="text"
          @handleInput="handleName"
        />

        <itemSelector
          data-test-selector-entity-type
          ref="entityType"
          :currentValue="{
            data: selectedEntityTypeRole,
            origin: 'entityTypes',
          }"
          :watchCurrent="true"
          :inputConfig="{
            label: $t('PermissionsPage.EditEntityRole.typeEntity.title'),
            showAvatar: false,
            subtleMode: true,
          }"
          :menuConfig="{
            attach: false,
            showTabs: false,
          }"
          :menuOptions="_listEntityType"
          :fillWidth="true"
          :multiple="false"
          :showElements="true"
          :readonly="true"
          :local-search="true"
        />
        <itemSelector
          data-test-selector-active-cycles
          ref="activeCycles"
          :currentValue="{
            data: selectedActiveCycles,
            origin: 'cycles',
          }"
          :watchCurrent="true"
          :inputConfig="{
            label: $t('PermissionsPage.EditEntityRole.activeCycles.title'),
            showAvatar: false,
          }"
          :menuConfig="{
            attach: false,
            showTabs: false,
          }"
          :menuOptions="_listActiveCycles"
          :fillWidth="true"
          :multiple="true"
          :showElements="true"
          :local-search="true"
          @update:item="handleActiveCycles"
        />
      </v-form>
    </template>
    <template
      v-if="deletable && _deleteEntityRolePermission"
      v-slot:footer-left
    >
      <v-btn
        data-test-modal-btn-delete
        elevation="0"
        class="footer-left"
        @click="deleteEntity"
      >
        <v-icon class="fi-rr-trash" />
        <span> {{ $t('Modal.buttonExcluir') }}</span>
      </v-btn>
    </template>
    <template v-slot:footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        @click="cancelEvent"
        tabindex="1"
      >
        <span v-text="$t('Modal.buttonCancel')" />
      </v-btn>

      <v-btn
        data-test-edit-entity-role
        class="button-confirm"
        text
        :loading="loading"
        @click="confirmedEvent"
        tabindex="2"
      >
        <span v-text="$t('PermissionsPage.EditEntityRole.submit')" />
      </v-btn>
    </template>
  </Modal>
</template>
<script>
import { management_performance_entities_access_profiles_delete } from '@/helpers/ability'
import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'
import { fetchEntityPaper, updateEntityPaper } from '@/services/papers'
import { getCycles } from '@/services/cycles'

export default {
  name: 'EditNewEntityRole',
  props: {
    moduleSeleted: {
      type: String,
      default: 'performance',
    },
    cycleSeleted: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deletable: false,
      menuDrop: false,
      valid: false,
      loading: false,
      selectedEntityTypeRole: null,
      selectedActiveCycles: null,
      listEntityType: [],
      listActiveCycles: [],
      form: {
        idEntityType: '',
        roleName: '',
        entityType: [],
        activeCycles: [],
      },
      rules: [
        v =>
          (!!v && v.length > 0) ||
          this.$t('TextQuickEdit.validations.required'),
      ],
    }
  },

  computed: {
    _deleteEntityRolePermission() {
      return this.$can(
        'access',
        management_performance_entities_access_profiles_delete
      )
    },
    _listActiveCycles() {
      return [
        {
          value: 'cycles',
          type: 'listview',
          label: this.$t('PermissionsPage.EditEntityRole.cyclesActives.title'),
          items: this.listActiveCycles,
        },
      ]
    },
    _listEntityType() {
      return [
        {
          value: 'entityTypes',
          type: 'listview',
          label: this.$t('PermissionsPage.EditEntityRole.typeEntity.title'),
          items: this.listEntityType,
        },
      ]
    },
  },
  methods: {
    async handleOpenDialog(value) {
      this.deletable = !!value.deletable
      if (this.$refs.modal) {
        this.form.idEntityType = value.id
        await this.$refs.modal.handleDialog(true)
        await this.fetchGetCycles()
        await this.handleFetchEntityRole(value)
      }
    },
    async fetchGetCycles(payload = {}) {
      return await getCycles(payload)
        .then(res => {
          this.listActiveCycles = this.formatCyclesActives(res.data)
        })
        .catch(error => {
          const message = {
            title: this.$t(
              'PermissionsPage.EditEntityRole.activeCycles.alerts.error.title'
            ),
            description: error?.response?.data?.message,
            type: 'error',
            hasLeftBorder: true,
          }
          this.displayAlert(message)
        })
    },
    formatCyclesActives(data) {
      if (Array.isArray(data)) {
        return data.map(item => {
          if (item.cycle && item.cycle.id && item.cycle.title) {
            return {
              id: item.cycle.id,
              label: item.cycle.title,
            }
          }

          if (item.id && item.title) {
            return {
              id: item.id,
              label: item.title,
            }
          }
        })
      }

      if (data && data.activeCycles && Array.isArray(data.activeCycles)) {
        return data.activeCycles.map(({ cycle }) => ({
          id: cycle.id,
          label: cycle.title,
        }))
      }

      return []
    },
    async handleFetchEntityRole(value) {
      const id = value.id
      try {
        const roleEntity = await fetchEntityPaper(id)

        this.form.roleName = roleEntity.label
        this.listEntityType = this.handleEntityTypeDataToItens([
          { ...roleEntity },
        ])
        this.selectedEntityTypeRole = this.listEntityType
        this.form.entityType = this.selectedEntityTypeRole

        this.selectedActiveCycles = this.formatCyclesActives(
          roleEntity.activeCycles
        )

        this.form.activeCycles = this.selectedActiveCycles
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.EditEntityRole.alert.error'),
          error
        )
        this.displayAlert(alertPayload)
      }
    },
    handleEntityTypeDataToItens(data = []) {
      if (!Array.isArray(data)) {
        return []
      }
      return data.map(item => {
        const title = this.getTitle(item.entityType, item.entityClass)
        const id = item.id

        return {
          id: id,
          label: title,
          entityType: item.entityType,
          entityClass: item.entityClass,
        }
      })
    },
    words(complement, customDefaultKey = null) {
      const defaultKey = customDefaultKey ?? `EntityTypes`

      return this.$t(`${defaultKey}.${complement}`)
    },
    getTitle(entityType, entityClass) {
      const etype = entityType ? entityType : 'emptyType'
      const eclass = entityClass ? entityClass : 'emptyClass'

      const translateKey = `${etype}-${eclass}`

      return this.words(translateKey, 'EntityTypes')
    },
    handleName(value) {
      if (this.form.roleName) {
        this.form.roleName = value
      }
    },

    handleActiveCycles(value) {
      this.form.activeCycles = value
    },
    canceled() {
      this.resetForm()
    },
    resetForm() {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()
      this.selectedEntityTypeRole = null
      this.selectedActiveCycles = null
      this.listEntityType = []
      this.listActiveCycles = []
      this.form.idEntityType = ''
      this.form.roleName = ''
      this.form.entityType = []
      this.form.activeCycles = []
      if (this.$refs?.roleName?.stateError) {
        this.$refs.roleName.stateError = false
      }
    },
    deleteEntity() {
      const rolePayload = structuredClone(this.form)
      this.$emit('delete', rolePayload.idEntityType)
    },

    submit() {
      if (!this.validate()) return
      this.editEntityRoleSubmit()
    },

    validate() {
      let valid = true

      let CustomInputReturn = this.ValidateCustomInputReturn(
        this.$refs['roleName'].validate()
      )
      const value = this.$refs.form.validate()
      if (!value && value != undefined) valid = false

      if (valid && !CustomInputReturn) valid = false

      if (!valid) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }
      return valid
    },
    ValidateCustomInputReturn(result) {
      const isInvalid = result instanceof Node
      if (isInvalid) {
        return false
      }
      return result === undefined
    },
    async editEntityRoleSubmit() {
      this.loading = true
      const id = this.form.idEntityType
      const payload = {
        label: this.form.roleName,
        module: this.moduleSeleted,
        activeCycleIDs: this.form.activeCycles.map(id => id.id),
      }
      try {
        const data = await updateEntityPaper(id, payload)

        const alertPayload = alertSuccessMessage(
          this.$t('PermissionsPage.EditEntityRole.alert.success')
        )
        this.loading = false
        this.$refs.modal.dialog = false
        this.displayAlert(alertPayload)

        this.handleSuccess(payload, data)
      } catch (error) {
        this.loading = false
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.EditEntityRole.alert.error')
        )
        this.displayAlert(alertPayload)
      }
    },
    handleSuccess(payload, data) {
      const moduleDefaultCycle = data.modules.find(
        item =>
          item.module === this.moduleSeleted && typeof item?.cycle !== 'object'
      )

      let isActive = !!moduleDefaultCycle?.active

      if (this.moduleSeleted === 'performance' && this.cycleSeleted) {
        isActive = payload.activeCycleIDs.includes(this.cycleSeleted)
      }

      this.$emit('success', { ...data, active: isActive })
    },
    displayAlert(alertPayload) {
      this.$emit('alert', alertPayload)
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
