<template>
  <div id="listCycleSettings" data-test-list-cycle-settings>
    <AlertBar ref="AlertBar"></AlertBar>

    <div class="card">
      <div class="header">
        <div>
          <div class="header-title" data-test-header-title>
            <v-icon class="crown">fi-sr-crown</v-icon>
            <h1>{{ $t('PerformanceTab.listCycleSettings.title') }}</h1>
          </div>
          <div class="header-subtitle">
            <h3>{{ $t('PerformanceTab.listCycleSettings.subtitle') }}</h3>
          </div>
        </div>
        <div class="btn-container">
          <div
            v-if="_canCycleManagement"
            data-test-cycle-management
            class="change-order-cycle-btn"
            @click="openCycleManagementForm"
          >
            <i class="fi fi-br-settings"></i>
            {{ $t('managementCycleForm.title') }}
          </div>

          <div
            v-if="_showCycleCreate"
            id="add-new-btn"
            data-test-add-new-cycle
            class="add-new-btn add-new-btn-size add-new-btn-primary"
            @click="createNewCycle"
          >
            <v-icon dark small>mdi-plus</v-icon>
            {{ $t('PerformanceTab.listCycleSettings.btnAddNewRoles') }}
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <TableTree
        ref="table"
        v-bind="tree"
        :config-list-cycle="true"
        :show-row-button-only-root="true"
        @down-side-row-button="handleAction"
        @handleAction="handleAction"
      >
        <template #search>
          <v-text-field
            v-model="search"
            class="input-search"
            data-test-data-table-search
            :placeholder="$t('PerformanceTab.listCycleSettings.placeholder')"
            background-color="transparent"
            hide-details
            dense
            solo
            flat
            @keyup="searchInput()"
          >
            <template #prepend-inner>
              <v-icon class="mr-1" color="neutral5" x-small>
                fi-rr-search
              </v-icon>
            </template>
          </v-text-field>
        </template>
      </TableTree>
    </div>

    <DeleteCycle ref="deleteCycle" @display-alert="showAlert"></DeleteCycle>
  </div>
</template>

<script>
import TableTree from '@/components/TableTree/TableTree.vue'
import {
  management_performance_cycle_delete,
  management_performance_cycle_edit,
  management_performance_cycle_create,
  management_performance_cycle_general_settings,
} from '@/helpers/ability'

import { getCyclesTree, putStatusCycle } from '@/services/cycles/index.js'
import DeleteCycle from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/CycleForm/partials/DeleteCycle/DeleteCycle.vue'
import { props } from '@/providers/tableTreeCycles/index.js'

export default {
  name: 'ListCycleSettings',

  components: {
    DeleteCycle,
    TableTree,
  },

  data() {
    return {
      defaltOrderCycles: [
        { period: 'current', expanded: true },
        { period: 'future', expanded: false },
        { period: 'past', expanded: false },
      ],
      orderCycles: [],
      search: '',
      afterSearch: false,
      originTree: null,
      cycleID: null,
      tree: {
        ...props,
        loading: false,
        downSideRowButton: this.$can(
          'access',
          management_performance_cycle_create
        ),
        searchOptions: {
          ...props.searchOptions,
          placeholder: this.$t('GroupsPage.treeSearch.placeholder'),
        },
        columns: props.columns(this.getAction, this.handleAction),
      },
    }
  },

  computed: {
    _showCycleCreate() {
      return this.$can('access', management_performance_cycle_create)
    },

    _canCycleManagement() {
      return this.$can('access', management_performance_cycle_general_settings)
    },

    _currentOrderCycles() {
      return this.orderCycles[0]?.cyclesOrder
        ? this.orderCycles[0]?.cyclesOrder
        : this.defaltOrderCycles
    },
  },

  beforeMount() {
    this.$root.$on('handle-cycles', this.handleCyclesTree)
  },

  async mounted() {
    this.handleCyclesTree()
  },

  beforeDestroy() {
    this.$root.$off('handle-cycles', this.handleCyclesTree)
  },

  methods: {
    showAlert(event) {
      this.$emit('display-alert', {
        statusAlert: event.statusAlert,
        messageAlert: event.messageAlert,
      })
    },

    edit({ node }) {
      this.$emit('editCycle', node)
    },

    remove({ node }) {
      this.$refs.deleteCycle.handleDialog({
        id: node.id,
        parentCycleID: node.parentCycleID,
      })
    },

    createNewCycle({ node } = {}) {
      this.$emit('createNewCycle', node)
    },

    openCycleManagementForm() {
      this.$emit('openCycleManagementForm')
    },

    searchInCycle(cycle, searchText, foundCycles) {
      if (
        cycle.title &&
        cycle.title.toLowerCase().includes(searchText.toLowerCase())
      ) {
        foundCycles.push(cycle)
      }

      if (cycle.children) {
        cycle.children.forEach(childCycle =>
          this.searchInCycle(childCycle, searchText, foundCycles)
        )
      }
    },

    searchCycles(cycles, searchText) {
      const foundCycles = []
      cycles.forEach(cycle =>
        this.searchInCycle(cycle, searchText, foundCycles)
      )
      return foundCycles
    },

    searchInput() {
      this.tree.tree = this.originTree
      const trimmedSearch = this.search.trim()

      if (trimmedSearch !== '') {
        const searchCycles = this.searchCycles(this.tree.tree, trimmedSearch)
        this.tree.tree = searchCycles
        setTimeout(() => {
          this.$refs?.table?.resetTable()
        }, 50)
        this.afterSearch = false
        return
      }
      if (this.afterSearch === false) {
        this.handleCyclesTree()
        this.afterSearch = true
      }
    },

    async handleCyclesTree() {
      this.tree.loading = true

      const { data } = await getCyclesTree({
        sortOrder: 'desc',
        sortBy: 'createdAt',
      })

      this.tree.tree = data
      this.originTree = data
      setTimeout(() => {
        this.$refs?.table?.resetTable()
        this.tree.loading = false
      }, 50)
      this.searchInput()
    },

    handleAction(act, node, i, control) {
      const payload = { node, i, control }

      const actions = {
        edit: this.edit,
        delete: this.remove,
        'down-side-row-button': this.createNewCycle,
        'change-status': this.changeGroupStatus,
      }

      return actions[act](payload)
    },

    async changeGroupStatus({ node }) {
      let statusCurrent = node.status === 'active' ? 'inactive' : 'active'

      try {
        await putStatusCycle(node.id, {
          status: statusCurrent,
        })

        const status = statusCurrent
        node.status = status
        if (status === 'inactive') {
          this.handleAlert('cycleDisabled', 'success', node)
        } else {
          this.handleAlert('cycleActivated', 'success', node)
        }
        const RuleCycleUpdateSubcycles =
          status === 'inactive' && node.childrenCount
        const RuleSubcyclesUpdateCycle =
          status === 'active' && node.parentCycleID
        if (!RuleCycleUpdateSubcycles && !RuleSubcyclesUpdateCycle) {
          return
        }

        this.handleCyclesTree()
      } catch (err) {
        this.handleAlert('ERR_BAD_REQUEST', 'alert', node)
      }
    },

    getAction(row) {
      const actions = []
      const status =
        row.status === 'inactive'
          ? this.$t('PerformanceTab.listCycleSettings.actions.activateGroup')
          : this.$t('PerformanceTab.listCycleSettings.actions.disableGroup')

      actions.push({
        name: this.$t('TreeView.edit'),
        icon: 'fi fi-rr-pencil',
        action: 'edit',
        inline: true,
      })
      if (this.$can('access', management_performance_cycle_edit)) {
        actions.push({
          name: status,
          icon: 'fi fi-rr-power',
          action: 'change-status',
          inline: false,
        })
      }

      if (this.$can('access', management_performance_cycle_delete)) {
        actions.push({
          name: this.$t('TreeView.delete'),
          icon: 'fi-rr-trash',
          action: 'delete',
        })
      }

      return actions
    },

    handleAlert(act, status, node) {
      const config = {
        messagePrefix: '',
        description: node.title,
        type: status,
        hasLeftBorder: false,
        hasFooter: false,
      }

      switch (act) {
        case 'cycleDisabled':
          config.messagePrefix = this.$t(
            'PerformanceTab.listCycleSettings.statusLabel.cycleDisabled'
          )
          break
        case 'cycleActivated':
          config.messagePrefix = this.$t(
            'PerformanceTab.listCycleSettings.statusLabel.cycleActivated'
          )
          break
        case 'ERR_BAD_REQUEST':
          config.description = this.$t('TreeView.alert.network-err')
      }

      this.$refs.AlertBar.displayAlert(config)
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
