<template>
  <td :class="tabClass()">
    <div class="first-td history-btn">
      <div v-if="story && story.parentIndex" class="d-flex">
        <div class="three-dot" @click="handleThreeDot">
          <v-icon :size="16">mdi-dots-horizontal</v-icon>
        </div>
        <div class="back-to" @click="toggleNodeChildren(controls, rowIndex)">
          <v-icon :size="16">mdi-chevron-left</v-icon>
          <span> {{ row.name }} </span>
        </div>
      </div>
    </div>

    <DownsideRowButton
      style="margin-top: -1px"
      @down-side-row-button="handleClick"
      :rowIndex="rowIndex"
      :event="event"
      :row="row"
    />
    <HistoryList
      :show="historyShow"
      :row="row"
      :rowIndex="rowIndex"
      :controls="controls"
      :stories="stories"
      :toggleNodeChildren="toggleNodeChildren"
    />
  </td>
</template>
<script>
import DownsideRowButton from './DownsideRowButton'

import HistoryList from './HistoryList'

export default {
  name: 'HistoryBtn',
  components: {
    HistoryList,
    DownsideRowButton,
  },
  props: {
    controls: Array,
    stories: Array,
    row: Object,
    rowIndex: Number,
    toggleNodeChildren: Function,
  },
  inject: ['hideNodesAfter'],
  data() {
    return {
      historyShow: false,
    }
  },
  computed: {
    story() {
      return this.stories.find(story => story.key === this.row.key)
    },
    control() {
      return this.controls[this.rowIndex]
    },
    event() {
      return {
        nivel: this.control.nivel + 2,
        control: this.control,
      }
    },
  },
  methods: {
    handleThreeDot() {
      this.historyShow = !this.historyShow
    },
    handleClick(button, row, rowIndex) {
      this.$emit(button, button, row, rowIndex)
    },
    tabClass() {
      return `nivel-${this.hideNodesAfter} d-flex`
    },
    rowTabClass() {
      return `nivel-${this.hideNodesAfter + 1}`
    },
  },
}
</script>
<style src="../style.scss" lang="scss" scoped />
