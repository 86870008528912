<template>
  <Modal
    ref="modal"
    :title="$t('PermissionsPage.AddNewRoles.group-roles.title')"
    :has-buttons="true"
    width="634px"
    @confirmed="confirmed"
    @cancel="canceled"
  >
    <template #no-tab="{ dialogState }">
      <v-form
        v-if="dialogState"
        ref="form"
        v-model="formIsValid"
        class="modal-content"
        lazy-validation
        @submit.prevent
      >
        <ComposeForm
          ref="ComposeForm"
          :form-name="'groups-roles'"
          :selectors="_filterSelectors"
          :lazy-submit="false"
          :storage-preferences="false"
          @compose:values="edgeValues = $event"
          @hot:loading="handleFormReady()"
          @api:itens="handleApiItens"
        ></ComposeForm>
      </v-form>
    </template>

    <template #footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        tabindex="1"
        @click="cancelEvent"
      >
        <span v-text="$t('Modal.buttonCancel')"></span>
      </v-btn>

      <v-btn
        data-test-configure-groups-roles-permissions-confirm
        class="button-confirm"
        text
        :loading="formIsAnimating"
        tabindex="2"
        @click="confirmedEvent"
      >
        <span
          v-text="$t('PermissionsPage.AddNewRoles.group-roles.submit')"
        ></span>
      </v-btn>
    </template>
  </Modal>
</template>

<script>
import { fetchPerformanceEntityList, createGroupPaper } from '@/services/papers'
import { getCycles } from '@/services/cycles'

import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'

import { debounce } from 'lodash'

export default {
  name: 'AddNewGroupRole',

  props: {
    tabSeleted: {
      type: String,
      default: 'group-roles',
    },
    moduleSeleted: {
      type: String,
      default: 'performance',
    },
  },

  data() {
    return {
      handleConfirmed: debounce(async function (resetDialogCallback) {
        await this.addNewGroupsRolesPermissions(
          this.moduleSeleted,
          this.edgeValues
        ).then(status => {
          this.formIsAnimating = false

          if (status === 'success') {
            resetDialogCallback()
          }
        })
      }, 500),

      filters: {
        default: [
          {
            id: 'label',
            label: this.$t('PermissionsPage.AddNewRoles.fields.label'),
            type: 'CustomInput',
            CustomInput: {
              mandatory: true,
              type: 'text',
            },
          },
        ],
        performance: [
          {
            id: 'activeCycleIDs',
            _label: this.$t('PermissionsPage.filter.fields.activeCycles'),
            type: 'ItemSelector',
            submit: {
              type: 'arrayOf',
              identifier: 'id',
            },
            ItemSelector: {
              localSearch: false,
              multiple: true,
            },
            eagerApi: true,
            api: {
              endpoint: getCycles,
              searchKey: 'title',
              infinity: true,
              identifier: 'id',
              limit: 500,
              hidrateFn: item => {
                return {
                  ...item,
                  label: item.title,
                }
              },
            },
          },
        ],
        'access-profiles': [
          {
            id: 'Type_Class',
            _label: this.$t('PermissionsPage.filter.fields.category'),
            type: 'ItemSelector',
            submit: {
              type: 'original',
            },
            ItemSelector: {
              localSearch: true,
              multiple: false,
              inputConfig: {
                rules: ['required'],
              },
            },
            api: {
              endpoint: fetchPerformanceEntityList,
              searchKey: null,
              infinity: false,
              identifier: 'id',
              hidrateFn: item => {
                const calcLabel = item => {
                  let label = []

                  if (item.entityType) {
                    label.push(this.$t(`entityType.${item.entityType}`))
                  }

                  if (item.entityClass) {
                    label.push(this.$t(`entityClass.${item.entityClass}`))
                  }

                  return label.join(' ')
                }

                return {
                  ...item,
                  label: calcLabel(item),
                }
              },
            },
          },
        ],
      },

      edgeValues: {},
      form: {},
      formIsAnimating: false,
      formIsValid: true,
    }
  },
  computed: {
    _filterSelectors() {
      return this.getFilterSelectors(this.moduleSeleted, this.tabSeleted)
    },
  },
  methods: {
    getFilterSelectors(moduleSeleted, tabSeleted) {
      const defaultFilterSelectors = this.filters.default
      const currentModuleFilterSelectors = this.filters[moduleSeleted] || []
      const currentTabFilterSelectors = this.filters[tabSeleted] || []

      const FilterSelectors = {
        fields: [
          ...defaultFilterSelectors,
          ...currentTabFilterSelectors,
          ...currentModuleFilterSelectors,
        ],
      }

      return FilterSelectors
    },

    manualValidate() {
      if (this.$refs.ComposeForm) {
        const { formValid } = this.$refs.ComposeForm.validate()

        if (formValid === false) {
          return false
        }
      }

      if (this.$refs.form) {
        const formValid = this.$refs.form.validate()

        if (formValid === false) {
          return false
        }
      }

      return true
    },

    async confirmed(resetDialogCallback) {
      if (!this.manualValidate()) {
        return
      }

      this.formIsAnimating = true
      this.handleConfirmed(resetDialogCallback)
    },

    handleFormReady() {
      this.handleConfirmed.flush()
    },

    async addNewGroupsRolesPermissions(moduleID, payload) {
      return await createGroupPaper({
        ...payload,
        module: moduleID,
        contexts: [],
      })
        .then(res => {
          this.$emit('success', res)

          const alertPayload = alertSuccessMessage(
            this.$t('PermissionsPage.AddNewRoles.group-roles.alert.success')
          )

          this.displayAlert(alertPayload)
          return 'success'
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.AddNewRoles.group-roles.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
          return 'error'
        })
    },

    displayAlert(alertPayload) {
      this.$emit('alert', alertPayload)
    },

    canceled() {
      this.formIsAnimating = false
      this.handleConfirmed.cancel()

      this.$emit('cancel')
    },

    handleOpenDialog() {
      if (this.$refs.modal) {
        this.$refs.modal.handleDialog(true)
      }
    },

    handleApiItens({ fieldID, hidratedItens, currentValue, isDirt }) {
      if (!this.$refs.ComposeForm) {
        return
      }

      const HidrateOnFirstLoad = !Array.isArray(currentValue) && !isDirt
      const HasItensToHidrate =
        Array.isArray(hidratedItens) && hidratedItens.length

      if (
        fieldID === 'activeCycleIDs' &&
        HidrateOnFirstLoad &&
        HasItensToHidrate
      ) {
        this.$refs.ComposeForm.defineFieldsAsValue(
          {
            [fieldID]: hidratedItens,
          },
          false
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
