import i18n from '@/plugins/i18n'
import TextLabel from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextLabel/TextLabel.vue'
import TextStatus from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextStatus/TextStatus.vue'
import TextIcon from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TextIcon/TextIcon.vue'
import MoreActions from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/Actions/Actions.vue'
import moment from 'moment'
export const searchOptions = {
  searchable: true,
  path: ['name'],
  placeholder: '',
}

export const columns = (actions, handleAction) => [
  {
    key: 'name',
    label: i18n.t('PerformanceTab.listCycleSettings.header.name'),
    component: 'TextLabel',
    schemas: [
      {
        name: 'label',
        mutation: row => row.title,
      },
      {
        name: 'overflow',
        mutation: () => 44,
      },
      {
        name: 'onClick',
        mutation: (row, i) =>
          i >= 0 ? () => handleAction('edit', row, i) : null,
      },
    ],
  },
  {
    key: 'childrenCount',
    label: i18n.t('PerformanceTab.listCycleSettings.header.hierarchy'),
    component: 'ChipGroup',
    schemas: [
      {
        name: 'data',
        mutation: row => {
          const value = row?.childrenCount
          if (!value) return ['-']
          return [
            value + ' ' + i18n.t('PerformanceTab.listCycleSettings.subcycle'),
          ]
        },
      },
    ],
  },
  {
    key: 'period',
    label: i18n.t('PerformanceTab.listCycleSettings.header.period'),
    component: 'TextLabel',
    schemas: [
      {
        name: 'label',
        mutation: row => {
          const startDate = moment(row?.startDate).format('DD/MM/YYYY')
          const endDate = moment(row?.endDate).format('DD/MM/YYYY')

          if (startDate === 'Invalid date' && endDate === 'Invalid date')
            return ['-']

          return `${startDate} - ${endDate}`
        },
      },
    ],
  },
  {
    key: 'status',
    label: i18n.t('PerformanceTab.listCycleSettings.header.status'),
    component: 'TextStatus',
    schemas: [
      {
        name: 'active',
        mutation: row => {
          if (row.status === 'active') return true
          return false
        },
      },
      {
        name: 'outlined',
        mutation: () => true,
      },
      {
        name: 'configListCycle',
        mutation: () => true,
      },
    ],
  },
  {
    key: 'actions',
    label: i18n.t('PerformanceTab.listCycleSettings.header.actions'),
    component: 'MoreActions',
    schemas: [
      {
        name: 'options',
        mutation: row => actions(row),
      },
    ],
    width: '50px',
  },
]

export const components = [
  {
    component: TextStatus,
    name: 'TextStatus',
  },
  {
    component: MoreActions,
    name: 'MoreActions',
  },
  {
    component: TextIcon,
    name: 'TextIcon',
  },
  {
    component: TextLabel,
    name: 'TextLabel',
  },
]

export const props = {
  searchOptions: searchOptions,
  components: components,
  columns: columns,
  hideNodesAfter: 3,
  tree: [],
}
