<template>
  <div
    class="group-detail__container group-detail__row"
    data-test-group-detail-component
  >
    <div class="group-detail__column">
      <div class="group-detail__row">
        <div class="group-detail__column">
          <AvatarComponent
            :id="this.item.id"
            :text="_name"
            :src="_image"
            :color="_color"
            outlined
          />
          <v-icon v-if="haveMore">fi-rr-arrow-small-down</v-icon>
        </div>

        <div class="group-detail__column group-detail__details">
          <span v-text="_name" />
          <span v-text="_code" />
          <span
            :class="`group-detail__details__status--${
              item.inactive ? 'inactive' : 'active'
            }`"
            v-text="_active"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupDetail',
  props: {
    item: {
      type: Object,
    },
    haveMore: {
      type: Boolean,
    },
  },
  computed: {
    _name: function () {
      return this.item.name || ''
    },
    _code: function () {
      return this.item.costCenter || ''
    },
    _active: function () {
      return this.item.inactive ? 'Inativo' : 'Ativo'
    },
    _image: function () {
      return this.item.image || ''
    },
    _color: function () {
      return this._image ? '$neutral' : null
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
