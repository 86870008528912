<template>
  <MenuForm
    ref="MenuForm"
    kebabNameMenu="permission-filter"
    :selectors="_filterSelectors"
    :menu="{
      title: $t('PermissionsPage.filter.title'),
      footer: {
        cancel: $t('PermissionsPage.filter.cancel'),
        apply: $t('PermissionsPage.filter.apply'),
      },
    }"
    :menuProps="{
      minWidth: '18rem',
      maxWidth: '18rem',
      nudgeBottom: '3px',
    }"
    :restorePreviousState="true"
    @form:values="submit"
  >
    <template v-slot:default="{ formHasValue, cleanValueCallback }">
      <v-btn
        class="permission-filter"
        :class="{
          active: formHasValue,
        }"
        elevation="0"
        text
      >
        <v-icon size="0.75rem" class="fi-rr-filter" />
        <div>
          <span v-text="'Filtros'" />

          <v-btn
            data-test-menu-filters-clear="permission"
            v-show="formHasValue"
            icon
            @click.stop.prevent="cleanValueCallback"
            x-small
          >
            <v-icon size="0.875rem" class="clearable fi-rr-cross-small" />
          </v-btn>
        </div>
      </v-btn>
    </template>
  </MenuForm>
</template>

<script>
import _ from 'lodash'
import MenuForm from '@/views/ManagementPage/Tabs/PermissionsPage/Partials/MenuForm/MenuForm.vue'
import { fetchPerformanceEntityList } from '@/services/papers'

export default {
  name: 'PermissionFilter',
  props: {
    moduleSeleted: {
      type: String,
      default: 'performance',
    },
    tabSeleted: {
      type: String,
      default: 'permission-groups',
    },
  },
  components: {
    MenuForm,
  },

  data() {
    return {
      filters: {
        default: [
          {
            id: 'label',
            label: this.$t('PermissionsPage.filter.fields.label'),
            type: 'CustomInput',
          },
        ],
        'access-profiles': [
          {
            id: 'hierarchySettingsID',
            _label: this.$t('PermissionsPage.filter.fields.category'),
            type: 'ItemSelector',
            ItemSelector: {
              localSearch: true,
              multiple: false,
              inputConfig: {
                closeOnSelect: true,
              },
            },
            api: {
              endpoint: fetchPerformanceEntityList,
              searchKey: null,
              infinity: false,
              limit: 5,
              identifier: 'id',
              hidrateFn: item => {
                const calcLabel = item => {
                  let label = []

                  if (item.entityType) {
                    label.push(this.$t(`entityTypeShort.${item.entityType}`))
                  }

                  if (item.entityClass) {
                    label.push(this.$t(`entityClass.${item.entityClass}`))
                  }

                  return label.join(' ')
                }

                return {
                  ...item,
                  label: calcLabel(item),
                }
              },
            },
          },
        ],
        performance: [
          {
            id: 'active',
            _label: this.$t('PermissionsPage.filter.fields.active'),
            options: [
              {
                value: 'active',
                label: this.$t('PermissionsPage.filter.fields.active'),
                type: 'listview',
                items: [
                  { id: 'true', label: 'Hábilitado' },
                  { id: 'false', label: 'Desabilitado' },
                ],
              },
            ],
            type: 'ItemSelector',
            ItemSelector: {
              localSearch: true,
              multiple: false,
              inputConfig: {
                closeOnSelect: true,
              },
            },
          },
        ],
      },
    }
  },

  computed: {
    _filterSelectors() {
      return this.getFilterSelectors(this.moduleSeleted, this.tabSeleted)
    },
  },

  methods: {
    getFilterSelectors(moduleSeleted, tabSeleted) {
      const defaultFilterSelectors = this.filters.default
      const currentModuleFilterSelectors = this.filters[moduleSeleted] || []
      const currentTabFilterSelectors = this.filters[tabSeleted] || []

      const FilterSelectors = {
        fields: [
          ...defaultFilterSelectors,
          ...currentTabFilterSelectors,
          ...currentModuleFilterSelectors,
        ],
      }

      return FilterSelectors
    },

    submit(form) {
      this.$emit('filter:permission:values', form)
    },

    cleanFilters: _.debounce(function () {
      if (this.$refs.MenuForm) {
        this.$refs.MenuForm.handleActionClean()
      }
    }, 150),
  },

  watch: {
    moduleSeleted: {
      handler() {
        this.cleanFilters()
      },
      immediate: true,
    },
    tabSeleted: {
      handler() {
        this.cleanFilters()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
