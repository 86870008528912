import { render, staticRenderFns } from "./EntityBox.vue?vue&type=template&id=309ceb17&scoped=true&"
import script from "./EntityBox.vue?vue&type=script&lang=js&"
export * from "./EntityBox.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=309ceb17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309ceb17",
  null
  
)

export default component.exports