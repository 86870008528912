<template>
  <section :data-test-audit-log="AuditLog.id" class="audit-log-view">
    <header class="audit-log-view-header">
      <div class="audit-log-actor">
        <AvatarComponent
          size="3rem"
          :id="AuditLog.actor.id"
          :text="AuditLog.actor.name"
          :image="AuditLog.actor?.photo"
        ></AvatarComponent>
        <span class="audit-log-actor-name" v-text="AuditLog.actor.name"></span>
        <span
          class="audit-log-actor-email"
          v-text="AuditLog.actor.email"
        ></span>
      </div>

      <div class="audit-log-action">
        <v-icon size="1rem">fi-rr-bell-ring</v-icon>

        <span>
          {{ _actions[AuditLog.action] }}

          <strong
            v-if="calcActionText(AuditLog)"
            v-text="calcActionText(AuditLog)"
          ></strong>
        </span>
      </div>
    </header>

    <div class="audit-log-view-content">
      <div class="audit-log-body">
        <small v-if="AuditLog.path" class="audit-log-path">
          [{{ AuditLog.method }}] {{ AuditLog.path.split('v1')[1] }}
        </small>

        <pre
          v-if="AuditLog.body"
          class="audit-log-payload"
          v-text="JSON.stringify(AuditLog.body, null, 2)"
        ></pre>
      </div>

      <footer class="audit-log-view-footer">
        <v-divider class="neutral-divider"></v-divider>

        <span
          class="audit-log-user_agent"
          v-text="AuditLog.actor.user_agent"
        ></span>
        <span
          class="audit-log-forwarded_for"
          v-text="AuditLog.actor.forwarded_for"
        ></span>
      </footer>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuditLog',
  props: {
    AuditLog: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      _entities: 'AuditLogsPage/entitiesI18n',
      _actions: 'AuditLogsPage/actionsI18n',
    }),
  },

  methods: {
    calcActionText(AuditLog) {
      if (AuditLog.entity === 'groups' && AuditLog.object) {
        return AuditLog.object.name
      }

      const isEditSelf = [
        'users.edit.self',
        'users.invite',
        'users.export',
      ].includes(AuditLog.action)

      if (AuditLog.entity === 'users' && AuditLog.object && !isEditSelf) {
        return `${AuditLog.object.profile.firstname} ${AuditLog.object.profile.lastname}`
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
