<template>
  <div data-app class="table-view-page">
    <card-wrapper
      :title="$t('GroupsPage.title')"
      :subtitle="$t('GroupsPage.subtitle')"
      :helper-props="{
        videoType: 'managementPage',
        short: true,
        hasVideo: false,
      }"
    >
      <template #action>
        <v-btn
          data-test-show-treeview
          class="text-none show-treeview"
          color="primary"
          text
          @click="toggleViews(true)"
        >
          <div class="action-icon d-flex align-center justify-center">
            <v-icon size="0.75rem">fi-rr-ftp</v-icon>
          </div>
          <span>{{ $t('GroupsPage.treeview') }}</span>
        </v-btn>
      </template>

      <template #content>
        <TableTree
          ref="table"
          v-bind="tree"
          @down-side-row-button="handleAction"
          @handleAction="handleAction"
        >
          <template #search>
            <TreeSearch
              ref="treeSearch"
              v-bind="searchBarConfig"
              @reset="fetchAccountGroups"
            ></TreeSearch>
          </template>
        </TableTree>
      </template>
    </card-wrapper>

    <ModalInactiveGroup
      ref="inactiveModal"
      @confirm:group-only="statusSubmit(false, false, $event)"
      @confirm:group-people="statusSubmit(false, true, $event)"
    ></ModalInactiveGroup>

    <AlertBar ref="AlertBar">
      <div class="alert-container">
        <span @click="handleAlertAction('edit')">
          <i class="fi-rr-browser"></i>
          {{ $t('TreeView.alert.open') }}
        </span>
        <span @click="handleAlertAction('search')">
          <i class="fi-rr-search"></i>
          {{ $t('TreeView.alert.search') }}
        </span>
      </div>
    </AlertBar>
  </div>
</template>

<script>
import TreeSearch from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TreeSearch/TreeSearch.vue'
import ItemOfList from '@/components/ItemOfList/ItemOfList.vue'
import TableTree from '@/components/TableTree/TableTree.vue'
import ModalInactiveGroup from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/ModalInactiveGroup/ModalInactiveGroup.vue'

import {
  fetchAccountGroups,
  fetchGroupsList,
  fetchGroupParents,
  fetchGroupChildren,
  changeGroupStatus,
} from '@/services/groups'
import { props } from '@/providers/tableTree'
import { mapGetters } from 'vuex'

import * as _permissions from '@/helpers/ability/permissions'

export default {
  name: 'GroupsPage',
  components: {
    TreeSearch,
    TableTree,
    ModalInactiveGroup,
  },
  inject: ['toggleViews', 'handleConfigModal', 'fetchAccount'],
  data() {
    return {
      modalConfig: null,
      tree: {
        ...props,
        loading: false,
        downSideRowButton: this.$can(
          'access',
          _permissions.management_groups_create
        ),
        searchOptions: {
          ...props.searchOptions,
          placeholder: this.$t('GroupsPage.treeSearch.placeholder'),
        },
        columns: props.columns(this.getAction, this.handleAction),

        hooks: {
          beforeNodeOpen: this.beforeNodeOpen,
        },
      },
      stirredNode: {},
      searchBarConfig: null,
    }
  },
  computed: {
    ...mapGetters({
      account: 'currentAccount/account',
    }),
  },

  watch: {
    account: {
      handler() {
        this.fetchAccountGroups()
      },
      deep: true,
    },
  },
  async mounted() {
    this.initTreeSearch()

    if (!this.account.name) {
      await this.fetchAccount()
    }

    this.fetchAccountGroups()
  },
  methods: {
    getAction(row) {
      const inactive = row.inactive

      const actions = [
        {
          permission: this.$can('access', _permissions.management_groups_edit),
          status: true,
          name: this.$t('TreeView.actions.edit'),
          icon: 'fi-rr-pencil',
          action: 'edit',
          inline: true,
        },
        {
          permission:
            this.$can('access', _permissions.management_groups_edit) ||
            this.$can('access', _permissions.management_groups_activate),
          status: inactive,
          name: this.$t('TreeView.actions.activate-group'),
          icon: 'fi-rr-power',
          action: 'change-status',
        },
        {
          permission:
            this.$can('access', _permissions.management_groups_edit) ||
            this.$can('access', _permissions.management_groups_inactivate),
          status: !inactive,
          name: this.$t('TreeView.actions.disable-group'),
          icon: 'fi-br-power',
          action: 'change-status',
        },
        {
          permission: this.$can('access', _permissions.management_groups_move),
          status: true,
          name: this.$t('TreeView.move'),
          icon: 'fi-rr-arrow-small-right',
          action: 'move',
        },
        {
          permission: this.$can(
            'access',
            _permissions.management_groups_duplicate
          ),
          status: true,
          name: this.$t('TreeView.duplicate'),
          icon: 'fi-rr-copy-alt',
          action: 'duplicate',
        },
        {
          permission: this.$can(
            'access',
            _permissions.management_groups_delete
          ),
          status: true,
          name: this.$t('TreeView.delete'),
          icon: 'fi-rr-trash',
          action: 'delete',
        },
      ]

      const items = actions.filter(action => action.permission && action.status)

      return items
    },
    async handleAlertAction(act) {
      if (act === 'search') {
        this.$refs?.treeSearch?.insertText(this.stirredNode.name, true)

        const node = this.stirredNode?.parentGroupID
          ? await fetchGroupParents(this.stirredNode)
          : this.stirredNode
        await this.$refs.table.overrideChildren(node, 0)
      }

      if (act === 'edit')
        return this.openEditNodeForm({ node: this.stirredNode })
    },
    handleAction(act, node, i, control) {
      const payload = { node, i, control }

      const actions = {
        edit: this.openEditNodeForm,
        move: this.openMoveNodeModalForm,
        delete: this.openDeleteModalForm,
        duplicate: this.openDuplicateNodeModalForm,
        'down-side-row-button': this.openAddNodeForm,
        'change-status': this.changeGroupStatus,
      }

      return actions[act](payload)
    },
    handleAlert(act, status, node) {
      const config = {
        messagePrefix: '',
        description: node.title,
        type: status,
        hasLeftBorder: false,
        hasFooter: false,
      }

      switch (act) {
        case 'add':
          config.messagePrefix = this.$t('TreeView.alert.add')
          break
        case 'edit':
          config.messagePrefix = this.$t('TreeView.alert.edit')
          break
        case 'duplicate':
          this.stirredNode = node
          config.messagePrefix = this.$t('TreeView.alert.duplicate')
          config.hasLeftBorder = true
          config.hasFooter = true
          break
        case 'move-fail':
          config.messagePrefix = this.$t('TreeView.alert.move-fail')
          break
        case 'move':
          this.stirredNode = node
          config.messagePrefix = this.$t('TreeView.alert.move')
          config.hasLeftBorder = true
          config.hasFooter = true
          break
        case 'delete':
          config.messagePrefix = this.$t('TreeView.alert.delete')
          break
        case 'network-err':
          config.messagePrefix = this.$t('TreeView.alert.network-err')
          break
        case 'group-disabled':
          config.messagePrefix = this.$t('TreeView.alert.group-disabled')
          break
        case 'group-activated':
          config.messagePrefix = this.$t('TreeView.alert.group-activated')
          break
      }

      this.$refs.AlertBar.displayAlert(config)
    },

    async changeGroupStatus({ node }) {
      if (!node.inactive) {
        this.$refs.inactiveModal.handleDialog(node)
        return
      }

      try {
        await this.statusSubmit(node.inactive, false, node)
      } catch (err) {
        if (err.code === 'ERR_NETWORK')
          this.handleAlert('network-err', 'alert', node)
      }
    },
    async statusSubmit(activate, people, node) {
      const options = {
        people: people,
        hierarchy: false,
      }

      await changeGroupStatus(node.id, activate, options).then(() => {
        if (!activate) {
          this.$refs.inactiveModal.handleDialog()
        }

        node.inactive = !node.inactive

        if (activate) {
          this.handleAlert('group-disabled', 'success', node)
        } else {
          this.handleAlert('group-activated', 'success', node)
        }
      })
    },

    handleCreated(data) {
      const table = this.$refs?.table
      data.childrenCount = data.childrenCount || 0

      if (table) {
        const { rows, controls } = table
        const index = rows.findIndex(e => e.id === data.parentGroupID)
        if (index >= 0 && controls[index].visible) {
          const target = rows[index]
          target.childrenCount += 1

          const resp = table.insertChild(index, data, false)
          const { hidingNodesAfter, row, rowControl } = resp

          if (hidingNodesAfter)
            table.handleToggleRow(row, rowControl, false, index)

          if (data.subgroupIDs.length) {
            this.moveSubgroups(data.subgroupIDs, index + 1)
          }
        } else {
          if (data.subgroupIDs.length) {
            this.removeSubgroups(data.subgroupIDs)
          }
        }
      }
    },
    async handleUpdateSubgroups(act, groupID, subgroupID, showAlert) {
      const table = this.$refs?.table
      const { rows } = table

      if (act === 'add') {
        const refIndex = rows.findIndex(e => e.id === groupID)

        if (refIndex !== -1) return this.moveSubgroups([subgroupID], refIndex)
        return this.removeSubgroups([subgroupID])
      }

      if (act === 'remove') {
        const refIndex = rows.findIndex(e => e.id === subgroupID)
        const refNode = rows[refIndex]

        if (refNode)
          return this.handleMoveTable(refNode, 'root', refIndex, showAlert)
        return this.removeSubgroups([subgroupID])
      }
    },
    moveSubgroups(subgroups, targetIndex) {
      const table = this.$refs?.table
      const { rows } = table

      const child = rows[targetIndex]

      subgroups.forEach(group => {
        const currIndex = rows.findIndex(row => row.id === group)

        child.childrenCount = child.childrenCount + 1

        if (currIndex !== -1) {
          const row = rows[currIndex]
          row.parentGroupID = child.id

          const { oldParent } = this.$refs.table.moveRow(
            currIndex,
            targetIndex,
            false
          )
          if (!oldParent) return

          oldParent.childrenCount = oldParent.childrenCount - 1
        }
      })
    },
    removeSubgroups(subgroups) {
      const table = this.$refs?.table
      const { rows } = table

      subgroups.forEach(group => {
        const currIndex = rows.findIndex(row => row.id === group)

        if (currIndex !== -1) {
          const { parent } = table.removeRow(currIndex)
          parent.childrenCount = parent.childrenCount - 1
        }
      })
    },
    openAddNodeForm({ node }) {
      const payload = {
        callback: null,
        key: 'new',
        action: 'addGroup',
        target: structuredClone(node),
      }

      this.$emit('handleAction', payload)
    },
    openEditNodeForm({ node }) {
      const act = 'edit'

      const callback = editedNode => {
        const blocked = ['children', '_children', 'key']

        for (let key in editedNode) {
          if (!blocked.includes(key)) node[key] = editedNode[key]
        }
        this.handleAlert(act, 'success', node)
      }

      const payload = {
        callback,
        key: node.id,
        action: 'viewGroup',
        target: structuredClone(node),
        act: act,
      }

      this.$emit('handleAction', payload)
    },
    openDuplicateNodeModalForm({ node }) {
      const callback = async (duplicated, id) => {
        const targetIndex = this.$refs.table.findRowIndexBy('id', id)

        if (targetIndex !== -1) {
          duplicated.peopleCount = duplicated.people.length
          duplicated.childrenCount = 0

          const { row } = this.$refs.table.insertChild(
            targetIndex,
            duplicated,
            false
          )

          row.childrenCount = row.childrenCount + 1
        }
        this.handleAlert('duplicate', 'success', duplicated)
      }

      this.handleConfigModal('duplicate', {
        callback: callback,
        target: structuredClone(node),
      })
    },
    async handleMoveTable(
      node,
      targetID,
      originIndex = null,
      showAlert = true
    ) {
      if (!targetID && showAlert)
        return this.handleAlert('move-fail', 'alert', node)
      if (!originIndex)
        originIndex = this.$refs.table.findRowIndexBy('id', node.id)

      const targetIndex =
        targetID === 'root'
          ? 0
          : this.$refs.table.findRowIndexBy('id', targetID)

      node.parentGroupID = targetID === 'root' ? null : targetID
      if (targetIndex !== -1) {
        const { target, oldParent } = this.$refs.table.moveRow(
          originIndex,
          targetIndex,
          true
        )

        if (target) target.childrenCount = target.childrenCount + 1
        if (oldParent) oldParent.childrenCount = oldParent.childrenCount - 1
      } else {
        this.$refs.table.removeRow(originIndex)
      }
      if (showAlert) this.handleAlert('move', 'success', node)
    },
    openMoveNodeModalForm({ node, i }) {
      const callback = async id => this.handleMoveTable(node, id, i)

      this.handleConfigModal('move', {
        callback: callback,
        target: structuredClone(node),
      })
    },
    openDeleteModalForm({ node, i }) {
      const callback = () => {
        const parent = this.$refs.table.removeRow(i)
        parent.childrenCount = parent.childrenCount - 1
        this.handleAlert('delete', 'success', node)
      }

      this.handleConfigModal('delete', {
        callback: callback,
        target: node,
      })
    },
    async handleUpdated(data) {
      const table = this.$refs.table
      if (!table) return

      const row = table.findRowBy('id', data.id)
      if (!row) return

      const allowedFields = ['image', 'name', 'costCenter', 'inactive']
      allowedFields.forEach(key => (row[key] = data[key]))
    },
    async handleUpdatePeople(groupID, newPeopleCount) {
      const treeRef = this.$refs.table.findRowBy('id', groupID)

      if (treeRef) treeRef.peopleCount = newPeopleCount
    },
    async fetchAccountGroups() {
      this.tree.loading = true

      const children = await fetchAccountGroups()
      const tree = {
        ...this.account,
        children,
        childrenCount: children.length,
        updatedAt: null,
      }

      this.tree.tree = [tree]

      this.tree.loading = false
      setTimeout(() => {
        this.$refs?.table?.resetTable()
      }, 50)
      setTimeout(() => {
        this.$refs?.table?.toggleRow(0)
      }, 150)
    },
    async beforeNodeOpen(rowIndex, row) {
      if (rowIndex === 0) return true
      let childs = await fetchGroupChildren(row)

      await this.$refs.table.insertChildren(rowIndex, childs)
      return true
    },
    initTreeSearch() {
      const callback = async selected => {
        const childrenCount = selected.childrenCount

        if (selected?.parentGroup?.id)
          selected = await fetchGroupParents(selected)

        const lastChild = await this.$refs.table.overrideChildren(selected, 0)
        lastChild.childrenCount = childrenCount
      }

      const submit = async selected => callback(selected)

      const search = async (text, limit, offset) =>
        fetchGroupsList(limit, text, offset)

      this.searchBarConfig = {
        component: ItemOfList,
        search: search,
        submit: submit,
        act: 'search',
      }
    },
  },
}
</script>

<style lang="scss" src="./style.scss" scoped />
