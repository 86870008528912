<template>
  <span
    :class="{
      rotate: isOpened,
      caret: hasChildren,
      'caret-block': !hasChildren,
    }"
    @click="handleClick"
  >
    <v-icon v-show="hasChildren" :size="18">mdi-chevron-down</v-icon>
  </span>
</template>
<script>
export default {
  name: 'rowCaret',
  methods: {
    handleClick() {
      if (this.hasChildren) this.$emit('click')
    },
  },
  props: {
    hasChildren: Boolean,
    isOpened: Boolean,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped />
