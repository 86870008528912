import { render, staticRenderFns } from "./DeleteCustomFieldsModal.vue?vue&type=template&id=0fcb0855&scoped=true&"
import script from "./DeleteCustomFieldsModal.vue?vue&type=script&lang=js&"
export * from "./DeleteCustomFieldsModal.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=0fcb0855&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fcb0855",
  null
  
)

export default component.exports