<template>
  <AttributeForm
    ref="form"
    data-test-attribute-form-edit
    action="view"
    :loading="loading"
    :readonly="!_canManagementAttributesEdit"
    v-bind="AttributeForm"
    @update:attribute="callHandleEdit"
  ></AttributeForm>
</template>

<script>
import * as _permissions from '@/helpers/ability/permissions'
import AttributeForm from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/Partials/AttributeForm/AttributeForm.vue'

import {
  getPeopleAttributesDetail,
  putPeopleAttributes,
} from '@/services/people-attributes'
import { alertErrorMessage } from '@/helpers/alerts'

import {
  handleAxiosSignalAbortController,
  isCanceledError,
} from '@/helpers/api'

import { debounce } from 'lodash'

export default {
  name: 'AttributeFormEdit',
  components: {
    AttributeForm,
  },

  props: {
    demographicAttribute: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      ApiController: {
        axiosControllers: [],
      },

      loading: true,
      AttributeForm: {},

      callHandleEdit: debounce(async payload => {
        await this.handleEdit(payload)
      }, 1000),
    }
  },

  computed: {
    _canManagementAttributesEdit() {
      return this.$can('access', _permissions.management_attributes_edit)
    },
  },

  beforeMount() {
    this.handleView(this.demographicAttribute.id)
  },

  methods: {
    calcFormPayload(payload) {
      const clearEmptyObjectKeys = obj => {
        Object.keys(obj).forEach(key => {
          if (obj[key] === '') delete obj[key]
        })

        return obj
      }

      return {
        label: clearEmptyObjectKeys(payload.label),
        values: payload.values.map(value => ({
          ...(!value.id.startsWith('new-') ? { id: value.id } : {}),
          label: clearEmptyObjectKeys(value.label),
        })),
      }
    },

    async handleView(attributeID) {
      this.loading = true

      return await getPeopleAttributesDetail({ attributeID })
        .then(({ data }) => {
          this.$set(this.AttributeForm, 'attribute', data)
          this.loading = false
        })
        .catch(error => {
          if (isCanceledError(error)) {
            this.loading = true
            return
          }

          this.$emit(
            'alert',
            alertErrorMessage(
              this.$t('alerts.ManagementPeopleAttributesAttributeID.error.get'),
              error
            )
          )
        })
    },

    handleSyncLocalValuesWithApi(newValue, oldValue) {
      const isNewValuePresent = oldValue.values.some(value =>
        value.id.startsWith('new-')
      )
      if (!isNewValuePresent) return

      this.$set(this.AttributeForm, 'attribute', newValue)
    },

    async handleEdit(payload) {
      if (!this._canManagementAttributesEdit) return

      const { signal } = handleAxiosSignalAbortController(
        this.ApiController.axiosControllers,
        'edit'
      )

      await putPeopleAttributes(
        payload.id,
        this.calcFormPayload(payload),
        signal
      )
        .then(({ data }) => {
          this.handleSyncLocalValuesWithApi(data, payload)
          this.$root.$emit('demographic-attribute:table', data)
        })
        .catch(error => {
          if (isCanceledError(error)) return

          this.$emit(
            'alert',
            alertErrorMessage(
              this.$t('alerts.ManagementPeopleAttributesAttributeID.error.put'),
              error
            )
          )
        })
    },
  },
}
</script>
