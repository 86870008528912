import { format, parse } from 'date-fns'

function calcLocaleToDateFns(locale) {
  if (locale === 'es-AR') {
    return 'es'
  }

  return locale
}

const getLocale = locale =>
  require(`date-fns/locale/${calcLocaleToDateFns(locale)}/index.js`)

export function formatDate(
  dateString,
  parseFormatString = 'yyyy-MM-dd',
  locale = 'pt-BR'
) {
  if (!dateString) return ''

  const date = parse(dateString, parseFormatString, new Date())

  return getFormatedDate('dd/MM/yyyy', date, locale)
}

/**
 * @param {string} formatString
 * @param {Date} date
 * @param {string} parseFormatString
 * @param {string} locale
 */
export function formatAsString(
  formatString,
  dateString,
  parseFormatString = 'yyyy-MM-dd',
  locale = 'pt-BR'
) {
  const date = parse(dateString, parseFormatString, new Date())

  return getFormatedDate(formatString, date, locale)
}

/**
 * @param {string} formatString
 * @param {Date} date
 * @param {string} locale
 */
export function getFormatedDate(
  formatString,
  date = new Date(),
  locale = 'pt-BR'
) {
  const formatedDate = format(date, formatString, {
    locale: getLocale(locale),
  })

  return formatedDate
}
