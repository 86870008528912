<template>
  <div id="cycle-selection" data-test-cycle-selection>
    <ItemSelector
      ref="cycleInput"
      :inputConfig="{
        label: $t('PermissionsPage.cycle.title'),
        showAvatar: false,
        closeOnSelect: true,
      }"
      :menuConfig="{
        attach: true,
        showTabs: false,
      }"
      :currentValue="currentCycle"
      :menuOptions="_cycleOptions"
      :infinityScroll="true"
      :persistent="true"
      :watchCurrent="true"
      @update:item="setCycle($event)"
      @infinity:scroll="handleCycleScroll($event)"
      @search:item="searchCycle($event)"
      @focus:input="searchCycles = []"
    />
  </div>
</template>

<script>
import { getCycles } from '@/services/cycles'
import { formatAsString } from '@/helpers/strings/date'

export default {
  data() {
    return {
      currentCycle: {
        data: [
          {
            id: '',
            label: this.$t('PermissionsPage.cycle.defaultCycle'),
          },
        ],
        origin: 'cycle',
      },
      cycles: [],
      searchCycles: [],
      cyclePagination: {
        limit: 20,
        offset: 0,
        total: 0,
      },
    }
  },

  mounted() {
    this.getCycles({
      limit: this.cyclePagination.limit,
      offset: this.cyclePagination.offset,
    })
  },

  computed: {
    _cycleOptions() {
      return [
        {
          value: 'cycle',
          label: this.$t('PermissionsPage.cycle.title'),
          type: 'listview',
          items: this.searchCycles.length ? this.searchCycles : this.cycles,
        },
      ]
    },
  },

  methods: {
    async getCycles(params = {}, infinity = false, search = false) {
      const payload = {
        ...params,
      }

      await getCycles(payload).then(({ data, headers }) => {
        this.cyclePagination.total = parseInt(headers['x-count']) || 0

        const cycles = data.map(el => ({
          id: el.id,
          label: `${el.title} (${this.formatDate(
            el.startDate
          )} - ${this.formatDate(el.endDate)})`,
        }))

        const result = infinity
          ? this.cycles.concat(cycles)
          : [
              ...(!search
                ? [
                    {
                      id: '',
                      label: this.$t('PermissionsPage.cycle.defaultCycle'),
                    },
                  ]
                : []),
              ...cycles,
            ]

        if (search) this.searchCycles = result
        else this.cycles = result
      })
    },

    async handleCycleScroll() {
      if (this.cycles.length >= this.cyclePagination.total) return

      this.cyclePagination.offset =
        this.cyclePagination.offset + this.cyclePagination.limit

      await this.getCycles(
        {
          limit: this.cyclePagination.limit,
          offset: this.cyclePagination.offset,
        },
        true
      )

      this.$refs.cycleInput.holdSpamInfinityScroll = false
    },

    setCycle(event) {
      this.$emit('change:cycle', event)
    },

    searchCycle(event) {
      const payload = {
        ...(event && { title: event }),
        limit: this.cyclePagination.limit,
        offset: 0,
      }

      this.getCycles(payload, false, true)
    },

    forceValue(event) {
      const payload = {
        data: [this.cycles.find(el => el.id === event)],
        origin: 'cycle',
      }

      this.$refs.cycleInput.setCurrentValue(payload)
    },

    formatDate(value) {
      return formatAsString('d MMM', value, 'yyyy-MM-dd', this.$t('dateLocale'))
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
