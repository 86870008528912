export function ScrollIntoViewErroClass($el) {
  const errors = $el.getElementsByClassName('error--text')

  setTimeout(() => {
    const el = errors[0]
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, 0)
}

export const ValidateCustomInputReturn = result => {
  const isInvalid = result instanceof Node
  if (isInvalid) {
    return false
  }

  return result === undefined
}

export async function calcPayloadToUpdateEntityOkrSettings(payload) {
  const newPayload = {
    ...payload,
    ...(!!payload?.suggestedCycle?.id && {
      suggestedCycle: { id: payload.suggestedCycle.id },
    }),
    ...(!!payload?.suggestedAccessProfile?.id && {
      suggestedAccessProfile: { id: payload.suggestedAccessProfile.id },
    }),
    ...(!!payload?.suggestedGoalGroup?.id && {
      suggestedGoalGroup: { id: payload.suggestedGoalGroup.id },
    }),
    goalGroupAccessProfiles: payload?.goalGroupAccessProfiles?.map(e => ({
      id: e.id,
    })),
  }

  if (!payload?.suggestedCycle?.id) newPayload.suggestedCycle = null
  if (!payload?.suggestedAccessProfile?.id)
    newPayload.suggestedAccessProfile = null
  if (!payload?.goalEnabled) newPayload.goalEnabled = false
  if (!payload?.goalGroupAccessProfiles) newPayload.goalGroupAccessProfiles = []

  if (newPayload?.id) {
    delete newPayload.id
  }

  if (!newPayload.newPlannedSeries) {
    payload.newPlannedSeries = []
  }

  return newPayload
}
