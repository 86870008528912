var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attribute-form",attrs:{"data-test-attribute-form":""}},[_c('div',{staticClass:"attribute-form-label"},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('DemographicAttributesPage.form.default.name.label'))}}),_c('div',{staticClass:"attribute-form-label-items"},[_vm._l((_vm.flags),function(flagKey){return [_c('AttributeInput',{key:`${flagKey}-${_vm.form.id}`,ref:"label",refInFor:true,staticClass:"attribute-form-label-item",attrs:{"loading":_vm.loading,"data-test-attribute-label-lang":flagKey,"data-test-attribute-label":_vm.form.id,"item":{ flag: flagKey, value: _vm.form?.label?.[flagKey] },"custom-input-props":{
            label: _vm.$t('DemographicAttributesPage.form.default.name.input'),
            mandatory: !_vm.valid,
            readonly: _vm.readonly,
          }},on:{"handleInput":function($event){return _vm.handleLabel(_vm.form, flagKey, $event)}}})]})],2)]),_c('div',{staticClass:"attribute-form-values"},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('DemographicAttributesPage.form.default.values.label'))}}),_c('OrderDraggable',{attrs:{"itens":_vm.form.values,"get-display-index":_vm.getDisplayIndex,"enableDraggable":!_vm.readonly},on:{"update:itens":_vm.handleOrder},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_vm._l((_vm.flags),function(flagKey){return [_c('AttributeInput',{key:`${flagKey}-${item.id}`,staticClass:"attribute-form-values-item",attrs:{"loading":_vm.loading,"data-test-attribute-value-lang":flagKey,"data-test-attribute-value":item.id,"item":{ flag: flagKey, value: item?.label?.[flagKey] },"custom-input-props":{
              label: _vm.$t(
                'DemographicAttributesPage.form.default.values.input',
                {
                  n: index + 1,
                }
              ),
              readonly: _vm.readonly,
            }},on:{"handleInput":function($event){return _vm.handleInput(item, flagKey, $event)}}})]}),(_vm.form.values.length > 1 && !_vm.readonly)?_c('v-btn',{staticClass:"attribute-form-values-item-remove-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.handleRemove(index)}}},[_c('v-icon',{attrs:{"size":"1.125rem"}},[_vm._v("fi-rr-trash")])],1):_vm._e()]}}])})],1),(!_vm.readonly)?_c('div',{staticClass:"attribute-form-add"},[_c('v-btn',{staticClass:"attribute-form-add-btn",attrs:{"text":""},on:{"click":_vm.handleAdd}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('DemographicAttributesPage.form.default.add'))}})])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }