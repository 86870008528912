<template>
  <div class="content-card" data-test-tag-management>
    <div class="add-row" data-test-add-tag-form>
      <div class="input-content" data-test-input-tag-name>
        <custom-input
          ref="tagName"
          :label="$t('PerformancePage.TagManagement.form.inputTagName')"
          :current-value="form.tagName"
          @handleInput="changeTagName"
        ></custom-input>
      </div>

      <v-btn
        class="text-none btn"
        color="primary"
        data-test-add-tag-btn
        depressed
        @click="createTag()"
      >
        <span class="label">
          {{ $t('PerformancePage.TagManagement.form.add') }}
        </span>
      </v-btn>
    </div>

    <div data-test-tags-table>
      <DataTable
        class="data-table-tags"
        height="15rem"
        :title="$t('PerformancePage.TagManagement.labelSearch')"
        :headers="_headers"
        :items="tags"
        :hide-option="true"
        :content-style="false"
        :hide-header="true"
        :items-per-page="pagination.limit"
        :total-items="pagination.total"
        :loading="loading"
        :skeleton-lines="5"
        :empty-placeholder="
          $t('PerformancePage.TagManagement.emptyPlaceholder')
        "
        @get-data="handleSearch($event)"
        @update-data="updateLabelTag($event)"
        @search="handleSearch($event)"
        @remove="handleDeleteTag($event)"
      >
        <template #generic-item-status="{ item }">
          <v-tooltip bottom z-index="300" max-width="12rem">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <CustomSwitch
                  :value="!item.disabled"
                  @input="handleDisabledTag($event, item)"
                ></CustomSwitch>
              </div>
            </template>

            <div class="chip-tooltip">
              <span> {{ handleTextTooltip(item) }} </span>
            </div>
          </v-tooltip>
        </template>
      </DataTable>
    </div>

    <ConfirmDeleteTag
      ref="deleteDialog"
      @handleConfirm="deleteTag($event)"
    ></ConfirmDeleteTag>
  </div>
</template>

<script>
import { getTags, createTag, updateTag, deleteTag } from '@/services/tags'
import ConfirmDeleteTag from './Partials/ConfirmDeleteTag/ConfirmDeleteTag.vue'

export default {
  components: {
    ConfirmDeleteTag,
  },
  data() {
    return {
      loading: false,
      form: {
        tagName: '',
      },
      pagination: {
        name: null,
        limit: 5,
        offset: 0,
        total: 0,
      },
      filters: {},
      tags: [],
    }
  },

  computed: {
    _headers() {
      return [
        {
          text: this.$t('PerformancePage.TagManagement.headers.label'),
          value: 'label',
          type: 'chip-edit',
          width: '40%',
          style: {
            'max-width': 'unset',
          },
        },
        {
          text: this.$t('PerformancePage.TagManagement.headers.status'),
          value: 'status',
          type: 'generic-item-status',
          width: '25%',
          style: {
            'max-width': 'unset',
          },
        },
        {
          text: this.$t('PerformancePage.TagManagement.headers.entities'),
          value: 'entities',
          type: 'text',
          width: '25%',
        },
        {
          text: this.$t('PerformancePage.TagManagement.headers.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: 'max-content',
        },
      ]
    },
  },

  methods: {
    handleTextTooltip(item) {
      if (item.disabled)
        return this.$t('PerformancePage.TagManagement.tagDisabled')
      return this.$t('PerformancePage.TagManagement.tagActive')
    },
    changeTagName(event) {
      this.form.tagName = event
    },
    handleDisabledTag(value, item) {
      const payload = {
        id: item.id,
        body: {
          label: item.label.value,
          disabled: !value,
        },
      }

      this.updateTag(payload)
    },
    async getTags() {
      this.tags = []
      this.loading = true
      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        ...this.filters,
      }

      await getTags(payload).then(({ data, headers }) => {
        this.tags = data.map(el => ({
          ...el,
          label: {
            value: el.label,
          },
          entities: el.entitiesCount ? el.entitiesCount : 0,
          actions: ['remove'],
        }))

        this.pagination.total = Number(headers['x-count'])
      })

      this.loading = false
    },

    async createTag() {
      if (!this.form.tagName) return

      const payload = {
        label: this.form.tagName,
      }

      await createTag(payload)
        .then(res => {
          this.tags.unshift({
            ...res.data,
            label: {
              value: res.data.label,
            },
            entities: res.data.entitiesCount ? res.data.entitiesCount : 0,
            actions: ['remove'],
          })

          this.handleAlert(
            this.$t('PerformancePage.TagManagement.successCreate'),
            'success'
          )

          this.changeTagName(null)
        })
        .catch(error => {
          this.handleAlert(
            this.$t('PerformancePage.TagManagement.errorCreate'),
            'error',
            error?.response?.data?.message
          )
        })
    },

    updateLabelTag(event) {
      const tag = this.tags.find(e => e.id === event.id)

      const payload = {
        id: event.id,
        body: {
          label: event.value,
          disabled: tag.disabled,
        },
      }

      this.updateTag(payload)
    },

    async updateTag(payload) {
      await updateTag(payload)
        .then(() => {
          this.handleAlert(
            this.$t('PerformancePage.TagManagement.successUpdate'),
            'success'
          )
        })
        .catch(error => {
          this.handleAlert(
            this.$t('PerformancePage.TagManagement.errorUpdate'),
            'error',
            error?.response?.data?.message
          )
        })

      this.getTags()
    },

    async deleteTag(event) {
      await deleteTag(event.id)
        .then(() => {
          this.handleAlert(
            this.$t('PerformancePage.TagManagement.successDelete'),
            'success'
          )
          this.getTags()
        })
        .catch(() => {
          this.handleAlert(
            this.$t('PerformancePage.TagManagement.errorDelete'),
            'error'
          )
        })
    },

    handleDeleteTag(event) {
      this.$refs.deleteDialog.handleOpen(event)
    },

    handleSearch(event) {
      this.handlePagination(event)

      const payload = {
        label: event.name !== '' ? event.name : null,
      }

      this.filters = {
        ...this.filters,
        ...payload,
      }

      this.getTags()
    },

    handlePagination(event) {
      this.pagination.offset = event.offset
    },

    handleAlert(message, type, title) {
      const payload = {
        ...(title && { title: title }),
        messagePrefix: '',
        messageAlert: message,
        statusAlert: type,
      }

      this.$emit('alert', payload)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
