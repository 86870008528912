<template>
  <v-dialog v-if="target" v-model="show" class="show">
    <v-card>
      <v-card-title class="dialog-header">
        <span class="ml-5">{{ $t('TreeView.duplicateModal.title') }}</span>
        <i class="fi-rr-cross-small" @click="toggleModal(false)"></i>
      </v-card-title>

      <v-divider></v-divider>

      <SearchBar
        class="ml-5 mb-2 mr-3 mt-2"
        ref="search"
        @search="_search"
        @clear="clear"
      />

      <SearchResults
        :text="text"
        :nodes="nodes"
        :selected="selected"
        :component="component"
        @change="changeSelection"
        @scroll="handleScroll"
      />

      <v-divider></v-divider>

      <v-card-actions>
        <button @click="toggleModal(false)" class="btn ml-auto">
          {{ $t('GroupsPage.treeModal.cancel') }}
        </button>
        <button
          @click="submit"
          :disabled="!selected"
          :class="{ 'btn-disabled': !selected }"
          data-test-duplicate-confirm-bttn
          class="btn btn-primary"
        >
          <v-progress-circular
            v-if="loading.submit"
            indeterminate
            color="white"
            size="22"
            width="3"
          />
          <span v-else>
            {{ $t('GroupsPage.treeModal.submit') }}
          </span>
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash'
import SearchBar from '../SearchBar/SearchBar.vue'
import SearchResults from '../SearchResults/SearchResults.vue'
import ItemOfList from '@/components/ItemOfList/ItemOfList.vue'

import {
  fetchGroupsList,
  insertGroup,
  fetchGroupPeople,
} from '@/services/groups'

import { formatToInsert } from '@/helpers/groups'
import { mapGetters } from 'vuex'

export default {
  name: 'DuplicateModal',
  components: {
    SearchBar,
    SearchResults,
  },
  props: {
    callback: {
      type: Function,
    },
    component: {
      type: Object,
      default: () => ItemOfList,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      text: '',
      nodes: [],
      selected: null,
      loading: {
        scroll: false,
        submit: false,
      },
    }
  },
  watch: {
    async show() {
      if (this.show && !this.deleteMode) {
        this.selected = null
        this.text = ''
        this.search(this.text)
        this.$refs.search?.handleClear()
      }
    },
  },
  computed: {
    ...mapGetters({
      account: 'currentAccount/account',
    }),
  },
  methods: {
    changeSelection(node) {
      if (this.selected) this.selected._selected = false
      this.selected = node
      this.selected._selected = true
    },
    async handleScroll(e) {
      const scrollY = Math.floor(e.currentTarget.scrollTop)

      const listItemSize = 78
      const onScreen = 3
      const limit = 20
      const page = Math.ceil(this.nodes.length / limit)
      const overScroll = listItemSize * (page * limit) - listItemSize * onScreen

      if (scrollY > overScroll && !this.loading.scroll) {
        this.loading.scroll = true

        setTimeout(async () => {
          const offset = this.nodes.length
          const nodes = await fetchGroupsList(20, this.text, offset)
          this.nodes = this.nodes.concat(nodes)

          this.loading.scroll = false
        }, 1000)
      }
    },
    clear() {
      this.text = ''
      this.search('')
    },
    async submit() {
      if (this.loading.submit) return false
      this.loading.submit = true

      const group = await this.formatToAPI(this.target)
      const newGroup = await insertGroup(group)

      this.loading.submit = false
      this.show = false
      if (this.callback) this.callback(newGroup, this.selected.id)
    },
    async formatToAPI(group) {
      let peoples = await fetchGroupPeople(group)

      peoples = peoples.map(people => {
        return {
          engagement: people.engagement.map(paper => {
            return { id: paper.id }
          }),
          growth: people.growth.map(paper => {
            return { id: paper.id }
          }),
          management: people.management.map(paper => {
            return { id: paper.id }
          }),
          performance: people.performance.map(paper => {
            return { id: paper.id }
          }),
          person: {
            id: people.person.id,
          },
        }
      })

      group.parentGroupID = this.selected.id
      group.name = group.name + '-copy' + Math.random(1000)
      group.costCenter = group.costCenter + '-copy' + Math.random(1000)
      group.people = peoples

      group = formatToInsert(group)

      return group
    },
    async search(text, offset = 0) {
      const nodes = await fetchGroupsList(20, text, offset)

      const root = structuredClone(this.account)

      if (this.shouldShowRootNode(text)) nodes.push(root)

      this.selected = null
      this.nodes = nodes
      this.text = text

      document
        .getElementById('dialog-body')
        .scrollTo({ top: 0, behavior: 'smooth' })
    },
    shouldShowRootNode(text) {
      return this.account.name?.toUpperCase().search(text?.toUpperCase()) !== -1
    },
    _search: _.debounce(function (text) {
      this.search(text)
    }, 750),
    toggleModal(state) {
      this.show = state
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
