import api from '@/services/Http'

export async function groupsExport(params) {
  return (await api()).post('/management/groups/export', params)
}

export async function groupsExportStatus(code) {
  return (await api()).get('/management/groups/export/:code', {
    _params: {
      code,
    },
  })
}

export async function groupsExportFile(code, params) {
  return (await api()).get('/management/groups/export/:code/file', {
    params,
    _params: {
      code,
    },
    responseType: 'blob',
  })
}

export async function peopleExport(params) {
  return (await api()).post('/management/people/export', params)
}

export async function peopleExportStatus(code) {
  return (await api()).get('/management/people/export/:code', {
    _params: {
      code,
    },
  })
}

export async function peopleExportFile(code, params) {
  return (await api()).get('/management/people/export/:code/file', {
    params,
    _params: {
      code,
    },
    responseType: 'blob',
  })
}
