import api from '@/services/Http'

export async function updatePermissions(permissions) {
  return (await api()).put(`/management/permissions`, permissions)
}

export const getPermissions = async data => {
  return (await api()).get(`/management/permissions/${data.type}`, {
    params: data.params,
  })
}
