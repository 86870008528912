class ColumnSchemaInterface {
  constructor(props) {
    const { name, mutation } = props

    this.name = name
    this.mutation = mutation || this.defaultMutation
  }

  defaultMutation(row = '-') {
    return row
  }

  getValue(row, i) {
    return this.mutation(row, i)
  }
}

export default ColumnSchemaInterface
