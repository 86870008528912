import api from '@/services/Http'

export async function getIntegration(data) {
  return (await api()).get('/management/integrations/:integrationID', {
    _params: {
      integrationID: data.integrationID,
    },
  })
}

export async function connectIntegration(data) {
  return (await api()).post(
    '/management/integrations/:integrationID',
    {
      code: data.code,
    },
    {
      _params: {
        integrationID: data.integrationID,
      },
    }
  )
}

export async function removeIntegration(data) {
  return (await api()).delete('/management/integrations/:integrationID/:code', {
    _params: {
      integrationID: data.integrationID,
      code: data.code,
    },
  })
}
