<template>
  <div id="textstatus" data-test-text-status class="d-flex" @click="submit()">
    <span
      data-test-text-status-content
      :class="`${statusColor + '--text'} ${
        !clickable || 'clickable-status'
      } label`"
    >
      {{ data.label }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    emitValue: null,

    clickable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    statusColor() {
      switch (this.data.status) {
        case 'active':
        case 'success':
        case 'confirmed':
          return 'greencolor'
        case 'waiting':
        case 'invited':
          return 'secondary'
        case 'inactive':
          return 'neutral5'
        case 'error':
          return 'redcolor'
        default:
          return 'neutral1'
      }
    },
  },

  methods: {
    submit() {
      if (this.clickable) this.$emit('submit', this.emitValue)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
