<template>
  <div class="input-search" data-test-search-input @click="handleClick">
    <v-text-field
      ref="search"
      v-model="text"
      hide-details="auto"
      :placeholder="$t('PerformancePage.EntityConfig.search-placeholder')"
      prepend-inner-icon="mdi-magnify"
      data-test-search-field
      @input="handleInput"
    >
    </v-text-field>
    <i v-show="text" @click="handleClear" class="fi-rr-cross-small"></i>
  </div>
</template>
<script>
export default {
  name: 'SearchBar',
  data() {
    return {
      text: '',
    }
  },
  methods: {
    insertText(text) {
      this.text = text
    },
    handleClick() {
      this.$emit('click', this.text)
    },
    handleInput(text) {
      if (!text) return this.handleClear()
      return this.$emit('input', text)
    },
    handleClear() {
      this.$emit('clear')
      this.text = ''
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
