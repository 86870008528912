<template>
  <div class="list-order-cycles" data-test-list-order-cycles>
    <ChangeOrderCycleModal
      ref="changeOrderCycleModal"
      :show="showChangeOrderCycleModal"
      :current-order-cycles="_currentOrderCycles"
      :default-order-cycles="defaultOrderCycles"
      @handleConfirm="handleConfirmOrderCycle"
      @handleClose="handleCloseOrderCycle"
    ></ChangeOrderCycleModal>

    <DetailSectionAccordion
      type="settings"
      :title="$t('managementCycleForm.sectionListOrderCycles.title')"
      :has-button="_canCycleOrder"
      :text-button="$t('PerformanceTab.ListMetadataGroups.changeOrder')"
      :opened="true"
      @headerClick="handleChangeOrder"
    >
      <DataTable
        data-test-cycles-group-form-order
        :title="''"
        :fixed-header="true"
        :hide-table-header="true"
        :hide-header="true"
        :hide-option="true"
        :hide-search="true"
        :roles-readonly="true"
        :enable-draggable="true"
        :can-drag="false"
        :items="cyclesItems"
        :loading="loading"
        :skeleton-lines="3"
        :headers="_tableHeaders"
        :items-per-page="3"
        :enable-pagination="false"
      >
        <template #leftButton="{ position }">
          <v-tooltip bottom open-on-hover>
            <template #activator="{ on, attrs }">
              <v-icon
                data-test-definition-icon-tooltip
                v-bind="attrs"
                size="0.75rem"
                class="tooltip-icon"
                v-on="on"
              >
                fi-rr-info
              </v-icon>
            </template>
            <div data-test-definition-tooltip>
              {{ handleTextTooltip(position) }}
            </div>
          </v-tooltip>

          <div class="input-bottom">
            <span class="input-label">{{ handleTextSwitch(position) }}</span>
          </div>
        </template>
      </DataTable>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import { updateEntityOkrSettings } from '@/services/papers'

import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import ChangeOrderCycleModal from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/ManagementCycleForm/Parts/ChangeOrderCycleModal/ChangeOrderCycleModal.vue'

import { management_performance_cycle_order } from '@/helpers/ability'

export default {
  name: 'ListOrderCycles',

  components: {
    ChangeOrderCycleModal,
  },

  props: {
    hierarchySettings: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      defaultOrderCycles: [
        { period: 'current', expanded: true },
        { period: 'future', expanded: false },
        { period: 'past', expanded: false },
      ],
      loading: true,
      cyclesItems: [],
      showChangeOrderCycleModal: false,
    }
  },
  computed: {
    _canCycleOrder() {
      return this.$can('access', management_performance_cycle_order)
    },
    _currentOrderCycles() {
      return this.cyclesItems
    },
    _tableHeaders() {
      return [
        {
          text: '',
          value: 'name',
          type: 'text-status',
          naturalizeIndex: true,
        },
        {
          text: '',
          value: 'actions',
          type: 'action-buttons',
        },
      ]
    },
  },
  watch: {
    hierarchySettings() {
      this.updateListCycleOrder()
    },
  },
  methods: {
    updateListCycleOrder() {
      this.loading = true
      const orderCycles = this.hierarchySettings.cyclesOrder

      let items = orderCycles.length ? orderCycles : this.defaultOrderCycles

      this.cyclesItems = items.map((item, index) => {
        return {
          position: index + 1,
          name: {
            label: this.$t(`changeOrderCycleModal.label-${item.period}`),
          },
          actions: [''],
          expanded: item.expanded,
          period: item.period,
        }
      })

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    handleCloseOrderCycle() {
      this.showChangeOrderCycleModal = false
    },
    async handleConfirmOrderCycle(ref, newOrderCycles) {
      const orderCycles = newOrderCycles.map(cycle => {
        return {
          period: cycle.period,
          expanded: cycle.expanded,
        }
      })

      Object.assign(this.hierarchySettings, { cyclesOrder: orderCycles })

      const payload = {
        ...this.hierarchySettings,
        ...(!!this.hierarchySettings.suggestedCycle?.id && {
          suggestedCycle: { id: this.hierarchySettings.suggestedCycle.id },
        }),
      }

      if (!payload?.suggestedCycle?.id) payload.suggestedCycle = null

      const entityHierarchyID = payload.id
      delete payload.id

      await updateEntityOkrSettings(entityHierarchyID, payload)
        .then(() => {
          alertSuccessBus(this.$t('changeOrderCycleModal.alert.success'))

          this.$emit('handleHierarchySettings')
        })
        .catch(error => {
          alertErrorBus(this.$t('changeOrderCycleModal.alert.error'), error)
        })

      ref.handleDialog()
    },
    handleChangeOrder() {
      this.showChangeOrderCycleModal = true
    },
    handleTextTooltip(position) {
      const cycleSelected = this.cyclesItems[position]
      if (cycleSelected.period === 'current') {
        return this.$t('changeOrderCycleModal.tooltipCurrent')
      }

      if (cycleSelected.period === 'future') {
        return this.$t('changeOrderCycleModal.tooltipFuture')
      }

      return this.$t('changeOrderCycleModal.tooltipPast')
    },
    handleTextSwitch(position) {
      const cycleSelected = this.cyclesItems[position]
      if (cycleSelected.expanded) {
        return this.$t('changeOrderCycleModal.expand')
      }

      return this.$t('changeOrderCycleModal.collapse')
    },
  },
}
</script>

<style src="./style.scss" scoped lang="scss"></style>
