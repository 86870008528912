<template>
  <div class="planned-form" data-test-planned-form>
    <div class="planned-form-content" data-test-planned-form>
      <v-card class="message-card" flat>
        <div class="label">
          <span
            v-html="
              $t('plannedForm.axelTip', {
                entityClass: $t(
                  `plannedList.entityClass-name.${hierarchySetting?.entityClass}`
                ),
              })
            "
          />
        </div>
      </v-card>

      <v-form v-model="valid" ref="form">
        <ItemSelector
          ref="cycleInput"
          :inputConfig="{
            label: $t('plannedForm.cycleField'),
            showAvatar: false,
            rules: rules,
          }"
          :menuConfig="{
            align: 'right',
            attach: false,
            showTabs: false,
          }"
          :menuOptions="_listCycles"
          :fillWidth="true"
          :multiple="false"
          :showElements="true"
          :local-search="true"
          @update:item="setValueCycles($event)"
        />

        <div class="toggle">
          <span class="label">{{
            $t('plannedForm.copyPreviousPlannedValuesField')
          }}</span>

          <v-menu
            content-class="custom-tooltip"
            max-width="18.75rem"
            open-on-hover
            open-on-click
            offset-y
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <i
                v-bind="attrs"
                v-on="on"
                data-test-tooltip-icon
                class="fi-rr-interrogation"
              />
            </template>

            <v-card
              class="custom-tooltip-message"
              width="18.75rem"
              data-test-tooltip-message
              flat
            >
              <div class="custom-tooltip-message-description">
                {{ $t('plannedForm.tooltipDescription') }}
              </div>
            </v-card>
          </v-menu>

          <CustomSwitch @input="handleToggle"></CustomSwitch>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { getCycles } from '@/services/cycles/index.js'
import { updateEntityOkrSettings } from '@/services/papers'

export default {
  name: 'PlannedForm',
  props: {
    showAlert: {
      type: Function,
    },
  },
  data() {
    return {
      newPlannedSeries: [],
      cycles: [],
      valid: true,
      rules: [
        v => {
          const requiredLabel = this.$t('CustomInput.requiredField')

          if (v instanceof Array && v.length == 0) return requiredLabel
          return !!v || requiredLabel
        },
      ],
      form: {
        cycle: null,
        copyPreviousPlannedValues: false,
      },
      entityID: null,
      hierarchySetting: null,
    }
  },

  computed: {
    _listCycles() {
      return [
        {
          value: 'cycles',
          label: this.$t('plannedForm.cycleField'),
          type: 'listview',
          items: this.cycles,
        },
      ]
    },
  },

  methods: {
    setValueCycles(event) {
      if (event) {
        this.form.cycle = {
          id: event.id,
          title: event.label,
        }

        return
      }

      this.form.cycle = null
    },
    handleToggle(valueToggle) {
      this.form.copyPreviousPlannedValues = valueToggle
    },
    async fecthCycles() {
      try {
        const newPlannedSeriesIsNotExist =
          this.newPlannedSeries === null || this.newPlannedSeries === undefined

        if (newPlannedSeriesIsNotExist) return

        const res = await getCycles()

        this.cycles = res.data
          .map(e => {
            if (!this.newPlannedSeries.find(serie => serie.cycle.id === e.id)) {
              return {
                id: e.id,
                label: e.title,
              }
            }
          })
          .filter(Boolean)
      } catch (error) {
        this.showAlert({
          statusAlert: 'error',
          title: this.$t('plannedForm.alert.errorMessageGetCycles'),
          messageAlert: error?.response?.data?.message,
        })
      }
    },
    handleValuesByRouteParams() {
      this.newPlannedSeries = this.$route.params.newPlannedSeries
      this.hierarchySetting = this.$route.params.hierarchySetting
      this.entityID = this.$route.params.entityID
    },
    validate() {
      const forms = ['form']
      let valid = true

      forms.forEach(e => {
        const value = this.$refs[e].validate()
        if (!value && value != undefined) valid = false
      })

      if (!valid) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }

      return valid
    },
    async updateSeriesPlanned() {
      let payload = this.hierarchySetting
      delete payload.id

      if (!payload.newPlannedSeries) {
        payload.newPlannedSeries = []
      }

      const index = payload.newPlannedSeries?.findIndex(
        serie => serie.cycle.id === this.form.cycle.id
      )

      if (index >= 0) {
        payload.newPlannedSeries = payload.newPlannedSeries.slice(0, index)
      }

      payload.newPlannedSeries.push(this.form)

      const newPayload = {
        ...payload,
        ...(!!payload?.suggestedCycle?.id && {
          suggestedCycle: { id: payload.suggestedCycle.id },
        }),
      }

      await updateEntityOkrSettings(this.entityID, newPayload)
        .then(() => {
          this.showAlert({
            statusAlert: 'success',
            title: this.$t('plannedForm.alert.messageSuccessUpdate'),
          })

          this.$emit('close')
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            title: this.$t('plannedForm.alert.messageErrorUpdate'),
            messageAlert: error?.response?.data?.message,
          })
        })
    },
    async submit() {
      if (!this.validate()) {
        return
      }

      this.updateSeriesPlanned()
    },
  },

  mounted() {
    this.handleValuesByRouteParams()
    this.fecthCycles()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
