import api from '@/services/Http'

export async function getGoalGroups(
  limit,
  offset,
  title,
  status,
  entityType,
  entityClass
) {
  return (await api()).get('/performance/goals/goal-groups', {
    params: {
      limit,
      offset,
      title,
      status,
      entityType,
      entityClass,
    },
  })
}
