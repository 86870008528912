<template>
  <tr :class="['table-tree-header', { 'sticky-row': configListCycle }]">
    <th
      v-for="column in columns"
      :key="column.label"
      :class="{ ordenable: column.ordenable }"
    >
      {{ column.label }}
    </th>
  </tr>
</template>
<script>
export default {
  name: 'rowColumns',
  props: {
    configListCycle: {
      type: Boolean,
      default: false,
      required: false,
    },
    columns: Array,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped />
