var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"audit-logs-table",attrs:{"data-test-audit-logs-table":""}},[_c('CardWrapper',{attrs:{"title":_vm.$t('AuditLogsPage.title'),"subtitle":_vm.$t('AuditLogsPage.subtitle', {
        n: _vm.tableConfig.xcount,
      }),"helper-props":{
      videoType: 'AuditLogsPage',
      short: true,
      hasVideo: false,
    }}}),_c('DataTable',_vm._g({class:{ 'no-data': !_vm.tableConfig.xcount && !_vm.tableApiController.loading },attrs:{"title":_vm.$t('AuditLogsPage.title'),"headers":_vm.headers,"items":_vm._tableItens,"enable-pagination":"","enable-pagination-limit-change":"","hide-header":"","hide-option":"","hide-search":"","content-style":false,"total-items":_vm.tableConfig.xcount,"items-per-page":_vm.tableConfig.limitPagination,"loading":_vm.tableApiController.loading,"mobile-breakpoint-switch":0},on:{"updateList":_vm.handleDataTablePagination}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }