<template>
  <Modal
    ref="modal"
    :title="$t('PerformanceTab.ListCustomFields.modalDelete.title')"
    width="30%"
    :has-tab="false"
  >
    <template v-if="CustomFields" v-slot:[`no-tab`]>
      <div class="modal-delete">
        <h1>
          {{ $t('PerformanceTab.ListCustomFields.modalDelete.subtitle1') }}
          <a>{{ CustomFields.name.label }}</a>
        </h1>

        <div class="modal-delete-subtitle">
          <h1>
            <a style="color: red">{{
              $t('PerformanceTab.ListCustomFields.modalDelete.rememberLabel')
            }}</a>
            {{ $t('PerformanceTab.ListCustomFields.modalDelete.subtitle2') }}
          </h1>
        </div>
      </div>

      <v-divider></v-divider>
      <div class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn
          data-test-cancel-custom-field
          class="dialog-footer-btnCancel"
          depressed
          plain
          @click="handleDialog()"
        >
          {{ $t('PerformanceTab.ListCustomFields.modalDelete.btnCancel') }}
        </v-btn>
        <v-btn
          data-test-delete-custom-field
          class="dialog-footer-btn"
          depressed
          @click="removeCustomFields()"
        >
          {{ $t('PerformanceTab.ListCustomFields.modalDelete.btnDelete') }}
        </v-btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import { deleteMetadata } from '@/services/metadata/index.js'
export default {
  name: 'DeleteCustomFieldsModal',
  props: {
    CustomFields: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    handleDialog() {
      this.$refs.modal?.handleDialog()
    },
    async removeCustomFields() {
      await deleteMetadata(this.CustomFields.id)
        .then(() => {
          this.$emit('display-alert', {
            statusAlert: 'success',
            messageAlert: this.$t(
              'PerformanceTab.ListCustomFields.modalDelete.messageSuccessDeleted'
            ),
          })

          this.handleDialog()
          this.$emit('CustomFieldsDeleted')
        })
        .catch(err => {
          this.$emit('display-alert', {
            statusAlert: 'error',
            messageAlert: err.message,
          })
        })
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
