import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

const baseUrl = '/performance/okrs'

export async function getCyclesTree(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'title', 'status', 'sortBy', 'sortOrder'],
    data
  )

  return (await api()).get(`${baseUrl}/cycles/tree`, {
    params: params,
    ...signal,
  })
}

export async function putStatusCycle(cycleID, cycle) {
  return (await api()).put(`${baseUrl}/cycles/${cycleID}/status`, cycle)
}

export async function getCycles(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'title', 'status', 'current'],
    data
  )

  return (await api()).get(`${baseUrl}/cycles`, {
    params: params,
    ...signal,
  })
}

export async function getCycle(cycleID) {
  return (await api()).get(`${baseUrl}/cycles/${cycleID}`)
}

export async function deleteCycle(cycleID) {
  return (await api()).delete(`${baseUrl}/cycles/${cycleID}`)
}

export async function editCycle(cycleID, cycle) {
  return (await api()).put(`${baseUrl}/cycles/${cycleID}`, cycle)
}

export const createCycles = async payload => {
  const { data: newCycle } = await (
    await api()
  ).post(`${baseUrl}/cycles`, payload)
  return newCycle
}

export async function getCycleHierarchyTree(cycleID) {
  return (await api()).get(`performance/cycles/${cycleID}/tree`)
}

export async function getCycleHierarchy(cycleID) {
  return (await api()).get(`/performance/okrs/cycles/${cycleID}/hierarchy`)
}

export async function getCycleHierarchyChildren(cycleID) {
  return (await api()).get(`/performance/okrs/cycles/${cycleID}/tree`)
}
