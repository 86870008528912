<template>
  <div
    data-test-table-pagination
    id="tablepagination"
    class="d-flex justify-center align-center py-3"
  >
    <v-btn
      data-test-table-pagination-previous
      v-if="pagination.page > 1"
      class="mr-1"
      tile
      icon
      x-small
      @click="prevPage()"
    >
      <v-icon size="10">fi fi-br-angle-left</v-icon>
    </v-btn>

    <div class="d-flex sheet align-center justify-center rounded-md">
      <span class="label-pagination"> {{ pagination.page }}</span>
    </div>

    <v-btn
      data-test-table-pagination-next
      v-if="pagination.offset + pagination.limit < limiter"
      class="ml-1"
      tile
      icon
      x-small
      @click="nextPage()"
    >
      <v-icon size="10">fi fi-br-angle-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
    },

    limiter: {
      type: Number,
      requierd: true,
    },
  },

  methods: {
    // prev table page
    prevPage() {
      const payload = {
        page: this.pagination.page - 1,
        offset: this.pagination.offset - this.pagination.limit,
      }

      this.$emit('update:page', payload)
    },

    // next table page
    nextPage() {
      const payload = {
        page: this.pagination.page + 1,
        offset: this.pagination.offset + this.pagination.limit,
      }

      this.$emit('update:page', payload)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
