<template>
  <DetailSectionAccordion
    data-test-detail-history-alteration
    :type="'tree'"
    :title="$t('management-people-history.title')"
    :opened="true"
  >
    <div v-if="loading">
      <v-skeleton-loader type="image" max-height="20"></v-skeleton-loader>
    </div>

    <div v-else class="history-list">
      <div class="search">
        <v-text-field
          v-model="search"
          data-test-history-section-input
          :placeholder="$t('management-people-history.search.placeholder')"
          background-color="transparent"
          hide-details
          dense
          solo
          flat
          @keyup="searchInput()"
        >
          <template #prepend-inner>
            <v-icon color="neutral5" x-small> fi-rr-search </v-icon>
          </template>
        </v-text-field>
      </div>

      <div
        ref="scrollable"
        data-test-history-section-items
        class="scrollable"
        @scroll="handleInfiniteScroll"
      >
        <template v-for="(item, index) in history">
          <ItemView
            :key="`${index}`"
            :item="item"
            :color-pallete="statusPallete"
            :translate-pallete="attributePallete"
          ></ItemView>
        </template>

        <v-btn
          v-show="history.length > 0 && showGoUpButton"
          icon
          class="scroll-to-top"
          @click="
            () => {
              $refs.scrollable.scrollTop = 0
            }
          "
        >
          <v-icon> fi-sr-arrow-small-up </v-icon>
        </v-btn>
      </div>
    </div>
  </DetailSectionAccordion>
</template>

<script>
import ItemView from './Parts/ItemView/ItemView'

export default {
  components: {
    ItemView,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    history: {
      type: Array,
      default: () => [],
    },

    enablePagination: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showGoUpButton: false,
      infinityInterval: null,
      interval: null,
      search: '',
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
      },
    }
  },

  computed: {
    statusPallete() {
      return {
        'at-risk': {
          title: this.$t('management-people-history.colorPallete.at-risk'),
          color: '#E71616',
        },
        'on-time': {
          title: this.$t('management-people-history.colorPallete.on-time'),
          color: '#0AB057',
        },
        delayed: {
          title: this.$t('management-people-history.colorPallete.delayed'),
          color: '#FBAD17',
        },
      }
    },
    attributePallete() {
      return {
        name: this.$t('management-people-history.translatePallete.name'),
        phoneNumber: this.$t(
          'management-people-history.translatePallete.phoneNumber'
        ),
        occupation: this.$t('management-people-history.translatePallete.role'),
        directManager: this.$t(
          'management-people-history.translatePallete.directManager'
        ),
        photo: this.$t('management-people-history.translatePallete.photo'),
      }
    },
  },

  methods: {
    async searchInput() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.searchSubmit()
      }, 500)
    },

    async searchSubmit() {
      const payload = {
        name: this.search,
        limit: this.enablePagination ? this.pagination.limit : 99999,
        offset: 0,
      }

      this.$emit('search:history', payload)
    },

    handleInfiniteScroll(params) {
      this.showGoUpButton = params.target.scrollTop >= 100
      const pixelTolerance = 5
      if (
        params.target.clientHeight + params.target.scrollTop + pixelTolerance >=
        params.target.scrollHeight
      ) {
        clearInterval(this.infinityInterval)
        this.infinityInterval = setInterval(() => {
          this.$emit('infinity:entity-history')
          clearInterval(this.infinityInterval)
        }, 750)
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
