<template>
  <div id="account-page" data-test-id="account-page">
    <router-view
      v-on="$listeners"
      :options="options"
      :quickRegister="quickRegister"
      :closeSideQuickForm="closeSideQuickForm"
    />
  </div>
</template>

<script>
export default {
  name: 'accountPage',
  props: {
    options: {
      type: Array,
    },
    quickRegister: {
      type: Object,
    },
    closeSideQuickForm: {
      type: Function,
    },
  },
}
</script>
