<template>
  <div
    class="tree-loading__content d-flex flex-column align-center justify-center"
  >
    <!--root-->
    <div class="tree-loading tree-loading__root">
      <v-skeleton-loader type="list-item-avatar" />
    </div>
    <div class="d-flex justify-center">
      <div class="tree-loading__vl" />
    </div>
    <!--node-->
    <div class="tree-loading tree-loading__node">
      <v-skeleton-loader type="list-item-avatar, list-item-three-line" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TreeLoading',
  data() {
    return {
      dataset: {
        root: true,
        children: [{}],
      },
      config: { nodeWidth: 301, nodeHeight: 201, levelHeight: 250 },
    }
  },
}
</script>
<style scoped src="./style.scss" lang="scss" />
