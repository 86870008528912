<template>
  <div data-test-avatar-group class="avatar-group__content" id="avatar-group">
    <v-tooltip data-test-avatar-tooltip top z-index="300">
      <template v-slot:activator="{ attrs, on }">
        <!--EmptyStage-->
        <div v-if="!data.length">
          <v-avatar :size="size" :class="_avatarClass" v-bind="attrs" v-on="on">
            <span
              :style="{ ..._font }"
              class="content-avatar-text"
              v-text="`--`"
            />
          </v-avatar>
        </div>
        <!--Multiple Avatars-->
        <div v-else>
          <v-avatar
            data-test-avatar
            v-for="(_, index) of _max"
            :key="index"
            :size="size"
            :style="{ marginLeft: index ? `-${size / 2}px` : 0 }"
            v-bind="attrs"
            v-on="on"
          >
            <AvatarComponent
              v-if="data.length"
              :id="data[index].id"
              :size="size.toString()"
              :src="data[index].image"
              :text="data[index].text"
            />
          </v-avatar>
        </div>

        <span
          data-test-avatar-overflow
          v-if="InsertMode"
          :style="{ ..._font }"
          @click="submit(data)"
        >
          <v-icon x-small class="content-avatar-icon">fi-rr-user-add</v-icon>
        </span>
      </template>
      <div class="avatar-group__names" v-if="data.length > 0">
        <span v-for="name of _names" :key="name" class="pa-1" v-text="name" />
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'AvatarGroup',
  props: {
    data: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      required: false,
      default: () => 4,
    },
    size: {
      type: Number,
      default: () => 48,
    },
    InsertMode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    _max: function () {
      return this.max < this.data.length ? this.max : this.data.length || 1
    },
    _overflow: function () {
      const haveOverflow = this.data.length > this.max
      const overflow = this.data.length - this.max
      return haveOverflow ? overflow : 0
    },
    _font: function () {
      return {
        fontSize: `${this.size * 0.03}em`,
      }
    },
    _names: function () {
      return this.data.map(e => e.text)
    },
    _avatarClass: function () {
      return `content-avatar${this.InsertMode ? '-cursor' : ''}`
    },
  },
  methods: {
    submit(event) {
      if (!this.InsertMode) return

      this.$emit('submit', event)
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
