<template>
  <div id="filter-selection" data-test-filter-selection>
    <v-menu
      v-model="opened"
      offset-y
      max-width="11rem"
      :close-on-content-click="false"
      z-index="1100"
      id="filter-selection-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot v-bind="attrs" v-on="on" />
      </template>
      <div
        v-for="(option, optionId) of options"
        :key="optionId"
        class="filter-selection-accordion"
      >
        <DetailSectionAccordion
          data-test-filter-selection-accordion
          class="px-2"
          :title="titleOptions[optionId]"
        >
          <div class="scrolled" data-test-filter-selection-items>
            <div v-for="(role, roleId) of option" :key="roleId">
              <v-checkbox
                data-test-filter-selection-item
                :id="`role-${roleId}`"
                :label="role.label"
                :value="role.id"
                color="#dd0041"
                @change="handleOptionChange(role, optionId)"
              />
            </div>
          </div>
        </DetailSectionAccordion>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'FilterSelection',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      opened: false,
      titleOptions: this.$t('FilterSelection.titleOptions'),
    }
  },
  watch: {
    show(newValue) {
      this.opened = newValue
    },
    opened(newValue) {
      this.$emit('handleVision', newValue)
    },
  },
  methods: {
    handleOptionChange(value, optionId) {
      this.$emit('handleOptionChange', value, optionId)
    },
  },
  mounted() {
    this.opened = this.show
  },
}
</script>

<style src="./style.scss" scoped lang="scss"></style>
