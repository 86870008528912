<template>
  <tr class="down-side-row-button">
    <td @click="handleClick">
      <div class="d-flex add-new-btn">
        <v-icon :size="17" dense class="plus-icon">mdi-plus</v-icon>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'downSideRowButton',
  props: {
    rowIndex: Number,
    row: Object,
    event: Object,
  },
  inject: ['hideNodesAfter', 'downSideRowButton'],
  methods: {
    handleClick() {
      this.$emit(
        'down-side-row-button',
        'down-side-row-button',
        this.row,
        this.rowIndex
      )
    },
  },
}
</script>
<style src="../style.scss" lang="scss" scoped />
