<template>
  <div v-if="_showListDefaultFields" data-test-section-default-fields>
    <DetailSectionAccordion
      type="people"
      :title="$t('PerformanceTab.listDefaultFields.title')"
      :opened="true"
    >
      <DataTable
        data-test-list-default-fields
        :title="$t('PerformanceTab.listDefaultFields.title')"
        :attach="true"
        :loading="loading"
        :action-buttons-loading="
          buttonsActionLoading ? actionButtonsLoading : []
        "
        :hide-header="true"
        :hide-option="true"
        :roles-readonly="false"
        :local-add-search="true"
        :items="filteredItems"
        :headers="_headers"
        :items-per-page="4"
        :skeleton-lines="4"
        :limit-pagination="4"
        @search="searchDefaultFields($event)"
        @edit="handleEdit($event)"
      ></DataTable>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import { management_performance_default_fields } from '@/helpers/ability'

export default {
  name: 'ListDefaultFields',

  props: {
    settingsId: {
      type: String,
      required: true,
    },
    settingsByEntity: {
      type: Object,
      default: () => ({}),
    },
    buttonsActionLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      filteredItems: [],
      items: [],
      defaultListItens: ['people', 'owners', 'description'],
      defaultFieldList: [],
      actionButtonsLoading: [
        {
          positionRow: 0,
          actionClicked: 'edit',
        },
        {
          positionRow: 1,
          actionClicked: 'edit',
        },
        {
          positionRow: 2,
          actionClicked: 'edit',
        },
      ],
    }
  },

  computed: {
    _showListDefaultFields() {
      return this.$can('access', management_performance_default_fields)
    },
    _headers() {
      return [
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.name'),
          value: 'name',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.section'),
          value: 'section',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.type'),
          value: 'type',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.required'),
          value: 'required',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
  },

  watch: {
    async settingsByEntity() {
      if (this.settingsByEntity) {
        this.defaultFieldList = this.settingsByEntity
        this.fetchDefaultFields()
      }
    },
  },

  async mounted() {
    this.updateDefaultListItens()
  },

  methods: {
    searchDefaultFields(event) {
      const array = []
      if (event.name != '') {
        this.items.map(el => {
          if (el?.name?.label.toLowerCase().includes(event.name.toLowerCase()))
            array.push(el)
        })

        this.filteredItems = array
        return
      }

      if (event.name === '') this.fetchDefaultFields()
    },
    async fetchDefaultFields() {
      this.loading = true

      this.items = []

      this.menuTable = ['edit']

      for (const fieldName of this.defaultListItens) {
        this.items.push({
          actions: this.menuTable,
          id: this.defaultFieldList.id,
          name: {
            label: this.handleName(fieldName),
            status: '',
          },
          section: {
            label: this.handleSection(fieldName),
            status: '',
          },
          type: {
            label: this.handleType(fieldName),
            status: '',
          },
          required: {
            label: this.handleRequired(
              fieldName,
              this.defaultFieldList.requiredFields
            ),
            status: '',
          },
          entity: this.defaultFieldList,
        })
      }
      this.filteredItems = this.items
      this.loading = false
    },
    handleName(defaultFieldName) {
      if (
        defaultFieldName === 'description' &&
        this.$route.params.entityType === 'initiative'
      ) {
        return this.$t(
          `PerformanceTab.listDefaultFields.items.iniciativeDescription`
        )
      }

      return this.$t(
        `PerformanceTab.listDefaultFields.items.${defaultFieldName}`
      )
    },
    handleSection(defaultFieldName) {
      let keyTraduction = null
      switch (defaultFieldName) {
        case 'people':
          keyTraduction = 'people'
          break
        case 'corresponsibles':
          keyTraduction = 'corresponsibles'
          break
        case 'owners':
          keyTraduction = 'detail'
          break
        case 'description':
          keyTraduction = 'detail'
          break

        default:
          keyTraduction = '--'
          break
      }

      return this.$t(
        `PerformanceTab.listDefaultFields.sections.${keyTraduction}`
      )
    },
    handleType(defaultFieldName) {
      let keyTraduction = null
      switch (defaultFieldName) {
        case 'people':
          keyTraduction = 'table'
          break
        case 'corresponsibles':
          keyTraduction = 'table'
          break
        case 'owners':
          keyTraduction = 'field'
          break
        case 'description':
          keyTraduction = 'field'
          break

        default:
          keyTraduction = '--'
          break
      }

      return this.$t(`PerformanceTab.listDefaultFields.type.${keyTraduction}`)
    },
    handleRequired(defaultFieldName, requiredFieldsList) {
      for (let obj of requiredFieldsList) {
        if (obj && obj.includes(defaultFieldName)) {
          return this.$t(`PerformanceTab.listDefaultFields.requiredField.yes`)
        }
      }
      return this.$t(`PerformanceTab.listDefaultFields.requiredField.no`)
    },

    handleEdit(defaultFieldPayload) {
      this.$emit('edit-default-field', defaultFieldPayload)
    },

    updateDefaultListItens() {
      if (this.$route.params.entityType === 'initiative') {
        this.defaultListItens.push('corresponsibles')
      } else if (this.$route.params.entityType === 'context') {
        this.defaultListItens = this.defaultListItens.filter(
          item => item !== 'description'
        )
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
