<template>
  <v-overlay absolute color="transparent" class="empty-overlay">
    <div class="d-flex flex-column align-center justify-center text-center">
      <span class="empty-overlay-text mb-8">
        {{ $t('TreeView.emptyOverlay.message1') }}
      </span>

      <span class="empty-overlay-text mb-8">
        {{ $t('TreeView.emptyOverlay.message3') }}
      </span>

      <span class="empty-overlay-text-highlight mb-8">
        {{ $t('TreeView.emptyOverlay.message4') }}
      </span>

      <v-btn
        data-test-empty-overlay-text-button
        class="empty-overlay-text-button"
        @click="handleClick"
      >
        <v-icon color="white" left>fi-rr-plus-small</v-icon>
        <span>
          {{ $t('TreeView.emptyOverlay.addNew') }}
        </span>
      </v-btn>
    </div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleClick() {
      if (this.onClick) this.onClick()
    },
  },
}
</script>
<style lang="scss" src="../style.scss" scoped />
