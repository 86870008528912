<template>
  <ItemSelector
    data-test-selector-entity-type
    ref="entityType"
    :inputConfig="{
      label: $t('PermissionsPage.AddNewEntityRole.typeEntity.title'),
      showAvatar: false,
      rules: rules,
      closeOnSelect: true,
    }"
    :menuConfig="{
      attach: false,
      showTabs: false,
    }"
    :menuOptions="_listEntityType"
    :fillWidth="true"
    :multiple="false"
    :showElements="true"
    :local-search="true"
    @update:item="handleEntityType"
  />
</template>

<script>
import { getHierarchySettings } from '@/services/entities'
export default {
  name: 'EntityType',
  data() {
    return {
      listEntityType: [],
      rules: [
        v =>
          (!!v && v.length > 0) ||
          this.$t('TextQuickEdit.validations.required'),
      ],
    }
  },

  beforeMount() {
    this.handleGetEntityTypes()
  },
  computed: {
    _listEntityType() {
      return [
        {
          value: 'entityTypes',
          type: 'listview',
          label: this.$t('PermissionsPage.AddNewEntityRole.typeEntity.title'),
          items: this.listEntityType,
        },
      ]
    },
  },
  methods: {
    handleEntityType(event) {
      this.$emit('setEntityType', event)
    },
    async handleGetEntityTypes() {
      this.listEntityType = []
      await this.fetchHierarchySettings({
        entityTypeExclusiveParam: 'context',
      })

      const settings = JSON.parse(JSON.stringify(this.listEntityType))
      this.listEntityType = settings
    },
    async fetchHierarchySettings(payload = {}) {
      return await getHierarchySettings(payload)
        .then(res => {
          this.listEntityType = this.handleEntityTypeDataToItens(res.data)
        })
        .catch(error => {
          const message = {
            title: this.$t('AddNewEntityRole.typeEntity.alerts.error.title'),
            description: error?.response?.data?.message,
            type: 'error',
            hasLeftBorder: true,
          }
          this.$refs.AlertBar.displayAlert(message)
        })
    },
    handleEntityTypeDataToItens(data = []) {
      if (!Array.isArray(data)) {
        return []
      }

      return data.map(item => {
        const title = this.getTitle(item.entityType, item.entityClass)
        const id = title.replace(/\s/g, '')

        return {
          id: id,
          label: title,
          entityType: item.entityType,
          entityClass: item.entityClass,
          root: item.root,
        }
      })
    },
    words(complement, customDefaultKey = null) {
      const defaultKey = customDefaultKey ?? `EntityTypes`

      return this.$t(`${defaultKey}.${complement}`)
    },
    getTitle(entityType, entityClass) {
      const etype = entityType ? entityType : 'emptyType'
      const eclass = entityClass ? entityClass : 'emptyClass'

      const translateKey = `${etype}-${eclass}`

      return this.words(translateKey, 'EntityTypes')
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
