<template>
  <div id="people-and-groups-page" data-test-id="people-and-groups-page">
    <router-view
      :cycle-selected-i-d="cycleSelectedID"
      :cycle-selected-name="cycleSelectedName"
      :group-selected="groupSelected"
      :options="options"
      :quick-register="quickRegister"
      :close-side-quick-form="closeSideQuickForm"
      v-on="$listeners"
    ></router-view>
  </div>
</template>

<script>
export default {
  name: 'PeopleAndGroupPage',
  props: {
    cycleSelectedID: {
      type: String,
      default: '',
    },
    cycleSelectedName: {
      type: String,
      default: '',
    },
    groupSelected: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
    },
    quickRegister: {
      type: Object,
    },
    closeSideQuickForm: {
      type: Function,
    },
  },
}
</script>
