<template>
  <v-card data-test-data-table id="datatable" color="transparent" flat>
    <section class="d-flex flex-column content">
      <div v-if="!hideHeader" class="content-header">
        <v-avatar v-if="!!icon" class="mr-2" size="22" color="neutral9" rounded>
          <v-icon x-small>{{ icon }}</v-icon>
        </v-avatar>

        <div class="content-header-title">{{ title }}</div>
      </div>

      <div :class="!contentStyle || 'content-section'">
        <v-row no-gutters v-if="!hideOption || !hideSearch">
          <v-col>
            <v-text-field
              data-test-data-table-search
              v-if="!hideSearch"
              v-model="search"
              :placeholder="$t('dataTable.search', { title })"
              background-color="transparent"
              hide-details
              dense
              solo
              flat
              @keyup="searchInput()"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-1" color="neutral5" x-small>
                  fi-rr-search
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <div class="custom-divider" v-if="searchDivider"></div>
          <v-col class="d-flex align-center justify-end">
            <div
              v-if="!hideOption"
              class="d-flex pointer"
              @click="optionSubmit()"
            >
              <v-icon class="mr-2" color="neutral5" x-small>
                fi fi-rr-list
              </v-icon>

              <span class="content-section-label">
                {{ $t('dataTable.option', { title: title.toLowerCase() }) }}
              </span>
            </div>
            <div v-else>
              <slot name="filter"></slot>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          class="custom-table mb-2 mt-n1"
          data-test-data-table
          :height="height"
          :headers="headers"
          :items="ordenablePayload.items"
          :no-data-text="$t('dataTable.noResults')"
          :items-per-page="pagination.limit"
          :page.sync="pagination.page"
          hide-default-footer
          :fixed-header="fixedHeader"
          :disable-sort="disableSort"
          :mobile-breakpoint="mobileBreakpointSwitch"
          dense
          @update:options="updateOptions"
          ref="dataTable"
        >
          <template v-slot:body="{ items }">
            <tbody
              data-test-data-table-item-loading
              v-if="loading && !items.length"
              class="loading"
            >
              <tr v-for="rowIndex in skeletonLines" :key="rowIndex">
                <td v-for="(_, columnIndex) in headers" :key="columnIndex">
                  <div class="skeleton-line" data-test-data-table-item-loading>
                    <v-skeleton-loader type="chip" class="skeleton-1" />
                  </div>
                </td>
              </tr>
            </tbody>

            <draggable
              data-test-data-table-list
              v-if="items.length > 0"
              v-model="ordenablePayload.items"
              tag="tbody"
              :group="{ name: 'published', put: 'unpublished' }"
              :animation="120"
              :disabled="!enableDraggable || !canDrag"
            >
              <tr
                data-test-data-table-row
                v-for="(item, rowIndex) in items"
                :key="rowIndex"
              >
                <td
                  data-test-data-table-item
                  v-for="(column, columnIndex) in headers"
                  :key="columnIndex"
                  :class="{
                    'column-with-order': enableDraggable && columnIndex === 0,
                  }"
                  :style="{
                    width: column.width,
                    'max-width': column.width,
                  }"
                >
                  <div class="column">
                    <template v-if="enableDraggable && columnIndex === 0">
                      <OrderButton
                        :item="item"
                        :buttonIndex="rowIndex"
                        :minimumPosition="column?.minimumPosition || 0"
                        :maximumPosition="items.length - 1"
                        :naturalizeIndex="!!column?.naturalizeIndex"
                        :isOrdenable="enableDraggable && canDrag"
                        @update:order="handleInputOrder"
                      ></OrderButton>
                    </template>

                    <ActionButtons
                      v-if="column.type === 'action-buttons'"
                      :data="item[column.value]"
                      @submit="actionSubmit($event, item, rowIndex)"
                    >
                      <template slot="menu">
                        <slot name="menu" />
                      </template>
                    </ActionButtons>

                    <ChipGroup
                      v-else-if="column.type === 'chip-group'"
                      :data="item[column.value]"
                    />

                    <ProgressBar
                      v-else-if="column.type === 'progress-bar'"
                      type="percentage"
                      :lockProgress="true"
                      :hideFooter="true"
                      :data="item[column.value]"
                    />

                    <BorderedItem
                      v-else-if="column.type === 'bordered-item'"
                      :data="item[column.value]"
                      @itemClicked="borderedItemClicked($event, column.value)"
                    />

                    <template
                      v-else-if="
                        column.type === 'progress-bar-or-bordered-item'
                      "
                    >
                      <BorderedItem
                        v-if="item[column.value].text"
                        :data="item[column.value]"
                      />

                      <ProgressBar
                        v-else
                        type="percentage"
                        :lockProgress="true"
                        :hideFooter="true"
                        :data="item[column.value]"
                      />
                    </template>

                    <CycleItem
                      v-else-if="column.type === 'cycle-item'"
                      :data="item[column.value]"
                    />

                    <ComboSelection
                      v-else-if="column.type === 'combo-selection'"
                      :data="item[column.value]"
                      :options="
                        column.options || getSelectionOptions(columnIndex)
                      "
                      :label="column.text"
                      :keyValue="column.value"
                      :readonly="rolesReadonly"
                      @update="
                        updateData($event, item.id, column.value, columnIndex)
                      "
                    />

                    <div
                      v-else-if="column.type === 'combo-selection-popup'"
                      class="d-flex align-center flex-nowrap"
                    >
                      <ComboSelection
                        :data="item[column.value]"
                        :options="
                          column.options && column.options.length
                            ? column.options
                            : selectionOptions
                        "
                        :label="column.text"
                        :readonly="rolesReadonly"
                        @update="
                          updateData($event, item.id, column.value, columnIndex)
                        "
                      />
                      <slot name="popup" :item="item" />
                    </div>

                    <EntityItem
                      v-else-if="column.type === 'entity-item'"
                      :data="item[column.value]"
                      :id="item.id"
                      :clickable="column.clickable"
                      @submit="entitySubmit($event)"
                    >
                      <template slot="tooltip">
                        <slot name="entity-item-tooltip" :id="item.id" />
                      </template>
                    </EntityItem>

                    <PositionalItem
                      v-else-if="column.type === 'positional-item'"
                      :data="item[column.value]"
                    ></PositionalItem>

                    <MessagePopup
                      v-else-if="column.type === 'message-popup'"
                      :data="item[column.value]"
                    />

                    <HtmlMessage
                      v-else-if="column.type === 'html-message'"
                      :data="item[column.value]"
                    />

                    <RedirectLink
                      v-else-if="column.type === 'redirect-link'"
                      :data="item[column.value]"
                    />

                    <TextStatus
                      v-else-if="column.type === 'text-status'"
                      :data="item[column.value]"
                      :emitValue="item[column.emitValue]"
                      :clickable="column.clickable"
                      @submit="statusSubmit($event)"
                    />

                    <ChipEdit
                      v-else-if="column.type === 'chip-edit'"
                      :data="item[column.value]"
                      :index="rowIndex"
                      @update="updateData($event, item.id)"
                    />

                    <InputEdit
                      v-else-if="column.type === 'input-edit'"
                      :data="item[column.value]"
                      :index="rowIndex"
                      :readonly="rolesReadonly"
                      @update="updateInputEdit($event, item.id)"
                    />

                    <AvatarGroup
                      v-else-if="column.type === 'avatar-group'"
                      :data="item[column.value]"
                      :size="24"
                      :InsertMode="false"
                      @submit="avatarSubmit($event, item)"
                    />

                    <AvatarGroup
                      v-else-if="column.type === 'avatar-group-add'"
                      :data="item[column.value]"
                      :size="24"
                      :InsertMode="true"
                      @submit="avatarSubmit($event, item)"
                    />

                    <HoverModal
                      v-else-if="column.type === 'hover-modal'"
                      :data="item[column.value]"
                    >
                      <template slot="hover-modal-content">
                        <slot name="hover-modal-content" v-bind="{ item }" />
                      </template>
                    </HoverModal>

                    <span
                      v-else
                      class="content-section-label"
                      data-test-section-label
                    >
                      {{ item[column.value] }}
                    </span>
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>

        <div
          v-if="!loading && items.length < 1"
          class="d-flex text-center justify-center align-center"
          :class="{ 'empty-stage': height != '100%' }"
        >
          <span data-test-data-table-empty class="content-section-label mb-4">
            {{ emptyPlaceholder || $t('dataTable.noResults') }}
          </span>
        </div>

        <ItemSelector
          data-test-list-combo
          v-if="!rolesReadonly && addOptions.length > 0"
          :inputConfig="{
            ...(ItemSelectorProps?.inputConfig || {}),
            label: $t('dataTable.addPlaceholder', {
              title: title.toLowerCase(),
            }),
            submitMode: true,
          }"
          :menuConfig="{
            ...(ItemSelectorProps?.menuConfig || {}),
            attach: attach,
          }"
          :menuOptions="addOptions"
          :fillWidth="true"
          :localSearch="localAddSearch"
          :hideElements="hideAddElements"
          :alwayExpand="alwayAddExpand"
          :loading="loadingAddOptions"
          :dense="true"
          :zIndex="999"
          v-bind="ItemSelectorProps"
          @update:item="addData($event)"
          @search:item="searchItem($event)"
          @expand:item="$emit('expand:item', $event)"
          @focus:tab="$emit('focus:tab', $event)"
          @focus:input="$emit('focus:input')"
        >
          <template v-slot:footer="props">
            <slot name="item-selector-footer" v-bind="props" />
          </template>
        </ItemSelector>

        <span
          v-if="downLeftButton"
          @click="downLeftButton.click"
          class="down-left-button"
          data-test-down-left-button
        >
          <v-icon dense :size="17">{{ downLeftButton.icon }}</v-icon>
          {{ downLeftButton.text }}
        </span>

        <TablePagination
          data-test-table-pagination
          v-if="enablePagination"
          :pagination="pagination"
          :limiter="limitPagination != 0 ? limitPagination : items.length"
          @update:page="handlePagination($event)"
        />

        <slot name="footer"></slot>
      </div>
    </section>
  </v-card>
</template>

<script>
import ActionButtons from '@/components/DataTable/Parts/ActionButtons/ActionButtons'
import ChipGroup from '@/components/DataTable/Parts/ChipGroup/ChipGroup'
import ComboSelection from '@/components/DataTable/Parts/ComboSelection/ComboSelection'
import EntityItem from '@/components/DataTable/Parts/EntityItem/EntityItem'
import PositionalItem from '@/components/DataTable/Parts/PositionalItem/PositionalItem'
import HtmlMessage from '@/components/DataTable/Parts/HtmlMessage/HtmlMessage'
import RedirectLink from '@/components/DataTable/Parts/RedirectLink/RedirectLink'
import TextStatus from '@/components/DataTable/Parts/TextStatus/TextStatus'
import ChipEdit from '@/components/DataTable/Parts/ChipEdit/ChipEdit.vue'
import InputEdit from '@/components/DataTable/Parts/InputEdit/InputEdit.vue'
import TablePagination from '@/components/DataTable/Parts/TablePagination/TablePagination'
import BorderedItem from '@/components/DataTable/Parts/BorderedItem/BorderedItem.vue'
import CycleItem from '@/components/DataTable/Parts/CycleItem/CycleItem.vue'
import AvatarGroup from '@/components/DataTable/Parts/AvatarGroup/AvatarGroup'
import HoverModal from '@/components/DataTable/Parts/HoverModal/HoverModal'
import OrderButton from '@/components/DataTable/Parts/OrderButton/OrderButton'

import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

export default {
  name: 'DataTable',

  data() {
    return {
      infinityInterval: null,
      interval: null,
      search: '',
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
      },
      nextSelectionOptionsPosition: -1,
      dataTable: undefined,

      ordenablePayload: {
        items: [],
      },
    }
  },

  props: {
    title: {
      required: true, // title of table
    },

    icon: {
      type: String,
      default: '', // icon of header
    },

    headers: {
      type: Array,
      default: () => [], // headers of table, set with type of column (action-buttons, chip-group, combo-selection, entity-item, message-popup, redirect-link, text-status)
    },

    items: {
      type: Array,
      default: () => [], // array items of show in table, insert object according to type
    },

    enablePagination: {
      type: Boolean,
      default: true, // enable and disable pagination
    },

    itemsPerPage: {
      type: Number,
      default: 20, // quantity of elements on data table
    },

    addOptions: {
      type: Array,
      default: () => [], // options of ItemSelector component for add item event, leave empty to not include
    },

    hideAddElements: {
      type: Array,
      default: () => [], // hide elements on add people list
    },

    emptyPlaceholder: {
      type: String,
      default: '', // placeholder to empty data on table
    },

    selectionOptions: {
      type: Array,
      default: () => [], // options of combo-selection type
    },

    uniqueSelectionOptions: {
      type: Boolean,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      default: false, // hide table header with title and icon
    },

    hideOption: {
      type: Boolean,
      default: false, // hide option label on right to top on data table
    },

    contentStyle: {
      type: Boolean,
      default: true, // enable style for content marigns and paddings
    },

    attach: {
      type: Boolean,
      default: false, // enable attach event on data table
    },

    localAddSearch: {
      type: Boolean,
      default: false,
    },

    alwayAddExpand: {
      type: Boolean,
      default: false,
    },

    rolesReadonly: {
      type: Boolean,
      default: false,
    },

    searchDivider: {
      type: Boolean,
      default: false,
    },

    disableSort: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    loadingAddOptions: {
      type: Boolean,
      default: false,
    },

    height: {
      type: String,
      default: '100%',
    },

    fixedHeader: {
      type: Boolean,
      default: false,
    },

    mobileBreakpointSwitch: {
      type: Number,
      default: 600,
    },

    hideSearch: {
      type: Boolean,
      default: false,
    },

    limitPagination: {
      type: Number,
      default: 0, // quantity of elements on page
    },

    skeletonLines: {
      type: Number,
      default: 0, // Skeleton's number of Lines
    },

    downLeftButton: {
      type: Object,
    },

    enableDraggable: {
      type: Boolean,
      default: false,
    },

    canDrag: {
      type: Boolean,
      default: true,
    },

    ItemSelectorProps: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Draggable,
    OrderButton,
    ActionButtons,
    ChipGroup,
    ComboSelection,
    EntityItem,
    PositionalItem,
    HtmlMessage,
    MessagePopup: () => import('./Parts/MessagePopup/MessagePopup'),
    RedirectLink,
    TextStatus,
    ChipEdit,
    InputEdit,
    TablePagination,
    BorderedItem,
    CycleItem,
    AvatarGroup,
    HoverModal,
  },

  beforeMount() {
    this.pagination.limit = this.itemsPerPage
  },

  mounted() {
    this.getData()
    this.$nextTick(() => {
      this.handleMountedDataTable()
    })
  },

  computed: {
    ...mapGetters({
      user: 'currentUser/user',
    }),
  },

  methods: {
    async getData() {
      const payload = {
        name: this.search,
        limit: this.enablePagination ? this.pagination.limit : 99999,
        offset: this.pagination.offset,
      }

      this.$emit('get-data', payload)
    },

    getSelectionOptions(id) {
      if (this.uniqueSelectionOptions) {
        return this.selectionOptions[id - 1]
      }
      return this.selectionOptions
    },

    addData(event) {
      if (event) this.$emit('add-data', event)
    },

    updateData(event, id, key, index) {
      const payload = {
        id: id,
        key: key,
        index: index - 1,
        ...event,
      }

      this.$emit('update-data', payload)
    },

    updateInputEdit(event, id, key, index) {
      const payload = {
        id: id,
        key: key,
        index: index - 1,
        ...event,
      }

      this.$emit('update-inputEdit', payload)
    },

    async searchInput() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.searchSubmit()
      }, 500)
    },

    async searchSubmit() {
      const payload = {
        name: this.search,
        limit: this.enablePagination ? this.pagination.limit : 99999,
        offset: 0,
      }

      if (this.search != '') {
        this.pagination.page = 1
        this.pagination.offset = 0
      }

      this.$emit('search', payload)
    },

    optionSubmit() {
      this.$emit('view-all')
    },

    actionSubmit(event, data, position) {
      this.$emit(event, data, position)
    },

    handlePagination(event) {
      this.$emit('updateList', event)
      this.pagination.page = event.page
      this.pagination.offset = event.offset

      this.getData()
    },

    searchItem(event) {
      this.$emit('search:item', event)
    },

    updateOptions(event) {
      this.$emit('updateOptions', event)
    },

    borderedItemClicked(data, itemName) {
      this.$emit('borderedItemClicked', data, itemName)
    },

    handleMountedDataTable() {
      const dataTable = this.$refs.dataTable
      if (dataTable) {
        const dt = dataTable.$el.querySelector('.v-data-table__wrapper')
        dt.addEventListener('scroll', this.handleInfiniteScroll)
        this.dataTable = dt
      }
    },

    handleInfiniteScroll(params) {
      const pixelTolerance = 5
      if (
        params.target.clientHeight + params.target.scrollTop + pixelTolerance >=
        params.target.scrollHeight
      ) {
        clearInterval(this.infinityInterval)
        this.infinityInterval = setInterval(() => {
          this.$emit('infinity-scroll')
          clearInterval(this.infinityInterval)
        }, 750)
      }
    },

    clearSearch() {
      this.search = ''
    },

    avatarSubmit(data) {
      this.$emit('avatarSubmit', data)
    },

    entitySubmit(data) {
      this.$emit('entity-submit', data)
    },

    statusSubmit(data) {
      this.$emit('status-submit', data)
    },

    handleInputOrder({ oldIndex, newIndex }) {
      if (!this.canDrag) return

      const item = this.ordenablePayload.items[oldIndex]

      this.ordenablePayload.items.splice(oldIndex, 1)
      this.ordenablePayload.items.splice(newIndex, 0, item)
    },
  },
  watch: {
    loading() {
      this.handleMountedDataTable()
    },

    items: {
      handler(val) {
        this.ordenablePayload.items = val
      },
      immediate: true,
      deep: true,
    },

    'ordenablePayload.items': {
      handler(val) {
        this.$emit('update:table-order', val)
      },
      deep: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
