<template>
  <div data-test-integrations-page class="integrations-page">
    <router-view :integrations="integrations"></router-view>
  </div>
</template>

<script>
import { getIntegration } from '@/services/integrations'
import { alertErrorBus } from '@/helpers/alerts'

export default {
  name: 'IntegrationsPage',
  data() {
    return {
      integrations: [
        {
          id: 'slack',
          workspaces: [],
        },
      ],
    }
  },

  beforeMount() {
    this.handleIntegrations()
  },

  mounted() {
    this.$store.dispatch('setCurrentPage', 'IntegrationsPage')
  },

  methods: {
    async getIntegration(integration) {
      return await getIntegration({ integrationID: integration.id })
        .then(({ data }) => {
          integration.workspaces.push(...data)
        })
        .catch(error => {
          alertErrorBus(
            this.$t('alerts.ManagementIntegrationsIntegrationID.error.get'),
            error
          )
        })
    },

    async handleIntegrations() {
      for (const integration of this.integrations) {
        await this.getIntegration(integration)
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
