var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ItemSelector',{ref:"entityType",attrs:{"data-test-selector-entity-type":"","inputConfig":{
    label: _vm.$t('PermissionsPage.AddNewEntityRole.typeEntity.title'),
    showAvatar: false,
    rules: _vm.rules,
    closeOnSelect: true,
  },"menuConfig":{
    attach: false,
    showTabs: false,
  },"menuOptions":_vm._listEntityType,"fillWidth":true,"multiple":false,"showElements":true,"local-search":true},on:{"update:item":_vm.handleEntityType}})
}
var staticRenderFns = []

export { render, staticRenderFns }