<template>
  <div id="text-status" class="text-status d-flex" data-test-node-status>
    <span :class="_statusColor + ' label'">
      {{ _statusLabel }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    cycleType: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    configListCycle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _statusColor() {
      const prefix = !this.outlined ? 'text' : 'bg'

      if (this.active) return `greencolor--${prefix}`
      return `neutral7--${prefix}`
    },
    _statusLabel() {
      if (this.active) return this.$t('TreeNode.status.active')
      if (!this.active && (this.cycleType || this.configListCycle)) {
        return this.$t('PerformanceTab.listCycleSettings.statusLabel.filed')
      }
      return this.$t('TreeNode.status.inactive')
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
