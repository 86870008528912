<template>
  <div id="hover-modal" class="d-flex" data-test-hover-modal>
    <v-menu
      :max-width="$vuetify.breakpoint.mdAndUp ? '60vw' : '80vw'"
      :close-on-click="false"
      :close-on-content-click="false"
      offset-y
      left
      open-on-hover
      close-delay="100"
    >
      <template v-slot:activator="{ on, attrs }">
        <div>
          <span
            dark
            v-bind="attrs"
            v-on="on"
            v-html="data.label"
            class="label"
            data-test-slot-activator
          />
          <v-icon class="icon" v-bind="attrs" v-on="on">
            {{ data.icon }}</v-icon
          >
        </div>
      </template>

      <div>
        <div class="hover-title">{{ data.title }}</div>
        <v-divider></v-divider>
        <div class="hover-content">
          <slot name="hover-modal-content" />
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'HoverModal',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
