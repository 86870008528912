import api from '@/services/Http'

export const getTags = async data =>
  (await api()).get('/performance/okrs/tags', { params: data })

export const createTag = async data =>
  (await api()).post('/performance/okrs/tags', data)

export const updateTag = async data =>
  (await api()).put(`/performance/okrs/tags/${data.id}`, data.body)

export const deleteTag = async data =>
  (await api()).delete(`/performance/okrs/tags/${data}`)
