var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-imput"},[_c('div',{staticClass:"group-imput-item",attrs:{"data-test-select-group":""}},[(_vm.listGroup.length)?_c('ItemSelector',{attrs:{"input-config":{
        label: _vm.$t('LateralMenu.PeopleMenu.groupCombo'),
        showAvatar: true,
      },"menu-config":{
        align: 'right',
        attach: false,
        showTabs: false,
      },"menu-options":_vm.listGroup,"fill-width":true,"multiple":true,"show-elements":true,"local-search":true},on:{"update:item":function($event){return _vm.setValueGroups($event)}}}):_vm._e()],1),_c('div',{staticClass:"group-imput-item",attrs:{"data-test-select-roles":""}},[_c('ItemSelector',{attrs:{"input-config":{
        label: _vm.$t('LateralMenu.PeopleMenu.rolesCombo'),
        showAvatar: false,
      },"menu-config":{
        align: 'right',
        attach: false,
        showTabs: false,
      },"menu-options":_vm.listRoles,"fill-width":true,"multiple":true,"show-elements":true},on:{"update:item":function($event){return _vm.setValueRoles($event)},"focus:input":function($event){return _vm.getOptionsRoles()},"search:item":function($event){return _vm.getOptionsRoles($event)}}})],1),_c('div',{staticClass:"group-imput-item",attrs:{"data-test-select-group-permissions":""}},[_c('ItemSelector',{attrs:{"input-config":{
        label: _vm.$t('LateralMenu.PeopleMenu.groupPermissionsCombo'),
        showAvatar: false,
      },"menu-config":{
        align: 'right',
        attach: false,
        showTabs: false,
      },"menu-options":_vm.listGroupPermissions,"fill-width":true,"multiple":true,"show-elements":true},on:{"update:item":function($event){return _vm.setValueGroupsPermissions($event)},"focus:input":function($event){return _vm.getOptionsGroupPermissions()},"search:item":function($event){return _vm.getOptionsGroupPermissions($event)}}})],1),_c('div',{staticClass:"group-imput-item",attrs:{"data-test-select-status":""}},[_c('ItemSelector',{attrs:{"input-config":{
        label: _vm.$t('LateralMenu.PeopleMenu.statusCombo'),
        showAvatar: false,
      },"menu-config":{
        align: 'right',
        attach: false,
        showTabs: false,
      },"menu-options":_vm.listStatus,"fill-width":true,"multiple":true,"show-elements":true},on:{"update:item":function($event){return _vm.setValueStatus($event)},"focus:input":function($event){return _vm.getOptionsStatus()}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }