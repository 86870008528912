<template>
  <div id="add-new-group">
    <PeopleViewModal
      ref="peopleViewModal"
      :people="subgroupPeople"
      @searchPerson="searchSubgroupPerson"
      @addPerson="addSubgroupPerson"
      @deletePerson="deleteSubgroupPerson"
      @optionsSelected="optionsSelectedSubPeople"
      @filterPeople="filterSubgroupPeople"
    ></PeopleViewModal>

    <ModalMoveSubgroup
      ref="modalMoveSubgroup"
      :subgroup-move-name="subgroupMoveName"
      @cancel="handleMoveSubgroupModal()"
      @confirm="confirmMoveSubgroup()"
    ></ModalMoveSubgroup>

    <div class="horizontal-inputs mt-4">
      <UploadIMG
        class="ms-3"
        :link-image="form.image"
        @handleInput="handleInput($event, 'image')"
      ></UploadIMG>
      <div class="group-inputs-size">
        <v-row class="mr-4">
          <v-col>
            <CustomInput
              ref="InputName"
              data-test-group-form-input="name"
              :current-value="form.name"
              :label="`${$t('GroupsPage.addNewGroup.groupNameLabel')}*`"
              :mandatory="true"
              @handleInput="handleInput($event, 'name')"
            ></CustomInput>
          </v-col>
        </v-row>

        <v-row class="mr-4">
          <v-col class="mr-6">
            <CustomInput
              ref="InputCode"
              data-test-group-form-input="code"
              :current-value="form.code"
              :label="`${$t('GroupsPage.addNewGroup.groupCodeLabel')}`"
              @handleInput="handleInput($event, 'code')"
            ></CustomInput>
          </v-col>
          <v-col>
            <SingleSelection
              ref="statusField"
              data-test-group-form-selection="status"
              :selected-item="form.status"
              :placeholder="`${$t('GroupsPage.addNewGroup.groupStatusLabel')}*`"
              :label="$t('GroupsPage.addNewGroup.groupStatusLabel')"
              :items="statusOptions"
              :rules="rules"
              :attach="true"
              :mandatory="true"
              :color="_statusColor"
              @selectOption="handleInput($event.value, 'status')"
            ></SingleSelection>
          </v-col>
        </v-row>
      </div>
    </div>

    <DetailSectionAccordion
      id="accordion-subgroups"
      data-test-group-form-subgroups
      type="subgrupo"
      :title="$t('GroupsPage.accordion.subgroups.title')"
      :opened="true"
    >
      <DataTable
        ref="subgroupTable"
        class="data-table-margin-resized"
        :add-options="subgroups.options"
        :headers="subgroups.headers"
        :items="subgroups.filteredItems"
        :selection-options="subgroups.selectionOptions"
        :limit-pagination="limitPaginationSubgroup"
        :visible-empty="visibleEmpty"
        :items-per-page="7"
        :hide-option="true"
        :hide-header="true"
        :attach="true"
        :local-add-search="true"
        :title="$t('GroupsPage.accordion.subgroups.titleLower')"
        :empty-placeholder="
          $t('GroupsPage.accordion.subgroups.emptyPlaceholder')
        "
        @add-data="addSubgroup"
        @search="searchSubgroup"
        @delete="deleteSubgroup"
        @avatarSubmit="handlePeopleAddModal"
      ></DataTable>
    </DetailSectionAccordion>

    <DetailSectionAccordion
      id="accordion-people"
      data-test-group-form-people
      type="people"
      :title="$t('GroupsPage.accordion.people.title')"
      :opened="true"
    >
      <DataTable
        ref="peopleTable"
        class="data-table-margin-resized"
        :add-options="people.options"
        :headers="people.headers"
        :items="people.filteredItems"
        :selection-options="people.selectionOptions"
        :limit-pagination="limitPagination"
        :visible-empty="visibleEmpty"
        :items-per-page="7"
        :hide-option="true"
        :hide-header="true"
        :attach="true"
        :unique-selection-options="true"
        :title="$t('GroupsPage.accordion.people.titleLower')"
        :empty-placeholder="$t('GroupsPage.accordion.people.emptyPlaceholder')"
        @add-data="addPerson"
        @update-data="optionsSelected"
        @delete="deletePerson"
        @search="searchPerson"
        @search:item="handleSearchPerson"
        @focus:input="fetchPeople({ limit: 30, offset: 0 })"
      >
        <template #filter>
          <FilterSelection
            :show="rolesFilterOpened"
            :options="people.selectionOptions"
            @handleVision="handleRolesFilterVision"
            @handleOptionChange="handleRoleOptionChange"
          >
            <div class="filter-roles" @click="handleRolesFilterMenu">
              <i class="fi fi-rr-filter mr-1 filter-roles-icon-mt"></i>
              <span> {{ filterTitle }}</span>
              <i class="ml-1 filter-roles-icon-mt" :class="rolesFilterIcon"></i>
            </div>
          </FilterSelection>
        </template>
      </DataTable>
    </DetailSectionAccordion>

    <DetailSectionAccordion
      id="accordion-tree"
      data-test-group-form-hierarchy
      type="tree"
      :title="$t('GroupsPage.accordion.hierarchy.title')"
      :has-button="hierarchy.length > 0"
      :text-button="$t('GroupsPage.accordion.hierarchy.linkHierarchy')"
      :opened="true"
      @headerClick="openInsertHierarchyModalForm"
    >
      <div v-if="hierarchy.length > 0">
        <HierarchyBetweenElements
          :nodes="hierarchy"
          :has-bar="true"
          :has-remove="false"
          :has-clicked="true"
          @titleClicked="$emit('edit-node', $event)"
          @removeItem="deleteHierarchy"
        ></HierarchyBetweenElements>
      </div>

      <div v-else class="add-new-group__column--center">
        <div class="form-content-text">
          {{ $t('GroupsPage.accordion.hierarchy.empty.message') }}
        </div>

        <div class="linkHierarchy" @click="openInsertHierarchyModalForm()">
          <v-icon class="linkHierarchy" x-small left> fi fi-br-plus</v-icon>

          <span class="form-content-text linkHierarchy">
            {{ $t('GroupsPage.accordion.hierarchy.empty.link') }}
          </span>
        </div>
      </div>
    </DetailSectionAccordion>

    <InsertModal
      ref="insertModal"
      :ignored-nodes="subgroups?.items"
      @linkSelected="setParentGroup($event)"
    ></InsertModal>
  </div>
</template>

<script>
import FilterSelection from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Partials/FilterSelection/FilterSelection.vue'
import UploadIMG from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/Partials/UploadIMG/UploadIMG.vue'
import HierarchyBetweenElements from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/HierarchyBetweenElements/HierarchyBetweenElements.vue'
import PeopleViewModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/Partials/PeopleViewModal/PeopleViewModal.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import InsertModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/InsertModal/InsertModal.vue'
import ModalMoveSubgroup from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/ModalMoveSubgroup/ModalMoveSubgroup.vue'

import { getPeople } from '@/services/people'
import {
  getGroupsTree,
  addGroup,
  getGroupsPeople,
  getHierarchyById,
} from '@/services/groups'
import { getGroupsRoles } from '@/services/papers'

import _colors from '@/styles/abstracts/_colors.module.scss'

let _self = null

export default {
  name: 'AddNewGroup',
  components: {
    FilterSelection,
    UploadIMG,
    HierarchyBetweenElements,
    PeopleViewModal,
    DataTable,
    InsertModal,
    ModalMoveSubgroup,
  },
  props: {
    handleOpenQuickRegister: {
      type: Function,
    },
  },
  data() {
    return {
      subgroupTemp: [],
      subgroupMoveName: '',
      visibleEmpty: false,
      countRolesAdded: 0,
      limitPagination: 0,
      limitPaginationSubgroup: 0,
      rolesSelected: [[], [], []],
      subgroupPeople: {
        options: [
          {
            value: 'people',
            label: this.$t('GroupsPage.accordion.people.addLabel'),
            type: 'listview',
            itemsf: [],
          },
        ],
        items: [],
        filteredItems: [],
        selectionOptions: [],
      },
      rolesFilterOpened: false,
      image: null,
      parentGroupID: null,
      hierarchyObjectTeste: {
        childrenCount: 73,
        code: 'GT0001',
        cycle: {
          id: '6014713145402f0e7b305da2',
          title: '2° Semestre de 2021',
        },
        entityClass: null,
        entityType: 'context',
        group: {
          id: null,
          image:
            'https://pbs.twimg.com/profile_images/652147288966479872/co4SZ_a2_400x400.jpg',
          name: 'Gerência de Marketing',
        },
        hierarchyOwners: [],
        id: '628bb18ca633d5059d7d45eb',
        parentEntityChildrenCount: 0,
        parentEntityID: null,
        permissions: [],
        title: 'Contexto',
      },
      hierarchy: [],
      statusOptions: this.$t('GroupsPage.addNewGroup.statusOptions'),
      rules: [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
      ],
      form: {
        name: '',
        code: '',
        status: '',
        image: '',
      },
      footerButtons: [
        {
          name: this.$t('GroupsPage.addNewGroup.cancelButtonLabel'),
          type: 'text',
          action: 'close',
        },
        {
          name: this.$t('GroupsPage.addNewGroup.addButtonLabel'),
          type: 'default',
          action: 'add',
        },
      ],
      subgroups: {
        headers: [
          {
            text: this.$t(
              'GroupsPage.accordion.subgroups.headers.subgroupName'
            ),
            value: 'subgroupName',
            type: 'entity-item',
          },
          {
            text: this.$t('GroupsPage.accordion.subgroups.headers.people'),
            value: 'people',
            type: 'avatar-group',
            align: 'start',
          },
          {
            text: this.$t('GroupsPage.accordion.subgroups.headers.subgroups'),
            value: 'subgroups',
            type: 'avatar-group',
            align: 'start',
          },
          {
            text: this.$t('GroupsPage.accordion.subgroups.headers.status'),
            value: 'status',
            type: 'text-status',
          },
          {
            text: this.$t('GroupsPage.accordion.subgroups.headers.actions'),
            value: 'actions',
            type: 'action-buttons',
            align: 'center',
          },
        ],
        options: [
          {
            value: 'subgroup',
            label: this.$t('PeoplePage.newPerson.groups.addGroup'),
            type: 'treeview',
            items: [],
          },
        ],
        items: [],
        filteredItems: [],
        selectionOptions: [],
      },
      people: {
        headers: [
          {
            text: this.$t('GroupsPage.accordion.people.headers.name'),
            value: 'name',
            type: 'entity-item',
          },
          {
            text: this.$t('GroupsPage.accordion.people.headers.engagement'),
            value: 'engagement',
            type: 'combo-selection',
          },
          {
            text: this.$t('GroupsPage.accordion.people.headers.growth'),
            value: 'growth',
            type: 'combo-selection',
          },
          {
            text: this.$t('GroupsPage.accordion.people.headers.performance'),
            value: 'performance',
            type: 'combo-selection',
          },
          {
            text: this.$t('GroupsPage.accordion.people.headers.status'),
            value: 'access',
            type: 'text-status',
          },
          {
            text: this.$t('GroupsPage.accordion.people.headers.actions'),
            value: 'actions',
            type: 'action-buttons',
            align: 'center',
          },
        ],
        options: [
          {
            value: 'people',
            label: this.$t('GroupsPage.accordion.people.addLabel'),
            type: 'listview',
            items: [],
          },
        ],
        items: [],
        filteredItems: [],
        selectionOptions: [],
      },
    }
  },
  computed: {
    filterTitle() {
      if (this.countRolesAdded === 1) {
        return this.$t('GroupsPage.accordion.people.filterRole', {
          num: this.countRolesAdded,
        })
      }
      if (this.countRolesAdded > 1) {
        return this.$t('GroupsPage.accordion.people.filterNRoles', {
          num: this.countRolesAdded,
        })
      }
      return this.$t('GroupsPage.accordion.people.filterAllRoles')
    },
    rolesFilterIcon() {
      if (this.rolesFilterOpened) {
        return 'fi-br-caret-up'
      }
      return 'fi-br-caret-down'
    },
    _statusColor: function () {
      const isActive = this.form.status === this.statusOptions[0]
      return isActive ? _colors.greenColor : _colors.neutral5
    },
  },
  watch: {
    rolesFilterOpened(newValue) {
      if (!newValue) {
        this.filterPeople(this.rolesSelected, this.countRolesAdded)
        this.filterSubgroupPeople(this.rolesSelected, this.countRolesAdded)
      }
    },
    parentGroupID: function (v) {
      if (!v) return (this.hierarchy = [])

      getHierarchyById(v, { flat: true })
        .then(({ data }) => (this.hierarchy = data))
        .catch(err =>
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )
        )
    },
    'subgroups.items': function (subgroups) {
      if (subgroups.some(s => this.hierarchy.some(h => s.id === h.id)))
        return (this.hierarchy = [])
    },
    '$route.query': function (query) {
      const { parentGroupID } = query

      if (parentGroupID) this.parentGroupID = parentGroupID
    },
  },
  beforeMount() {
    _self = this
  },
  async mounted() {
    this.parentGroupID = this.$route.query.parentGroupID

    if (this.checkAddURL()) {
      this.handleOpenQuickRegister('addGroup')
    }
    this.fetchGroupsTree()
    this.fetchPeople({ limit: 30, offset: 0 })
    this.fetchGroupRoles()
  },
  methods: {
    handleRoleOptionChange(role, id) {
      if (this.rolesSelected[id].includes(role)) {
        this.countRolesAdded--
        this.rolesSelected[id] = this.rolesSelected[id].filter(
          item => item.id !== role.id
        )
      } else {
        this.countRolesAdded++
        this.rolesSelected[id].push(role)
      }
    },
    hasAnyRole(item, roles) {
      const found = []
      const moduleName = {
        0: 'engagement',
        1: 'growth',
        2: 'performance',
      }
      roles.forEach((role, id) => {
        const idArray = item[moduleName[id]].map(item => item.id)
        let arr = []
        role.forEach(r => {
          arr.push(idArray.includes(r.id))
        })
        found.push(arr.includes(true))
      })
      return found.includes(true)
    },
    filterPeople(roles, countRolesAdded) {
      const newItems = this.people.items.slice(0, this.people.items.length)
      if (countRolesAdded === 0) {
        this.people.filteredItems = newItems
      } else {
        this.people.filteredItems = this.people.items.filter(item => {
          return this.hasAnyRole(item, roles)
        })
      }
    },
    filterSubgroupPeople(roles, countRolesAdded) {
      const newItems = this.subgroupPeople.items.slice(
        0,
        this.subgroupPeople.items.length
      )
      if (countRolesAdded === 0) {
        this.subgroupPeople.filteredItems = newItems
      } else {
        this.subgroupPeople.filteredItems = this.subgroupPeople.items.filter(
          item => {
            return this.hasAnyRole(item, roles)
          }
        )
      }
    },
    handleRolesFilterVision(value) {
      this.rolesFilterOpened = value
    },
    handleRolesFilterMenu() {
      this.rolesFilterOpened = !this.rolesFilterOpened
    },
    handleInput(value, inputName) {
      this.form[inputName] = value
    },
    addSubgroupPerson(person) {
      const personAlreadyAdded = this.subgroupPeople.items.find(
        item => item.id === person.id
      )
      if (personAlreadyAdded) return
      const row = {
        ...person,
        id: person.id,
        name: person.name,
        engagement: person.engagement ? person.engagement : [],
        growth: person.growth ? person.growth : [],
        performance: person.performance ? person.performance : [],
        status: this.defineStatus(person.inactive),
        actions: ['delete'],
      }
      this.subgroupPeople.filteredItems.unshift(row)
      this.subgroupPeople.items.unshift(row)
    },
    addPerson(person) {
      this.limitPagination++
      const personAlreadyAdded = this.people.items.find(
        item => item.id === person.id
      )
      if (personAlreadyAdded) return
      const row = {
        ...person,
        id: person.id,
        name: person.name,
        groups: person.groups.length,
        engagement: [],
        growth: [],
        performance: [],
        status: this.defineStatus(person.inactive),
        actions: ['delete'],
      }
      this.people.filteredItems.unshift(row)
      this.people.items.unshift(row)
    },
    async searchSubgroupPerson(search) {
      if (!search) return
      if (search.name === '') {
        this.subgroupPeople.filteredItems = this.subgroupPeople.items
      } else {
        this.subgroupPeople.filteredItems = this.subgroupPeople.items.filter(
          item => {
            return item.name.title
              .toLowerCase()
              .includes(search.name.toLowerCase())
          }
        )
      }
    },
    async searchPerson(search) {
      if (!search) return
      if (search.name === '') {
        this.people.filteredItems = this.people.items
      } else {
        this.people.filteredItems = this.people.items.filter(item => {
          return item.name.title
            .toLowerCase()
            .includes(search.name.toLowerCase())
        })
      }
    },
    defineStatus(inactive) {
      const statusOptions = this.$t('GroupsPage.addNewGroup.statusOptions')
      return {
        label: inactive ? statusOptions[1] : statusOptions[0],
        status: inactive ? 'inactive' : 'active',
        color: inactive ? _colors.neutral5 : _colors.greenColor,
      }
    },
    deleteSubgroupPerson(person) {
      this.subgroupPeople.items = this.subgroupPeople.items.filter(item => {
        return item.id !== person.id
      })
      this.subgroupPeople.filteredItems =
        this.subgroupPeople.filteredItems.filter(item => {
          return item.id !== person.id
        })
    },
    deletePerson(person) {
      this.limitPagination--
      this.people.items = this.people.items.filter(item => {
        return item.id !== person.id
      })
      this.people.filteredItems = this.people.filteredItems.filter(item => {
        return item.id !== person.id
      })
    },
    async insertSubgroup(subgroup) {
      const peopleArray = []
      const subgroupsArray = []
      const res = await getGroupsPeople(subgroup.id)
      for (let i = 0; i < res.data.length; i++) {
        peopleArray.push({
          id: res.data[i].person.id,
          image: res.data[i].person.photo,
          text: res.data[i].person.name,
          listPeople: res.data[i],
        })
      }

      if (subgroup.children) {
        for (let x = 0; x < subgroup.children.length; x++) {
          subgroupsArray.push({
            id: subgroup.children[x].id,
            image: subgroup.children[x].image,
            text: subgroup.children[x].label,
          })
        }
      }

      const row = {
        id: subgroup.id,
        subgroupName: {
          id: subgroup.id,
          title: subgroup.label,
          image: subgroup.image,
        },
        status: this.defineStatus(subgroup.inactive),
        people: peopleArray,
        subgroups: subgroupsArray,
        actions: ['delete'],
      }
      this.subgroups.filteredItems.unshift(row)
      this.subgroups.items.unshift(row)
    },
    async addSubgroup(subgroup) {
      this.limitPaginationSubgroup++
      const subgroupAlreadyAdded = this.subgroups.items.find(
        item => item.id === subgroup.id
      )
      if (subgroupAlreadyAdded) return

      this.subgroupTemp = subgroup

      if (subgroup.parentGroupID != null) {
        this.subgroupMoveName = subgroup.label
        this.$refs.modalMoveSubgroup.handleDialog()
      } else {
        this.insertSubgroup(subgroup)
      }
    },
    async searchSubgroup(search) {
      if (!search) return
      if (search.name === '') {
        this.subgroups.filteredItems = this.subgroups.items
      } else {
        this.subgroups.filteredItems = this.subgroups.items.filter(item => {
          return item.subgroupName.title
            .toLowerCase()
            .includes(search.name.toLowerCase())
        })
      }
    },
    deleteSubgroup(group) {
      this.limitPaginationSubgroup--
      this.subgroups.items = this.subgroups.items.filter(item => {
        return item.id !== group.id
      })
      this.subgroups.filteredItems = this.subgroups.filteredItems.filter(
        item => {
          return item.id !== group.id
        }
      )
    },
    optionsSelected(options) {
      this.people.items.map(item => {
        const moduleName = {
          0: 'engagement',
          1: 'growth',
          2: 'performance',
        }[options.index]
        if (options.id === item.id) {
          item[moduleName] = options.values
        }
        return item
      })
      this.people.filteredItems.map(item => {
        const moduleName = {
          0: 'engagement',
          1: 'growth',
          2: 'performance',
        }[options.index]
        if (options.id === item.id) {
          item[moduleName] = options.values
        }
        return item
      })
    },
    optionsSelectedSubPeople(options) {
      this.subgroupPeople.items.map(item => {
        const moduleName = {
          0: 'engagement',
          1: 'growth',
          2: 'performance',
        }[options.index]
        if (options.id === item.id) {
          item[moduleName] = options.values
        }
        return item
      })
      this.subgroupPeople.filteredItems.map(item => {
        const moduleName = {
          0: 'engagement',
          1: 'growth',
          2: 'performance',
        }[options.index]
        if (options.id === item.id) {
          item[moduleName] = options.values
        }
        return item
      })
    },
    handlePeopleAddModal(people) {
      for (let i = 0; i < people.length; i++) {
        const row = {
          id: people[i].listPeople.person.id,
          label: people[i].listPeople.person.name,
          name: {
            image: people[i].listPeople.person.photo,
            title: people[i].listPeople.person.name,
            subtitle: people[i].listPeople.person.email,
          },
          permissionGroups: [],
          photo: people[i].listPeople.person.photo,
          email: people[i].listPeople.person.email,
          status: people[i].listPeople.person.status,
          statusColor: '',
          growth: people[i].listPeople.growth,
          performance: people[i].listPeople.performance,
          engagement: people[i].listPeople.engagement,
          access: {
            id: people[i].listPeople.person.status,
            label: this.$t(
              `GroupsPage.viewGroup.about.status.${people[i].listPeople.person.status}`
            ),
            status: people[i].listPeople.person.status,
          },
          actions: ['delete'],
        }

        this.addSubgroupPerson(row)
      }

      this.$refs.peopleViewModal.handleOpenDialog()
    },
    async fetchGroupsTree() {
      const { data } = await getGroupsTree()
      this.subgroups.options[0].items = data.filter(e => e.id)
    },
    async fetchPeople(params) {
      let { data } = await getPeople({ ...params, showInactive: true })

      const statusMap = {
        confirmed: {
          label: this.$t('PeoplePage.confirmedLabel'),
          color: _colors.greenColor,
        },
        invited: {
          label: this.$t('PeoplePage.guestLabel'),
          color: _colors.secondary,
        },
        inactive: {
          label: this.$t('PeoplePage.inactiveLabel'),
          color: _colors.neutral5,
        },
      }

      data = data.map(people => {
        const statusInfo = statusMap[people.status] || {
          label: '--',
          color: '',
        }

        return {
          ...people,
          label: people.name,
          inactive: people.status === 'inactive',
          name: {
            image: people.photo,
            title: people.name,
            subtitle: people.email,
          },
          access: {
            id: people.status,
            ...statusInfo,
            status: people.status,
          },
        }
      })
      this.people.options[0].items = data
      this.subgroupPeople.options[0].items = data
    },
    validate() {
      const inputName = !this.$refs['InputName'].validate()
      const statusField = this.$refs['statusField'].$refs.SingleSelection.valid
      this.$refs['statusField'].$refs.SingleSelection.hasFocused = !statusField

      if (!statusField || !inputName) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
        return
      }
      return true
    },
    async handleSubmit() {
      if (!_self.validate()) return
      const statusOptions = _self.$t('GroupsPage.addNewGroup.statusOptions')
      const body = {
        name: _self.form.name,
        image: _self.form.image === '' ? null : _self.form.image,
        inactive: _self.form.status === statusOptions[1],
        parentGroupID: _self.parentGroupID,
        subgroupIDs: _self.subgroups.items.map(item => item.id),
        people: _self.people.items
          .map(item => {
            return {
              person: {
                id: item.id,
              },
              engagement: item.engagement,
              growth: item.growth,
              performance: item.performance,
            }
          })
          .filter(
            e => e.engagement.length || e.growth.length || e.performance.length
          ),
      }

      _self.form.code &&
        _self.form.code.length &&
        (body.costCenter = _self.form.code)

      await addGroup(body)
        .then(({ data }) => {
          this.displayAlert('success', this.$t('GroupsPage.groupCreated'))

          data.peopleCount = data.people.length
          data.subgroupIDs = body.subgroupIDs
          if (!data.children) data.children = []
          if (!data.childrenCount)
            data.childrenCount = data.children.length || 0

          _self.$emit('success-create', data)
          _self.handleOpenQuickRegister('close')
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message ||
              this.$t('GroupsPage.groupCreatedError')
          )
        })
    },
    async fetchGroupRoles() {
      const payload = {
        limit: 500,
        offset: 0,
      }

      const { data } = await getGroupsRoles(payload)
      this.people.selectionOptions[0] = data.engagement
      this.people.selectionOptions[1] = data.growth
      this.people.selectionOptions[2] = data.performance

      this.subgroupPeople.selectionOptions[0] = data.engagement
      this.subgroupPeople.selectionOptions[1] = data.growth
      this.subgroupPeople.selectionOptions[2] = data.performance
    },
    openInsertHierarchyModalForm() {
      this.$refs.insertModal.toggleModal(true)
    },
    setParentGroup(parentGroup) {
      this.parentGroupID = parentGroup.id
    },
    deleteHierarchy() {
      if (this.hierarchy.length > 0) {
        for (let index = this.hierarchy.length; index > 0; index--) {
          this.hierarchy.pop()
        }
      }

      this.parentGroupID = null
    },
    handleMoveSubgroupModal() {
      this.$refs.modalMoveSubgroup.handleDialog()
    },
    async confirmMoveSubgroup() {
      this.insertSubgroup(this.subgroupTemp)
      this.handleMoveSubgroupModal()
    },
    checkAddURL() {
      const url = window.location.href

      if (url.match('/newGroup')) {
        return true
      }
    },

    handleSearchPerson(search) {
      if (!search || !search.length) return

      this.fetchPeople({ limit: 30, offset: 0, name: search })
    },

    displayAlert(type, description) {
      this.$emit('display-alert', { type, description })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
