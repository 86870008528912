<template>
  <v-form v-model="valid" ref="form">
    <div data-test-default-field-form class="default-field-form">
      <AlertBar ref="AlertBar" />
      <div>
        <v-row>
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="skeleton-label input"
          />

          <CustomInput
            v-else
            class="input"
            ref="label"
            :currentValue="defaultField.name.label"
            :mandatory="true"
            :viewMode="editMode"
            :readonly="editMode"
            :label="this.$t('viewDefaultFields.details.fieldName')"
          />
        </v-row>

        <v-row class="input select-type">
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="skeleton-label"
          />

          <ItemSelector
            v-else
            :inputConfig="{
              label: this.$t('viewDefaultFields.details.type'),
              showAvatar: false,
              showIcon: true,
              closeOnSelect: true,
              subtleMode: editMode,
            }"
            :menuConfig="{
              attach: true,
              showTabs: false,
            }"
            :menuOptions="_TypeOptions"
            :currentValue="{
              data: this.currentType,
              origin: 'type',
            }"
            :readonly="editMode"
            :persistent="false"
            :watchCurrent="true"
            :fillWidth="true"
            :multiple="false"
            :showElements="true"
            :local-search="true"
          />
        </v-row>
      </div>

      <v-divider></v-divider>

      <div id="view-modal-default-fields">
        <v-row>
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="skeleton-toggle input-bottom"
          />

          <div v-else class="input-bottom">
            {{ $t('viewDefaultFields.details.requiredField') }}
            <v-switch
              data-test-required-field
              v-model="requiredField"
              class="switch-toggle"
              inset
              :disabled="!_canEditListDefaultFields"
              @click="handleToggle"
            ></v-switch>
          </div>
        </v-row>
      </div>
    </div>
  </v-form>
</template>
<script>
import { updateEntityOkrSettings } from '@/services/papers'
import { management_performance_default_fields_edit } from '@/helpers/ability'
export default {
  name: 'defaultFieldForm',
  props: {
    showAlert: {
      type: Function,
    },
  },
  data() {
    return {
      entityType: null,
      entityClass: null,
      currentType: null,
      defaultField: null,
      valid: null,
      entityID: null,
      editMode: true,
      loading: false,
      typeSelected: [],
      defaultListItens: [],
      requiredField: false,
      form: null,
      type: [
        {
          id: 'field',
          label: this.$t('viewDefaultFields.type.field'),
          typeIcon: 'fi fi-rr-edit',
        },
        {
          id: 'table',
          label: this.$t('viewDefaultFields.type.table'),
          typeIcon: 'fi-rr-tablet',
        },
      ],
    }
  },
  computed: {
    _TypeOptions() {
      return [
        {
          value: 'type',
          label: this.$t('newCustomFields.details.type'),
          type: 'listview',
          items: this.type,
        },
      ]
    },
    _canEditListDefaultFields() {
      return this.$can('access', management_performance_default_fields_edit)
    },
  },
  methods: {
    handleToggle() {
      const labelMapping = {
        [this.$t('PerformanceTab.listDefaultFields.items.people')]: 'people',
        [this.$t('PerformanceTab.listDefaultFields.items.corresponsibles')]:
          'corresponsibles',
        [this.$t('PerformanceTab.listDefaultFields.items.owners')]: 'owners',
        [this.$t('PerformanceTab.listDefaultFields.items.description')]:
          'description',
        [this.$t(
          'PerformanceTab.listDefaultFields.items.iniciativeDescription'
        )]: 'description',
      }

      const label = this.defaultField.name.label
      const valor = labelMapping[label]

      if (this.requiredField) {
        if (this.defaultListItens.includes(valor)) return
        this.defaultListItens.push(valor)
      }

      if (!this.requiredField) {
        if (!this.defaultListItens.includes(valor)) return

        let indice = this.defaultListItens.indexOf(valor)
        if (indice != -1) {
          this.defaultListItens.splice(indice, 1)
        }
      }

      this.updateDefaultField()
    },
    async setStartValues() {
      this.loading = true
      this.defaultField = this.$route.params.defaultField
      this.entityType = this.$route.params.entityType
      this.entityClass = this.$route.params.entityClass
      this.entityID = this.$route.params.id
      this.defaultListItens =
        this.$route.params.defaultField?.entity?.requiredFields
    },
    setCurrentType() {
      if (
        this.defaultField.type.label === this.$t('viewDefaultFields.type.table')
      ) {
        this.currentType = [this.type[1]]
        return
      }

      this.currentType = [this.type[0]]
    },
    setCurrenteRequerid() {
      if (
        this.defaultField.required.label ===
        this.$t(`PerformanceTab.listDefaultFields.requiredField.yes`)
      ) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
      this.loading = false
    },
    async updateDefaultField() {
      let payload = this.defaultField.entity
      delete payload.id
      payload.requiredFields = this.defaultListItens

      const newPayload = {
        ...payload,
        ...(!!payload?.suggestedCycle?.id && {
          suggestedCycle: { id: payload.suggestedCycle.id },
        }),
      }

      await updateEntityOkrSettings(this.entityID, newPayload)
        .then(() => {
          this.showAlert({
            statusAlert: 'success',
            title: this.$t('viewDefaultFields.alert.messageSuccessUpdate'),
          })
        })
        .catch(error => {
          this.showAlert({
            statusAlert: 'error',
            title: this.$t('viewDefaultFields.alert.messageErrorUpdate'),
            messageAlert: error?.response?.data?.message,
          })
        })
    },
  },
  async beforeMount() {
    await this.setStartValues()
    await this.setCurrentType()
    await this.setCurrenteRequerid()
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
