<template>
  <Modal
    data-test-modal-permissions-page-edit-entity-role
    ref="modal"
    :title="$t('PermissionsPage.AddNewEntityRole.title')"
    :confirmButton="$t('PermissionsPage.AddNewEntityRole.submit')"
    :hasButtons="true"
    width="39.625rem"
    @confirmed="confirmed"
  >
    <template v-slot:no-tab="{ dialogState }">
      <v-form
        data-test-form-edit-entity-role
        v-if="dialogState"
        ref="form"
        v-model="valid"
        class="dropdown-content"
        @reset="resetForm"
      >
        <RoleName ref="roleName" @setRoleName="setRoleName" />
        <EntityType ref="entityType" @setEntityType="setEntityType" />
        <ActiveCycles ref="activeCycles" @setActiveCycle="setActiveCycle" />
      </v-form>
    </template>
    <template v-slot:footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        @click="cancelEvent"
        tabindex="1"
      >
        <span v-text="$t('Modal.buttonCancel')" />
      </v-btn>

      <v-btn
        data-test-add-entity-role
        class="button-confirm"
        text
        :loading="loading"
        @click="confirmedEvent"
        tabindex="2"
      >
        <span v-text="$t('PermissionsPage.AddNewEntityRole.submit')" />
      </v-btn>
    </template>
  </Modal>
</template>
<script>
import RoleName from './Partials/RoleName/RoleName.vue'
import EntityType from './Partials/EntityType/EntityType.vue'
import ActiveCycles from './Partials/ActiveCycles/ActiveCycles.vue'

import { createEntityPaper } from '@/services/papers'
import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'
export default {
  name: 'AddNewEntityRole',
  components: {
    EntityType,
    RoleName,
    ActiveCycles,
  },
  data() {
    return {
      loading: false,
      menuDrop: false,
      valid: false,
      form: {
        roleName: '',
        entityType: '',
        activeCycles: [],
      },
    }
  },
  methods: {
    handleOpenDialog() {
      if (this.$refs.modal) {
        this.$refs.modal.handleDialog(true)
      }
    },
    confirmed() {
      this.submit()
    },

    resetForm() {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()

      if (this.$refs?.roleName?.$refs?.roleName?.stateError) {
        this.$refs.roleName.$refs.roleName.stateError = false
      }
    },
    setRoleName(value) {
      this.form.roleName = value
    },
    setEntityType(value) {
      this.form.entityType = value
    },
    setActiveCycle(value) {
      this.form.activeCycles = value
    },
    deleteEntity() {
      this.$emit('deleteEntityRole')
    },
    submit() {
      if (!this.validate()) return
      this.addNewEntityRoleSubmit(this.form)
    },

    validate() {
      let valid = true

      let CustomInputReturn = this.ValidateCustomInputReturn(
        this.$refs['roleName'].$refs['roleName'].validate()
      )
      const value = this.$refs.form.validate()
      if (!value && value != undefined) valid = false

      if (valid && !CustomInputReturn) valid = false

      if (!valid) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }
      return valid
    },
    ValidateCustomInputReturn(result) {
      const isInvalid = result instanceof Node
      if (isInvalid) {
        return false
      }

      return result === undefined
    },
    async addNewEntityRoleSubmit(formEntityRole) {
      this.loading = true
      const cycles = formEntityRole.activeCycles.map(el => el.id)
      const { entityType, entityClass } = formEntityRole.entityType
      const payload = {
        label: formEntityRole.roleName,
        entityType: entityType,
        entityClass: entityClass,
        contexts: [],
        activeCycleIDs: cycles,
      }

      try {
        const newPaper = await createEntityPaper(payload)

        if (this.callback) this.callback(newPaper)

        const alertPayload = alertSuccessMessage(
          this.$t('PermissionsPage.AddNewEntityRole.alert.success')
        )

        this.loading = false
        this.form.roleName = ''
        this.form.entityType = ''
        this.form.activeCycles = []
        this.$refs.modal.dialog = false
        this.$emit('success', newPaper)
        this.displayAlert(alertPayload)
      } catch (error) {
        this.loading = false
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.AddNewEntityRole.alert.error'),
          error
        )

        this.displayAlert(alertPayload)
      }
    },
    displayAlert(alertPayload) {
      this.$emit('alert', alertPayload)
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
