const colors = {
  $primary: '#dd0041',
  $secondary: '#fdcc4a',
  '$bg-1': '#f3f6fb',
  '$bg-2': '#f3f3f3',
  $neutral: '#fff',
  '$neutral-1': '#2c3a4b',
  '$neutral-2': '#394452',
  '$neutral-3': '#545d69',
  '$neutral-4': '#545d69',
  '$neutral-5': '#858c94',
  '$neutral-6': '#a5abb3',
  '$neutral-7': '#dadee3',
  '$neutral-8': '#ebeef2',
  '$neutral-9': '#f4f6f9',
  '$neutral-10': '#f4f6f9',
  '$neutral-11': '#dadada',
  '$red-color': '#e71616',
  '$red-1': '#ed3126',
  '$red-2': '#ef5d5d',
  '$red-5': '#ffe6e6',
  '$red-6': '#a91111',
  '$red-7': '#fee6e6',
  '$red-lighten-1': '#fddddd',
  '$pink-1': '#fc4a69',
  '$pink-2': '#ff4560',
  '$yellow-1': '#fbad17',
  '$yellow-2': '#ffba33',
  '$yellow-lighten-1': '#fffdd6',
  '$yellow-3': '#ffd953',
  '$yellow-4': '#ffc350',
  '$yellow-5': '#f6931e',
  '$yellow-6': '#feb019',
  '$orange-color': '#fb9217',
  '$orange-color-2': '#ffb55e',
  '$orange-3': '#f9ab63',
  '$green-color': '#0ab057',
  '$green-lighten-1': '#e6ffe6',
  '$green-1': '#12c130',
  '$green-2': '#08864a',
  '$green-3': '#19ab4f',
  '$green-4': '#027932',
  '$green-5': '#00e396',
  '$green-6': '#e6fee6',
  '$blue-color': '#2392b5',
  '$blue-2': '#62dfc9',
  '$blue-1': '#1e52d9',
  '$blue-3': '#17a2b8',
  '$blue-4': '#008ffb',
  '$blue-6': '#f2f3fd',
  '$blue-7': '#6390f9',
  '$gray-color': '#ebeef2',
  '$gray-2': '#9e9e9e',
  '$gray-color-2': '#eee',
  '$gray-color-3': '#6d7580',
  '$gray-color-4': '#ddd',
  '$gray-color-5': '#f2f2f2',
  '$shadow-1': '#0000000d',
  '$shadow-2': '#0908080a',
  '$shadow-3': '#ffffff4c',
  '$shadow-4': '#0000001d',
  '$green-success-background': '#dafce0',
  '$red-error-background': '#fcdada',
  '$status-success': '#287d3c',
  '$status-error': '#da1414',
  '$status-neutral': '#a5abb3',
  $violet: '#775dd0',
  '$purple-1': '#9363f9',
}

export default {
  primary: colors['$primary'],
  redcolor: colors['$red-color'],
  redcolor2: colors['$red-2'],
  redcolor3: colors['$red-3'],
  redcolor4: colors['$red-4'],
  yellowcolor: colors['$yellow-1'],
  greencolor: colors['$green-color'],
  graycolor: colors['$gray-color'],
  greencolor1: colors['$green-1'],
  greencolor2: colors['$green-2'],
  greencolor3: colors['$green-3'],
  greencolor4: colors['$green-4'],
  yellowcolor1: colors['$yellow-1'],
  yellowcolor2: colors['$yellow-2'],
  yellowcolor3: colors['$yellow-3'],
  yellowcolor4: colors['$yellow-4'],
  yellowcolor5: colors['$yellow-5'],
  orangecolor: colors['$orange-color'],
  orangecolor3: colors['$orange-3'],
  bluecolor: colors['$blue-color'],
  bluecolor7: colors['$blue-7'],
  bluecolor2: colors['$blue-2'],
  secondary: colors['$secondary'],
  neutral: colors['$neutral'],
  neutral1: colors['$neutral-1'],
  neutral2: colors['$neutral-2'],
  neutral3: colors['$neutral-3'],
  neutral5: colors['$neutral-5'],
  neutral6: colors['$neutral-6'],
  neutral8: colors['$neutral-8'],
  neutral11: colors['$neutral-11'],
  bluecolor3: colors['$blue-3'],
  graycolor2: colors['$gray-2'],
  statuserror: colors['$status-error'],
  bluecolor4: colors['$blue-4'],
  greencolor5: colors['$green-5'],
  yellowcolor6: colors['$yellow-6'],
  redcolor6: colors['$red-6'],
  violet: colors['$violet'],
  purple1: colors['$purple-1'],
  pinkcolor2: colors['$pink-2'],
}
