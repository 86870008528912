<template>
  <div
    class="editor"
    data-test-editor
    :class="{ focused, 'focused-out': !focused }"
    v-click-outside="handleFocusOut"
  >
    <div class="editor-header" v-if="focused">
      <button
        v-for="({ title, action, isActive, icon }, index) in menuItems"
        :key="`editor-header-item-${index}`"
        class="menu-item"
        :class="{ 'is-active': isActive ? isActive() : null }"
        @click="action"
        :title="title"
      >
        <svg class="remix">
          <use :xlink:href="`${remixIconUrl}#ri-${icon}`" />
        </svg>
      </button>
    </div>

    <ReadMore v-if="editor && !focused" custom-class="editor-over-flow-content">
      <editor-content
        class="editor-content"
        :class="{ 'editor-empty-content': isValueEmpty }"
        :editor="editor"
        @focus="handleFocus"
      />
    </ReadMore>
    <editor-content
      v-else
      class="editor-content"
      :class="{ 'editor-empty-content': isValueEmpty }"
      :editor="editor"
      @focus="handleFocus"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Underline } from '@tiptap/extension-underline'
import { TextAlign } from '@tiptap/extension-text-align'
import { Link } from '@tiptap/extension-link'
import remixIconUrl from '@/assets/remix/icon.symbol.svg'

const buildMenuItems = (editor, t) => [
  {
    icon: 'arrow-go-back-line',
    title: t('RichTextEditor.editorCommands.undo'),
    action: () => editor.chain().focus().undo().run(),
  },
  {
    icon: 'arrow-go-forward-line',
    title: t('RichTextEditor.editorCommands.redo'),
    action: () => editor.chain().focus().redo().run(),
  },
  {
    icon: 'bold',
    title: t('RichTextEditor.editorCommands.bold'),
    action: () => editor.chain().focus().toggleBold().run(),
    isActive: () => editor.isActive('bold'),
  },
  {
    icon: 'underline',
    title: t('RichTextEditor.editorCommands.underline'),
    action: () => editor.chain().focus().setUnderline().run(),
  },
  {
    icon: 'double-quotes-l',
    title: t('RichTextEditor.editorCommands.blockQuote'),
    action: () => editor.chain().focus().toggleBlockquote().run(),
    isActive: () => editor.isActive('blockquote'),
  },
  {
    icon: 'list-unordered',
    title: t('RichTextEditor.editorCommands.bulletList'),
    action: () => editor.chain().focus().toggleBulletList().run(),
    isActive: () => editor.isActive('bulletList'),
  },
  {
    icon: 'link',
    title: t('RichTextEditor.editorCommands.link'),
    action: () => {
      const previousUrl = editor.getAttributes('link').href
      const commandChain = editor.chain().focus().extendMarkRange('link')

      if (previousUrl) {
        commandChain.unsetLink().run()
        return
      }

      const url = window.prompt('URL', previousUrl)

      if (url === null) {
        return
      }

      if (url === '') {
        commandChain.unsetLink().run()
        return
      }

      commandChain.setLink({ href: url }).run()
    },
  },
  {
    icon: 'align-left',
    title: t('RichTextEditor.editorCommands.alignLeft'),
    action: () => editor.commands.setTextAlign('left'),
  },
  {
    icon: 'align-center',
    title: t('RichTextEditor.editorCommands.alignCenter'),
    action: () => editor.commands.setTextAlign('center'),
  },
  {
    icon: 'align-right',
    title: t('RichTextEditor.editorCommands.alignRight'),
    action: () => editor.commands.setTextAlign('right'),
  },
]

export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    isValueEmpty() {
      return this.value === ''
    },
  },
  emits: ['change'],
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeHolder,
        }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Link.configure({
          autolink: false,
        }),
      ],
      onFocus: () => {
        this.handleFocus()
      },
      onUpdate: () => {
        this.$emit('change', this.editor.getHTML())
      },
    })
    this.menuItems = buildMenuItems(this.editor, name => this.$t(name))
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  data() {
    return {
      editor: null,
      menuItems: [],
      remixIconUrl,
      placeHolder: this.$t('RichTextEditor.placeHolder'),
      focused: false,
    }
  },
  methods: {
    handleFocus() {
      this.focused = true
      this.$emit('focus')
    },
    handleFocusOut() {
      if (!this.focused) return

      this.focused = false
      this.$emit('blur', this.editor.getHTML())
    },
  },
}
</script>

<style src="./style.scss" lang="scss"></style>
