<template>
  <div>
    <DetailSectionAccordion
      type="settings"
      :title="$t('measurementModelConfigSection.title')"
      :opened="true"
    >
      <div>
        <v-row class="custom-row">
          <span>{{ $t('measurementModelConfigSection.options.monthly') }}</span>

          <div>
            <v-tooltip bottom min-width="26rem">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <CustomSwitch
                    :value="form['monthly-sum']"
                    :readonly="true"
                    @input="setValue($event, 'monthly-sum')"
                  ></CustomSwitch>
                </div>
              </template>

              <div>
                <span>{{
                  $t('measurementModelConfigSection.tooltipText')
                }}</span>
              </div>
            </v-tooltip>
          </div>
        </v-row>

        <v-row class="custom-row">
          <span>{{
            $t('measurementModelConfigSection.options.accumulated')
          }}</span>
          <div>
            <CustomSwitch
              :readonly="readonly"
              :value="form['monthly-accrued']"
              @input="setValue($event, 'monthly-accrued')"
            ></CustomSwitch>
          </div>
        </v-row>

        <v-row class="custom-row">
          <span>{{ $t('measurementModelConfigSection.options.average') }}</span>
          <div>
            <CustomSwitch
              :readonly="readonly"
              :value="form.average"
              @input="setValue($event, 'average')"
            ></CustomSwitch>
          </div>
        </v-row>
      </div>
    </DetailSectionAccordion>
  </div>
</template>
<script>
export default {
  name: 'MeasurementModelConfig',
  props: {
    readonly: {
      type: Boolean,
      default: false,
      required: true,
    },
    settingsByEntity: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      interval: null,
      settingsForm: null,
      form: {
        average: true,
        'monthly-accrued': true,
        'monthly-sum': true, //somente leitura
      },
    }
  },
  watch: {
    settingsByEntity() {
      this.handleSettings()
    },
  },
  methods: {
    calcPayload() {
      const payload = structuredClone(this.settingsForm)
      delete payload.id

      const newPayload = {
        ...payload,
        ...(!!payload?.suggestedCycle?.id && {
          suggestedCycle: { id: payload.suggestedCycle.id },
        }),
        ...(!payload?.disabledMeasurements && {
          disabledMeasurements: [],
        }),
        ...(!payload?.disabledPurposes && {
          disabledPurposes: [],
        }),
      }

      if (!payload?.suggestedCycle?.id) newPayload.suggestedCycle = null

      newPayload.disabledMeasurements = Object.keys(this.form).filter(
        key => !this.form[key]
      )

      return newPayload
    },
    async handleSettings() {
      this.settingsForm = this.settingsByEntity

      this.settingsByEntity?.disabledMeasurements?.forEach(measurement => {
        if (Object.prototype.hasOwnProperty.call(this.form, measurement)) {
          this.form[measurement] = false
        }
      })
    },
    setValue(value, field) {
      this.form[field] = value

      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.submit()
      }, 300)
    },
    async submit() {
      const payload = this.calcPayload()
      this.$emit('submit', payload)
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
