<template>
  <Modal
    data-test-metadata-group-order-modal
    ref="modal"
    :title="$t('orderMetadataGroup.title')"
    :hasButtons="true"
    width="690px"
    @cancel="canceled"
    @confirmed="confirmed"
  >
    <template v-slot:no-tab="{ dialogState }">
      <div class="modal-content" v-if="dialogState">
        <DataTable
          data-test-metadata-group-form-order
          :title="$t('PerformanceTab.ListMetadataGroups.table.title')"
          :fixedHeader="true"
          :hideHeader="true"
          :hideOption="true"
          :hideSearch="true"
          :rolesReadonly="true"
          :enableDraggable="true"
          :canDrag="true"
          :emptyPlaceholder="$t('orderMetadataGroup.empty')"
          :items="_FormMetadataGroupsItems"
          :loading="loading"
          :skeleton-lines="skeletonLines"
          :headers="tableHeaders"
          :itemsPerPage="-1"
          :enablePagination="false"
          @get-data="getOptions()"
          @update:table-order="handleOrderMetadata($event)"
        ></DataTable>
      </div>
    </template>

    <template v-slot:footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        @click="cancelEvent"
        tabindex="1"
      >
        <span v-text="$t('Modal.buttonCancel')" />
      </v-btn>

      <v-btn
        data-test-confirm-metadata-group-order
        class="button-confirm"
        text
        :loading="formIsAnimating"
        @click="confirmedEvent"
        tabindex="2"
      >
        <span v-text="$t('orderMetadataGroup.submit')" />
      </v-btn>
    </template>
  </Modal>
</template>

<script>
import DataTable from '@/components/DataTable/DataTable.vue'

import {
  getMetadataGroups,
  putMetadataGroupsPositions,
} from '@/services/metadata'

import { debounce } from 'lodash'

export default {
  name: 'OrderModal',

  props: {
    skeletonLines: {
      type: Number,
      default: 4,
    },
  },

  components: {
    DataTable,
  },
  data() {
    return {
      handleConfirmed: debounce(async function (resetDialogCallback) {
        await this.updateMetadataGroupsPositions().then(status => {
          this.formIsAnimating = false

          if (status === 'success') {
            this.$emit('success')
            resetDialogCallback()
          }
        })
      }, 500),

      entityType: null,
      entityClass: null,

      loading: false,
      formIsAnimating: false,

      tableHeaders: [
        {
          text: this.$t('PerformanceTab.ListMetadataGroups.table.header.name'),
          value: 'name',
          type: 'text-status',
          naturalizeIndex: true,
        },
      ],

      form: {
        metadataGroups: [],
      },
    }
  },

  computed: {
    _FormMetadataGroupsItems() {
      const items = this.form.metadataGroups
      return this.calcMetadataGroupsItensToTable(items)
    },
  },

  methods: {
    resetModal() {
      this.formIsAnimating = false
      this.loading = true
      this.$set(this.form, 'metadataGroups', [])
    },

    handleOpenDialog() {
      this.resetModal()

      if (this.$refs.modal) {
        this.$refs.modal.handleDialog(true)
      }
    },

    setEntity(
      entityType = this.$route.params.entityType,
      entityClass = this.$route.params.entityClass
    ) {
      this.entityType = entityType
      this.entityClass = entityClass
    },

    calcMetadataGroupsItensToTable(itens) {
      if (!Array.isArray(itens)) {
        return []
      }

      return itens.map(item => {
        return {
          ...item,
          position: item?.position || 0,
          name: {
            label: item.label,
            status: '',
          },
        }
      })
    },

    handleOrderMetadata(payload = []) {
      function calcOrder(metadataGroups) {
        return metadataGroups
          .map((metadata, index) => `${index}_${metadata.id}`)
          .join(',')
      }

      const payloadPositions = calcOrder(payload)
      const metadataGroupsPositions = calcOrder(this.form.metadataGroups)

      if (payloadPositions === metadataGroupsPositions) {
        return
      }

      this.form.metadataGroups = payload.map((metadata, index) => {
        return {
          ...metadata,
          position: index,
        }
      })
    },

    handleAlert(payload) {
      this.$emit('display-alert', payload)
    },

    async getOptions() {
      this.resetModal()

      await getMetadataGroups({
        limit: 500,
        offset: 0,
        entityType: this.entityType,
        entityClass: this.entityClass,
      })
        .then(({ data }) => {
          this.$set(this.form, 'metadataGroups', data)
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t('orderMetadataGroup.alert.get.error'),
            messageAlert: error?.response?.data?.message,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    async confirmed(resetDialogCallback) {
      this.formIsAnimating = true
      this.handleConfirmed(resetDialogCallback)
    },

    canceled() {
      this.handleConfirmed.cancel()
      this.formIsAnimating = false
    },

    async updateMetadataGroupsPositions() {
      const payload = {
        entityType: this.entityType,
        entityClass: this.entityClass,
        metadataGroups: this.form.metadataGroups.map((metadata, index) => ({
          id: metadata.id,
          position: index,
        })),
      }

      return await putMetadataGroupsPositions(payload)
        .then(() => {
          this.handleAlert({
            statusAlert: 'success',
            title: this.$t('orderMetadataGroup.alert.put.success'),
          })

          return 'success'
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t('orderMetadataGroup.alert.put.error'),
            messageAlert: error?.response?.data?.message,
          })

          return 'error'
        })
    },
  },

  beforeMount() {
    this.setEntity()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
