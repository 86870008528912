import i18n from '@/plugins/i18n'

export function formatPermissionsApiToComponent(
  modules,
  currentPermissions = null
) {
  const merge = !!currentPermissions

  modules = modules.filter(modulo => modulo?.contexts?.length)

  return modules.map(modulo => {
    const contexts = modulo.contexts.map(context => {
      const permissions = context.permissions.map(permission => {
        const label = permission.label || getTranslate(permission.permission)

        const available = merge
          ? mergePermission(currentPermissions, permission.permission)
          : permission.status || false

        const formattedPermission = {
          label: label,
          available: available,
          permission: permission.permission,
        }
        return formattedPermission
      })

      const formattedContext = {
        label: context.label || getTranslate(context.context),
        context: context.context,
        permissions: permissions,
      }
      return formattedContext
    })
    const formattedModule = {
      module: modulo.module,
      label: modulo.label || getTranslate(modulo.module),
      contexts: contexts,
    }
    return formattedModule
  })
}

export function formatPermissionsComponentToApi(modules) {
  modules = modules.filter(modulo => modulo?.contexts?.length)

  return (modules = modules.map(modulo => {
    const contexts = modulo.contexts.map(context => {
      const permissions = context.permissions.map(permission => {
        const formattedPermission = {
          active: permission.available,
          permission: permission.permission,
        }
        return formattedPermission
      })

      const formattedContext = {
        context: context.context,
        permissions: permissions,
      }
      return formattedContext
    })
    const formattedModule = {
      module: modulo.module,
      contexts: contexts,
    }
    return formattedModule
  }))
}

export function mergePermission(modulo, target) {
  let active = false

  modulo.contexts.forEach(context => {
    context.permissions.forEach(permission => {
      if (permission.permission === target) active = permission.active
    })
  })

  return active
}

export function getTranslate(permission) {
  const name = permission.replaceAll('.', '-')
  const translation = `ViewObjective.historyAlteration.keys.${name}`

  return i18n.t(translation)
}
