<template>
  <CardWrapper
    :title="$t('IntegrationPage.title')"
    :subtitle="$t('IntegrationPage.subtitle')"
    :helper-props="{
      videoType: 'IntegrationsPage',
      short: true,
      hasVideo: false,
    }"
    data-test-integrations-list
  >
    <template #content>
      <section
        v-for="integration of integrations"
        :key="integration.id"
        :data-test-integration-item="integration.id"
        class="integration-item"
        :class="{
          [integration.id]: true,
          'integration-has-workspaces': integration.workspaces.length,
        }"
      >
        <div class="integration-image">
          <v-img
            contain
            max-width="2.5rem"
            max-height="2.25rem"
            :src="`${CDN_BASE_URL}/images/integration/${integration.id}.svg`"
          ></v-img>

          <h2 v-text="$t(`IntegrationPage.integration.${integration.id}`)"></h2>
        </div>

        <div class="integration-description">
          <p
            class="integration-description--title"
            v-text="
              $t('IntegrationPage.integrations.title', {
                integration: $t(
                  `IntegrationPage.integrations.${integration.id}`
                ),
              })
            "
          ></p>

          <span class="integration-description--subtitle">
            <span
              class="integration-description--reminder"
              v-text="$t('IntegrationPage.integrations.reminder')"
            ></span>
            <span
              v-text="
                $t('IntegrationPage.integrations.description', {
                  integration: $t(
                    `IntegrationPage.integrations.${integration.id}`
                  ),
                })
              "
            ></span>
          </span>
        </div>

        <div class="integration-actions">
          <div
            v-if="integration.workspaces.length"
            class="integration-actions__status"
          >
            <span v-text="$t('IntegrationPage.integrations.enabled')"></span>
            <v-icon size="1rem">fi-sr-checkbox</v-icon>
          </div>

          <v-btn
            data-test-integration-item-view-details
            class="integration-actions__view-details"
            color="primary"
            elevation="0"
            :text="!!integration.workspaces.length"
            @click="handleIntegration(integration)"
          >
            <span v-text="$t('IntegrationPage.integrations.open')"></span>
            <v-icon size="1rem">fi-sr-arrow-small-right</v-icon>
          </v-btn>
        </div>
      </section>
    </template>
  </CardWrapper>
</template>

<script>
export default {
  name: 'IntegrationsList',
  props: {
    integrations: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleIntegration({ id: integrationID }) {
      this.$router.push({
        name: 'IntegrationView',
        params: { integrationID },
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
