<template>
  <div data-test-order-button class="order">
    <div
      v-if="isOrdenable"
      role="button"
      :aria-disabled="buttonIndex <= minimumPosition"
      :class="{ disabled: buttonIndex <= minimumPosition }"
      @click="moveUp(buttonIndex)"
      data-test-order-button-up
    >
      <v-icon
        data-test-order-button-chevron-up
        size="16"
        class="fi-br-angle-up"
      ></v-icon>
    </div>

    <span
      data-test-order-button-position
      class="order-position"
      v-text="_displayIndex"
    />

    <div
      v-if="isOrdenable"
      role="button"
      :aria-disabled="buttonIndex >= maximumPosition"
      :class="{ disabled: buttonIndex >= maximumPosition }"
      @click="moveDown(buttonIndex)"
      data-test-order-button-down
    >
      <v-icon
        data-test-order-button-chevron-down
        size="16"
        class="fi-br-angle-down"
      ></v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderButton',
  props: {
    item: {
      type: Object,
      required: true,
    },

    minimumPosition: {
      type: Number,
      default: 0,
    },

    buttonIndex: {
      type: Number,
      default: 0,
    },

    maximumPosition: {
      type: Number,
      default: 0,
    },

    isOrdenable: {
      type: Boolean,
      default: false,
    },

    naturalizeIndex: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _displayIndex() {
      return this.displayIndex(this.item)
    },
  },

  methods: {
    displayIndex(item) {
      let index = this.isOrdenable ? this.buttonIndex : item.position

      if (this.naturalizeIndex || this.isOrdenable) {
        index = index + 1
      }

      return `${index}º`
    },

    moveUp(index) {
      if (index > this.minimumPosition) {
        this.saveOrder({ oldIndex: index, newIndex: index - 1 })
      }
    },

    moveDown(index) {
      if (index < this.maximumPosition) {
        this.saveOrder({ oldIndex: index, newIndex: index + 1 })
      }
    },

    saveOrder(data) {
      this.$emit('update:order', data)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
