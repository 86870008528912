<template>
  <Modal
    data-test-modal-permissions-page-add-new-template
    ref="modal"
    :title="$t('PermissionsPage.addNewTemplate.title')"
    :confirmButton="$t('PermissionsPage.addNewTemplate.submit')"
    :hasButtons="true"
    width="39.625rem"
    @confirmed="confirmed"
  >
    <template v-slot:no-tab="{ dialogState }">
      <v-form
        data-test-form-add-new-template
        v-if="dialogState"
        ref="form"
        v-model="valid"
        class="dropdown-content"
        @reset="resetForm"
      >
        <div class="radio-buttons">
          <div class="radio-buttons-new">
            <input
              data-test-radio-button-new-template
              type="radio"
              id="new"
              value="new"
              v-model="newOrReplaceTemplate"
            />

            <label for="new" data-test-new-label>
              {{
                $t('PermissionsPage.addNewTemplate.radioButtons.newTemplate')
              }}
            </label>
          </div>

          <div v-if="newOrReplaceTemplate === 'new'" class="input-custom">
            <CustomInput
              ref="inputNewTemplate"
              data-test-name-input
              :label="$t('PermissionsPage.addNewTemplate.labelNewTemplate')"
              class="name-input"
              :currentValue="form.nameNewTemplate"
              :mandatory="true"
              type="text"
              @handleInput="handleNewTemplate"
            />
          </div>

          <br />

          <div class="radio-buttons-replace">
            <input
              data-test-radio-button-replace-template
              type="radio"
              id="replace"
              value="replace"
              v-model="newOrReplaceTemplate"
            />
            <label for="replace" data-test-replace-label>
              {{
                $t(
                  'PermissionsPage.addNewTemplate.radioButtons.replaceTemplate'
                )
              }}
            </label>
          </div>

          <div
            v-if="newOrReplaceTemplate === 'replace'"
            class="input-selector"
            data-test-item-selector-replace
          >
            <ItemSelector
              data-test-selector-replace-template
              ref="entityType"
              :inputConfig="{
                label: $t(
                  'PermissionsPage.addNewTemplate.labelReplaceTemplate'
                ),
                showAvatar: false,
                rules: rules,
                closeOnSelect: true,
              }"
              :menuConfig="{
                attach: false,
                showTabs: false,
              }"
              :menuOptions="_listTemplates"
              :fillWidth="true"
              :multiple="false"
              :showElements="true"
              :local-search="true"
              @update:item="handleTemplate($event)"
            />
          </div>
        </div>
      </v-form>
    </template>

    <template v-slot:footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        @click="cancelEvent"
        tabindex="1"
      >
        <span v-text="$t('Modal.buttonCancel')" />
      </v-btn>

      <v-btn
        data-test-add-template
        class="button-confirm"
        text
        :loading="loading"
        @click="confirmedEvent"
        tabindex="2"
      >
        <span v-text="$t('PermissionsPage.addNewTemplate.submit')" />
      </v-btn>
    </template>
  </Modal>
</template>
<script>
export default {
  name: 'AddNewTemplate',

  data() {
    return {
      loading: false,
      menuDrop: false,
      valid: false,
      newOrReplaceTemplate: 'new',
      form: {
        nameNewTemplate: '',
        replaceTemplate: null,
      },
      rules: [
        v =>
          (!!v && v.length > 0) ||
          this.$t('TextQuickEdit.validations.required'),
      ],
    }
  },

  props: {
    templateList: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    newOrReplaceTemplate() {
      if (this.newOrReplaceTemplate === 'new') {
        this.form.replaceTemplate = null
        return
      }

      this.form.nameNewTemplate = ''
    },
  },

  computed: {
    _listTemplates() {
      return [
        {
          value: 'entityTypes',
          type: 'listview',
          label: this.$t('PermissionsPage.AddNewEntityRole.typeEntity.title'),
          items: this.templateList.map(el => ({
            id: el.id,
            label: el.label,
          })),
        },
      ]
    },
  },
  methods: {
    handleOpenDialog() {
      if (this.$refs.modal) {
        this.form.nameNewTemplate = ''
        this.form.replaceTemplate = null
        this.$refs.modal.handleDialog(true)
      }
    },

    handleNewTemplate(event) {
      this.form.nameNewTemplate = event
    },

    handleTemplate(event) {
      const template = this.templateList.find(el => el.id === event.id)
      this.form.replaceTemplate = template
    },

    confirmed() {
      this.submit()
    },

    resetForm() {
      this.$refs.form.resetValidation()
      this.$refs.form.reset()

      if (this.$refs?.inputNewTemplate?.stateError) {
        this.$refs.inputNewTemplate.stateError = false
      }
    },

    submit() {
      if (
        this.newOrReplaceTemplate === 'new' &&
        this.$refs['inputNewTemplate'].validate()
      )
        return
      else if (!this.$refs.form.validate()) return

      const payload = {
        typeRequest: this.newOrReplaceTemplate,
        form: this.form,
      }
      this.$emit('submit', payload)
      this.close()
    },

    close() {
      this.$refs.modal.handleDialog(false)
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
