<template>
  <div data-app>
    <SideQuickRegister
      v-if="quickRegister"
      ref="quickRegister"
      :title="quickRegister.actionSelected.title"
      :image-title="quickRegister.actionSelected.image"
      :footer-buttons="quickRegister.footerButtons"
      :popover-options="quickRegister.popoverOptions"
      :show="quickRegister.show"
      @handleAction="handleAction"
    >
      <template #default="{ alert: callAlert, loading: callLoading }">
        <router-view
          ref="personForm"
          :handle-open-quick-register="handleOpenQuickRegister"
          @close="handleAction('close')"
          @loading="callLoading"
          @display-alert="handleAlert($event, callAlert)"
        ></router-view>
      </template>
    </SideQuickRegister>

    <AlertBar ref="AlertBar"></AlertBar>

    <Modal
      ref="modal"
      :title="$t('PeopleTab.modalDelete.Title')"
      width="30%"
      :has-tab="false"
    >
      <template #[`no-tab`]>
        <div class="modal-delete">
          <h1>
            {{ $t('PeopleTab.modalDelete.subtitle1') }}
            <a>{{ deleteUserName }}</a>
          </h1>
          <div class="modal-delete-subtitle">
            <h1>
              <a :style="{ color: 'red' }">{{
                $t('PeopleTab.modalDelete.rememberLabel')
              }}</a>
              {{ $t('PeopleTab.modalDelete.subtitle2') }}
            </h1>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn
            class="dialog-footer-btnCancel"
            depressed
            plain
            @click="handleDeleteModal()"
          >
            {{ $t('PeopleTab.modalDelete.btnCancel') }}
          </v-btn>
          <v-btn
            data-test-btn-delete-people
            class="dialog-footer-btn"
            depressed
            @click="deletePeople(personID)"
          >
            {{ $t('PeopleTab.modalDelete.btnDelete') }}
          </v-btn>
        </div>
      </template>
    </Modal>

    <v-card class="card">
      <div class="header">
        <div class="header-title" data-test-header-title>
          <h1>{{ $t('PeopleTab.title') }}</h1>
          <HowToBeginAndWatchAVideo
            video-type="listPeople"
            short
            :has-video="false"
          ></HowToBeginAndWatchAVideo>
        </div>
        <div class="header-subtitle">
          <h3>{{ $t('PeopleTab.subtitle') }}</h3>
        </div>
      </div>

      <v-divider></v-divider>

      <div>
        <DataTable
          v-if="show"
          id="DataTable"
          ref="dataTable"
          :headers="header"
          :items="items"
          :title="title"
          hide-header
          hide-option
          enable-pagination
          enable-pagination-limit-change
          :content-style="false"
          :initial-selected-items="itemsSelectedBeforeTheLastUpdate"
          :items-per-page="data.limit"
          :total-items="xcount"
          :loading="loading"
          :selection-mode="_selectionMode"
          :disable-selection-popover="false"
          :total-count-selection="limitPagination"
          @confirmFullSelection="confirmSelectedPeople()"
          @cancelFullSelection="cancelSelectedPeople()"
          @menu-options="showMenuAction"
          @search="search($event)"
          @edit="handleAction('viewPerson', $event.id, null, $event)"
          @updateList="handlePagination"
          @entity-submit="handleAction('viewPerson', $event)"
          @onClickSelecteAll="handleOnClickSelectAll"
          @onUpdateTable="handleOnUpdateTable"
          @onSelectEntities="handleOnSelectPeople"
        >
          <template #menu="{ position }">
            <MoreOptions
              class="moreOptions"
              :show="menuOpened && position === menuOpenedIndex"
              :dots="false"
              :options="_moreOptionsItens"
              :left-or-right="false"
              @handleClose="handleMenuClose"
              @handleAction="handleMenuAction($event, position)"
            ></MoreOptions>
          </template>
        </DataTable>

        <div v-else class="empty" data-test-empty-list-people>
          <div class="img">
            <v-img :src="require(`@/assets/people/img1.png`)"></v-img>
          </div>
          <div class="itens" data-test-empty-message>
            <h1>{{ $t('PeopleTab.emptySubtitle1') }}</h1>
            <a :href="$t('PeopleTab.HowPeopleWork')" target="_blank">{{
              $t('PeopleTab.emptySubtitle2')
            }}</a>
          </div>
          <div
            id="add-new-btn"
            class="add-new-btn-size add-new-btn-primary btn"
            @click="openSideQuickForm('addPerson')"
          >
            <v-icon dark small class="icon">mdi-plus</v-icon>
            {{ $t('PeopleTab.buttonTitle') }}
          </div>
        </div>
      </div>
    </v-card>

    <QuickTopBar
      :show="_showQuickTopBar"
      :entity-counter="_totalPeopleSelected"
      :actions="_quickTopBarActions"
      @cancel="closeQuickTopBar()"
    >
      <template #[`slot-permissionGroup`]="{ action }">
        <ConfigureGroupPermission
          :entities="selectedPeople"
          :action="action"
          :is-all-selected="peopleConfirmSelected"
          @alert="$refs.AlertBar.displayAlert($event)"
          @confirmed:reload="handleRefreshPeoplePage()"
        ></ConfigureGroupPermission>
      </template>
    </QuickTopBar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfigureGroupPermission from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/Partials/QuickTopBar/ConfigureGroupPermission/ConfigureGroupPermission.vue'
import _colors from '@/styles/abstracts/_colors.module.scss'

import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import {
  management_users,
  management_users_edit,
  management_users_delete,
  management_users_activate,
  management_users_inactivate,
  management_users_resent,
} from '@/helpers/ability'

import {
  deletePerson,
  changePersonStatus,
  resendPersonInvite,
} from '@/services/people/index.js'

export default {
  name: 'PeoplePage',
  components: { ConfigureGroupPermission },

  inject: ['closeSideQuickForm', 'openSideQuickForm'],

  props: {
    quickRegister: {
      type: Object,
    },
  },
  data() {
    return {
      menuOpened: false,
      peopleConfirmSelected: false,
      menuOpenedIndex: 0,
      menuIndex: 0,
      isActive: null,
      loading: true,
      alert: {
        model: false,
        statusAlert: '',
        messageAlert: '',
      },
      data: {
        limit: 20,
        offset: 0,
        name: null,
        showInactive: true,
      },
      xcount: 0,
      limitPagination: 20,
      param: {
        name: null,
        offset: 0,
        limit: 0,
        showInactive: true,
      },
      show: true,
      isStatusActive: null,
      selectionMode: true,
      personID: null,
      selectAllPeople: false,
      selectedPeople: [],
      itemSelectedStatus: '',
      itemsSelectedBeforeTheLastUpdate: [],
      arrayGroups: [],
      arrayGroupsPermissions: [],
      title: this.$t('PeopleTab.searchLabel'),
      icon: 'fi-rr-user',
      deleteUserName: '',
      header: [
        {
          text: this.$t('PeopleTab.headerTable.name'),
          value: 'name',
          type: 'entity-item',
          clickable: this.$can('access', management_users),
        },
        {
          text: this.$t('PeopleTab.headerTable.attribute'),
          value: 'attribute',
          type: 'chip-group',
        },
        {
          text: this.$t('PeopleTab.headerTable.groups'),
          value: 'groups',
          type: 'chip-group',
        },
        {
          text: this.$t('PeopleTab.headerTable.permissiongroups'),
          value: 'permissiongroups',
          type: 'chip-group',
        },
        {
          text: this.$t('PeopleTab.headerTable.roles'),
          value: 'occupation',
          type: 'chip-group',
        },
        {
          text: this.$t('PeopleTab.headerTable.status'),
          value: 'access',
          type: 'text-status',
        },
        {
          text: this.$t('PeopleTab.headerTable.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ],
      items: [],

      showQuickTopBar: false,
    }
  },

  computed: {
    ...mapGetters([
      'getfilterPeople',
      'getfilterPeopleCount',
      'getfilterPeopleEmpty',
      'getfilterPeopleParamsByName',
    ]),

    _canManagementUsersEdit() {
      return this.$can('access', management_users_edit)
    },
    _canManagementUsersDelete() {
      return this.$can('access', management_users_delete)
    },
    _canManagementUsersActivate() {
      return this.$can('access', management_users_activate)
    },
    _canManagementUsersInactivate() {
      return this.$can('access', management_users_inactivate)
    },
    _canManagementUsersResent() {
      return this.$can('access', management_users_resent)
    },

    _totalPeopleSelected() {
      return this.peopleConfirmSelected
        ? this.limitPagination
        : this.selectedPeople.length
    },
    _showQuickTopBar() {
      return this._totalPeopleSelected > 0 && !this.selectAllPeople
    },

    _moreOptionsItens() {
      const isInvitedStatus = this.itemSelectedStatus === 'invited'
      const isConfirmedStatus = this.itemSelectedStatus === 'confirmed'
      const isInactiveStatus = this.itemSelectedStatus === 'inactive'

      const actions = [
        {
          permission:
            this._canManagementUsersEdit || this._canManagementUsersResent,
          status: isInvitedStatus,
          name: this.$t('PeopleTab.resendInvitationPerson'),
          icon: 'fi-rr-paper-plane',
          action: 'resendInvite',
        },
        {
          permission:
            this._canManagementUsersEdit || this._canManagementUsersActivate,
          status: isInactiveStatus,
          name: this.$t('PeopleTab.activePerson'),
          icon: 'fi-rr-power',
          action: 'active',
        },
        {
          permission:
            this._canManagementUsersEdit || this._canManagementUsersInactivate,
          status: isConfirmedStatus || isInvitedStatus,
          name: this.$t('PeopleTab.inactivatePerson'),
          icon: 'fi-rr-power',
          action: 'inative',
        },
        {
          permission: this._canManagementUsersDelete,
          status: true,
          name: this.$t('PeopleTab.deletePerson'),
          icon: 'fi-rr-trash',
          action: 'delete',
        },
      ]

      const items = actions.filter(action => action.permission && action.status)

      return items
    },
    _selectionMode() {
      const hasDeletedTrueInListItems = this.items.some(
        obj => obj.deleted === true
      )

      if (hasDeletedTrueInListItems) return false
      return this.selectionMode
    },
    _quickTopBarActions() {
      const item = [
        {
          icon: 'fi-rr-shield',
          label: this.$t('quickTopBar.addGroupPermission'),
          action: 'permissionGroup',
        },
      ]
      return item
    },
  },

  watch: {
    getfilterPeople(newValue) {
      this.mountedPeople(newValue)
      this.resetSelectionState()
    },
    getfilterPeopleCount(newValue) {
      this.limitPagination = newValue
      this.xcount = newValue
    },
    getfilterPeopleEmpty(value) {
      this.show = !value
    },
  },

  mounted() {
    this.setCurrentPage('PeoplePage')
  },

  methods: {
    ...mapActions(['setCurrentPage', 'setFilterPeopleParams', 'setReload']),

    getPermissionMenu(status) {
      switch (status) {
        case 'confirmed':
          return (
            this._canManagementUsersInactivate || this._canManagementUsersEdit
          )
        case 'invited':
          return this._canManagementUsersResent || this._canManagementUsersEdit
        case 'inactive':
          return (
            this._canManagementUsersActivate || this._canManagementUsersEdit
          )
      }

      return false
    },

    handleAction(action, personid = null, index = null, menuOption) {
      if (personid && !menuOption) {
        menuOption = this.items.find(el => el.id === personid)
      }

      switch (action) {
        case 'close':
          this.setReload({ ...this.data })
          return this.closeSideQuickForm('PeoplePage')
        case 'invite':
          return this.$refs.personForm.submit(true)
        case 'inviteLater':
          return this.$refs.personForm.submit()
        case 'resendInvite':
          return this.$refs.personForm.resendInviteSubmit()
        case 'active':
          return this.$refs.personForm.statusSubmit(action)
        case 'inactive':
          return this.$refs.personForm.statusSubmit(action)
        case 'delete':
          return this.$refs.personForm.deletePerson()
        case 'newPerson':
          this.personID = null
          return this.openSideQuickForm(action)
        case 'viewPerson':
          this.personID = personid
          return this.openSideQuickForm(action, personid, index, menuOption)
      }
    },
    handleOpenQuickRegister(action, personID, index) {
      if (action === 'close') {
        this.closeSideQuickForm('PeoplePage')
      } else {
        this.openSideQuickForm(action, personID, index)
      }
    },

    handleAlert(message, callAlert) {
      const config = {
        messagePrefix: '',
        description: message.description,
        type: message.type,
        hasLeftBorder: false,
        hasFooter: false,
      }

      callAlert(config)
    },

    mountedPeople(people) {
      const statusMap = {
        confirmed: {
          label: this.$t('PeoplePage.confirmedLabel'),
          color: _colors.greenColor,
        },
        invited: {
          label: this.$t('PeoplePage.guestLabel'),
          color: _colors.secondary,
        },
        inactive: {
          label: this.$t('PeoplePage.inactiveLabel'),
          color: _colors.neutral5,
        },
      }

      this.items = people.map(person => {
        const statusInfo = statusMap[person.status] || {
          label: '--',
          color: '',
        }

        const groups = person.groups.map(group => group.name)
        const permissionGroups = person.permissionGroups
          ? person.permissionGroups.map(
              permissionGroup => permissionGroup.label
            )
          : []

        const attributeText =
          person.attributes.length !== 0
            ? `${person.attributes.length} ${this.$t(
                'PeopleTab.headerTable.attribute'
              )}`
            : '--'

        return {
          'entity-detail': {
            id: person.id,
          },
          id: person.id,
          name: {
            image: person.photo,
            title: person.name,
            subtitle: person.email,
          },
          groups,
          attribute: [attributeText],
          permissiongroups: permissionGroups,
          occupation: [
            person.occupation !== null ? person.occupation.title : '--',
          ],
          access: {
            id: person.status,
            ...statusInfo,
          },
          actions: this.getPermissionMenu(person.status)
            ? ['edit', 'menu-options']
            : ['edit'],
        }
      })

      this.loading = false
    },

    updatePayload(payload) {
      this.data = {
        ...this.data,
        ...payload,
      }
      this.setFilterPeopleParams(this.data)
    },
    search(event) {
      const searchTerm = event.name === '' ? null : event.name
      if (searchTerm === this.getfilterPeopleParamsByName) return

      this.items = []
      this.loading = true
      this.resetSelectionState()
      this.updatePayload({ name: searchTerm, offset: 0 })
    },
    resetSelectionState() {
      if (this.peopleConfirmSelected) {
        this.selectAllPeople = false
        this.selectedPeople = []
        this.closeQuickTopBar()
      }
    },
    handlePagination(event) {
      this.items = []
      this.loading = true
      this.resetSelectionState()

      this.updatePayload({ offset: event.offset, limit: event.limit })
    },
    handleDeleteModal() {
      this.$refs.modal.handleDialog()
    },
    async deletePeople(personID) {
      try {
        await deletePerson(personID)

        alertSuccessBus(this.$t('PeopleTab.AlertSuccessDelete'))

        this.handleReloadTable()

        this.$refs.modal.handleDialog()
        this.handleMenuClose()
      } catch (error) {
        alertErrorBus(this.$t('PeoplePage.viewPerson.errorDelete'), error)
      }
    },
    async InativePeopleStatus(personID) {
      try {
        const payload = {
          personID: personID,
          status: 'inactive',
        }
        await changePersonStatus(payload)

        alertSuccessBus(this.$t('PeopleTab.AlertSuccessInativeStatus'))

        this.handleReloadTable()

        this.handleMenuClose()
      } catch (error) {
        alertErrorBus(this.$t('PeoplePage.viewPerson.errorUpdate'), error)
      }
    },
    async ActivePeopleStatus(personID) {
      try {
        const payload = {
          personID: personID,
          status: 'active',
        }
        await changePersonStatus(payload)

        alertSuccessBus(this.$t('PeopleTab.AlertSuccessActivateStatus'))

        this.handleReloadTable()

        this.handleMenuClose()
      } catch (error) {
        alertErrorBus(this.$t('PeoplePage.viewPerson.errorUpdate'), error)
      }
    },
    async resendInvitePeople(personID) {
      try {
        await resendPersonInvite({ personID })

        alertSuccessBus(this.$t('PeopleTab.AlertSuccessResendInvite'))

        this.handleReloadTable()

        this.handleMenuClose()
      } catch (error) {
        alertErrorBus(this.$t('PeoplePage.viewPerson.errorResend'), error)
      }
    },

    handleReloadTable() {
      this.items = []
      this.loading = true
      this.setReload({ ...this.data })
    },

    handleMenuClose() {
      this.menuOpened = false
      this.menuOpenedIndex = 0
    },
    showMenuAction(event, position) {
      this.itemSelectedStatus = event.access.id
      this.selectedPeople = event
      this.menuOpenedIndex = position
      this.menuOpened = true
    },
    handleMenuAction(event, position) {
      switch (event) {
        case 'resendInvite':
          return this.resendInvitePeople(this.items[position].id)
        case 'inative':
          return this.InativePeopleStatus(this.items[position].id)
        case 'active':
          return this.ActivePeopleStatus(this.items[position].id)
        case 'delete':
          this.deleteUserName = this.items[position].name.title
          this.personID = this.items[position].id
          return this.handleDeleteModal(this.items[position].id)
      }
    },
    handleOnClickSelectAll(selectAll) {
      this.selectAllPeople = selectAll
    },
    handleOnSelectPeople(items) {
      this.selectedPeople = items
      this.peopleConfirmSelected = false
    },
    confirmSelectedPeople() {
      this.selectAllPeople = false
      this.peopleConfirmSelected = true
    },
    cancelSelectedPeople() {
      this.selectAllPeople = false
    },
    handleOnUpdateTable({ currentSelectedItems }) {
      const items = Array.isArray(currentSelectedItems)
        ? currentSelectedItems
        : []

      this.itemsSelectedBeforeTheLastUpdate = items
    },
    closeQuickTopBar() {
      if (this.$refs.dataTable) {
        this.itemsSelectedBeforeTheLastUpdate = []
        this.$refs.dataTable.clearSelection()
      }
    },
    handleRefreshPeoplePage() {
      this.resetSelectionState()
      this.loading = true
      this.setReload({ ...this.data })
    },
  },
}
</script>
<style lang="scss" src="./style.scss" scoped />
