<template>
  <DropdownWithSteps
    ref="dropdownWithSteps"
    :options="options"
    :loading="loading.submit"
    @handleSave="submit()"
    @handleOption="handleOption($event)"
    @handleCancel="$emit('cancel')"
  >
    <template #content>
      <ActionButton :action="action"></ActionButton>
    </template>

    <template #add-permissionGroup>
      <ComposeForm
        ref="addPermissionForm"
        :form-name="'add-permissions'"
        :selectors="{ fields }"
        :lazy-submit="false"
        :storage-preferences="false"
        @compose:values="addPermissionsValues = $event"
      ></ComposeForm>
    </template>

    <template #remove-permissionGroup>
      <ComposeForm
        ref="removePermissionForm"
        :form-name="'remove-permissions'"
        :selectors="{ fields }"
        :lazy-submit="false"
        :storage-preferences="false"
        @compose:values="removePermissionsValues = $event"
      ></ComposeForm>
    </template>
  </DropdownWithSteps>
</template>

<script>
import ActionButton from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/Partials/QuickTopBar/ActionButton/ActionButton.vue'

import {
  getPermissionGroups,
  addMassPermissionGroup,
  deleteMassPermissionGroup,
} from '@/services/permission-groups'
import { mapGetters } from 'vuex'

export default {
  name: 'ConfigureGroupPermission',
  components: {
    ActionButton,
  },

  props: {
    entities: {
      type: Array,
      default: () => [],
    },

    action: null,

    isAllSelected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: {
        submit: false,
        permissionGroup: false,
      },

      optionSelected: null,
      options: [
        {
          name: this.$t('quickTopBar.addGroupPermission'),
          value: 'add-permissionGroup',
          icon: 'fi fi-rr-plus-small',
        },
        {
          name: this.$t('quickTopBar.removeGroupPermission'),
          value: 'remove-permissionGroup',
          icon: 'fi fi-rr-cross-circle',
        },
      ],

      addPermissionsValues: {},
      removePermissionsValues: {},

      fields: [
        {
          id: 'permissionGroupIDs',
          _label: this.$t('quickTopBar.selectPermissionGroup'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            localSearch: false,
            multiple: false,
            inputConfig: {
              rules: ['required'],
            },
          },
          api: {
            endpoint: getPermissionGroups,
            searchKey: 'label',
            infinity: true,
            identifier: 'id',
            hidrateFn: item => {
              return {
                ...item,
                label: item.label,
              }
            },
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      _filterPayload: 'getfilterPeopleParams',
    }),
  },

  methods: {
    handleOption(event) {
      if (!event) return

      this.optionSelected = event.value
    },

    validate(refName) {
      return this.$refs[refName].validate()
    },

    submit() {
      if (this.optionSelected === 'remove-permissionGroup') {
        return this.handleRemovePermissionGroup()
      }

      if (this.optionSelected === 'add-permissionGroup') {
        return this.handleAddPermissionGroup()
      }
    },

    calcPayload(payload) {
      if (this.isAllSelected) {
        let filters = structuredClone(this._filterPayload)
        if (Object.prototype.hasOwnProperty.call(filters, 'sortBy')) {
          delete filters.sortBy
        }
        if (Object.prototype.hasOwnProperty.call(filters, 'sortOrder')) {
          delete filters.sortOrder
        }
        return { ...filters, ...payload }
      }

      return {
        personIDs: this.entities.map(entity => entity.id),
        ...payload,
      }
    },

    async handleRemovePermissionGroup() {
      if (!this.validate('removePermissionForm')) return

      const payload = this.calcPayload()
      const permissionGroupID =
        this.removePermissionsValues.permissionGroupIDs[0]

      try {
        await deleteMassPermissionGroup(permissionGroupID, payload)
        const message = {
          title: this.$t('DropdownWithSteps.successRemove'),
          type: 'success',
          hasIconTitle: true,
        }

        this.$emit('alert', message)
        this.$emit('confirmed:reload')
      } catch (error) {
        const errorMessage = {
          title: this.$t('DropdownWithSteps.errorRemove'),
          type: 'error',
          hasIconTitle: true,
        }
        this.$emit('alert', errorMessage)
      }
    },

    async handleAddPermissionGroup() {
      if (!this.validate('addPermissionForm')) return

      const payload = this.calcPayload()
      const permissionGroupID = this.addPermissionsValues.permissionGroupIDs[0]

      try {
        await addMassPermissionGroup(permissionGroupID, payload)
        const message = {
          title: this.$t('DropdownWithSteps.successAdd'),
          type: 'success',
          hasIconTitle: true,
        }

        this.$emit('alert', message)
        this.$emit('confirmed:reload')
      } catch (error) {
        const errorMessage = {
          title: this.$t('DropdownWithSteps.errorAdd'),
          type: 'error',
          hasIconTitle: true,
        }
        this.$emit('alert', errorMessage)
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
