<template>
  <div
    class="demographic-attributes-page"
    data-test-demographic-attributes-page
  >
    <AttributesTable @edit="handleAttributeView"></AttributesTable>
    <AttributeView @action:close="handleAttributeClose"></AttributeView>
  </div>
</template>

<script>
import AttributesTable from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributesTable/AttributesTable.vue'
import AttributeView, {
  ACTIONS_NEW,
  ACTIONS_VIEW,
} from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/AttributeView.vue'

export default {
  name: 'DemographicAttributesPage',

  components: {
    AttributesTable,
    AttributeView,
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.handleRoute(to?.params)
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.handleRoute(to?.params)
    next()
  },

  mounted() {
    this.$store.dispatch('setCurrentPage', 'DemographicAttributesPage')
  },

  methods: {
    handleRoute(params) {
      const action =
        [ACTIONS_NEW, ACTIONS_VIEW].includes(params?.action) && params?.action
      if (!action) return

      this.$root.$emit('demographic-attribute:view', action, {
        id: params?.id ?? null,
      })
    },

    handleAttributeView(attribute) {
      if (!attribute?.id) return

      this.$router
        .push({
          name: 'ActionDemographicAttributesPage',
          params: {
            action: ACTIONS_VIEW,
            id: attribute.id,
          },
        })
        .catch(() => {})
    },

    handleAttributeClose(state) {
      if (state) return

      this.$router.push({ name: 'DemographicAttributesPage' }).catch(() => {})
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
