import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getOccupations(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'title'], data)

  return (await api()).get('/management/occupations', {
    params: params,
    ...signal,
  })
}
