<template>
  <div data-test-configure-metadata-groups>
    <OrderModal
      v-if="_canOrderMetadataGroups && _items.length > 0"
      ref="OrderModal"
      :skeletonLines="_items.length"
      @display-alert="handleAlert($event)"
      @success="handleChangeSuccess('order')"
    ></OrderModal>

    <DeleteModal
      ref="DeleteModal"
      @display-alert="handleAlert($event)"
      @success="handleChangeSuccess('delete')"
    ></DeleteModal>

    <DetailSectionAccordion
      type="people"
      :title="$t('PerformanceTab.ListMetadataGroups.title')"
      :hasButton="_canOrderMetadataGroups && _items.length > 0"
      :textButton="$t('PerformanceTab.ListMetadataGroups.changeOrder')"
      @headerClick="handleChangeOrder"
      :opened="true"
    >
      <DataTable
        data-test-section-metadata-table-body
        :title="$t('PerformanceTab.ListMetadataGroups.table.title')"
        :attach="true"
        :loading="MetadataGroupsApiController.loading"
        :hideHeader="true"
        :hideOption="true"
        :rolesReadonly="false"
        :items="_items"
        :headers="tableHeaders"
        :itemsPerPage="-1"
        :enablePagination="false"
        :skeleton-lines="tableConfig.limit"
        :downLeftButton="
          _canCreateMetadataGroups && {
            click: handleAddNew,
            text: $t('PerformanceTab.ListMetadataGroups.table.addNew'),
            icon: 'mdi-plus',
          }
        "
        @search="handleSearch($event)"
        @get-data="handleSearch($event)"
        @remove="handleDelete($event)"
        @edit="handleEdit($event)"
      ></DataTable>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import {
  handleAxiosSignalAbortController,
  handleApiRequestWithController,
} from '@/helpers/api'
import { getMetadataGroups } from '@/services/metadata'
import DataTable from '@/components/DataTable/DataTable.vue'
import OrderModal from './Partials/OrderModal/OrderModal.vue'
import DeleteModal from './Partials/DeleteModal/DeleteModal.vue'

import {
  management_performance_metadata_groups_create,
  management_performance_metadata_groups_edit,
  management_performance_metadata_groups_delete,
  management_performance_metadata_groups_order,
} from '@/helpers/ability'

export default {
  name: 'ListMetadataGroups',
  components: {
    DataTable,
    OrderModal,
    DeleteModal,
  },
  inject: ['openMetadataGroupForm'],

  data() {
    return {
      tableHeaders: [
        {
          text: this.$t('PerformanceTab.ListMetadataGroups.table.header.name'),
          value: 'positional',
          type: 'positional-item',
        },
        {
          text: this.$t(
            'PerformanceTab.ListMetadataGroups.table.header.metadata'
          ),
          value: 'label-metadata',
          type: 'chip-group',
          width: '300',
        },
        {
          text: this.$t(
            'PerformanceTab.ListMetadataGroups.table.header.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ],
      tableConfig: {
        limit: 4,
      },

      AxiosController: {
        SignalHistory: [],
      },

      MetadataGroupsApiController: {
        label: '',
        limit: 500,
        offset: 0,
        loading: false,
        itens: [],
      },

      entityType: null,
      entityClass: null,
    }
  },

  computed: {
    _items() {
      const itens = this.MetadataGroupsApiController.itens
      return this.calcMetadataGroupsItensToTable(itens)
    },

    _canCreateMetadataGroups() {
      return this.$can('access', management_performance_metadata_groups_create)
    },

    _canEditMetadataGroups() {
      return this.$can('access', management_performance_metadata_groups_edit)
    },

    _canDeleteMetadataGroups() {
      return this.$can('access', management_performance_metadata_groups_delete)
    },

    _canOrderMetadataGroups() {
      return this.$can('access', management_performance_metadata_groups_order)
    },
  },

  methods: {
    setEntity(
      entityType = this.$route.params.entityType,
      entityClass = this.$route.params.entityClass
    ) {
      this.entityType = entityType
      this.entityClass = entityClass
    },

    calcMetadataGroupsItensToTable(itens) {
      if (!Array.isArray(itens)) {
        return []
      }

      const canDelete = this._canDeleteMetadataGroups

      return itens?.map(item => {
        const LabelMetadata = this.$tc(
          'PerformanceTab.ListMetadataGroups.table.itens.metadata',
          item.metadatasCount
        )

        const actions = ['edit']

        if (canDelete && !item.default) {
          actions.push('remove')
        }

        return {
          ...item,
          positional: {
            position: item.position + 1,
            title: item.label,
          },
          'label-metadata': [LabelMetadata],
          actions: actions,
        }
      })
    },

    handleAddNew() {
      this.openMetadataGroupForm('addMetadataGroup')
    },
    handleEdit(MetadataGroupPayload) {
      this.openMetadataGroupForm('editMetadataGroup', {
        id: MetadataGroupPayload.id,
      })
    },
    handleChangeOrder() {
      if (this.$refs.OrderModal) {
        this.$refs.OrderModal.handleOpenDialog()
      }
    },
    handleChangeSuccess(type) {
      this.getOptionsSearch()

      if (type === 'delete') {
        this.$emit('delete-success')
      }
    },
    handleDelete({ id = null }) {
      if (this.$refs.DeleteModal && id) {
        this.$refs.DeleteModal.handleOpenDialog(id)
      }
    },

    async getOptionsSearch(search = null) {
      const id = 'MetadataGroups'

      const { signal } = handleAxiosSignalAbortController(
        this.AxiosController.SignalHistory,
        id
      )

      const payload = {
        limit: this.MetadataGroupsApiController.limit,
        offset: 0,
        entityType: this.entityType,
        entityClass: this.entityClass,
        label: search,
      }

      this.MetadataGroupsApiController = Object.assign(
        {},
        this.MetadataGroupsApiController,
        {
          loading: true,
          itens: [],
          ...payload,
        }
      )

      const ApiResult = await handleApiRequestWithController(
        getMetadataGroups,
        payload,
        this.MetadataGroupsApiController,
        'id',
        signal
      )

      if (ApiResult.state === 'error') {
        this.handleAlert({
          statusAlert: 'error',
          title: this.$t('orderMetadataGroup.alert.get.error'),
          messageAlert: ApiResult.error?.response?.data?.message,
        })
      }
    },

    handleAlert(payload) {
      this.$emit('display-alert', payload)
    },

    handleSearch(event) {
      this.tableConfig = Object.assign({}, this.tableConfig, {
        limit: 4,
      })

      this.getOptionsSearch(event.name)
    },
  },

  beforeMount() {
    this.setEntity()
  },

  mounted() {
    if (window.Cypress) {
      window.ListMetadataGroups = {
        refresh: () => this.getOptionsSearch(),
      }
    }
  },
}
</script>
