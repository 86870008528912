import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

// fix necessário para POST e PUT de metadados devido regra de SQL Injection do CloudArmor
const fixSelectTypeOfMetadata = data => ({
  ...data,
  ...(data?.type === 'select' && { type: 'selectable' }),
})

export async function getMetadata(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'limit',
      'offset',
      'entityType',
      'entityClass',
      'label',
      'cycleIDs',
      'matchHierarchy',
    ],
    data
  )

  return (await api()).get('/performance/okrs/metadata', {
    params: params,
    ...signal,
  })
}

export const getMetadataByID = async metadataID => {
  return (await api()).get(`/performance/okrs/metadata/${metadataID}`)
}

export const deleteMetadata = async data => {
  return (await api()).delete(`/performance/okrs/metadata/${data}`)
}

export const postMetadata = async data => {
  return (await api()).post(
    `/performance/okrs/metadata`,
    fixSelectTypeOfMetadata(data)
  )
}

export async function putMetadata(metadataID, data) {
  return (await api()).put(
    `/performance/okrs/metadata/${metadataID}`,
    fixSelectTypeOfMetadata(data)
  )
}

export async function getMetadataGroups(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'entityType', 'entityClass', 'label'],
    data
  )

  return (await api()).get('/performance/okrs/metadata-groups', {
    params: params,
    ...signal,
  })
}

export async function getMetadataGroupsByID(metadataGroupID) {
  return (await api()).get(
    `/performance/okrs/metadata-groups/${metadataGroupID}`
  )
}

export async function postMetadataGroups(data) {
  return (await api()).post('/performance/okrs/metadata-groups', data)
}

export async function putMetadataGroups(metadataGroupID, data) {
  return (await api()).put(
    `/performance/okrs/metadata-groups/${metadataGroupID}`,
    data
  )
}

export async function deleteMetadataGroupByID(metadataGroupID) {
  return (await api()).delete(
    `/performance/okrs/metadata-groups/${metadataGroupID}`
  )
}

export async function putMetadataGroupsPositions(data) {
  return (await api()).put(`/performance/okrs/metadata-groups/positions`, data)
}
