<template>
  <div data-test-menu-people-page>
    <ComposeForm
      ref="ComposeForm"
      class="form"
      :form-name="'people-filters'"
      :selectors="{
        fields: _fields,
      }"
      :lazy-submit="false"
      :lazy-mount="false"
      :storage-preferences="false"
      @compose:values="setFilters"
    ></ComposeForm>
  </div>
</template>

<script>
import { getOccupations } from '@/services/occupations'
import { advancedSearch } from '@/services/people'
import { getGroupsTree } from '@/services/groups'
import { getPermissionGroups } from '@/services/permission-groups'
import { getPeopleAttributes } from '@/services/people-attributes'

import { mapActions, mapGetters } from 'vuex'
import { alertErrorBus } from '@/helpers/alerts'

export default {
  name: 'MenuPeoplePage',

  data() {
    return {
      params: {
        attributes: null,
        roleIDs: null,
        permissionGroupIDs: null,
        groupIDs: null,
        showInactive: true,
        showDeleted: false,
      },
    }
  },

  computed: {
    ...mapGetters([
      'getReload',
      'getfilterPeopleParamsByName',
      'getfilterPeopleParamsByLimit',
      'getfilterPeopleParamsByOffset',
    ]),

    _fields() {
      return [
        {
          id: 'groupIDs',
          _label: this.$t('LateralMenu.PeopleMenu.groupCombo'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },

          ItemSelector: {
            type: 'treeview',
            multiple: true,
            localSearch: true,
            inputConfig: {
              showAvatar: true,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
            viewConfig: {
              showElements: true,
              hideElements: [null],
            },
          },

          api: {
            endpoint: getGroupsTree,
            searchKey: null,
            infinity: false,
          },
        },
        {
          id: 'roleIDs',
          _label: this.$t('LateralMenu.PeopleMenu.rolesCombo'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },

          ItemSelector: {
            multiple: true,
            localSearch: false,
            inputConfig: {
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },

          api: {
            endpoint: getOccupations,
            hidrateFn: item => ({
              ...item,
              label: item.title,
            }),
            searchKey: 'title',
            infinity: true,
          },
        },
        {
          id: 'permissionGroupIDs',
          _label: this.$t('LateralMenu.PeopleMenu.groupPermissionsCombo'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },

          ItemSelector: {
            multiple: true,
            localSearch: false,
            inputConfig: {
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },

          api: {
            endpoint: getPermissionGroups,
            searchKey: 'label',
            infinity: true,
          },
        },
        {
          display: {
            hidden: true,
          },
          id: 'attributes',
          _label: this.$t('LateralMenu.PeopleMenu.attributesCombo'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },

          ItemSelector: {
            multiple: true,
            localSearch: false,
            inputConfig: {
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },

          api: {
            endpoint: getPeopleAttributes,
            searchKey: 'label',
            infinity: true,
          },
        },
        {
          id: 'statuses',
          _label: this.$t('LateralMenu.PeopleMenu.statusCombo'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },

          ItemSelector: {
            multiple: true,
            localSearch: true,
            inputConfig: {
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },

          options: [
            {
              value: 'statuses',
              label: '',
              type: 'listview',
              items: [
                {
                  label: this.$t('PeoplePage.confirmedLabel'),
                  id: 'confirmed',
                },
                {
                  label: this.$t('PeoplePage.guestLabel'),
                  id: 'invited',
                },
                {
                  label: this.$t('PeoplePage.inactiveLabel'),
                  id: 'inactive',
                },
                {
                  label: this.$t('PeoplePage.deletedLabel'),
                  id: 'deleted',
                },
              ],
            },
          ],
        },
      ]
    },
  },

  watch: {
    getReload() {
      this.lateralMenuSearch()
    },
    getfilterPeopleParamsByName() {
      this.lateralMenuSearch()
    },
    getfilterPeopleParamsByLimit() {
      this.lateralMenuSearch()
    },
    getfilterPeopleParamsByOffset() {
      this.lateralMenuSearch()
    },
  },

  methods: {
    ...mapActions(['setFilterPeople']),

    cleanFilter() {
      if (!this.$refs.ComposeForm) return
      this.$refs.ComposeForm.resetComposeForm()
    },

    setFilters(payload = {}) {
      const data = {
        attributes: null,
        roleIDs: null,
        permissionGroupIDs: null,
        groupIDs: null,
        ...payload,
        statuses: payload?.statuses,
        showInactive: true,
        showDeleted: payload?.statuses?.includes('deleted') || false,
      }

      this.$set(this, 'params', data)
      this.lateralMenuSearch()
    },

    async lateralMenuSearch() {
      const payload = {
        sortBy: 'name',
        sortOrder: 'asc',
        name: this.getfilterPeopleParamsByName,
        limit: this.getfilterPeopleParamsByLimit,
        offset: this.getfilterPeopleParamsByOffset,
        ...this.params,
      }

      try {
        const { data, headers } = await advancedSearch(payload)
        this.setFilterPeople({
          items: data,
          payload,
          countItems: parseInt(headers['x-count']) || 0,
          isDirtySearch: [
            payload.name,
            payload.roleIDs,
            payload.permissionGroupIDs,
            payload.groupIDs,
            payload.attributes,
          ].some(el => !!el?.length),
        })
      } catch (error) {
        alertErrorBus(this.$t('LateralMenu.PeopleMenu.errorSearch'), error)
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
