import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getAuditLogs(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'text', 'entity', 'action'],
    data
  )

  return (await api()).get('/management/audit-logs', {
    params: params,
    ...signal,
  })
}

export async function getAuditLogsEntities(data) {
  const signal = extractSignal(data)

  return (await api()).get('/management/audit-logs/entities', {
    ...signal,
  })
}

export async function getAuditLogsActions(data) {
  const signal = extractSignal(data)

  return (await api()).get('/management/audit-logs/actions', {
    ...signal,
  })
}
