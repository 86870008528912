<template>
  <Modal
    ref="inactiveDialog"
    data-test-modal-inactive-group
    width="60%"
    :title="$t('GroupsPage.viewGroup.inactiveDialog.title')"
    :has-tab="false"
  >
    <template #[`no-tab`]>
      <section class="dialog-content dialog-inactive-group d-flex flex-column">
        <div class="dialog-inactive-group-info text-center d-flex flex-column">
          <p v-text="$t('GroupsPage.viewGroup.inactiveDialog.text1')"></p>

          <p v-text="$t('GroupsPage.viewGroup.inactiveDialog.text2')"></p>
        </div>

        <div
          class="dialog-inactive-group-actions d-flex flex-wrap justify-center align-center"
        >
          <v-btn
            data-test-view-group-inactive-group
            class="text-none"
            elevation="0"
            color="primary"
            @click="$emit('confirm:group-only', node)"
          >
            <span
              v-text="$t('GroupsPage.viewGroup.inactiveDialog.bttn1')"
            ></span>
          </v-btn>

          <v-btn
            data-test-view-group-inactive-group-people
            class="text-none"
            elevation="0"
            color="primary"
            @click="$emit('confirm:group-people', node)"
          >
            <span
              v-text="$t('GroupsPage.viewGroup.inactiveDialog.bttn2')"
            ></span>
          </v-btn>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ModalInactiveGroup',
  data() {
    return {
      node: null,
    }
  },
  methods: {
    handleDialog(node = null) {
      this.node = node
      this.$refs.inactiveDialog.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
