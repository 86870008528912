import Vue from 'vue'

import { alertErrorBus } from '@/helpers/alerts'
import i18n from '@/plugins/i18n'

import {
  getAuditLogsEntities,
  getAuditLogsActions,
} from '@/services/audit-logs'

/**
 * @type {import('vuex').Module}
 */
export const AuditLogsPage = {
  namespaced: true,

  state: () => ({
    filters: {},
    entities: null,
    actions: null,
  }),

  getters: {
    filters: state => state.filters,
    entities: state => state.entities,
    actions: state => state.actions,

    entitiesI18n: state =>
      Object.fromEntries(
        state.entities?.map(({ id, label }) => [id, label]) ?? []
      ),
    actionsI18n: state =>
      Object.fromEntries(
        state.actions?.map(({ id, label }) => [id, label]) ?? []
      ),
  },

  actions: {
    async getAuditLogsEntities({ commit, state }, payload) {
      if (!payload?.refresh && state.entities?.length) {
        return Promise.resolve(state.entities)
      }

      return getAuditLogsEntities()
        .then(async ({ data }) => {
          commit('SET_ENTITIES', data)
          return data
        })
        .catch(error => {
          alertErrorBus(
            i18n.t('alerts.ManagementAuditLogsEntities.error.get'),
            error
          )
        })
    },

    async getAuditLogsActions({ commit, state }, payload) {
      if (!payload?.refresh && state.actions?.length) {
        return Promise.resolve(state.actions)
      }

      return getAuditLogsActions()
        .then(({ data }) => {
          commit('SET_ACTIONS', data)
          return data
        })
        .catch(error => {
          alertErrorBus(
            i18n.t('alerts.ManagementAuditLogsActions.error.get'),
            error
          )
        })
    },
  },

  mutations: {
    SET_FILTERS(state, payload) {
      Vue.set(state, 'filters', payload)
    },

    SET_ENTITIES(state, payload) {
      Vue.set(state, 'entities', payload)
    },

    SET_ACTIONS(state, payload) {
      Vue.set(state, 'actions', payload)
    },
  },
}
