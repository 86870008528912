<template>
  <Modal
    data-test-metadata-group-delete-modal
    ref="modal"
    :title="$t('deleteMetadataGroup.title')"
    :hasButtons="true"
    width="690px"
    @cancel="canceled"
    @confirmed="confirmed"
  >
    <template v-slot:no-tab>
      <div class="modal-content">
        <i18n path="deleteMetadataGroup.content.text" tag="p">
          <template #metadata>
            <strong v-text="metadata.label" />
          </template>
          <template #section>
            <strong v-text="metadata.group.label" />
          </template>
        </i18n>

        <i18n path="deleteMetadataGroup.remind.text" tag="p" class="remind">
          <template #strong>
            <strong v-text="$t('deleteMetadataGroup.remind.strong')" />
          </template>
        </i18n>
      </div>
    </template>

    <template v-slot:footer-right="{ cancelEvent, confirmedEvent }">
      <v-btn
        data-test-modal-cancel
        class="button-cancel"
        text
        @click="cancelEvent"
        tabindex="1"
      >
        <span v-text="$t('Modal.buttonCancel')" />
      </v-btn>

      <v-btn
        data-test-confirm-metadata-group-order
        class="button-confirm"
        text
        :loading="formIsAnimating"
        @click="confirmedEvent"
        tabindex="2"
      >
        <span v-text="$t('deleteMetadataGroup.submit')" />
      </v-btn>
    </template>
  </Modal>
</template>

<script>
import { deleteMetadataGroupByID } from '@/services/metadata'

import { debounce } from 'lodash'

export default {
  name: 'DeleteModal',
  data() {
    return {
      handleConfirmed: debounce(async function (resetDialogCallback) {
        await this.deleteMetadataGroup().then(status => {
          this.formIsAnimating = false

          if (status === 'success') {
            this.$emit('success')
            resetDialogCallback()
          }
        })
      }, 500),

      formIsAnimating: false,

      form: {
        id: null,
      },
    }
  },

  methods: {
    resetModal() {
      this.formIsAnimating = false
      this.loading = true
      this.$set(this.form, 'id', null)
    },

    handleOpenDialog(payloadID) {
      this.resetModal()
      this.setEntity(payloadID)

      if (this.$refs.modal) {
        this.$refs.modal.handleDialog(true)
      }
    },

    setEntity(payloadID = this.$route.params.id) {
      this.form.id = payloadID
    },

    handleAlert(payload) {
      this.$emit('display-alert', payload)
    },

    async confirmed(resetDialogCallback) {
      this.formIsAnimating = true
      this.handleConfirmed(resetDialogCallback)
    },

    canceled() {
      this.handleConfirmed.cancel()
      this.formIsAnimating = false
    },

    async deleteMetadataGroup() {
      return await deleteMetadataGroupByID(this.form.id)
        .then(() => {
          this.handleAlert({
            statusAlert: 'success',
            title: this.$t('deleteMetadataGroup.alert.delete.success'),
          })

          return 'success'
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t('deleteMetadataGroup.alert.delete.error'),
            messageAlert: error?.response?.data?.message,
          })

          return 'error'
        })
    },
  },

  beforeMount() {
    this.setEntity()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
