var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"module-selection","data-test-module-selection":""}},[_c('ItemSelector',{ref:"moduleInput",attrs:{"current-value":_vm.moduleSeleted,"watch-current":true,"input-config":{
      label: _vm.$t('PermissionsPage.selectModule.title'),
      showAvatar: false,
      closeOnSelect: true,
    },"menu-config":{
      attach: true,
      showTabs: false,
    },"menu-options":_vm._menuOptionsModules,"local-search":true,"persistent":true},on:{"update:item":_vm.submit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }