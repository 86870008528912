var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('v-form',{ref:"form",attrs:{"data-test-cycle-form":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{attrs:{"id":"cycle-form"}},[(_vm.loading)?_c('div',{staticClass:"form"},[_c('v-row',_vm._l((6),function(n){return _c('v-col',{key:n,attrs:{"cols":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line","max-height":"3.375rem"}})],1)}),1),_c('v-skeleton-loader',{staticClass:"loader-box",attrs:{"type":"image"}}),_vm._l((6),function(n){return _c('v-skeleton-loader',{key:n,attrs:{"type":"list-item-two-line","max-height":"3.375rem"}})})],2):_c('div',{staticClass:"form"},[_c('section',{staticClass:"form-section"},[_c('v-row',[_c('v-col',{staticClass:"form-column",attrs:{"cols":"12"}},[_c('CustomInput',{ref:"label",attrs:{"current-value":_vm.form.title,"mandatory":true,"view-mode":_vm._editionMode,"label":_vm.$t('PerformanceTab.cycleForm.name'),"readonly":_vm._createMode ? !_vm._canCycleCreate : !_vm._canCycleEdit},on:{"handleInput":function($event){return _vm.handleInput($event, 'title', 750)}}})],1),_c('v-col',{staticClass:"form-column",attrs:{"cols":"12","md":"6"}},[_c('CustomDatePicker',{ref:"startDate",attrs:{"data-test-input-startdate":"","label":_vm.$t('PerformanceTab.cycleForm.startDate'),"text-input-props":{
                  'hide-details': 'auto',
                },"menu-props":{
                  attach: true,
                  'z-index': 99,
                  'content-class': 'mt-1',
                  nudgeBottom: '-20',
                },"close-on-click":true,"date-selected":_vm.form.startDate,"subtle-mode":_vm._editionMode,"readonly":_vm._createMode ? !_vm._canCycleCreate : !_vm._canCycleEdit,"rules":_vm._createMode ? _vm._createStartDateRules : _vm._editStartDateRules},on:{"input":function($event){return _vm.handleInput($event, 'startDate')}}})],1),_c('v-col',{staticClass:"form-column",attrs:{"cols":"12","md":"6"}},[_c('CustomDatePicker',{ref:"endDate",attrs:{"data-test-input-endDate":"","label":_vm.$t('PerformanceTab.cycleForm.endDate'),"text-input-props":{
                  'hide-details': 'auto',
                },"menu-props":{
                  attach: true,
                  'z-index': 99,
                  'content-class': 'mt-1',
                  nudgeBottom: '-20',
                },"close-on-click":true,"date-selected":_vm.form.endDate,"subtle-mode":_vm._editionMode,"readonly":_vm._createMode ? !_vm._canCycleCreate : !_vm._canCycleEdit,"rules":_vm._createMode ? _vm._createEndDateRules : _vm._editEndDateRules},on:{"input":function($event){return _vm.handleInput($event, 'endDate')}}})],1),_c('v-col',{staticClass:"form-column",attrs:{"cols":"12","md":"6"}},[_c('ItemSelector',{ref:"cycleInput",attrs:{"data-test-cycle-form-selection":"status","input-config":{
                  label: _vm.$t('PerformanceTab.cycleForm.status'),
                  showAvatar: false,
                  closeOnSelect: true,
                  subtleMode: _vm._editionMode,
                },"menu-config":{
                  attach: true,
                  showTabs: false,
                },"current-value":{
                  data: _vm._currentStatus,
                  origin: 'status',
                },"menu-options":_vm._statusOptions,"persistent":true,"local-search":true,"readonly":_vm._createMode ? !_vm._canCycleCreate : !_vm._canCycleEdit},on:{"update:item":function($event){return _vm.handleInput($event.id, 'status')}}})],1),_c('v-col',{staticClass:"form-column",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"toggle"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm._blockToggle},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"data-test-activator-selection":""}},'div',attrs,false),on),[_c('CustomSwitch',{attrs:{"data-test-toggle-default":"","value":_vm.form.current,"readonly":!_vm._canEditToggleCycleDefault},on:{"input":_vm.handleDefaultDialog}})],1)]}}])},[_c('div',{staticClass:"item-label-tooltip"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                          'PerformanceTab.cycleForm.tooltipBlockCycleDefault'
                        )
                      )}})])]),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('PerformanceTab.cycleForm.default'))+" ")]),_c('v-menu',{attrs:{"content-class":"menu","max-width":"18.75rem","open-on-hover":"","open-on-click":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('i',_vm._g(_vm._b({staticClass:"fi-rr-interrogation",attrs:{"data-test-default-info":""}},'i',attrs,false),on))]}}])},[_c('v-card',{staticClass:"menu-info",attrs:{"width":"18.75rem","data-test-menu-info":"","flat":""}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('PerformanceTab.cycleForm.defaultTitle'))+" ")]),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('PerformanceTab.cycleForm.defaultInfo'))+" ")])])],1)],1)])],1)],1),_c('DetailSectionAccordion',{staticClass:"form-section",attrs:{"type":"tree","title":_vm.form.parentCycleID || _vm._createMode
              ? _vm.$t('PerformanceTab.cycleForm.hierarchy.title')
              : _vm.$t('PerformanceTab.cycleForm.hierarchy.title2'),"text-button":_vm.$t('GroupsPage.viewGroup.hierarchy.linkHierarchy'),"opened":true,"has-button":!_vm.cycleID && _vm.hierarchy.length > 0,"data-test-cycle-hierarchy":""},on:{"headerClick":function($event){return _vm.handleHierarchyDialog()}}},[(
              _vm.hierarchy.length > 0 &&
              (_vm.form.parentCycleID || _vm.hierarchy[0].children.length)
            )?_c('CycleHierarchy',{attrs:{"items":_vm.hierarchy,"root":!_vm.form.parentCycleID && _vm._editionMode,"data-test-hierarhcy-list":""}}):_c('div',{staticClass:"hierarchy",attrs:{"data-test-hierarchy-empty":""}},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm._editionMode ? _vm.$t('PerformanceTab.cycleForm.hierarchy.locked') : _vm.$t('PerformanceTab.cycleForm.hierarchy.empty'))+" ")]),(!_vm._editionMode)?_c('div',{attrs:{"data-test-link-hierarchy":""},on:{"click":function($event){return _vm.handleHierarchyDialog()}}},[_c('i',{staticClass:"fi fi-br-plus"}),_c('span',{staticClass:"label label-link"},[_vm._v(" "+_vm._s(_vm.$t('PerformanceTab.cycleForm.hierarchy.bttnLink'))+" ")])]):_vm._e()])],1),(!_vm.form.parentCycleID)?_c('DetailSectionAccordion',{staticClass:"form-section",attrs:{"type":"tree","title":_vm.$t('PerformanceTab.cycleForm.hierarchySettings.title'),"opened":true,"data-test-cycle-hierarchy-model":""}},_vm._l((_vm.form.hierarchySettings),function(item,i){return _c('div',{key:i,staticClass:"hierarchy-model",style:(`padding-left: ${i * 8}px`)},[_c('v-img',{attrs:{"src":require(`@/assets/icons/${item.entityType}.png`),"max-width":"1.625rem","height":"1.625rem"}}),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t( `PerformanceTab.cycleForm.hierarchySettings.${item.entityType}` ))+" "),(item.entityClass)?_c('span',[_vm._v(" "+_vm._s(_vm.$t( `PerformanceTab.cycleForm.hierarchySettings.${item.entityClass}` ))+" ")]):_vm._e()]),_c('v-spacer'),_c('div',{staticClass:"input"},[_c('ItemSelector',{ref:"cycleInput",refInFor:true,attrs:{"input-config":{
                  label: 'Ciclo padrão',
                  showAvatar: false,
                  closeOnSelect: true,
                  subtleMode: _vm._editionMode,
                },"menu-config":{
                  attach: true,
                  showTabs: false,
                },"current-value":{
                  data: [
                    item.defaultCycle && item.defaultCycle.id
                      ? {
                          id: item.defaultCycle.id,
                          label: item.defaultCycle.name,
                        }
                      : _vm._cycleOptions[0].items[0],
                  ],
                  origin: 'cycle',
                },"menu-options":_vm._cycleOptions,"infinity-scroll":true,"persistent":true,"local-search":true,"readonly":_vm._createMode ? !_vm._canCycleCreate : !_vm._canCycleEdit},on:{"update:item":function($event){return _vm.setDefaultCycle($event, i)}}})],1)],1)}),0):_vm._e(),_c('v-card',{staticClass:"message-card",attrs:{"flat":""}},[_c('div',{staticClass:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('PerformanceTab.cycleForm.message.text1'))}}),_c('br'),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('PerformanceTab.cycleForm.message.text2'))}})])])],1)])]),_c('ConfirmDefault',{ref:"confirmDefault",attrs:{"cycle-name":_vm.preSelectedCycles.length ? _vm.preSelectedCycles[0].title : ''},on:{"submit":function($event){return _vm.handleDefaultAction($event)}}}),_c('InsertModal',{ref:"insertModal",attrs:{"cycle-type":true,"filter-only-root-cycles":true},on:{"linkSelected":function($event){return _vm.setHierarchy($event)}}}),(_vm._editionMode)?_c('DeleteCycle',{ref:"deleteCycle",on:{"success":function($event){return _vm.$emit('close')},"display-alert":function($event){return _vm.$emit('display-alert', $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }