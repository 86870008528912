<template>
  <div class="management-selected-cycle" data-test-management-selected-cycle>
    <DetailSectionAccordion
      type="settings"
      :title="$t('managementCycleForm.sectionManagementSelectedCycle.title')"
      :opened="true"
    >
      <div class="content-toggle">
        <CustomSwitch
          :value="toggleValue"
          :readonly="!_canCycleSelected"
          @input="setValue($event)"
        ></CustomSwitch>

        <span class="message-title">{{ handleTitle() }}</span>
      </div>

      <span class="message">{{
        $t('managementCycleForm.sectionManagementSelectedCycle.ToggleMessage1')
      }}</span>

      <span class="message">{{
        $t('managementCycleForm.sectionManagementSelectedCycle.ToggleMessage2')
      }}</span>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import { management_performance_cycle_selection } from '@/helpers/ability'
import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import { updateEntityOkrSettings } from '@/services/papers'

export default {
  name: 'ManagementSelectedCycle',

  props: {
    hierarchySettings: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      toggleValue: false,
    }
  },

  computed: {
    _canCycleSelected() {
      return this.$can('access', management_performance_cycle_selection)
    },
  },

  watch: {
    hierarchySettings() {
      this.handleValueByHierarchySettings()
    },
  },

  methods: {
    handleValueByHierarchySettings() {
      this.toggleValue = this.hierarchySettings.allowSelectRootCycle
        ? this.hierarchySettings.allowSelectRootCycle
        : false
    },
    setValue(value) {
      this.toggleValue = value

      this.updateHierarchySettings()
    },
    async updateHierarchySettings() {
      const payload = {
        ...this.hierarchySettings,
        allowSelectRootCycle: this.toggleValue,
        ...(!!this.hierarchySettings.suggestedCycle?.id && {
          suggestedCycle: { id: this.hierarchySettings.suggestedCycle.id },
        }),
      }

      if (!payload?.suggestedCycle?.id) payload.suggestedCycle = null

      const entityHierarchyID = payload.id
      delete payload.id

      await updateEntityOkrSettings(entityHierarchyID, payload)
        .then(() => {
          alertSuccessBus(
            this.$t(
              'managementCycleForm.sectionManagementSelectedCycle.alert.success'
            )
          )

          setTimeout(() => {
            this.$emit('handleHierarchySettings')
          }, 1000)
        })
        .catch(error => {
          alertErrorBus(
            this.$t(
              'managementCycleForm.sectionManagementSelectedCycle.alert.error'
            ),
            error
          )
        })
    },
    handleTitle() {
      return this.$t(
        'managementCycleForm.sectionManagementSelectedCycle.activeToggleTitle'
      )
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
