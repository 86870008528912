<template>
  <div class="groups-page">
    <TableView
      v-if="!treeview"
      ref="table"
      @handleAction="handleTreeAction"
    ></TableView>

    <TreeView
      v-if="treeview"
      ref="tree"
      @handleAction="handleTreeAction"
    ></TreeView>

    <SideQuickRegister
      v-if="quickRegister"
      ref="quickRegister"
      :title="quickRegister.actionSelected.title"
      :image-title="quickRegister.actionSelected.image"
      :header-buttons="quickRegister.headerButtons"
      :footer-buttons="quickRegister.footerButtons"
      :popover-options="quickRegister.popoverOptions"
      :show="quickRegister.show"
      @handleAction="handleSideQuickAction"
    >
      <template #default="{ alert: callAlert, loading: callLoading }">
        <router-view
          ref="groupsForm"
          :handle-loading="handleLoading"
          :handle-open-quick-register="handleOpenQuickRegister"
          @success-create="handleCreated"
          @success-move="handleMoveGroup"
          @success-update="handleUpdated"
          @updated-subgroups="handleUpdateSubgroups"
          @updated-people="handleUpdatePeople"
          @close="handleSideQuickAction('close')"
          @loading="callLoading"
          @display-alert="handleAlert($event, callAlert)"
        ></router-view>
      </template>
    </SideQuickRegister>

    <MoveModal v-bind="modalConfig.move" ref="moveModal"></MoveModal>

    <DeleteModal v-bind="modalConfig.delete" ref="deleteModal"></DeleteModal>

    <DuplicateModal
      v-bind="modalConfig.duplicate"
      ref="duplicateModal"
    ></DuplicateModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getGroupSubgroups } from '@/services/groups'
import TableView from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/views/TableView/TableView.vue'
import TreeView from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/views/TreeView/TreeView.vue'

import MoveModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/MoveModal/MoveModal.vue'
import DuplicateModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/DuplicateModal/DuplicateModal.vue'
import DeleteModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/DeleteModal/DeleteModal.vue'

export default {
  name: 'GroupsPage',

  components: {
    TableView,
    TreeView,
    DuplicateModal,
    DeleteModal,
    MoveModal,
  },

  inject: [
    'handleHideHeaders',
    'closeGroupsForm',
    'openGroupsForm',
    'closeSideQuickForm',
    'openSideQuickForm',
  ],

  provide() {
    return {
      handleConfigModal: this.handleConfigModal,
      toggleViews: this.toggleViews,
    }
  },

  props: {
    quickRegister: {
      type: Object,
    },
  },

  data() {
    return {
      modalConfig: {
        duplicate: null,
        delete: null,
        move: null,
        insert: null,
      },
      treeview: false,
    }
  },

  computed: {
    treeRef() {
      return this.$refs.tree.$refs.tree
    },
  },

  watch: {
    treeview(state) {
      this.handleHideHeaders(state)
    },
  },

  destroyed() {
    this.setVisibleFilterLateral(true)
  },

  mounted() {
    this.setCurrentPage('GroupsPage')
    this.setVisibleFilterLateral(false)
  },

  methods: {
    ...mapActions([
      'setCurrentPage',
      'setVisibleLateralMenu',
      'setVisibleFilterLateral',
    ]),
    toggleViews(state) {
      this.setVisibleLateralMenu(!state)
      this.treeview = state
    },
    handleLoading(event) {
      this.$refs.quickRegister.handleLoading(event)
    },
    pushChild(child, node) {
      this.treeRef.pushChild(child, node)
    },
    handleTreeAction(payload) {
      switch (payload.action) {
        case 'viewGroup':
          return this.openSideQuickForm(payload.action, payload.target)
        case 'addGroup':
          return this.openGroupsForm(payload)
      }
    },
    handleSideQuickAction(action) {
      switch (action) {
        case 'close':
          return this.closeSideQuickForm('GroupsPage')
        case 'submit':
          return this.$refs.groupsForm.handleSubmit()
        case 'active':
          return this.$refs.groupsForm.changeStatus(true)
        case 'inactive':
          return this.$refs.groupsForm.changeStatus(false)
        case 'delete':
          return this.$refs.groupsForm.deleteGroup()
      }
    },
    handleOpenQuickRegister(action, groupid, index) {
      if (action === 'close') return this.closeSideQuickForm('GroupsPage')

      this.openSideQuickForm(action, groupid, index)
    },
    handleConfigModal(modal, config) {
      switch (modal) {
        case 'move':
          this.modalConfig.move = config
          return this.$refs.moveModal.toggleModal(true)
        case 'duplicate':
          this.modalConfig.duplicate = config
          return this.$refs.duplicateModal.toggleModal(true)
        case 'delete':
          this.modalConfig.delete = config
          return this.$refs.deleteModal.toggleModal(true)
      }
    },

    handleAlert(message, callAlert) {
      const config = {
        messagePrefix: '',
        description: message.description,
        type: message.type,
        hasLeftBorder: false,
        hasFooter: false,
      }

      callAlert(config)
    },

    handleCreated(data) {
      return this.treeview
        ? this.$refs?.tree.handleCreated(data)
        : this.$refs?.table.handleCreated(data)
    },
    handleUpdated(data) {
      return this.treeview
        ? this.$refs?.tree.handleUpdated(data)
        : this.$refs?.table.handleUpdated(data)
    },
    async handleMoveGroup(node) {
      if (this.treeview) {
        const refNode = await this.$refs.tree.$refs.tree.findNodeBy(
          'id',
          node.id
        )

        this.$refs.tree.handleMoveTree(refNode, node.parentGroupID)
        return
      }

      const tableElement = this.$refs.table
      const { rows, controls } = tableElement.$refs.table
      const showAlert = false

      tableElement.handleMoveTable(node, node.parentGroupID, null, showAlert)

      await getGroupSubgroups(node.parentGroupID).then(res => {
        const parentIndex = rows.findIndex(e => e.id === node.parentGroupID)

        if (parentIndex >= 0 && controls[parentIndex].visible) {
          rows[parentIndex].children = res.data
          rows[parentIndex].childrenCount = res.data.length
        }
      })
    },
    async handleUpdateSubgroups(act, groupID, subgroupID, showAlert) {
      return this.treeview
        ? this.$refs?.tree.handleUpdateSubgroups(
            act,
            groupID,
            subgroupID,
            showAlert
          )
        : this.$refs?.table.handleUpdateSubgroups(
            act,
            groupID,
            subgroupID,
            showAlert
          )
    },
    handleUpdatePeople(groupID, newPeopleCount) {
      return this.treeview
        ? this.$refs?.tree.handleUpdatePeople(groupID, newPeopleCount)
        : this.$refs?.table.handleUpdatePeople(groupID, newPeopleCount)
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
