<template>
  <Modal
    ref="modalChangesRequest"
    :title="$t('PermissionsPage.saveChangesRequest.title')"
    width="33.75rem"
    @close="submit('cancel')"
    @cancel="submit('cancel')"
  >
    <template v-slot:no-tab>
      <div class="dialog-content">
        <div class="text-message">
          <div>
            {{ $t('PermissionsPage.saveChangesRequest.text1') }}
          </div>
          <br />
          <div>
            <span class="highlight">
              {{ $t('PermissionsPage.saveChangesRequest.text2') }}
            </span>

            {{ $t('PermissionsPage.saveChangesRequest.text3') }}
          </div>
        </div>
      </div>

      <div class="dialog-footer">
        <v-spacer />

        <v-btn
          class="text-none text-btn"
          data-test-request-cancel
          text
          @click="submit('cancel')"
        >
          {{ $t('PermissionsPage.saveChangesRequest.cancel') }}
        </v-btn>

        <v-btn
          class="text-none primary-btn"
          color="primary"
          data-test-request-not-apply
          depressed
          @click="submit('not-apply')"
        >
          {{ $t('PermissionsPage.saveChangesRequest.notApply') }}
        </v-btn>

        <v-btn
          class="text-none primary-btn"
          color="primary"
          data-test-request-apply
          depressed
          @click="submit('apply')"
        >
          {{ $t('PermissionsPage.saveChangesRequest.apply') }}
        </v-btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      element: null,
    }
  },

  methods: {
    handleDialog(opened = null) {
      this.$refs.modalChangesRequest.handleDialog(opened)
    },

    open(event) {
      this.element = event
      this.handleDialog()
    },

    submit: _.debounce(function (action) {
      this.$emit('submit', { action: action, ...this.element })
      this.element = null

      this.handleDialog(false)
    }, 150),
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
