<template>
  <div class="tree-node">
    <v-textarea
      class="text-area"
      ref="form"
      rows="3"
      error-count="1"
      v-model="text"
      :background-color="colors['neutral']"
      :color="colors['neutral2']"
      :counter="length"
      :rules="[rules.required, rules.length(length)]"
      hide-spin-buttons
      no-resize
      filled
      @keydown.enter="handleKeyEnter"
    />
    <div class="actions">
      <v-btn :disabled="loading" @click="handleCancel" icon x-small>
        <v-icon x-small>fi-br-cross</v-icon>
      </v-btn>
      <v-btn
        :disabled="_disableSave"
        :loading="loading"
        @click="handleSave"
        icon
        x-small
      >
        <v-icon :color="colors['greencolor']" x-small>fi-br-check</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import colors from '@/styles/scss/temp.js'

import { insertGroup } from '@/services/groups'

export default {
  name: 'TreeNode',
  props: {
    node: {
      type: Object,
    },
    collapsed: {
      type: Boolean,
    },
    length: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    _disableSave() {
      return !this.text || !this.text.length || this.loading
    },
  },
  data() {
    return {
      loading: false,
      colors: colors,
      text: '',
      rules: {
        required: v => !!v || this.$t('TextQuickEdit.validations.required'),
        length: len => v =>
          (v || '').length <= len ||
          this.$tc('TextQuickEdit.validations.length', this.length),
      },
    }
  },
  methods: {
    handleKeyEnter() {
      this.handleSave()
    },
    async handleSave() {
      const node = {
        name: this.text,
        image: null,
        costCenter: null,
        inactive: false,
        parentGroupID: this.node.parentGroupID,
        subgroupIDs: [],
        people: [],
      }

      try {
        const newNode = await insertGroup(node)
        this.node.callback(newNode)
      } catch {
        this.node.fail()
      }
    },
    handleCancel() {
      this.node.cancel()
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
