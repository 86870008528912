var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-entities-configurtions":""}},[_c('DetailSectionAccordion',{attrs:{"type":"settings","title":_vm.$t('PerformanceTab.EntitiesConfigurations.title'),"opened":true}},[_vm._l((_vm._settings),function(configuration){return [(configuration.type === 'v-checkbox')?_c('v-checkbox',{key:configuration.id,staticClass:"custom-checkbox",attrs:{"id":configuration.id,"data-test-configuration":configuration.id,"label":_vm.$t(
            `PerformanceTab.EntitiesConfigurations.settings.${configuration.id}`
          ),"value":_vm._formConfigurationId,"color":"#dd0041"},on:{"change":function($event){return _vm.handleSettings(configuration, $event)}}}):_vm._e()]}),_c('div',{staticClass:"cycle-selection-content",attrs:{"data-test-cycle-selection":""}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-loading",attrs:{"type":"button"}}):_c('ItemSelector',{ref:"cycleInput",attrs:{"input-config":{
          label: _vm.$t(
            'PerformanceTab.EntitiesConfigurations.settings.definitionCycle'
          ),
          showAvatar: false,
          closeOnSelect: true,
        },"menu-config":{
          attach: true,
          showTabs: false,
        },"current-value":_vm.currentCycle,"menu-options":_vm._cycleOptions,"infinity-scroll":true,"persistent":true,"watch-current":true},on:{"update:item":function($event){return _vm.handleCycleChange($event)},"infinity:scroll":function($event){return _vm.handleCycleScroll($event)},"search:item":function($event){return _vm.searchCycle($event)},"focus:input":function($event){_vm.searchCycles = []}}}),_c('v-tooltip',{attrs:{"min-width":"800","max-width":"800","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"tooltip-icon",attrs:{"data-test-definition-cycle":"","size":"0.75rem"}},'v-icon',attrs,false),on),[_vm._v(" fi-rr-info ")])]}}])},[_c('div',{attrs:{"data-test-definition-cycle-tooltip":""},domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'PerformanceTab.EntitiesConfigurations.settings.definitionCycleTooltip'
            )
          )}})])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }