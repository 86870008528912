var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-imput",attrs:{"data-test-lateral-menu-people":""}},[_c('div',{staticClass:"group-imput-item",attrs:{"data-test-select-people":""}},[_c('ItemSelector',{attrs:{"inputConfig":{
        label: _vm.$t('LateralMenu.PermissionsMenu.peopleCombo'),
        showAvatar: true,
      },"menuConfig":{
        attach: true,
      },"menuOptions":_vm.listPeople,"fillWidth":true,"multiple":true,"showElements":true},on:{"update:item":function($event){return _vm.setValuePeople($event)},"focus:input":function($event){return _vm.getOptionsPeople()},"search:item":function($event){return _vm.getOptionsPeople($event)}}})],1),_c('div',{staticClass:"group-imput-item",attrs:{"data-test-select-permission":""}},[_c('ItemSelector',{attrs:{"inputConfig":{
        label: _vm.$t('LateralMenu.PermissionsMenu.functionalitiesCombo'),
        showAvatar: false,
      },"menuConfig":{
        attach: true,
      },"menuOptions":_vm.listFunctionalities,"fillWidth":true,"multiple":true,"showElements":true},on:{"update:item":function($event){return _vm.setValueFunctionalities($event)},"focus:input":function($event){return _vm.getOptionsFunctionalities()},"search:item":function($event){return _vm.getOptionsFunctionalities($event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }