import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getPeople(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'name',
      'permissionGroupID',
      'directManagerID',
      'occupationID',
      'showInactive',
      'module',
    ],
    data
  )

  return (await api()).get('/management/people', { params })
}

export const getPerson = async data =>
  (await api()).get(`/management/people/${data}`)

export const createPerson = async data =>
  (await api()).post('/management/people', data.body, { params: data.params })

export const updatePerson = async data =>
  await (await api()).put(`/management/people/${data.personID}`, data.body)

export const changePersonStatus = async data =>
  await (
    await api()
  ).put(`/management/people/${data.personID}/status/${data.status}`)

export async function resendPersonInvite(data) {
  return await (
    await api()
  ).post('/management/people/:personID/resend-invite', undefined, {
    _params: {
      personID: data.personID,
    },
  })
}

export async function getPeopleRoles(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'label'], data)

  return (await api()).get('/management/people/roles', {
    params: params,
    ...signal,
  })
}

export async function addPeopleToPermissionGroup(personID, groupID) {
  return (await api()).put(
    `/management/people/${personID}/permission-groups/${groupID}`
  )
}

export async function removePeopleFromPermissionGroup(personID, groupID) {
  return (await api()).delete(
    `/management/people/${personID}/permission-groups/${groupID}`
  )
}

export const deletePerson = async personID =>
  (await api()).delete(`/management/people/${personID}`)

export async function advancedSearch(data) {
  const params = constructParams(
    [
      'sortBy',
      'sortOrder',
      'limit',
      'offset',
      'name',
      'statuses',
      'groupIDs',
      'roleIDs',
      'occupationIDs',
      'directManagerIDs',
      'permissionGroupIDs',
      'attributes',
      'showInactive',
      'showDeleted',
    ],
    data
  )
  const signal = extractSignal(data)

  return (await api()).post('/management/people/search', params, {
    ...signal,
  })
}

export async function getHistory(personID, limit, offset) {
  const params = {}
  if (limit) Object.assign(params, { limit })
  if (offset) Object.assign(params, { offset })
  return (await api()).get(`/management/people/${personID}/history`, {
    params,
  })
}
