<template>
  <div v-if="story && story.parentIndex" v-show="show" class="history-list">
    <div
      v-for="(hist, i) in history.slice().reverse()"
      :key="hist.key"
      :class="{ gray: !!(i % 2) }"
    >
      <div class="d-flex history-btn" @click="openNodesByHistoryIndex(i)">
        <v-icon class="right-arrow" :size="16">mdi-arrow-left</v-icon>
        <component
          v-if="column.component"
          :is="column.component"
          v-bind="setColumnProps(column, hist.row, rowIndex)"
        />
        <v-icon class="left-arrow" :size="16">mdi-arrow-left</v-icon>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HistoryList',
  props: {
    row: Object,
    show: Boolean,
    controls: Array,
    stories: Array,
    rowIndex: Number,
    toggleNodeChildren: Function,
  },
  inject: ['columns', 'components', 'hideNodesAfter'],
  computed: {
    column() {
      return this.columns[0]
    },
    story() {
      return this.stories.find(story => story.key === this.row.key)
    },
    history() {
      const history = []
      let nodeIndex = this.story.nodeIndex

      do {
        const node = this.stories.find(story => story.nodeIndex === nodeIndex)

        if (!node) return history
        if (node.nivel < this.hideNodesAfter) return history

        nodeIndex = node.parentIndex
        history.push(node)
      } while (nodeIndex)

      return history
    },
  },
  methods: {
    openNodesByHistoryIndex(index) {
      const history = this.history
      const lastIndex = history.length - 1 - index

      history.forEach((hist, i) => {
        if (i <= lastIndex) {
          this.toggleNodeChildren(this.controls, hist.nodeIndex)
        }
      })
    },
    setColumnProps(column, row, rowIndex) {
      return column.getComponentProps(row, rowIndex)
    },
  },
  beforeMount() {
    this.components.forEach(component => {
      this.$options.components[component.name] = component.component
    })
  },
}
</script>
<style src="../style.scss" lang="scss" scoped />
