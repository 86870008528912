<template>
  <div class="d-flex action-buttons">
    <div
      v-for="icon in _inline"
      class="container"
      :key="icon.name"
      @click="handleAction(icon.action)"
    >
      <v-icon x-small class="container-icon">{{ icon.icon }}</v-icon>
    </div>
    <MoreOptions @handleAction="handleAction" :options="_more"></MoreOptions>
  </div>
</template>
<script>
export default {
  name: 'MoreActions',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleAction(action) {
      this.$emit('handleAction', action)
    },
  },
  computed: {
    _inline() {
      return this.options.filter(option => option?.inline)
    },
    _more() {
      return this.options.filter(option => !option?.inline)
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
