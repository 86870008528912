<template>
  <div>
    <div v-if="!editMode" class="editable">
      <span class="custom-chip-label" data-test-input-edit-value>
        {{ currentValue }}
      </span>

      <i
        v-if="readonly"
        data-test-pecil-edit
        class="fi-rr-pencil"
        @click="handleEdit()"
      />
    </div>

    <div ref="input">
      <v-text-field
        v-if="editMode"
        ref="textField"
        v-click-outside="handleEdit"
        v-model="newValue"
        color="primary"
        :full-width="false"
        hide-details
        data-test-input-edit
        dense
        @keydown="keydown($event)"
      >
        <template v-slot:append-outer>
          <div>
            <i
              class="fi-rr-cross"
              data-test-cancel-edit
              @click="handleEdit()"
            />
            <i
              class="fi-rr-check"
              data-test-confirm-edit
              @click="confirmEdit()"
            />
          </div>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputEdit',

  data() {
    return {
      editMode: false,
      currentValue: '',
      newValue: '',
      inputWidth: 0,
    }
  },

  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
        disabled: false,
        lockChangeLocally: false,
      }),
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  mounted() {
    this.changeValue(this.data.value)
  },

  watch: {
    'data.value': {
      handler(val) {
        this.changeValue(val)
      },
      deep: true,
    },
  },

  methods: {
    keydown(event) {
      if (event.key !== 'Enter') return
      if (event.key === 'Enter') this.confirmEdit()
    },
    handleEdit() {
      this.newValue = this.currentValue

      if (this.currentValue != null && this.currentValue != '') {
        this.editMode = !this.editMode
        this.focusTextField()
      }
    },

    confirmEdit() {
      if (this.newValue === null || this.newValue === '') return
      this.$emit('update', { value: this.newValue, index: this.index })

      if (!this.data.lockChangeLocally) this.changeValue(this.newValue)
      this.handleEdit()
    },

    changeValue(val) {
      if (val === null) {
        this.editMode = true

        const element = this.$refs.input
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }

        this.focusTextField()
      }
      this.currentValue = val
    },

    focusTextField() {
      this.$nextTick(() => {
        if (this.$refs?.textField?.$refs?.input)
          this.$refs.textField.$refs.input.focus()
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
