<template>
  <div id="permissions-model">
    <template>
      <div class="template-content">
        <v-menu
          v-model="menuDrop"
          ref="permissions-model"
          :close-on-click="true"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="c-btn"
              v-bind="attrs"
              v-on="on"
              data-test-select-template
            >
              {{
                $t(
                  `PermissionsPage.permissionsModel.${
                    applied ? 'selectedText' : 'emptyText'
                  }`
                )
              }}

              <span v-if="selectedTemplate" data-test-selected-template>
                {{ selectedTemplate.label }}
              </span>
            </span>
          </template>

          <v-card>
            <div class="c-container">
              <span class="c-container-title">
                {{ $t('PermissionsPage.permissionsModel.menuText') }}
              </span>
            </div>

            <v-divider></v-divider>

            <div v-if="loading">
              <v-skeleton-loader
                v-for="i in 3"
                class="loader"
                type="text"
                :key="i"
              />
            </div>

            <EditableListItems
              v-show="!loading"
              ref="editableListItems"
              :items="templateList"
              @select="handleSelected($event)"
              @edit-items="handleEdit($event)"
              @compare-template="handleCompare($event)"
              @remove-item="removeItem($event)"
            />

            <v-spacer></v-spacer>

            <v-card-actions>
              <v-btn
                class="c-close mx-1 d-flex align-center button-text"
                text
                data-test-cancel-template
                @click="close()"
              >
                {{ $t('PermissionsPage.permissionsModel.close') }}
              </v-btn>

              <v-btn
                class="c-submit"
                color="mx-1 d-flex align-center button-default"
                text
                data-test-apply-template
                @click="submit()"
              >
                {{ $t('PermissionsPage.permissionsModel.apply') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <div v-if="comparison" class="comparison-chip">
          <span>
            {{ $t('PermissionsPage.permissionsModel.compareText') }}
            {{ comparison.label }}
          </span>

          <i class="fi-rr-cross-small icon" @click="handleCompare(null)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EditableListItems from './Partials/EditableListItems/EditableListItems.vue'

export default {
  components: { EditableListItems },

  data() {
    return {
      loading: true,
      menuDrop: false,
      templateList: [],
      applied: false,
      selected: null,
      selectedTemplate: null,
      comparison: null,
    }
  },

  props: {
    templates: {
      type: Array,
      required: true,
    },
  },

  watch: {
    templates: {
      handler(val) {
        this.setTemplateList(val)
      },
      deep: true,
    },

    menuDrop(val) {
      if (val) this.handleGetTemplates()
    },
  },

  methods: {
    submit() {
      const payload = this.selected
        ? this.templates.find(el => el.id === this.selected.id)
        : null

      if (!this.selectedTemplate && payload === null) {
        const alertPayload = {
          title: this.$t('PermissionsPage.permissionsModel.errorSelect.title'),
          description: this.$t(
            'PermissionsPage.permissionsModel.errorSelect.description'
          ),
          hasLeftBorder: true,
          type: 'error',
        }

        this.$emit('alert', alertPayload)
        return
      }

      this.selectedTemplate = this.selected
      this.$emit('submit', payload)
      this.applied = !!this.selected
      this.close()
    },

    handleGetTemplates() {
      this.loading = true
      this.$emit('get-templates')
    },

    handleSelected(event) {
      this.selected = event
    },

    handleCompare(event) {
      this.comparison = event
      this.$emit('compare-template', event)

      this.close()
    },

    setTemplateList(data) {
      this.templateList = JSON.parse(JSON.stringify(data))
      this.loading = false
    },

    clearSelected() {
      this.selectedTemplate = null
      this.selected = null
      this.applied = false

      if (this.$refs.editableListItems) {
        this.$refs.editableListItems.clearSelected()
      }
    },

    removeItem(event) {
      this.$emit('delete-template', event)
      this.close()
    },

    handleEdit(event) {
      this.$emit('edit-template', event)
    },

    close() {
      this.menuDrop = false
    },

    checkSelected(id) {
      if (this.selectedTemplate !== null && this.selectedTemplate.id === id) {
        this.clearSelected()
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
