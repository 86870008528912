<template>
  <div id="editable-list-items" data-test-editable-list-items>
    <v-list data-test-editable-list-items-v-list class="c-list">
      <div
        v-if="!listItems.length"
        data-test-empty-templates
        class="empty-list"
      >
        {{ $t('noItemsFound') }}
      </div>

      <div v-else>
        <v-list-item
          data-test-editable-list-items-v-list-item
          class="c-list-item"
          v-for="(item, index) in listItems"
          :key="index"
          :class="{
            editing: editingIndex === index && selectedItem?.id !== item.id,
            'v-list-item--active': selectedItem?.id === item.id,
          }"
          @click="checkingEditableMode(item)"
        >
          <v-list-item-content
            class="item"
            :class="{
              'item-hover': hoveredItemIndex === index,
              'item-editing': editingIndex !== null,
            }"
            @mouseover="hoveredItemIndex = index"
            @mouseleave="hoveredItemIndex = null"
          >
            <v-tooltip
              v-if="item.label.length > textSize && editingIndex !== index"
              top
            >
              <template v-slot:activator="{ on }">
                <div class="c-container-text-field" v-on="on">
                  <v-text-field
                    :ref="`item_${item.id}`"
                    v-model="listItems[index].label"
                    :disabled="editingIndex !== index || !modeEditableData"
                    :truncate="true"
                    solo
                    :rules="_requiredRule"
                    :class="{
                      truncated:
                        item.label.length > textSize && editingIndex !== index,
                    }"
                  />
                </div>
              </template>
              <span>{{ item.label }} </span>
            </v-tooltip>

            <v-text-field
              v-else
              :ref="`item_${item.id}`"
              v-model="listItems[index].label"
              :readonly="editingIndex !== index || !modeEditableData"
              solo
              :rules="_requiredRule"
            />

            <div v-if="!disabledActions" class="item-icon">
              <span
                v-if="modeEditableData && editingIndex === index"
                class="show-icon"
                @click.stop="discardEdit(index)"
              >
                <v-icon class="icon cross"> fi-rr-cross </v-icon>
              </span>

              <span
                v-if="modeEditableData && editingIndex === index"
                class="show-icon"
                @click.stop="saveEdit(item)"
              >
                <v-icon class="icon check"> fi-rr-check </v-icon>
              </span>

              <span
                v-if="!modeEditableData && hoveredItemIndex === index"
                class="hide-icon"
                @click.stop="edit(index, item.label)"
              >
                <v-icon class="icon pencil"> fi-rr-pencil </v-icon>
              </span>

              <span
                v-if="!modeEditableData && hoveredItemIndex === index"
                class="hide-icon"
                @click.stop="compare(item)"
              >
                <v-icon class="icon search"> fi-rr-search-alt </v-icon>
              </span>

              <span
                v-if="hoveredItemIndex === index && !modeEditableData"
                class="hide-icon"
                @click.stop="remove(item)"
              >
                <v-icon class="icon trash"> fi-rr-trash </v-icon>
              </span>
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'EditableListItems',

  data() {
    return {
      listItems: [],
      editingIndex: null,
      selectedItem: null,
      hoveredItemIndex: null,
      modeEditableData: false,
      originalText: '',
    }
  },

  props: {
    items: {
      type: Array,
    },

    textSize: {
      type: Number,
      default: 31,
    },

    disabledActions: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _requiredRule() {
      return [v => !!v || this.$t('requiredField')]
    },
  },

  watch: {
    items() {
      this.setListItems()
    },
  },

  mounted() {
    this.setListItems()
  },

  methods: {
    edit(index, label) {
      this.editingIndex = index
      this.modeEditableData = true
      this.originalText = label
    },

    discardEdit(index) {
      this.listItems[index].label = this.originalText
      this.clearEdit()
    },

    remove(item) {
      this.clearEdit()
      this.$emit('remove-item', item)
    },

    compare(item) {
      this.clearEdit()
      this.$emit('compare-template', item)
    },

    saveEdit(item) {
      const fieldRef = this.$refs[`item_${item.id}`][0]
      const isValid = fieldRef.validate()
      if (!isValid) {
        return
      }
      this.$emit('edit-items', item)
    },

    clearEdit() {
      this.editingIndex = null
      this.modeEditableData = false
      this.originalText = ''
    },

    checkingEditableMode(event) {
      if (this.modeEditableData) return

      this.selectedItem = this.selectedItem?.id === event.id ? null : event
      this.$emit('select', this.selectedItem)
    },

    setListItems() {
      this.listItems = JSON.parse(JSON.stringify(this.items))
    },

    clearSelected() {
      this.selectedItem = null
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
