<template>
  <div class="management-cycle-form" data-test-management-cycle-form>
    <ManagementSelectedCycle
      :hierarchy-settings="hierarchySettings"
      @handleHierarchySettings="handleHierarchySettings"
    ></ManagementSelectedCycle>

    <ListOrderCycles
      :hierarchy-settings="hierarchySettings"
      @handleHierarchySettings="handleHierarchySettings"
    ></ListOrderCycles>
  </div>
</template>

<script>
import { alertErrorBus } from '@/helpers/alerts'
import { getHierarchySettings } from '@/services/entities'

import ListOrderCycles from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/ManagementCycleForm/Parts/ListOrderCycles/ListOrderCycles.vue'
import ManagementSelectedCycle from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/ManagementCycleForm/Parts/ManagementSelectedCycle/ManagementSelectedCycle.vue'

export default {
  name: 'ManagementCycleForm',

  components: {
    ManagementSelectedCycle,
    ListOrderCycles,
  },

  data() {
    return {
      hierarchySettings: null,
    }
  },

  computed: {},

  mounted() {
    this.handleHierarchySettings()
  },

  methods: {
    async handleHierarchySettings() {
      await getHierarchySettings({ root: true })
        .then(res => {
          this.hierarchySettings = res?.data[0]
        })
        .catch(error => {
          alertErrorBus(
            this.$t('PerformanceTab.EntitiesConfigurations.alert.get.error'),
            error
          )
        })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
