<template>
  <tr>
    <td v-for="i in count" :key="i + 'td'">
      <v-skeleton-loader type="image" max-height="32"></v-skeleton-loader>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'rowGhost',
  props: {
    count: Number,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped />
