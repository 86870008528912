var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ItemSelector',{ref:"activeCycles",attrs:{"data-test-selector-active-cycles":"","currentValue":{
    data: _vm.selectedActiveCycles,
    origin: 'cycles',
  },"watchCurrent":true,"inputConfig":{
    label: _vm.$t('PermissionsPage.AddNewEntityRole.activeCycles.title'),
    showAvatar: false,
  },"menuConfig":{
    attach: false,
    showTabs: false,
  },"menuOptions":_vm._listActiveCycles,"fillWidth":true,"multiple":true,"showElements":true,"local-search":true},on:{"update:item":_vm.handleActiveCycle}})
}
var staticRenderFns = []

export { render, staticRenderFns }