<template>
  <div
    class="demographic-attributes-table"
    data-test-demographic-attributes-table
  >
    <CardWrapper
      :title="$t('DemographicAttributesPage.title')"
      :subtitle="
        $t('DemographicAttributesPage.subtitle', {
          n: tableConfig.xcount,
        })
      "
      :helper-props="{
        videoType: 'DemographicAttributesPage',
        short: true,
        hasVideo: false,
      }"
    ></CardWrapper>

    <DataTable
      :title="$t('DemographicAttributesPage.title')"
      :headers="headers"
      :items="_tableItens"
      :class="{ 'no-data': !tableConfig.xcount && !tableApiController.loading }"
      enable-pagination
      enable-pagination-limit-change
      hide-header
      hide-option
      hide-search
      :content-style="false"
      :total-items="tableConfig.xcount"
      :items-per-page="tableApiPayload.limit"
      :loading="tableApiController.loading"
      :mobile-breakpoint-switch="0"
      @updateList="handleDataTablePagination"
      @remove="handleAttributeRemove"
      v-on="$listeners"
    ></DataTable>
  </div>
</template>

<script>
import * as _permissions from '@/helpers/ability/permissions'
import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import {
  getPeopleAttributes,
  deletePeopleAttributes,
} from '@/services/people-attributes'

import {
  handleAxiosSignalAbortController,
  handleTablePaginationApiRequestWithController,
} from '@/helpers/api'

import _ from 'lodash'

export default {
  name: 'AttributesTable',

  data() {
    const tableConfig = {
      limitPagination: 8,
      xcount: 0,
    }

    return {
      callDelayedGetTableData: _.debounce(() => {
        this.tableApiController.loading = true
        this.getTableData()
      }, 500),

      axiosControllers: [],

      tableConfig: tableConfig,
      tableApiPayload: {
        offset: 0,
        limit: tableConfig.limitPagination,
      },
      tableApiController: {
        loading: true,
        itens: Array.from({ length: tableConfig.limitPagination }),
      },

      headers: [
        {
          text: this.$t(
            'DemographicAttributesPage.table.headers.translated-item'
          ),
          value: 'translated-item',
          type: 'translated-item',
        },
        {
          text: this.$t(
            'DemographicAttributesPage.table.headers.action-buttons'
          ),
          value: 'action-buttons',
          type: 'action-buttons',
          width: '5rem',
        },
      ],
    }
  },

  computed: {
    _tableItens() {
      return this.handleTableDataToItens(this.tableApiController.itens)
    },
    _canManagementAttributesDelete() {
      return this.$can('access', _permissions.management_attributes_delete)
    },
  },

  watch: {
    tableApiPayload: {
      handler(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue)) return

        this.callDelayedGetTableData()
      },
      deep: true,
    },
  },

  created() {
    this.$root.$on('demographic-attribute:table', this.handleSyncTableData)
  },

  beforeDestroy() {
    this.$root.$off('demographic-attribute:table', this.handleSyncTableData)
  },

  mounted() {
    this.callDelayedGetTableData()
  },

  methods: {
    async handleAttributeRemove(attribute) {
      if (!attribute?.id) return

      return await deletePeopleAttributes(attribute.id)
        .then(() => {
          this.tableApiController.itens = this.tableApiController.itens.filter(
            item => item.id !== attribute.id
          )
          this.tableConfig.xcount = Math.max(0, this.tableConfig.xcount - 1)

          alertSuccessBus(this.$t('DemographicAttributesPage.delete.success'))
        })
        .catch(error => {
          alertErrorBus(
            this.$t(
              'alerts.ManagementPeopleAttributesAttributeID.error.delete'
            ),
            error
          )
        })
    },

    handleSyncTableData(payload) {
      if (!payload?.id) {
        this.callDelayedGetTableData()
        return
      }

      const attribute = this.tableApiController.itens.findIndex(
        item => item.id === payload.id
      )
      if (attribute === -1) return

      this.$set(this.tableApiController.itens, attribute, payload)
    },

    handleTableDataToItens(data) {
      const minimunDataTableItem = {
        id: 'loading',
        'translated-item': {},
        'action-buttons': [],
      }

      let itens = Array.from(
        { length: this.tableConfig.xcount },
        () => minimunDataTableItem
      )

      if (!Array.isArray(data)) {
        return itens
      }

      data.map((item, index) => {
        if (typeof item !== 'object' || !Object.keys(item?.label)?.length) {
          itens.splice(index, 1, minimunDataTableItem)
          return
        }

        itens.splice(index, 1, {
          id: `${item.id}`,
          'translated-item': item,
          'action-buttons': [
            'edit',
            this._canManagementAttributesDelete && 'remove',
          ].filter(Boolean),
        })
      })

      return itens
    },

    async fetchTableData(apiEndpoint, payload) {
      const { signal, axiosControllers } = handleAxiosSignalAbortController(
        this.axiosControllers,
        'tableApiController'
      )
      this.axiosControllers = axiosControllers

      return await handleTablePaginationApiRequestWithController(
        apiEndpoint,
        payload,
        this.tableApiController,
        this.tableConfig,
        signal
      ).catch(error => {
        alertErrorBus(
          this.$t('alerts.ManagementPeopleAttributes.error.get'),
          error
        )
      })
    },
    async getTableData() {
      const payload = JSON.parse(JSON.stringify(this.tableApiPayload))

      return await Promise.allSettled([
        this.fetchTableData(getPeopleAttributes, payload),
      ])
    },

    updatePayload(payloadObject) {
      if (typeof payloadObject !== 'object') {
        return
      }

      this.tableApiPayload = {
        ...this.tableApiPayload,
        ...payloadObject,
      }
    },
    handleDataTablePagination(payload) {
      this.tableApiController.loading = true

      this.updatePayload({
        offset: payload.offset,
        limit: payload.limit,
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
