<template>
  <div class="attribute-form" data-test-attribute-form>
    <div class="attribute-form-label">
      <label
        v-text="$t('DemographicAttributesPage.form.default.name.label')"
      ></label>

      <div class="attribute-form-label-items">
        <template v-for="flagKey in flags">
          <AttributeInput
            ref="label"
            :key="`${flagKey}-${form.id}`"
            :loading="loading"
            :data-test-attribute-label-lang="flagKey"
            :data-test-attribute-label="form.id"
            class="attribute-form-label-item"
            :item="{ flag: flagKey, value: form?.label?.[flagKey] }"
            :custom-input-props="{
              label: $t('DemographicAttributesPage.form.default.name.input'),
              mandatory: !valid,
              readonly: readonly,
            }"
            @handleInput="handleLabel(form, flagKey, $event)"
          ></AttributeInput>
        </template>
      </div>
    </div>

    <div class="attribute-form-values">
      <label
        v-text="$t('DemographicAttributesPage.form.default.values.label')"
      ></label>

      <OrderDraggable
        :itens="form.values"
        :get-display-index="getDisplayIndex"
        :enableDraggable="!readonly"
        @update:itens="handleOrder"
      >
        <template #default="{ item, index }">
          <template v-for="flagKey in flags">
            <AttributeInput
              :key="`${flagKey}-${item.id}`"
              :loading="loading"
              :data-test-attribute-value-lang="flagKey"
              :data-test-attribute-value="item.id"
              class="attribute-form-values-item"
              :item="{ flag: flagKey, value: item?.label?.[flagKey] }"
              :custom-input-props="{
                label: $t(
                  'DemographicAttributesPage.form.default.values.input',
                  {
                    n: index + 1,
                  }
                ),
                readonly: readonly,
              }"
              @handleInput="handleInput(item, flagKey, $event)"
            ></AttributeInput>
          </template>

          <v-btn
            v-if="form.values.length > 1 && !readonly"
            icon
            class="attribute-form-values-item-remove-btn"
            @click="handleRemove(index)"
          >
            <v-icon size="1.125rem">fi-rr-trash</v-icon>
          </v-btn>
        </template>
      </OrderDraggable>
    </div>

    <div class="attribute-form-add" v-if="!readonly">
      <v-btn text class="attribute-form-add-btn" @click="handleAdd">
        <span v-text="$t('DemographicAttributesPage.form.default.add')"></span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import AttributeInput from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/Partials/AttributeInput/AttributeInput.vue'

import {
  ACTIONS_NEW,
  ACTIONS_VIEW,
} from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/Partials/AttributeView/AttributeView.vue'

const AttributeDefault = () => ({
  label: {
    'pt-BR': '',
    'en-US': '',
    'es-AR': '',
  },
  values: [
    {
      id: uuidv4(),
      label: {
        'pt-BR': '',
        'en-US': '',
        'es-AR': '',
      },
    },
  ],
})

export default {
  name: 'AttributeForm',
  components: { AttributeInput },
  props: {
    action: {
      type: String,
      default: () => ACTIONS_NEW,
      validator: value => [ACTIONS_NEW, ACTIONS_VIEW].includes(value),
    },

    flags: {
      type: Array,
      default: () => {
        return ['pt-BR', 'en-US', 'es-AR']
      },
    },

    attribute: {
      type: Object,
      default: () => {
        return AttributeDefault()
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: null,
      valid: false,
    }
  },

  watch: {
    attribute: {
      handler() {
        this.form = this.attribute
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    handleSync() {
      if (!this.validate()) return

      this.$emit('update:attribute', this.form)
    },
    submit() {
      if (!this.validate()) return

      this.$emit('submit', this.form)
    },
    validate() {
      this.valid = Object.values(this.form.label).some(
        value => value.trim() !== ''
      )

      this.$nextTick(() => {
        const labels = this.$refs.label
        labels.forEach(label => {
          label.validate()
        })
      })

      return this.valid
    },

    calcOrdenableItens(itens) {
      return itens.flatMap((item, index) => {
        if (!item?.label || !Object.keys(item.label).length) return []
        return { ...item, position: index }
      })
    },

    getDisplayIndex(item, index) {
      return `#${index + 1}`
    },

    handleLabel(item, flag, value) {
      this.$set(item.label, flag, value)
      this.handleSync()
    },

    handleInput(item, flag, value) {
      const reference = this.form.values.find(e => e.id == item.id)
      this.handleLabel(reference, flag, value)
    },

    handleOrder(ordenabled) {
      this.$set(this.form, 'values', ordenabled)
      this.handleSync()
    },

    handleAdd() {
      this.form.values.push({
        id: 'new-' + uuidv4(),
        label: {
          'pt-BR': '',
          'en-US': '',
          'es-AR': '',
        },
      })
    },

    handleRemove(index) {
      this.form.values.splice(index, 1)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
