<template>
  <v-menu
    ref="menu"
    v-model="menuDrop"
    :content-class="`${kebabNameMenu}-menu menu-form`"
    :z-index="_menuConfig.zIndex"
    :attach="_menuConfig.attach"
    :right="_menuConfig.align === 'right'"
    :left="_menuConfig.align === 'left'"
    :close-on-content-click="false"
    v-bind="_BindMeOnMenu"
    offset-y
    @input="handleCloseMenu"
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        :data-test-menu-open="kebabNameMenu"
        :data-test-menu-state-active="_formHasValue"
        :class="`${kebabNameMenu}-menu-activator`"
        v-on="on"
      >
        <slot
          name="default"
          v-bind="{
            stateMenu: menuDrop,
            formHasValue: _formHasValue,
            cleanValueCallback: handleActionClean,
          }"
        ></slot>
      </div>
    </template>

    <div class="menu-content" :data-test-menu-content="kebabNameMenu">
      <div class="menu-header">
        <span v-text="menu.title"></span>
      </div>

      <div class="menu-content" :class="`${kebabNameMenu}-menu-content`">
        <ComposeForm
          ref="ComposeForm"
          :form-name="kebabNameMenu"
          :selectors="selectors"
          :lazy-submit="false"
          :storage-preferences="false"
          @compose:values="edgeValues = $event"
        ></ComposeForm>
      </div>

      <div class="menu-footer">
        <template v-if="menu.footer.delete">
          <v-btn
            data-test-menu-delete
            class="action-delete"
            text
            @click="handleActionDelete"
          >
            <v-icon size="0.75rem" class="fi-rr-trash"></v-icon>
            <span v-text="menu.footer.delete"></span>
          </v-btn>

          <v-spacer></v-spacer>
        </template>

        <v-btn
          data-test-menu-cancel
          class="action-cancel"
          text
          @click="handleActionCancel"
        >
          <span v-text="menu.footer.cancel"></span>
        </v-btn>

        <v-btn
          data-test-menu-apply
          class="action-apply"
          elevation="0"
          :disabled="!_edgeHasValue"
          :loading="formIsAnimating"
          @click="handleActionApply"
        >
          <span v-text="menu.footer.apply"></span>
        </v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'MenuForm',

  props: {
    kebabNameMenu: {
      type: String,
      required: true,
      validator(value) {
        if (!value || value.trim().length === 0) {
          return false
        }

        const shouldBeKebabCaseRegex = /([a-z][a-z0-9]*)(-[a-z0-9]+)*/g
        return shouldBeKebabCaseRegex.test(value)
      },
    },

    selectors: {
      type: Object,
      required: true,
      default: () => ({
        fields: [],
      }),
    },

    menu: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        footer: {
          cancel: '',
          apply: '',
        },
      }),
    },
    menuConfig: {
      type: Object,
      default: () => ({}),
    },
    menuProps: {
      type: Object,
      default: () => ({}),
    },

    restorePreviousState: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      edgeValues: {},
      form: {},

      formIsAnimating: false,
      menuDrop: false,
    }
  },

  computed: {
    _menuConfig() {
      return {
        attach: false,
        zIndex: 9999,
        align: 'left',
        nudgeBottom: 3,
        ...this.menuConfig,
      }
    },
    _BindMeOnMenu() {
      return this.genBindMeOnMenu()
    },

    _formHasValue() {
      return Object.values(this.form).some(Boolean)
    },
    _edgeHasValue() {
      return Object.values(this.edgeValues).some(Boolean)
    },
  },

  methods: {
    genBindMeOnMenu() {
      const VBind = {
        ...this.menuProps,
      }

      return VBind
    },

    submit() {
      this.$emit('form:values', this.form)
    },

    closeMenu() {
      if (this.$refs.menu) {
        this.$refs.menu.save()
      }
    },

    handleCloseMenu(event) {
      if (event) {
        return
      }

      this.handleActionCancel()
    },

    managePreviousState(state = this.form) {
      this.edgeValues = structuredClone(state)

      if (this.$refs.ComposeForm) {
        this.$refs.ComposeForm.defineFieldsAsValue(state)
      }
    },

    handleActionDelete() {
      this.$emit('delete')
    },

    handleActionCancel() {
      if (this.restorePreviousState) {
        this.managePreviousState()
      }

      this.closeMenu()
    },

    handleActionApply() {
      this.form = structuredClone(this.edgeValues)

      this.submit()
      this.closeMenu()
    },

    handleActionClean() {
      this.form = {}

      this.handleActionCancel()
      this.submit()
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
