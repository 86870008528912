<template>
  <Modal ref="Modal" width="782px" :title="_title" :zIndex="999999999">
    <template v-slot:no-tab="{ dialogState }">
      <AuditLog v-if="dialogState" :AuditLog="AuditLog?.AuditLog"></AuditLog>
    </template>
  </Modal>
</template>

<script>
export const ACTIONS_VIEW = 'view'
export const ACTIONS_CLOSE = 'close'

import AuditLog from '@/views/ManagementPage/Tabs/DataPage/Tabs/AuditLogsPage/Partials/AuditLogsViewer/Partials/AuditLog/AuditLog.vue'

export default {
  name: 'AuditLogsViewer',
  components: {
    AuditLog,
  },
  data() {
    return {
      AuditLog: null,
    }
  },

  computed: {
    _title() {
      return this.AuditLog?.title || ''
    },
  },

  created() {
    this.$root.$on('audit-log:view', this.handleViewAction)
  },

  beforeDestroy() {
    this.$root.$off('audit-log:view', this.handleViewAction)
  },

  methods: {
    handleModalState(state) {
      if (!this.$refs.Modal) return
      this.$refs.Modal.handleDialog(state)
    },

    async handleViewAction(action, payload = null) {
      if ([ACTIONS_CLOSE].includes(action)) {
        this.handleModalState(false)
      }

      this.$set(this, 'AuditLog', payload)

      if ([ACTIONS_VIEW].includes(action)) {
        this.handleModalState(true)
      }
    },
  },
}
</script>
