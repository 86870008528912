import api from '@/services/Http'
import { constructParams } from '@/helpers/api'

export async function getGroups(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'name',
      'excludeHierarchyGroupID',
      'personID',
      'status',
      'module',
    ],
    {
      ...data,
      module: 'management',
    }
  )

  return (await api()).get('/management/groups', { params })
}

export const getGroup = async data =>
  (await api()).get(`/management/groups/${data}`)

export const updateGroup = async data =>
  (await api()).put(`/management/groups/${data.groupID}`, data.body)

export async function getGroupSubgroups(data) {
  return (await api()).get(`/management/groups/${data}/subgroups`)
}

export const addSubgroup = async data =>
  (await api()).put(
    `/management/groups/${data.groupID}/subgroups/${data.subgroupID}`
  )

export const removeSubgroup = async data =>
  (await api()).delete(
    `/management/groups/${data.groupID}/subgroups/${data.subgroupID}`
  )

export async function getGroupPeople(data) {
  const params = await constructParams(['module'], {})

  return (await api()).get(`/management/groups/${data}/people`, {
    params,
  })
}

export async function changeGroupStatus(
  groupID,
  newState,
  options = {
    people: false,
    hierarchy: false,
  }
) {
  const newStatus = newState ? 'activate' : 'inactivate'
  return await (
    await api()
  ).put(`/management/groups/${groupID}/${newStatus}`, options)
}

export async function fetchGroupsList(
  limit = 20,
  search = '',
  offset = 0,
  except = ''
) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'name',
      'excludeHierarchyGroupID',
      'personID',
      'status',
      'module',
    ],
    {
      limit,
      offset,
      name: search,
      excludeHierarchyGroupID: except,
      module: 'management',
    }
  )

  return (await api())
    .get('/management/groups', {
      params,
    })
    .then(({ data }) => data)
}

export async function fetchAccountNode(peopleCount = '--') {
  const { data } = await (await api()).get('/management/account')

  const root = {
    id: null,
    root: true,
    name: data.name,
    image: data.logo,
    inactive: false,
    peopleCount: peopleCount,
    description: data.description,
    parentGroupID: null,
    childrenCount: 0,
    children: [],
  }

  return root
}

export async function fetchAccountGroups() {
  const params = constructParams(['module', 'level'], {
    module: 'management',
    level: 1,
  })

  return (await api())
    .get('/management/groups/hierarchy', {
      params,
    })
    .then(({ data }) => data)
}

export async function fetchGroupParents(node) {
  const params = constructParams(['module'], {
    module: 'management',
  })

  return (await api())
    .get(`/management/groups/${node.id}/hierarchy`, {
      params,
    })
    .then(({ data }) => data)
}

export async function fetchGroupChildren(node) {
  return (await api())
    .get(`/management/groups/${node.id}/subgroups`)
    .then(({ data }) => data)
}

export async function fetchGroupPeople(node) {
  const params = await constructParams(['module'], {})

  return (await api())
    .get(`/management/groups/${node.id}/people`, {
      params,
    })
    .then(({ data }) => data)
}

export async function insertGroup(node) {
  const { data } = await (await api()).post('/management/groups', node)
  return data
}

export async function removeGroup(node) {
  const _api = await api()
  await _api.delete(`/management/groups/${node.id}`)
  return node
}

export async function editGroup(node, groupID) {
  const { data } = await (
    await api()
  ).put(`/management/groups/${groupID}`, node)
  return data
}

export const updatePersonGroup = async data =>
  (await api()).put(
    `/management/groups/${data.groupID}/people/${data.personID}`,
    data.body
  )

export const removePersonGroup = async data =>
  (await api()).delete(
    `/management/groups/${data.groupID}/people/${data.personID}`
  )

export async function getGroupsTree() {
  const params = constructParams(['module'], {
    module: 'management',
  })

  return (await api()).get('/management/groups/tree', {
    params,
  })
}

export async function addGroup(data) {
  return (await api()).post(`/management/groups`, data)
}

export async function getGroupsPeople(groupID) {
  const params = await constructParams(['module'], {})

  return (await api()).get(`/management/groups/${groupID}/people`, {
    params,
  })
}

export async function getHierarchyById(groupId, payload = { flat: true }) {
  const params = await constructParams(['module', 'flat'], {
    ...payload,
    module: 'management',
  })

  return (await api()).get(`/management/groups/${groupId}/hierarchy`, {
    params,
  })
}

export const getGroupHistory = async data =>
  (await api()).get(`/management/groups/${data}/history`)

export async function getHierarchyTree() {
  const params = constructParams(['module', 'level'], {
    module: 'management',
  })

  return (await api()).get('/management/groups/hierarchy', {
    params,
  })
}

export const deleteGroup = async data => {
  return (await api()).delete(`/management/groups/${data}`)
}
