import api from '@/services/Http'
const baseUrl = '/management/permissions-templates'

export const getTemplates = async data => {
  return (await api()).get(baseUrl, { params: data })
}

export const newTemplate = async data => {
  return (await api()).post(baseUrl, data)
}

export const updateTemplate = async data => {
  return (await api()).put(`${baseUrl}/${data.templateID}`, data.body)
}

export const deleteTemplate = async data => {
  return (await api()).delete(`${baseUrl}/${data}`)
}
