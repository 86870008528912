<template>
  <Modal
    ref="confirmDeleteTag"
    data-test-confirm-delete
    :confirm-button="$t('deleteNewPlannedModal.confirmButton')"
    :has-buttons="true"
    :title="$t('deleteNewPlannedModal.title')"
    width="33.75rem"
    :style="{ 'z-index': 1000 }"
    @confirmed="handleConfirm"
  >
    <template #no-tab>
      <div class="message content">
        <span>
          {{ $t('deleteNewPlannedModal.description') }}
          <span class="message strong">
            {{
              $t('deleteNewPlannedModal.descriptionStrong', {
                entityType: entityTypeLabel,
                entityClass: entityClassLabel,
              })
            }}
            {{ selectedCycle ? selectedCycle.cycle.label : '' }}
            {{ $t('deleteNewPlannedModal.questionMark') }}
          </span>
        </span>

        <br />
        <br />

        <span class="highlight">{{
          $t('deleteNewPlannedModal.rememberLabel')
        }}</span>
        {{
          $t('deleteNewPlannedModal.rememberDescription1', {
            entityType: entityTypeLabel,
            entityClass: entityClassLabel,
          })
        }}
        {{ selectedCycle ? selectedCycle.cycle.label : '' }}
        {{ $t('deleteNewPlannedModal.rememberDescription2') }}
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'DeleteNewPlannedModal',

  props: {
    entityTypeLabel: {
      type: String,
      default: '',
    },
    entityClassLabel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedCycle: null,
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('handle-delete-planned', this.selectedCycle)
      this.handleModal()
    },

    handleOpen(data) {
      this.selectedCycle = data
      this.handleModal()
    },

    handleModal() {
      this.$refs.confirmDeleteTag.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
