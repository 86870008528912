import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getHierarchySettings(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'entityType',
      'entityClass',
      'entityTypeAllowedChildren',
      'entityClassAllowedChildren',
      'entityTypeExclusiveParam',
      'root',
    ],
    data
  )

  return (await api()).get(`/performance/okrs/entities/hierarchy/settings`, {
    params: params,
    ...signal,
  })
}

export async function getHierarchySettingsWithChildren(entityID) {
  return (await api()).get(
    `/performance/okrs/entities/hierarchy/settings/${entityID}/children`
  )
}
