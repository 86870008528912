<template>
  <v-card-text
    id="dialog-body"
    class="dialog-body"
    :style="_style"
    @scroll="scroll"
  >
    <h4 class="text-center pt-2" v-show="checkIsEmpty">
      {{ $t('GroupsPage.treeSearch.noResultsFound') }}
    </h4>

    <component
      v-if="selected"
      :is="component"
      :node="selected"
      :cycleType="cycleType"
      :selected="true"
      key="selected"
    />
    <template v-for="node in nodes">
      <component
        v-if="component"
        v-show="!node._selected"
        :cycleType="cycleType"
        :is="component"
        :node="node"
        :key="node._key"
        @selected="change(node)"
      />
    </template>
  </v-card-text>
</template>
<script>
export default {
  props: {
    cycleType: {
      type: Boolean,
      default: false,
    },
    nodes: {
      type: Array,
    },
    selected: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    component: {
      type: Object,
    },
    _style: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    checkIsEmpty() {
      if (!this.nodes.length && !!this.text) return true
      return false
    },
  },
  methods: {
    scroll(evt) {
      this.$emit('scroll', evt)
    },
    change(node) {
      this.$emit('change', node)
    },
  },
  beforeMount() {
    this.$options.components['ListItem'] = this.component
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
