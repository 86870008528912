<template>
  <div class="sidebar">
    <v-form ref="form" v-model="valid" data-test-cycle-form>
      <div id="cycle-form">
        <div v-if="loading" class="form">
          <v-row>
            <v-col v-for="n in 6" :key="n" cols="6">
              <v-skeleton-loader
                type="list-item-two-line"
                max-height="3.375rem"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

          <v-skeleton-loader
            class="loader-box"
            type="image"
          ></v-skeleton-loader>

          <v-skeleton-loader
            v-for="n in 6"
            :key="n"
            type="list-item-two-line"
            max-height="3.375rem"
          ></v-skeleton-loader>
        </div>

        <div v-else class="form">
          <section class="form-section">
            <v-row>
              <v-col cols="12" class="form-column">
                <CustomInput
                  ref="label"
                  :current-value="form.title"
                  :mandatory="true"
                  :view-mode="_editionMode"
                  :label="$t('PerformanceTab.cycleForm.name')"
                  :readonly="_createMode ? !_canCycleCreate : !_canCycleEdit"
                  @handleInput="handleInput($event, 'title', 750)"
                ></CustomInput>
              </v-col>

              <v-col cols="12" md="6" class="form-column">
                <CustomDatePicker
                  ref="startDate"
                  data-test-input-startdate
                  :label="$t('PerformanceTab.cycleForm.startDate')"
                  :text-input-props="{
                    'hide-details': 'auto',
                  }"
                  :menu-props="{
                    attach: true,
                    'z-index': 99,
                    'content-class': 'mt-1',
                    nudgeBottom: '-20',
                  }"
                  :close-on-click="true"
                  :date-selected="form.startDate"
                  :subtle-mode="_editionMode"
                  :readonly="_createMode ? !_canCycleCreate : !_canCycleEdit"
                  :rules="
                    _createMode ? _createStartDateRules : _editStartDateRules
                  "
                  @input="handleInput($event, 'startDate')"
                ></CustomDatePicker>
              </v-col>

              <v-col cols="12" md="6" class="form-column">
                <CustomDatePicker
                  ref="endDate"
                  data-test-input-endDate
                  :label="$t('PerformanceTab.cycleForm.endDate')"
                  :text-input-props="{
                    'hide-details': 'auto',
                  }"
                  :menu-props="{
                    attach: true,
                    'z-index': 99,
                    'content-class': 'mt-1',
                    nudgeBottom: '-20',
                  }"
                  :close-on-click="true"
                  :date-selected="form.endDate"
                  :subtle-mode="_editionMode"
                  :readonly="_createMode ? !_canCycleCreate : !_canCycleEdit"
                  :rules="_createMode ? _createEndDateRules : _editEndDateRules"
                  @input="handleInput($event, 'endDate')"
                ></CustomDatePicker>
              </v-col>

              <v-col cols="12" md="6" class="form-column">
                <ItemSelector
                  ref="cycleInput"
                  data-test-cycle-form-selection="status"
                  :input-config="{
                    label: $t('PerformanceTab.cycleForm.status'),
                    showAvatar: false,
                    closeOnSelect: true,
                    subtleMode: _editionMode,
                  }"
                  :menu-config="{
                    attach: true,
                    showTabs: false,
                  }"
                  :current-value="{
                    data: _currentStatus,
                    origin: 'status',
                  }"
                  :menu-options="_statusOptions"
                  :persistent="true"
                  :local-search="true"
                  :readonly="_createMode ? !_canCycleCreate : !_canCycleEdit"
                  @update:item="handleInput($event.id, 'status')"
                ></ItemSelector>
              </v-col>

              <v-col cols="12" md="6" class="form-column">
                <div class="toggle">
                  <v-tooltip bottom :disabled="!_blockToggle">
                    <template #activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        data-test-activator-selection
                        v-on="on"
                      >
                        <CustomSwitch
                          data-test-toggle-default
                          :value="form.current"
                          :readonly="!_canEditToggleCycleDefault"
                          @input="handleDefaultDialog"
                        ></CustomSwitch>
                      </div>
                    </template>

                    <div class="item-label-tooltip">
                      <span
                        v-html="
                          $t(
                            'PerformanceTab.cycleForm.tooltipBlockCycleDefault'
                          )
                        "
                      ></span>
                    </div>
                  </v-tooltip>

                  <div class="label">
                    {{ $t('PerformanceTab.cycleForm.default') }}
                  </div>

                  <v-menu
                    content-class="menu"
                    max-width="18.75rem"
                    open-on-hover
                    open-on-click
                    offset-y
                    offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <i
                        v-bind="attrs"
                        data-test-default-info
                        class="fi-rr-interrogation"
                        v-on="on"
                      ></i>
                    </template>

                    <v-card
                      class="menu-info"
                      width="18.75rem"
                      data-test-menu-info
                      flat
                    >
                      <div class="title">
                        {{ $t('PerformanceTab.cycleForm.defaultTitle') }}
                      </div>

                      <div class="label">
                        {{ $t('PerformanceTab.cycleForm.defaultInfo') }}
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </section>

          <DetailSectionAccordion
            class="form-section"
            type="tree"
            :title="
              form.parentCycleID || _createMode
                ? $t('PerformanceTab.cycleForm.hierarchy.title')
                : $t('PerformanceTab.cycleForm.hierarchy.title2')
            "
            :text-button="$t('GroupsPage.viewGroup.hierarchy.linkHierarchy')"
            :opened="true"
            :has-button="!cycleID && hierarchy.length > 0"
            data-test-cycle-hierarchy
            @headerClick="handleHierarchyDialog()"
          >
            <CycleHierarchy
              v-if="
                hierarchy.length > 0 &&
                (form.parentCycleID || hierarchy[0].children.length)
              "
              :items="hierarchy"
              :root="!form.parentCycleID && _editionMode"
              data-test-hierarhcy-list
            ></CycleHierarchy>

            <div v-else class="hierarchy" data-test-hierarchy-empty>
              <div class="label">
                {{
                  _editionMode
                    ? $t('PerformanceTab.cycleForm.hierarchy.locked')
                    : $t('PerformanceTab.cycleForm.hierarchy.empty')
                }}
              </div>

              <div
                v-if="!_editionMode"
                data-test-link-hierarchy
                @click="handleHierarchyDialog()"
              >
                <i class="fi fi-br-plus"></i>

                <span class="label label-link">
                  {{ $t('PerformanceTab.cycleForm.hierarchy.bttnLink') }}
                </span>
              </div>
            </div>
          </DetailSectionAccordion>

          <DetailSectionAccordion
            v-if="!form.parentCycleID"
            class="form-section"
            type="tree"
            :title="$t('PerformanceTab.cycleForm.hierarchySettings.title')"
            :opened="true"
            data-test-cycle-hierarchy-model
          >
            <div
              v-for="(item, i) in form.hierarchySettings"
              :key="i"
              class="hierarchy-model"
              :style="`padding-left: ${i * 8}px`"
            >
              <v-img
                :src="require(`@/assets/icons/${item.entityType}.png`)"
                max-width="1.625rem"
                height="1.625rem"
              ></v-img>

              <div class="label">
                {{
                  $t(
                    `PerformanceTab.cycleForm.hierarchySettings.${item.entityType}`
                  )
                }}

                <span v-if="item.entityClass">
                  {{
                    $t(
                      `PerformanceTab.cycleForm.hierarchySettings.${item.entityClass}`
                    )
                  }}
                </span>
              </div>

              <v-spacer></v-spacer>

              <div class="input">
                <ItemSelector
                  ref="cycleInput"
                  :input-config="{
                    label: 'Ciclo padrão',
                    showAvatar: false,
                    closeOnSelect: true,
                    subtleMode: _editionMode,
                  }"
                  :menu-config="{
                    attach: true,
                    showTabs: false,
                  }"
                  :current-value="{
                    data: [
                      item.defaultCycle && item.defaultCycle.id
                        ? {
                            id: item.defaultCycle.id,
                            label: item.defaultCycle.name,
                          }
                        : _cycleOptions[0].items[0],
                    ],
                    origin: 'cycle',
                  }"
                  :menu-options="_cycleOptions"
                  :infinity-scroll="true"
                  :persistent="true"
                  :local-search="true"
                  :readonly="_createMode ? !_canCycleCreate : !_canCycleEdit"
                  @update:item="setDefaultCycle($event, i)"
                ></ItemSelector>
              </div>
            </div>
          </DetailSectionAccordion>

          <v-card class="message-card" flat>
            <div class="label">
              <span
                v-html="$t('PerformanceTab.cycleForm.message.text1')"
              ></span>
              <br /><br />
              <span
                v-html="$t('PerformanceTab.cycleForm.message.text2')"
              ></span>
            </div>
          </v-card>
        </div>
      </div>
    </v-form>

    <ConfirmDefault
      ref="confirmDefault"
      :cycle-name="preSelectedCycles.length ? preSelectedCycles[0].title : ''"
      @submit="handleDefaultAction($event)"
    ></ConfirmDefault>

    <InsertModal
      ref="insertModal"
      :cycle-type="true"
      :filter-only-root-cycles="true"
      @linkSelected="setHierarchy($event)"
    ></InsertModal>

    <DeleteCycle
      v-if="_editionMode"
      ref="deleteCycle"
      @success="$emit('close')"
      @display-alert="$emit('display-alert', $event)"
    ></DeleteCycle>
  </div>
</template>

<script>
import {
  createCycles,
  editCycle,
  putStatusCycle,
  getCycle,
  getCycles,
  getCycleHierarchy,
  getCycleHierarchyChildren,
  getCyclesTree,
} from '@/services/cycles/index.js'
import { getHierarchySettings } from '@/services/entities'
import { formatDate } from '@/helpers/strings/date/index'
import {
  management_performance_cycle_create,
  management_performance_cycle_edit,
} from '@/helpers/ability'
import ConfirmDefault from './partials/ConfirmDefault/ConfirmDefault.vue'
import CycleHierarchy from './partials/CycleHierarchy/CycleHierarchy.vue'
import DeleteCycle from './partials/DeleteCycle/DeleteCycle.vue'
import InsertModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/InsertModal/InsertModal'

import { alertErrorBus } from '@/helpers/alerts'

export default {
  name: 'CycleForm',

  components: {
    ConfirmDefault,
    CycleHierarchy,
    InsertModal,
    DeleteCycle,
  },

  props: {
    showAlert: {
      type: Function,
    },
  },

  data() {
    return {
      listCycles: [],
      hierarchySettings: null,
      loading: true,
      gettingData: false,
      valid: false,
      interval: null,
      cycleID: null,
      parentPeriod: {
        startDate: null,
        endDate: null,
      },
      currentPeriod: {
        startDate: null,
        endDate: null,
      },

      hierarchy: [],
      form: {
        title: '',
        startDate: null,
        endDate: null,
        status: 'active',
        current: false,
        parentCycleID: null,
        hierarchySettings: [],
      },
      preSelectedCycles: [],
      sameFamily: false,
    }
  },

  computed: {
    _canEditToggleCycleDefault() {
      if (this._createMode) return this._canCycleCreate

      if (this._editionMode) {
        return this._canCycleEdit && !this._blockToggle
      }

      return false
    },
    _blockToggle() {
      const cycleIsRoot = this.form.parentCycleID === null
      const cycleSelected = this.listCycles.find(e => e.id === this.cycleID)
      const cycleHaveChildrens = cycleSelected?.children?.length >= 1

      const notAllowSelectRootCycle =
        !this.hierarchySettings?.allowSelectRootCycle

      if (cycleHaveChildrens && cycleIsRoot && notAllowSelectRootCycle) {
        return true
      }

      return false
    },
    _editionMode() {
      return this.$route.name === 'EditCycle' && !!this.cycleID
    },

    _createMode() {
      return this.$route.name === 'NewCycle'
    },

    _cycleOptions() {
      return [
        {
          value: 'cycle',
          label: this.$t(
            'PerformanceTab.cycleForm.hierarchySettings.defaultCycle'
          ),
          type: 'listview',
          items: [
            {
              id: null,
              label: this.$t(
                'PerformanceTab.cycleForm.hierarchySettings.inheritance'
              ),
            },
            ...this._cycles,
          ],
        },
      ]
    },

    _statusOptions() {
      return [
        {
          value: 'status',
          label: this.$t('PerformanceTab.cycleForm.status'),
          type: 'listview',
          items: [
            {
              id: 'active',
              label: this.$t(
                'PerformanceTab.listCycleSettings.statusLabel.active'
              ),
            },
            {
              id: 'inactive',
              label: this.$t(
                'PerformanceTab.listCycleSettings.statusLabel.filed'
              ),
            },
          ],
        },
      ]
    },

    _currentStatus() {
      if (this.cycleID)
        return [
          {
            id: this.form.status,
            label: this._statusOptions[0].items.find(
              el => el.id === this.form.status
            ).label,
          },
        ]

      return [
        {
          id: this._statusOptions[0].items[0].id,
          label: this._statusOptions[0].items[0].label,
        },
      ]
    },

    _cycles() {
      if (!this._editionMode) return []

      return this.hierarchy.map(el => ({
        id: el.id,
        label: el.title,
      }))
    },

    _rules() {
      return [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
      ]
    },

    _createStartDateRules() {
      return [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
        v =>
          (!!v &&
            (this.parentPeriod.startDate
              ? new Date(this.form.startDate).getTime() >=
                new Date(this.parentPeriod.startDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.higherDate') +
            formatDate(this.parentPeriod.startDate),
        v =>
          (!!v &&
            (this.form.endDate
              ? new Date(this.form.startDate).getTime() <
                new Date(this.form.endDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.lowerDate') +
            formatDate(this.form.endDate),
      ]
    },

    _createEndDateRules() {
      return [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
        v =>
          (!!v &&
            (this.parentPeriod.endDate
              ? new Date(this.form.endDate).getTime() <=
                new Date(this.parentPeriod.endDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.lowerDate') +
            formatDate(this.parentPeriod.endDate),
        v =>
          (!!v &&
            (this.form.startDate
              ? new Date(this.form.endDate) >= new Date(this.form.startDate)
              : true)) ||
          this.$t('PerformanceTab.cycleForm.higherDate') +
            formatDate(this.form.startDate),
      ]
    },

    _editStartDateRules() {
      return [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
        v =>
          (!!v &&
            (this.parentPeriod.startDate
              ? new Date(this.form.startDate).getTime() >=
                new Date(this.parentPeriod.startDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.higherDate') +
            formatDate(this.parentPeriod.startDate),
        v =>
          (!!v &&
            (this.currentPeriod.startDate
              ? new Date(this.form.startDate).getTime() <=
                new Date(this.currentPeriod.startDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.lowerDate') +
            formatDate(this.currentPeriod.startDate),
        v =>
          (!!v &&
            (this.form.endDate
              ? new Date(this.form.startDate).getTime() <
                new Date(this.form.endDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.lowerDate') +
            formatDate(this.form.endDate),
      ]
    },

    _editEndDateRules() {
      return [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
        v =>
          (!!v &&
            (this.parentPeriod.endDate
              ? new Date(this.form.endDate).getTime() <=
                new Date(this.parentPeriod.endDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.lowerDate') +
            formatDate(this.parentPeriod.endDate),
        v =>
          (!!v &&
            (this.currentPeriod.endDate
              ? new Date(this.form.endDate).getTime() >=
                new Date(this.currentPeriod.endDate).getTime()
              : true)) ||
          this.$t('PerformanceTab.cycleForm.higherDate') +
            formatDate(this.currentPeriod.endDate),
        v =>
          (!!v &&
            (this.form.startDate
              ? new Date(this.form.endDate) >= new Date(this.form.startDate)
              : true)) ||
          this.$t('PerformanceTab.cycleForm.higherDate') +
            formatDate(this.form.startDate),
      ]
    },

    _canCycleCreate() {
      return this.$can('access', management_performance_cycle_create)
    },

    _canCycleEdit() {
      return this.$can('access', management_performance_cycle_edit)
    },
  },

  watch: {
    ['$route.params.id']() {
      this.cycleID = this.$route.params.id || null
    },

    cycleID() {
      this.fetchCycle()
      if (!this.gettingData) this.startData()
    },
  },

  beforeMount() {
    this.cycleID = this.$route.params.id || null
    this.startData()
  },

  methods: {
    async handleListCycles() {
      const { data } = await getCyclesTree({
        sortOrder: 'desc',
        sortBy: 'createdAt',
      })

      this.listCycles = data
    },
    async handleConfigHierarchySettings() {
      await getHierarchySettings({ root: true })
        .then(res => {
          this.hierarchySettings = res?.data[0]
        })
        .catch(error => {
          alertErrorBus(
            this.$t('PerformanceTab.EntitiesConfigurations.alert.get.error'),
            error
          )
        })
    },
    async startData() {
      this.gettingData = true

      this.handleConfigHierarchySettings()
      this.handleListCycles()

      if (this._createMode) await this.getHierarchySettings()
      await this.getPreSelectedCycles()

      this.gettingData = false
    },

    async submit() {
      if (!this.validate()) return

      if (this._editionMode) this.editCycle()
      else this.createCycle()
    },

    async createCycle() {
      try {
        this.$emit('loading', true)

        const payload = {
          title: this.form.title,
          status: this.form.status,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          parentCycleID: this.form.parentCycleID || null,
          current: this.form.current,
          ...(!this.form.parentCycleID && {
            hierarchySettings: this.form.hierarchySettings,
          }),
        }

        await createCycles(payload).then(() => {
          this.showAlert({
            statusAlert: 'success',
            messageAlert: this.$t('PerformanceTab.cycleForm.messageCreated'),
          })

          this.$root.$emit('handle-cycles')

          this.$emit('loading', false)
          this.$emit('close')
        })
      } catch (err) {
        this.$emit('loading', false)

        this.showAlert({
          statusAlert: 'error',
          title: this.$t('PerformanceTab.cycleForm.messageErrorCreated'),
          messageAlert: err.response?.data?.message,
        })
      }
    },

    async editCycle() {
      try {
        const hierarchySettings = [...this.form.hierarchySettings]

        if (!this.form.parentCycleID)
          hierarchySettings.forEach(el => {
            delete el.defaultCycle
          })

        const payload = {
          title: this.form.title,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          current: this.form.current,
          parentCycleID: this.form.parentCycleID || null,
          ...(!this.form.parentCycleID && {
            hierarchySettings: hierarchySettings,
          }),
        }

        await editCycle(this.cycleID, payload).then(() => {
          this.$root.$emit('handle-cycles')

          this.showAlert({
            statusAlert: 'success',
            messageAlert: this.$t('PerformanceTab.cycleForm.messageEdited'),
          })
        })
      } catch (err) {
        this.showAlert({
          statusAlert: 'error',
          title: this.$t('PerformanceTab.cycleForm.messageErrorEdited'),
          messageAlert: err.response?.data?.message,
        })
      }
    },

    async editStatus() {
      try {
        const payload = {
          status: this.form.status,
        }

        await putStatusCycle(this.cycleID, payload).then(() => {
          this.$root.$emit('handle-cycles')

          this.showAlert({
            statusAlert: 'success',
            messageAlert: this.$t('PerformanceTab.cycleForm.messageEdited'),
          })
        })
      } catch (err) {
        this.showAlert({
          statusAlert: 'error',
          title: this.$t('PerformanceTab.cycleForm.messageErrorEdited'),
          messageAlert: err.response?.data?.message,
        })
      }
    },

    handleInput(event, field, delay = 0) {
      this.form[field] = event

      if (field === 'startDate' || field === 'endDate') {
        this.$refs.startDate.validate()
        this.$refs.endDate.validate()
      }

      if (this._editionMode) {
        if (this.interval) clearInterval(this.interval)

        this.interval = setTimeout(() => {
          if (field === 'status') this.editStatus()
          else this.submit()
        }, delay)
      }
    },

    setDefaultCycle(event, index) {
      this.form.hierarchySettings[index].defaultCycleID = event.id

      if (this._editionMode) this.submit()
    },

    validateCustomInputReturn(result) {
      const isInvalid = result instanceof Node
      if (isInvalid) {
        return false
      }

      return result === undefined
    },

    async fetchCycle() {
      if (!this.cycleID) return

      this.loading = true

      await this.getCycle()

      this.loading = false
    },

    async getCycle() {
      await getCycle(this.cycleID)
        .then(async ({ data }) => {
          const startDate = new Date(data.startDate).toISOString().split('T')[0]
          const endDate = new Date(data.endDate).toISOString().split('T')[0]

          this.form.startDate = startDate
          this.form.endDate = endDate
          this.form.status = data.status

          if (this._editionMode) {
            this.form.title = data.title
            this.form.status = data.status
            this.form.parentCycleID = data.parentCycleID
            this.form.current = data.current

            if (!data.parentCycleID) {
              this.form.hierarchySettings = data.hierarchySettings.map(el => ({
                entityType: el.entityType,
                entityClass: el.entityClass,
                entityAllowedChildren: el.entityAllowedChildren,
                root: el.root,
                defaultCycle: el.defaultCycle,
                defaultCycleID: el.defaultCycle ? el.defaultCycle.id : null,
                ...(this.isDefined(
                  el.disableMonthlyDataSeriesAutoGeneration
                ) && {
                  disableMonthlyDataSeriesAutoGeneration:
                    el.disableMonthlyDataSeriesAutoGeneration,
                }),
                ...(this.isDefined(el.requiredFields) && {
                  requiredFields: el.requiredFields,
                }),
              }))

              await this.getCycleHierarchyChildren()
            } else await this.getCycleHierarchy()

            this.currentPeriod.startDate = startDate
            this.currentPeriod.endDate = endDate
          }

          if (this._createMode) {
            this.parentPeriod.startDate = startDate
            this.parentPeriod.endDate = endDate
            this.form.parentCycleID = data.id

            await this.getCycleHierarchy()
          }
        })
        .catch(err => () => {
          this.showAlert({
            statusAlert: 'error',
            messageAlert: err.message,
          })
        })
    },

    async getCycleHierarchy(id) {
      this.sameFamily = false
      if (this._createMode && !this.cycleID) this.form.current = false

      await getCycleHierarchy(id || this.cycleID)
        .then(({ data }) => {
          const allow = this._editionMode ? data.id !== this.cycleID : true
          this.hierarchy = allow ? this.fetchHierarchy(data) : []

          this.hierarchy.forEach(el => {
            const find = this.preSelectedCycles.find(sel => sel.id === el.id)
            if (find) this.sameFamily = true
          })
        })
        .catch(err => () => {
          this.showAlert({
            statusAlert: 'error',
            messageAlert: err.message,
          })
        })
    },

    async getCycleHierarchyChildren(id) {
      await getCycleHierarchyChildren(id || this.cycleID)
        .then(({ data }) => {
          this.hierarchy = this.fetchHierarchy(data)
        })
        .catch(err => () => {
          this.showAlert({
            statusAlert: 'error',
            messageAlert: err.message,
          })
        })
    },

    fetchHierarchy(data) {
      const items = []

      function fetchHierarchy(element) {
        if (typeof element === 'object' && element) items.push(element)

        if (element.children && element.children.length)
          element.children.forEach(child => {
            fetchHierarchy(child)
          })
      }

      fetchHierarchy(data)

      return items
    },

    async getHierarchySettings() {
      await getHierarchySettings()
        .then(({ data }) => {
          this.form.hierarchySettings = data.map(el => ({
            entityType: el.entityType,
            entityClass: el.entityClass,
            entityAllowedChildren: el.entityAllowedChildren,
            root: el.root,
            defaultCycleID: null,
            ...(this.isDefined(el.disableMonthlyDataSeriesAutoGeneration) && {
              disableMonthlyDataSeriesAutoGeneration:
                el.disableMonthlyDataSeriesAutoGeneration,
            }),
            ...(this.isDefined(el.requiredFields) && {
              requiredFields: el.requiredFields,
            }),
          }))
        })
        .catch(err => () => {
          this.showAlert({
            statusAlert: 'error',
            messageAlert: err.message,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    async getPreSelectedCycles() {
      const payload = {
        current: true,
      }

      await getCycles(payload).then(({ data }) => {
        this.preSelectedCycles = data

        if (!this.form.parentCycleID && this.cycleID) {
          const find = this.preSelectedCycles.find(
            hie => hie.id === this.cycleID
          )
          if (find) this.sameFamily = true
        }
      })
    },

    handleDefaultDialog(value) {
      this.form.current = value

      if (
        this.form.current &&
        this.preSelectedCycles.length &&
        !this.sameFamily
      ) {
        this.$refs.confirmDefault.handleDialog()
        return
      }

      if (this._editionMode) this.submit()
    },

    handleDefaultAction(event) {
      if (event.action === 'cancel') this.form.current = false
      else if (this._editionMode) this.submit()
    },

    handleHierarchyDialog() {
      this.$refs.insertModal.toggleModal(true)
    },

    setHierarchy(event) {
      if (event) {
        this.parentPeriod.startDate = new Date(event.startDate)
          .toISOString()
          .split('T')[0]
        this.parentPeriod.endDate = new Date(event.endDate)
          .toISOString()
          .split('T')[0]

        this.$refs.startDate.validate()
        this.$refs.endDate.validate()

        this.form.parentCycleID = event.id
        return this.getCycleHierarchy(event.id)
      }

      this.form.parentCycleID = ''
      this.hierarchy = []
    },

    validate() {
      const forms = ['endDate', 'startDate']
      let valid = true

      let customInputReturn = this.validateCustomInputReturn(
        this.$refs['label'].validate()
      )

      forms.forEach(e => {
        const value = this.$refs[e].validate()
        if (!value && value != undefined) valid = false
      })

      if (valid && !customInputReturn) valid = false

      if (!valid) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }
      return valid
    },

    findCyclesFamily(id, cycles) {
      const entity = cycles.find(item => item.id === id)
      if (!entity) {
        return []
      }

      const family = [entity]
      const queue = [...family]

      while (queue.length) {
        const current = queue.shift()

        const siblings = cycles.filter(
          item =>
            item.parentCycleID === current.parentCycleID &&
            item.id !== current.id
        )
        const children = cycles.filter(
          item => item.parentCycleID === current.id
        )

        family.push(...siblings, ...children)
        queue.push(...children)
      }

      return family
    },

    isDefined(value) {
      return ![undefined, null].includes(value)
    },

    deleteCycle() {
      this.$refs.deleteCycle.handleDialog({
        id: this.cycleID,
        parentCycleID: this.form.parentCycleID,
      })
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
