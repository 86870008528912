<template>
  <div data-test-entities-configurtions>
    <DetailSectionAccordion
      type="settings"
      :title="$t('PerformanceTab.EntitiesConfigurations.title')"
      :opened="true"
    >
      <template v-for="configuration in _settings">
        <v-checkbox
          v-if="
            configuration.type === 'v-checkbox' &&
            configuration.id !== 'goalEnabled'
          "
          :id="configuration.id"
          :key="configuration.id"
          :disabled="loadingFields"
          class="custom-checkbox"
          :data-test-configuration="configuration.id"
          :label="
            $t(
              `PerformanceTab.EntitiesConfigurations.settings.${configuration.id}`,
              {
                entityType: $t(`plannedList.entityType-name.${entityType}`),
              }
            )
          "
          :value="_formConfigurationId"
          color="#dd0041"
          @change="handleSettings(configuration, $event)"
        ></v-checkbox>
      </template>

      <div class="cycle-selection-content" data-test-cycle-selection>
        <v-skeleton-loader
          v-if="loading"
          type="button"
          class="skeleton-loading"
        ></v-skeleton-loader>

        <ItemSelector
          v-else
          ref="cycleInput"
          class="selected-field"
          :input-config="{
            label: $t(
              'PerformanceTab.EntitiesConfigurations.settings.definitionCycle'
            ),
            showAvatar: false,
            closeOnSelect: true,
          }"
          :menu-config="{
            attach: true,
            showTabs: false,
          }"
          :current-value="currentCycle"
          :menu-options="_cycleOptions"
          :infinity-scroll="true"
          :persistent="true"
          :watch-current="true"
          @update:item="handleCycleChange($event)"
          @infinity:scroll="handleCycleScroll($event)"
          @search:item="searchCycle($event)"
          @focus:input="searchCycles = []"
        ></ItemSelector>

        <v-tooltip min-width="800" max-width="800" bottom open-on-hover>
          <template #activator="{ on, attrs }">
            <v-icon
              data-test-definition-cycle
              v-bind="attrs"
              size="0.75rem"
              class="tooltip-icon"
              v-on="on"
            >
              fi-rr-info
            </v-icon>
          </template>

          <div
            data-test-definition-cycle-tooltip
            v-html="
              $t(
                'PerformanceTab.EntitiesConfigurations.settings.definitionCycleTooltip'
              )
            "
          ></div>
        </v-tooltip>
      </div>

      <div
        class="access-profile-selection-content"
        data-test-access-profile-selection
      >
        <v-skeleton-loader
          v-if="loading"
          type="button"
          class="skeleton-loading"
        ></v-skeleton-loader>

        <ItemSelector
          v-else
          ref="accessProfileDefault"
          class="selected-field"
          :input-config="{
            label: $t(
              'PerformanceTab.EntitiesConfigurations.settings.defaultAccessProfile'
            ),
            showAvatar: false,
            closeOnSelect: true,
          }"
          :menu-config="{
            attach: true,
            showTabs: false,
          }"
          :current-value="currentAccessProfile"
          :menu-options="_accessProfiles"
          :infinity-scroll="true"
          :persistent="false"
          :multiple="false"
          :fill-width="true"
          :watch-current="true"
          :loading="accessProfileFieldLoading"
          @update:item="handleAccessProfileChange"
          @infinity:scroll="handleAccessProfileScroll"
          @search:item="handleSearchAccessProfile"
          @focus:input="handleFocusAccessProfile"
        ></ItemSelector>

        <v-tooltip min-width="800" max-width="800" bottom open-on-hover>
          <template #activator="{ on, attrs }">
            <v-icon
              data-test-definition-access-profile
              v-bind="attrs"
              size="0.75rem"
              class="tooltip-icon"
              v-on="on"
            >
              fi-rr-info
            </v-icon>
          </template>

          <div
            data-test-definition-access-profile-tooltip
            v-html="
              $t(
                'PerformanceTab.EntitiesConfigurations.settings.defaultAccessProfileTooltip'
              )
            "
          ></div>
        </v-tooltip>
      </div>

      <div v-if="_isGoalEnabledVisible" class="border">
        <v-checkbox
          v-if="_isGoalEnabledVisible"
          class="custom-checkbox"
          data-test-configuration="goalEnabled"
          :disabled="loadingFields"
          :label="
            $t('PerformanceTab.EntitiesConfigurations.settings.goalEnabled')
          "
          :value="_goalEnabled"
          @change="handleGoalEnabledChange"
        ></v-checkbox>

        <div class="goal-group">
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="skeleton-loading"
          ></v-skeleton-loader>

          <ItemSelector
            v-else
            ref="suggestedGoalGroup"
            class="selected-field"
            :input-config="{
              label: $t(
                'PerformanceTab.EntitiesConfigurations.defaultGoalGroup'
              ),
              showAvatar: false,
              closeOnSelect: true,
              clearable: true,
            }"
            :menu-config="{
              attach: true,
              showTabs: false,
            }"
            :current-value="_currentDefaultGoalGroup"
            :menu-options="_defaultGoalGroupOptions"
            :loading="loadingGoals"
            :infinity-scroll="true"
            :watch-current="true"
            :disabled="!_goalEnabled"
            :readonly="!_goalEnabled"
            :multiple="false"
            @update:item="handleDefaultGoalGroupChange($event)"
            @infinity:scroll="handleGoalGroupScroll($event)"
            @search:item="searchGoalGroup($event)"
          ></ItemSelector>

          <v-tooltip min-width="800" max-width="800" bottom open-on-hover>
            <template #activator="{ on, attrs }">
              <v-icon
                data-test-definition-roles
                v-bind="attrs"
                size="0.75rem"
                class="tooltip-icon"
                v-on="on"
              >
                fi-rr-info
              </v-icon>
            </template>
            <div
              data-test-definition-roles-tooltip
              v-html="
                $t(
                  'PerformanceTab.EntitiesConfigurations.suggestedGoalGroupTooltip'
                )
              "
            ></div>
          </v-tooltip>
        </div>

        <div class="goal-selection-content" data-test-roles-selection>
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="skeleton-loading"
          ></v-skeleton-loader>

          <ItemSelector
            ref="RolesUsedInGoalsInput"
            class="selected-field"
            :input-config="{
              label: $t(
                'PerformanceTab.EntitiesConfigurations.rolesUsedInGoals'
              ),
              showAvatar: false,
              clearable: true,
              rules: settingsForm?.goalGroupAccessProfiles ? rules : [],
            }"
            :menu-config="{
              attach: true,
              showTabs: false,
            }"
            :current-value="_currentGoalGroupAccessProfiles"
            :menu-options="_rolesOptions"
            :watch-current="true"
            :multiple="true"
            :infinity-scroll="true"
            :disabled="!_goalEnabled"
            :readonly="!_goalEnabled"
            @update:item="handleRolesChange"
            @search:item="searchGetRoles($event)"
            @infinity:scroll="handleRolesScroll($event)"
          ></ItemSelector>

          <v-tooltip min-width="800" max-width="800" bottom open-on-hover>
            <template #activator="{ on, attrs }">
              <v-icon
                data-test-definition-roles
                v-bind="attrs"
                size="0.75rem"
                class="tooltip-icon"
                v-on="on"
              >
                fi-rr-info
              </v-icon>
            </template>

            <div
              data-test-definition-roles-tooltip
              v-html="$t('PerformanceTab.EntitiesConfigurations.rolesTooltip')"
            ></div>
          </v-tooltip>
        </div>

        <v-card-text class="card-text-content">
          <div class="switch-container">
            <CustomSwitch
              :custom-props="{ inset: true }"
              color="primary"
              :value="settingsByEntity?.goalGroupRequired"
              @input="handleGoalGroupRequiredChange($event)"
            ></CustomSwitch>
            <span class="switch-container-description">
              {{
                $t(
                  'PerformanceTab.EntitiesConfigurations.settings.goalGroupRequired'
                )
              }}
            </span>
          </div>
        </v-card-text>
      </div>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import { getCycles } from '@/services/cycles'
import { getAccessProfiles } from '@/services/papers'
import { getGoalGroups } from '@/services/goal-group'

import { debounce } from 'lodash'
import { alertErrorMessage } from '@/helpers/alerts'
import { fetchOkrGroupPapers } from '@/services/papers'

export default {
  name: 'EntitiesConfigurations',

  props: {
    settingsByEntity: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    loadingFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localSuggestedGoalGroup: null,
      suggestedGoalGroup: [],
      loadingGoals: false,
      goalGroupPagination: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      currentDefaultGoalGroup: {
        data: [
          {
            id: null,
            label: '',
          },
        ],
        origin: 'suggestedGoalGroup',
      },
      localCurrentGoalGroupAccessProfiles: null,
      rules: [v => !!v && v.length > 0],
      loading: false,
      formConfigurationId: true,
      handleSubmit: debounce(async function () {
        this.$emit('set-loading', true)
        await this.submit()
      }, 500),

      configurations: {
        'key-result': {
          default: [
            {
              id: 'disableMonthlyDataSeriesAutoGeneration',
              type: 'v-checkbox',
            },
          ],
        },
        'sub-indicator': {
          default: [
            {
              id: 'disableMonthlyDataSeriesAutoGeneration',
              type: 'v-checkbox',
            },
          ],
        },
        kpi: {
          default: [
            {
              id: 'disableMonthlyDataSeriesAutoGeneration',
              type: 'v-checkbox',
            },
          ],
        },
      },

      form: {},
      settingsForm: {},

      entityType: null,
      entityClass: null,

      currentCycle: {
        data: [
          {
            id: null,
            label: this.$t('PermissionsPage.cycle.suggestEntityCycle'),
          },
        ],
        origin: 'cycle',
      },
      cycles: [],
      searchCycles: [],
      searchAccessProfile: null,
      accessProfilePagination: {
        limit: 10,
        offset: 0,
      },
      accessProfiles: [],
      totalAccessProfile: 0,
      accessProfileFieldLoading: false,
      currentAccessProfile: null,
      interval: null,
      cyclePagination: {
        limit: 20,
        offset: 0,
        total: 0,
      },
      roles: [],
      rolesPagination: {
        limit: 20,
        offset: 0,
        total: 0,
      },
    }
  },

  computed: {
    _accessProfiles() {
      return [
        {
          value: 'accessProfile',
          label: this.$t(
            'PerformanceTab.EntitiesConfigurations.settings.defaultAccessProfile'
          ),
          type: 'listview',
          items: this.accessProfiles,
        },
      ]
    },
    _currentDefaultGoalGroup() {
      const suggestedGoalGroup =
        this.localSuggestedGoalGroup || this.settingsForm.suggestedGoalGroup

      if (suggestedGoalGroup) {
        return {
          data: [
            {
              id: suggestedGoalGroup.id,
              label: suggestedGoalGroup.title,
            },
          ],
          origin: 'suggestedGoalGroup',
        }
      }

      return {
        data: [],
        origin: 'suggestedGoalGroup',
      }
    },
    _goalEnabled() {
      return !!this.settingsForm?.goalEnabled
    },
    _formConfigurationId() {
      return !this.formConfigurationId
    },
    _settings() {
      return this.getSettings(this.entityType, this.entityClass)
    },
    _cycleOptions() {
      return [
        {
          value: 'cycle',
          label: this.$t('PermissionsPage.cycle.title'),
          type: 'listview',
          items: this.searchCycles.length ? this.searchCycles : this.cycles,
        },
      ]
    },

    _isGoalEnabledVisible() {
      return ['key-result', 'sub-indicator', 'kpi', 'objective'].includes(
        this.entityType
      )
    },
    _defaultGoalGroupOptions() {
      return [
        {
          value: 'suggestedGoalGroup',
          label: '',
          type: 'listview',
          items: this.suggestedGoalGroup,
        },
      ]
    },

    _rolesOptions() {
      return [
        {
          value: 'RolesUsedInGoalsInput',
          type: 'listview',
          items: this.roles,
        },
      ]
    },
    _currentGoalGroupAccessProfiles() {
      const goalGroupAccessProfiles =
        this.localCurrentGoalGroupAccessProfiles ||
        this.settingsForm.goalGroupAccessProfiles

      if (goalGroupAccessProfiles) {
        return {
          data: goalGroupAccessProfiles,
          origin: 'RolesUsedInGoalsInput',
        }
      }
      return {
        data: [],
        origin: 'RolesUsedInGoalsInput',
      }
    },
  },

  watch: {
    'settingsForm.suggestedGoalGroup': function (newValue) {
      this.localSuggestedGoalGroup = newValue
    },
    'settingsForm.goalGroupAccessProfiles': function (newValue) {
      this.localCurrentGoalGroupAccessProfiles = newValue
    },
    _settings: {
      handler() {
        this.resetForm()
      },
      immediate: true,
      deep: true,
    },

    settingsForm: {
      handler() {
        this.resetForm()
      },
      deep: true,
    },

    settingsByEntity() {
      this.fetchDefaultSettings()
    },
  },

  beforeMount() {
    this.loading = true
    this.setEntity()
    this.getCycles({
      limit: this.cyclePagination.limit,
      offset: this.cyclePagination.offset,
    }),
      this.getRoles()
    this.getGoalGroupOptions()
  },

  methods: {
    handleAccessProfileChange(accessProfileSelected) {
      let newCccessProfile = null

      this.$set(this.settingsForm, 'suggestedAccessProfile', {
        id: newCccessProfile || accessProfileSelected?.id,
      })
      this.handleSubmit()
    },
    handleAccessProfileScroll() {
      if (
        this.accessProfiles.length >= this.totalAccessProfile ||
        this.accessProfileFieldLoading
      )
        return

      this.$refs.accessProfileDefault.holdSpamInfinityScroll = true
      this.accessProfileFieldLoading = true

      this.accessProfilePagination.offset =
        this.accessProfilePagination.offset + this.accessProfilePagination.limit

      this.handleAccessProfile()
    },
    handleSearchAccessProfile(event) {
      this.accessProfilePagination.offset = 0
      this.searchAccessProfile = event !== '' ? event : null
      this.accessProfiles = []

      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.handleAccessProfile()
      }, 500)
    },
    handleFocusAccessProfile() {
      this.accessProfilePagination.offset = 0
      this.searchAccessProfile = null
      this.accessProfiles = []

      this.handleAccessProfile()
    },
    async handleAccessProfile() {
      this.accessProfileFieldLoading = true
      let payload = {
        limit: this.accessProfilePagination.limit,
        offset: this.accessProfilePagination.offset,
        entityType: this.entityType,
        entityClass: this.entityClass,
        label: this.searchAccessProfile,
      }

      if (this.entityType === 'initiative') {
        payload.workflow = false
      }

      await getAccessProfiles(payload)
        .then(({ data, headers }) => {
          this.accessProfiles = [...this.accessProfiles, ...data]
          this.totalAccessProfile = parseInt(headers['x-count']) || 0
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            messageAlert: error?.message,
          })
        })
        .finally(() => {
          this.accessProfileFieldLoading = false
        })
    },
    searchGoalGroup(event) {
      const payload = {
        ...(event && { title: event }),
        limit: this.goalGroupPagination.limit,
        offset: 0,
      }

      this.getGoalGroupOptions(payload)
    },
    handleDefaultGoalGroupChange(goalGroup) {
      this.$set(
        this.settingsForm,
        'suggestedGoalGroup',
        goalGroup?.id ? { id: goalGroup.id, title: goalGroup.label } : null
      )
      this.localSuggestedGoalGroup = goalGroup || null

      this.handleSubmit()
    },
    async handleGoalGroupScroll() {
      if (this.suggestedGoalGroup.length >= this.goalGroupPagination.total)
        return

      this.goalGroupPagination.offset += this.goalGroupPagination.limit

      await this.getGoalGroupOptions({
        limit: this.goalGroupPagination.limit,
        offset: this.goalGroupPagination.offset,
      })

      this.$refs.suggestedGoalGroup.holdSpamInfinityScroll = false
    },

    async getGoalGroupOptions(params = {}) {
      try {
        this.loadingGoals = true

        const goalGroupParams = this.createGoalGroupPayload(params)

        const { data, headers } = await this.fetchGoalGroups(goalGroupParams)

        const goalGroups = this.formatGoalGroups(data)

        const uniqueGoalGroups = this.removeDuplicateGoalGroups(goalGroups)

        this.updateGoalGroupData(uniqueGoalGroups)

        this.goalGroupPagination.total = this.getTotalGoalGroupsCount(headers)
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t(
            'PerformanceTab.EntitiesConfigurations.getDefaultGoalGroupError'
          ),
          error
        )

        this.handleAlert(alertPayload)
      } finally {
        this.loadingGoals = false
      }
    },

    // Funções auxiliares

    createGoalGroupPayload(params) {
      return {
        limit: this.goalGroupPagination.limit,
        offset: this.goalGroupPagination.offset,
        status: 'active',
        ...params,
      }
    },

    async fetchGoalGroups(payload) {
      return await getGoalGroups(
        payload.limit,
        payload.offset,
        payload.title,
        payload.status
      )
    },

    formatGoalGroups(data) {
      return data.map(group => ({
        id: group.id,
        label: group.title,
      }))
    },

    removeDuplicateGoalGroups(goalGroups) {
      return goalGroups.filter(
        (item, index, self) => index === self.findIndex(t => t.id === item.id)
      )
    },

    updateGoalGroupData(goalGroups) {
      this.suggestedGoalGroup = goalGroups
    },

    getTotalGoalGroupsCount(headers) {
      return Number(headers['x-count'])
    },

    setEntity(
      entityType = this.$route.params.entityType,
      entityClass = this.$route.params.entityClass
    ) {
      this.entityType = entityType
      this.entityClass = entityClass
    },

    getSettings(entityType, entityClass) {
      const configurations = this.configurations[entityType]
      if (!configurations) return []

      const currentTypeDefault = configurations?.default || []
      const currentClass = (entityClass && configurations[entityClass]) || []

      return [...currentTypeDefault, ...currentClass]
    },

    calcValueType(type, value = null) {
      if (type === 'v-checkbox') {
        return !!value
      }
    },

    resetForm() {
      const form = {}
      const settings = this._settings
      for (const setting of settings) {
        form[setting.id] = this.calcValueType(
          setting.type,
          this.settingsForm[setting.id]
        )
      }

      this.$set(this, 'form', form)
    },

    handleSettings(configuration, event) {
      const disableMonthlyDataSeriesAutoGeneration = !!event

      const value = this.calcValueType(
        configuration.type,
        !disableMonthlyDataSeriesAutoGeneration
      )
      this.$set(this.form, configuration.id, value)

      this.handleSubmit()
    },
    handleCycleChange(cycle) {
      this.$set(this.settingsForm, 'suggestedCycle', { id: cycle.id })
      this.handleSubmit()
    },
    searchCycle(event) {
      const payload = {
        ...(event && { title: event }),
        limit: this.cyclePagination.limit,
        offset: 0,
      }

      this.getCycles(payload, false, true)
    },

    async getCycles(params = {}, infinity = false, search = false) {
      const payload = {
        ...params,
      }

      await getCycles(payload).then(({ data, headers }) => {
        this.cyclePagination.total = parseInt(headers['x-count']) || 0

        const cycles = data
          .filter(el => el.status !== 'inactive')
          .map(el => ({
            id: el.id,
            label: el.title,
          }))

        const result = infinity
          ? this.cycles.concat(cycles)
          : [
              ...(!search
                ? [
                    {
                      id: null,
                      label: this.$t(
                        'PermissionsPage.cycle.suggestEntityCycle'
                      ),
                    },
                  ]
                : []),
              ...cycles,
            ]

        if (search) this.searchCycles = result
        else this.cycles = result
      })

      this.loading = false
    },

    async handleCycleScroll() {
      if (this.cycles.length >= this.cyclePagination.total) return

      this.cyclePagination.offset =
        this.cyclePagination.offset + this.cyclePagination.limit

      await this.getCycles(
        {
          limit: this.cyclePagination.limit,
          offset: this.cyclePagination.offset,
        },
        true
      )

      this.$refs.cycleInput.holdSpamInfinityScroll = false
    },

    async submit() {
      const payload = structuredClone(this.settingsForm)
      this.$emit('submit', Object.assign(payload, this.form))
    },

    async handlePayload() {
      const payload = structuredClone(this.settingsForm)
      return Object.assign(payload, this.form)
    },

    handleAlert(payload) {
      this.$emit('display-alert', payload)
    },

    applyCurrentCycle(cycle) {
      this.currentCycle = {
        data: [
          {
            id: cycle.id,
            label: cycle.title,
          },
        ],
        origin: 'cycle',
      }
    },

    applyCurrentAccessProfile(currentAccessProfile) {
      this.currentAccessProfile = {
        data: [
          {
            id: currentAccessProfile.id,
            label: currentAccessProfile.label,
          },
        ],
        origin: 'accessProfile',
      }
    },

    async fetchDefaultSettings() {
      this.settingsForm = this.settingsByEntity
      this.formConfigurationId =
        this.settingsByEntity.disableMonthlyDataSeriesAutoGeneration
      const currentCycle = this.settingsByEntity.suggestedCycle
      const currentAccessProfile = this.settingsByEntity.suggestedAccessProfile

      if (currentCycle) {
        this.applyCurrentCycle(currentCycle)
      }

      if (currentAccessProfile) {
        this.applyCurrentAccessProfile(currentAccessProfile)
      }
    },
    handleGoalEnabledChange(event) {
      this.$set(this.form, 'goalEnabled', !!event)
      this.handleSubmit()
    },

    handleGoalGroupRequiredChange(event) {
      this.$set(this.form, 'goalGroupRequired', !!event)
      this.handleSubmit()
    },

    async getRoles(params = {}, isScroll = false) {
      try {
        const defaultPayload = {
          entityType: this.entityType,
          entityClass: this.entityClass,
          limit: this.rolesPagination.limit,
          offset: this.rolesPagination.offset,
        }

        const payload = {
          ...defaultPayload,
          ...params,
        }

        const { data, headers } = await fetchOkrGroupPapers(
          payload.entityType,
          payload.entityClass,
          {
            limit: payload.limit,
            offset: payload.offset,
            label: payload.label || null,
          }
        )

        const roles = data.map(paper => ({
          id: paper.id,
          label: paper.label,
          deletable: paper.deletable,
        }))

        const result = isScroll ? [...this.roles, ...roles] : roles

        this.roles = result.filter(
          (item, index, self) => index === self.findIndex(t => t.id === item.id)
        )

        this.rolesPagination.total = Number(headers['x-count']) || result.length
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.getError'),
          error
        )
        this.handleAlert(alertPayload)
      }
    },

    searchGetRoles(event) {
      const payload = {
        ...(event && { label: event }),
      }
      this.getRoles(payload)
    },

    async handleRolesScroll() {
      if (this.roles.length >= this.rolesPagination.total) return

      this.rolesPagination.offset += this.rolesPagination.limit

      await this.getRoles(
        {
          limit: this.rolesPagination.limit,
          offset: this.rolesPagination.offset,
        },
        true
      )

      this.$refs.RolesUsedInGoalsInput.holdSpamInfinityScroll = false
    },

    handleRolesChange(selectedRoles) {
      if (!selectedRoles || selectedRoles.length === 0) {
        const alertPayload = alertErrorMessage(
          this.$t(
            'PerformanceTab.EntitiesConfigurations.rolesSelectionErrorTitle'
          )
        )
        this.handleAlert(alertPayload)
        return
      }

      this.$set(this.settingsForm, 'goalGroupAccessProfiles', selectedRoles)

      this.localCurrentGoalGroupAccessProfiles = selectedRoles

      this.handleSubmit()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
