<template>
  <tr v-show="isVisible" class="table-row">
    <td
      v-for="(column, index) in columns"
      v-show="checkToRenderRow(control)"
      :key="column.key + rowIndex"
      :class="tabClass(control.nivel, index)"
      :style="{ width: setWidth(column) }"
    >
      <div v-if="!index" class="first-td">
        <RowCaret
          data-test-table-tree-row-caret
          :is-opened="isOpened"
          :has-children="hasChildren"
          @click="
            handleToggleChildren(row, control, isOpened, controls, rowIndex)
          "
        ></RowCaret>

        <component
          :is="column.component"
          v-if="column.component"
          v-bind="setColumnProps(column, rowIndex)"
          @handleAction="handleAction"
        ></component>
        <template v-else> {{ row[column.key] }} </template>

        <DownsideRowButton
          v-if="downSideRowButton && !index && _showRowButton"
          data-test-table-tree-row-add-button
          :row-index="rowIndex"
          :event="event"
          :row="row"
          @down-side-row-button="handleClick"
        ></DownsideRowButton>
      </div>

      <div v-else-if="column.component === 'date'">
        {{ column.transform ? column.transform(row) : row[column.key] }}
      </div>

      <component
        :is="column.component"
        v-else-if="column.component"
        v-bind="setColumnProps(column, rowIndex)"
        @handleAction="handleAction"
      ></component>

      <template v-else> {{ row[column.key] }} </template>
    </td>

    <HistoryBtn
      v-if="historyButton"
      :toggle-node-children="toggleNodeChildren"
      :row-index="rowIndex"
      :controls="controls"
      :stories="stories"
      :row="row"
      @down-side-row-button="handleClick"
    ></HistoryBtn>
  </tr>
</template>

<script>
import HistoryBtn from './HistoryBtn'
import DownsideRowButton from './DownsideRowButton'
import RowCaret from './Caret.vue'

export default {
  name: 'TableRow',
  components: {
    HistoryBtn,
    DownsideRowButton,
    RowCaret,
  },
  inject: [
    'columns',
    'putHistory',
    'hideNodesAfter',
    'hooks',
    'downSideRowButton',
    'findParentIndex',
  ],
  props: {
    row: Object,
    controls: Array,
    stories: Array,
    rowIndex: Number,
    components: Array,
    showRowButtonOnlyRoot: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    _showRowButton() {
      if (this.showRowButtonOnlyRoot) return this._isRoot

      return true
    },
    _isRoot() {
      return !this.control?.parent
    },
    isOpened() {
      return this?.control?.open
    },
    isVisible() {
      return this?.control?.visible
    },
    hasChildren() {
      return !!this.row.childrenCount
    },
    hasHiddenParents() {
      return !!this.row.parentGroupID
    },
    control() {
      return this.controls[this.rowIndex]
    },
    event() {
      return {
        nivel: this.control.nivel + 2,
        control: this.control,
      }
    },
    showDownSideRowButton() {
      return (
        !this.hasChildren ||
        (this.hasChildren && this.isOpened && !this.control.hideButton)
      )
    },
    historyButton() {
      return this.control.open && this.control.nivel >= this.hideNodesAfter
    },
  },
  created() {
    this.components.forEach(component => {
      this.$options.components[component.name] = component.component
    })
  },
  methods: {
    handleClick(button, row, rowIndex) {
      this.$emit(button, button, row, rowIndex)
    },
    handleAction(act) {
      this.$emit('handleAction', act, this.row, this.rowIndex, this.control)
    },
    toggleSpinner(state) {
      this.loading = state
    },
    async handleToggleChildren(row, control, isOpened, controls, rowIndex) {
      const { beforeNodeOpen, afterNodeOpen, beforeCloseNode, afterCloseNode } =
        this.hooks

      const before = !isOpened ? beforeNodeOpen : beforeCloseNode
      const after = !isOpened ? afterNodeOpen : afterCloseNode

      if (before) await before(rowIndex, row, control)

      this.toggleNodeChildren(controls, rowIndex, row, control)

      if (after) await after(rowIndex, row, control)
    },
    checkToRenderRow(control) {
      const isBiggerThan = control.nivel >= this.hideNodesAfter && !control.open
      const isLesserThan = control.nivel < this.hideNodesAfter

      return isBiggerThan || isLesserThan
    },
    async toggleNodeChildren(
      controls,
      n,
      externalRow = null,
      externalControl = null
    ) {
      const control = externalControl || controls[n]
      const row = externalRow || this.row

      const toggle = !control.open
      const currentNv = control.nivel
      const nextNv = control.nivel + 1

      control.open = toggle

      let currIndex = this.putHistory(n, control.parent, control, row)

      if (control.nivel === this.hideNodesAfter) {
        this.hideBrothers(controls, currentNv, nextNv, toggle, currIndex, row)
      }

      if (control.nivel > this.hideNodesAfter) {
        this.toggleChildrenHiding(
          controls,
          currentNv,
          nextNv,
          toggle,
          currIndex,
          row
        )
      }

      return this.toggleChildren(toggle, nextNv, controls, n)
    },
    async toggleChildren(toggle, nextNv, controls, n) {
      do {
        if (toggle) {
          if (controls[n].nivel === nextNv) controls[n].visible = true
        } else if (controls[n].nivel >= nextNv) {
          controls[n].visible = false
          controls[n].open = false
        }

        n++
        if (!controls[n]) return n
        if (controls[n].nivel < nextNv) return n
      } while (controls[n].nivel !== 0)
      return n
    },
    toggleChildrenHiding(controls, currentNv, nextNv, toggle, currIndex, row) {
      controls[currIndex].visible = !toggle
      let i = currIndex + 1
      do {
        const node = controls[i]

        if (node.nivel === currentNv && node.key !== row.key)
          node.visible = !toggle

        if (!toggle && node.nivel >= nextNv) node.visible = toggle

        if (toggle && node.nivel === nextNv && node.parent.key === row.key)
          node.visible = toggle

        i++

        if (!node) return i
        if (node.nivel < currentNv) return i
        if (!controls[i]) return i
      } while (controls[i].nivel !== 0)
      return i
    },
    hideBrothers(controls, currentNv, nextNv, toggle, currIndex, row) {
      controls[currIndex].hideButton = toggle
      let i = currIndex + 1
      do {
        const node = controls[i]

        if (node.nivel === currentNv && node.key !== row.key)
          node.visible = !toggle

        if (node.nivel === nextNv && node.parent.key === row.key)
          node.visible = toggle

        i++
        if (!controls[i]) return i - 1
        if (!node) return i
        if (node.nivel < currentNv) return i
      } while (controls[i].nivel !== 0)
      return i
    },
    tabClass(nivel, rowIndex) {
      if (nivel >= this.hideNodesAfter && !rowIndex)
        return `nivel-${this.hideNodesAfter}`

      if (!rowIndex) return `nivel-${nivel}`
    },
    setWidth(column) {
      if (column.width) return column.width
      return false
    },
    setColumnProps(column, rowIndex) {
      return column.getComponentProps(this.row, rowIndex)
    },
  },
}
</script>

<style src="../style.scss" lang="scss" scoped />
