import variables from '@/styles/abstracts/_colors.module.scss'

import i18n from '@/plugins/i18n'
import router from '@/router'

import * as _canKey from '@/helpers/ability/permissions'

/** @param {URL} url */
export function toLogin(url, basePath = '') {
  const token = localStorage.getItem('teamculture:token')

  if (token) {
    url.pathname = basePath + '/login'
    url.searchParams.set('t', token)
  }

  return url.toString()
}

export function genSiteNavigationItens(onClickLogout = () => {}) {
  const CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

  return {
    userLinks: {
      logout: {
        click: onClickLogout,
        text: i18n.t('ui-components.siteNavigation.user.logout'),
      },
      help: {
        url: i18n.t('ui-components.siteNavigation.helpInfoLink'),
        text: i18n.t('ui-components.siteNavigation.help'),
      },
      settings: {
        url: new URL(
          'users/profile',
          process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK
        ).toString(),
        text: i18n.t('ui-components.siteNavigation.user.settings'),
      },
    },
    menuLinks: [
      {
        name: 'Profile',
        url: '/profile',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/home.svg`,
          title: i18n.t('ui-components.siteNavigation.Home'),
          color: variables.yellowColor8,
        },
        permissioned: _canKey.profile,
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.profile'),
      },
      {
        name: 'Engagement',
        url: '/engagement',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/engagement.svg`,
          title: i18n.t('ui-components.siteNavigation.Engagement'),
          color: variables.redColor12,
        },
        permissioned: [
          _canKey.engagement_climate,
          _canKey.engagement_culture,
          _canKey.engagement_participations,
          _canKey.engagement_groups_comparation,
          _canKey.engagement_benchmark,
          _canKey.profile_opinions,
          _canKey.engagement_opinions,
          _canKey.engagement_action_plans,
        ],
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.engagement'),
      },
      {
        name: 'Performance',
        url: process.env.VUE_APP_PERFORMANCE_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/performance.svg`,
          title: i18n.t('ui-components.siteNavigation.Performance'),
          color: variables.blueColor8,
        },
        permissioned: _canKey.performance,
        ariaLabel: i18n.t('navBar.accessibility.performance'),
      },
      {
        name: 'Growth',
        url: '/growth',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/growth.svg`,
          title: i18n.t('ui-components.siteNavigation.Growth'),
          color: variables.greenColor12,
        },
        permissioned: [
          _canKey.growth,
          _canKey.profile_growth,
          _canKey.profile_feedback,
        ],
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.growth'),
      },
      {
        name: 'Surveys',
        url: process.env.VUE_APP_CUSTOM_SURVEY_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/advanced-survery.svg`,
          title: i18n.t('ui-components.siteNavigation.Survery'),
          color: variables.pinkColor5,
        },
        permissioned: _canKey.customSurvey,
        newLayout: {
          url: '/custom-survey',
          permissioned: _canKey.profile_home_custom_survey,
        },
      },
      {
        name: 'Management',
        url: null,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/management-active.svg`,
          title: i18n.t('ui-components.siteNavigation.Management'),
          color: variables.grayColor12,
          current: true,
        },
        permissioned: _canKey.management,
        customEvents: {
          click: () => {
            const navigation = { name: 'PeoplePage' }
            if (router.currentRoute.name !== navigation.name) {
              router.push(navigation)
            }
          },
        },
        ariaLabel: i18n.t('navBar.accessibility.management'),
      },
    ],
  }
}

export function genPageNavigationItens() {
  const routes = [
    {
      path: '/account',
      name: 'AccountPage',
      text: i18n.t('Routes.account'),
      children: [
        {
          path: '/engagement',
          name: 'EngagementPage',
          text: i18n.t('Routes.engagement'),
          permissioned: _canKey.management_engagement,
        },
        {
          path: '/performance',
          name: 'PerformancePage',
          text: i18n.t('Routes.performance'),
          permissioned: _canKey.management_performance,
        },
        {
          path: '/integrations',
          name: 'IntegrationsList',
          text: i18n.t('Routes.integrations'),
          rich: true,
          permissioned: _canKey.management_integrations,
        },
      ],
    },
    {
      path: '/people-and-groups',
      name: 'PeopleAndGroupPage',
      text: i18n.t('Routes.people-and-groups'),
      children: [
        {
          path: '/people',
          name: 'PeoplePage',
          text: i18n.t('Routes.people-and-groups-people'),
          helpCenter: 'listPeople',
          permissioned: _canKey.management_users,
        },
        {
          path: '/groups',
          name: 'GroupsPage',
          text: i18n.t('Routes.groups'),
          helpCenter: 'managementPage',
          permissioned: _canKey.management_groups,
        },
        {
          path: '/demographic-attributes',
          name: 'DemographicAttributesPage',
          text: i18n.t('Routes.demographic-attributes'),
          helpCenter: 'DemographicAttributesPage',
          permissioned: _canKey.management_attributes,
        },
      ],
    },
    {
      path: '/data',
      name: 'DataPage',
      text: i18n.t('Routes.data'),
      children: [
        {
          path: '/audit-logs',
          name: 'AuditLogsPage',
          text: i18n.t('Routes.audit-logs'),
          permissioned: _canKey.management_audit_logs,
        },
      ],
    },
    {
      path: '/permissions',
      name: 'PermissionsPage',
      text: i18n.t('Routes.permissions'),
      permissioned: [
        {
          _some: [_canKey.management_permissions_permission_groups],
          _and_some: [
            _canKey.management_permissions_module_profile,
            _canKey.management_permissions_module_engagement,
            _canKey.management_permissions_module_growth,
            _canKey.management_permissions_module_configuration,
            _canKey.management_permissions_module_notification,
            _canKey.management_permissions_module_custom_survey,
          ],
        },
        {
          _some: [
            _canKey.management_permissions_permission_groups,
            _canKey.management_performance_group_roles,
            _canKey.management_performance_entities_access_profiles,
          ],
          _and_some: [_canKey.management_permissions_module_performance],
        },
      ],
    },
  ]

  return routes
}
