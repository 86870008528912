<template>
  <Modal
    ref="modalShortcut"
    :title="$t('TreeView.shortcutModal.title')"
    width="636px"
  >
    <template v-slot:no-tab>
      <div class="text-modal-shortcut">
        <div class="shortcut">
          <span>{{ $t('TreeView.shortcutModal.zoomIn') }}</span>
          <div class="shortcut-keys">
            <div class="shortcut-first-key">
              <span>{{ $t('TreeView.shortcutModal.ctrl') }}</span>
            </div>
            <div class="shortcut-second-key">
              <span>{{ $t('TreeView.shortcutModal.scrollDown') }}</span>
            </div>
          </div>
        </div>
        <div class="shortcut">
          <span>{{ $t('TreeView.shortcutModal.zoomOut') }}</span>
          <div class="shortcut-keys">
            <div class="shortcut-first-key">
              <span>{{ $t('TreeView.shortcutModal.ctrl') }}</span>
            </div>
            <div class="shortcut-second-key">
              <span>{{ $t('TreeView.shortcutModal.scrollUp') }}</span>
            </div>
          </div>
        </div>
        <div class="shortcut">
          <span>{{ $t('TreeView.shortcutModal.moveScreen') }}</span>
          <div class="shortcut-keys">
            <div class="shortcut-first-key">
              <span>{{ $t('TreeView.shortcutModal.spaceKey') }}</span>
            </div>
            <div class="shortcut-second-key">
              <span>{{ $t('TreeView.shortcutModal.drag') }}</span>
            </div>
          </div>
        </div>
        <div class="shortcut">
          <span>{{ $t('TreeView.shortcutModal.centralize') }}</span>
          <div class="shortcut-keys">
            <div class="shortcut-first-key">
              <span>{{ $t('TreeView.shortcutModal.ctrl') }}</span>
            </div>
            <div class="shortcut-second-key">
              <span>{{ $t('TreeView.shortcutModal.1') }}</span>
            </div>
          </div>
        </div>
        <div class="shortcut">
          <span>{{ $t('TreeView.shortcutModal.zoom100') }}</span>
          <div class="shortcut-keys">
            <div class="shortcut-first-key">
              <span>{{ $t('TreeView.shortcutModal.ctrl') }}</span>
            </div>
            <div class="shortcut-second-key">
              <span>{{ $t('TreeView.shortcutModal.0') }}</span>
            </div>
          </div>
        </div>
        <div class="shortcut">
          <span>{{ $t('TreeView.shortcutModal.search') }}</span>
          <div class="shortcut-keys">
            <div class="shortcut-first-key">
              <span>{{ $t('TreeView.shortcutModal.ctrl') }}</span>
            </div>
            <div class="shortcut-second-key">
              <span>{{ $t('TreeView.shortcutModal.f') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  name: 'ShortCuts',
  props: {
    focusSearch: {
      type: Function,
    },
    openDrawerNewNode: {
      type: Function,
    },
    keys: {
      type: Object,
    },
  },
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    handleDialog() {
      this.$refs.modalShortcut.handleDialog()
    },
    hasCreatePermission(key) {
      return !!key
    },
    handleKeyDown(event) {
      const isBody = event.target.localName == 'body'

      if (event.ctrlKey && ['0', '1', 'f'].includes(event.key) && isBody) {
        event.preventDefault()

        if (event.key == '0') return this.restoreScale()
        if (event.key == '1') return this.restoreScale()
        if (event.key == 'f') return this.focusSearch()
      }

      if (['c', 'o', 'k', 'i'].includes(event.key) && !this.drawer && isBody)
        return this.openDrawerNewNode(event.key)
    },
  },
  inject: ['restoreScale'],
  created() {
    document.addEventListener('keydown', this.handleKeyDown)
  },
}
</script>
<style lang="scss" scoped src="../style.scss" />
