<template>
  <div data-test-redirect-link id="redirectlink" class="d-flex">
    <a data-test-redirect-link-link class="label" :href="data.url">
      {{ data.label }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
