<template>
  <div class="group-imput"></div>
</template>

<script>
export default {
  name: 'MenuPerformancePage',
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
