<template>
  <Modal
    data-test-modal-permissions-page-delete-template
    ref="modal"
    :title="$t('PermissionsPage.deleteTemplate.title')"
    :confirmButton="$t('PermissionsPage.deleteTemplate.submit')"
    :hasButtons="true"
    width="39.625rem"
    @confirmed="confirmed"
    @cancel="canceled"
  >
    <template v-slot:no-tab>
      <div class="container-content">
        <span
          class="sure-text"
          v-text="$t('PermissionsPage.deleteTemplate.sureText')"
        ></span>
        <span class="text-remind-you">
          <span
            class="c-pink"
            v-text="$t('PermissionsPage.deleteTemplate.remembering')"
          ></span>
          <span
            v-text="' ' + $t('PermissionsPage.deleteTemplate.textRemindYou')"
          ></span>
        </span>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'DeleteTemplate',

  data() {
    return {
      template: [],
    }
  },
  methods: {
    confirmed() {
      this.$emit('deleteTemplateSubmit', this.template)
    },
    canceled() {
      this.template = []
      this.$emit('open-close-template-menu', true)
      this.$refs.modal.handleDialog(false)
    },

    handleOpenDialog(event) {
      if (this.$refs.modal) {
        this.template = event
        this.$refs.modal.handleDialog(true)
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
