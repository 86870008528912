<template>
  <div class="hierarchy-elements__container" data-test-hierarchy-elements>
    <div
      class="hierarchy-elements__body"
      v-for="(item, index) in nodes"
      :key="index"
    >
      <GroupDetail :item="item" :have-more="!!nodes[index + 1]" />
    </div>
  </div>
</template>

<script>
import GroupDetail from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/GroupDetail/GroupDetail'

export default {
  name: 'HierarchyBetweenElements',
  components: { GroupDetail },
  props: {
    nodes: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
