<template>
  <Modal
    ref="modalMoveSubgroup"
    data-test-modal-move-subgroup
    width="60%"
    :title="$t('GroupsPage.modalMoveSubgroup.title')"
    :has-tab="false"
  >
    <template #[`no-tab`]>
      <section class="dialog-move-subgroup">
        <div class="dialog-content">
          <p class="dialog-move-subgroup-info">
            <span
              v-text="`${$t('GroupsPage.modalMoveSubgroup.subtitle1')} `"
            ></span>
            <strong v-text="subgroupMoveName"></strong>
            <span
              v-text="` ${$t('GroupsPage.modalMoveSubgroup.subtitle2')} `"
            ></span>
            <strong
              v-text="$t('GroupsPage.modalMoveSubgroup.subtitle3')"
            ></strong>
          </p>
          <br />
          <p class="dialog-move-subgroup-subtitle">
            <strong
              v-text="`${$t('GroupsPage.modalMoveSubgroup.rememberLabel')} `"
            ></strong>
            <span v-text="$t('GroupsPage.modalMoveSubgroup.subtitle4')"></span>
          </p>
        </div>

        <v-divider class="divider-neutral"></v-divider>

        <div
          class="dialog-footer dialog-move-subgroup-actions d-flex flex-wrap justify-center align-center"
        >
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

          <v-btn
            class="text-none neutral5--text"
            text
            elevation="0"
            @click="$emit('cancel')"
          >
            <span v-text="$t('GroupsPage.modalMoveSubgroup.btnCancel')"></span>
          </v-btn>

          <v-btn
            class="text-none"
            elevation="0"
            color="primary"
            @click="$emit('confirm')"
          >
            <span v-text="$t('GroupsPage.modalMoveSubgroup.btnOK')"></span>
          </v-btn>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ModalMoveSubgroup',
  props: {
    subgroupMoveName: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleDialog() {
      this.$refs.modalMoveSubgroup.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
