<template>
  <div id="chipgroup" class="d-flex" data-test-chip-group>
    <!-- chip groups -->
    <div v-if="data.length > 0" class="d-flex">
      <!-- first -->
      <v-chip class="custom-chip mr-1" color="neutral8">
        <span class="custom-chip-label">{{ data[0] }}</span>
      </v-chip>

      <!-- more groups -->
      <v-tooltip v-if="data.length > 1" bottom z-index="300" max-width="12rem">
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-bind="attrs" v-on="on" class="custom-chip" color="neutral8">
            <span class="custom-chip-label"> + {{ data.length - 1 }} </span>
          </v-chip>
        </template>

        <div class="chip-tooltip">
          <span>{{ array.toString().replace(/,/g, ', ') }}</span>
        </div>
      </v-tooltip>
    </div>

    <!-- empty -->
    <span v-else class="label">
      <v-chip class="custom-chip mr-1" color="neutral8">
        <span class="custom-chip-label">--</span>
      </v-chip>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  computed: {
    array() {
      return this.data.length > 1 ? this.data.slice(1) : []
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
