import { render, staticRenderFns } from "./ModalMoveSubgroup.vue?vue&type=template&id=128f4890&scoped=true&"
import script from "./ModalMoveSubgroup.vue?vue&type=script&lang=js&"
export * from "./ModalMoveSubgroup.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=128f4890&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128f4890",
  null
  
)

export default component.exports