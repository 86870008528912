import { fetchAccountNode } from '@/services/groups'

function initialState() {
  return {
    id: null,
    root: true,
    name: '',
    image: '',
    inactive: false,
    peopleCount: 0,
    description: '',
    parentGroupID: null,
    childrenCount: 0,
    children: [],
  }
}

const initial = initialState()

const getters = {
  account: state => state,
}

const actions = {
  async fetch({ commit }, peopleCount) {
    const account = await fetchAccountNode(peopleCount)

    commit('LOAD_CURRENT_ACCOUNT', account)
  },
  changeDescription({ commit }, description) {
    commit('CHANGE_DESCRIPTION', description)
  },
}

const mutations = {
  async LOAD_CURRENT_ACCOUNT(state, account) {
    for (let key in state) {
      state[key] = account[key]
    }
  },
  CHANGE_DESCRIPTION(state, description) {
    state.description = description
  },
}

export default {
  namespaced: true,
  state: initial,
  actions,
  getters,
  mutations,
}
