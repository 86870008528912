<template>
  <div id="actionbuttons" data-test-action-buttons>
    <div v-if="data.length > 0" class="d-flex container-buttons">
      <v-btn
        v-for="(item, i) in data"
        class="bttn transparent"
        :class="item"
        :key="i"
        small
        icon
        :data-test-action-buttons-icon="item"
        data-test-action-buttons-option
        @click="submit(item)"
      >
        <i class="d-flex align-center" :class="handleIcon(item)"></i>
      </v-btn>

      <!-- menu slot -->
      <v-menu v-model="menu" offset-y z-index="99">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" ref="menu" />
        </template>

        <v-card>
          <slot name="menu" />
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionButtons',
  data() {
    return {
      menu: false,
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    submit(event) {
      if (event === 'menu') this.menu = true
      this.$emit('submit', event)
    },

    handleIcon(value) {
      switch (value) {
        case 'accepted':
        case 'accepted-true':
        case 'positive':
          return `fi fi-rr-social-network ${value}`
        case 'rejected':
        case 'rejected-true':
        case 'negative':
          return `fi fi-rr-hands ${value}`

        case 'send':
          return 'fi fi-rr-paper-plane'
        case 'delete':
          return 'fi fi-rr-cross-circle'
        case 'remove':
          return 'fi fi-rr-trash'
        case 'menu':
          return 'fi fi-rr-menu-dots-vertical'
        case 'edit':
          return 'fi fi-rr-pencil'
        case 'people':
          return 'fi fi-rr-users'
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
