<template>
  <footer data-test-form-footer class="form-footer">
    <v-divider class="neutral-divider"></v-divider>

    <div class="form-footer__actions">
      <v-spacer></v-spacer>

      <div class="form-footer--actions">
        <v-btn
          elevation="0"
          text
          class="form-footer__cancel"
          @click="$emit('cancel')"
        >
          <span v-text="$t('EngagementPage.cancel')"></span>
        </v-btn>

        <v-btn
          elevation="0"
          color="primary"
          class="form-footer__save"
          @click="$emit('submit')"
        >
          <template v-if="saving">
            <v-progress-circular
              indeterminate
              color="white"
              size="16"
              width="2"
            ></v-progress-circular>
          </template>

          <template v-else>
            <span v-text="$t('EngagementPage.submit')"></span>
          </template>
        </v-btn>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FormFooter',
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
