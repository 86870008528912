<template>
  <div>
    <v-hover v-show="!editMode" v-slot="{ hover }">
      <v-chip
        class="custom-chip"
        :disabled="data.disabled"
        data-test-chip-edit
        @click="handleEdit()"
      >
        <span class="custom-chip-label" data-test-chip-edit-value>
          {{ currentValue }}
        </span>

        <i v-if="!data.disabled && hover" class="fi-rr-pencil" />
      </v-chip>
    </v-hover>

    <v-text-field
      v-if="editMode"
      v-click-outside="handleEdit"
      v-model="newValue"
      color="primary"
      :full-width="false"
      hide-details
      data-test-input-edit
      dense
      :style="{ maxWidth: '100% !important', width: 'max-content' }"
      @keydown="keydown($event)"
    >
      <template v-slot:append-outer>
        <i class="fi-rr-cross" data-test-cancel-edit @click="handleEdit()" />
        <i class="fi-rr-check" data-test-confirm-edit @click="confirmEdit()" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'ChipEdit',

  data() {
    return {
      editMode: false,
      currentValue: '',
      newValue: '',
    }
  },

  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
        disabled: false,
        lockChangeLocally: false,
      }),
    },

    inputMode: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  mounted() {
    this.changeValue(this.data.value)
  },

  watch: {
    'data.value': {
      handler(val) {
        this.changeValue(val)
      },
      deep: true,
    },
  },

  methods: {
    keydown(event) {
      if (event.key !== 'Enter') return
      if (event.key === 'Enter') this.confirmEdit()
    },
    handleEdit() {
      this.newValue = this.currentValue
      this.editMode = !this.editMode
    },

    confirmEdit() {
      this.$emit('update', { value: this.newValue })

      if (!this.data.lockChangeLocally) this.changeValue(this.newValue)
      this.handleEdit()
    },

    changeValue(val) {
      this.currentValue = val
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
